import i18next from 'i18next';
import icons from '../components/subscription-promo/icons';
import platformComponents from '../../user/constants/platform-components';

const { SitebuilderIcon, FreeDomainIcon, SeoToolsIcon, CustomerSupportIcon, EcommerceIcon } = icons;

const getSubscriptionPromoItems = (availablePlatformComponents, hasFreeDomainPromo) => {
  const availableItems = [
    {
      id: 'sitebuilder',
      IconComponent: SitebuilderIcon,
      title: i18next.t('Sitebuilder'),
      description: i18next.t(
        'Create a beautiful, functional website without any coding skills needed'
      ),
    },
  ];

  if (availablePlatformComponents.includes(platformComponents.DOMAINS) && hasFreeDomainPromo) {
    availableItems.push({
      id: 'free-domain',
      IconComponent: FreeDomainIcon,
      title: i18next.t('Free domain'),
      description: i18next.t('Get a free custom domain for a year with an annual subscription'),
    });
  }

  if (availablePlatformComponents.includes(platformComponents.SITEWIT)) {
    availableItems.push({
      id: 'seo-tools',
      IconComponent: SeoToolsIcon,
      title: i18next.t('SEO Tools'),
      description: i18next.t(
        "Improve your website's search engine ranking and attract more visitors"
      ),
    });
  }

  availableItems.push({
    id: 'customer-support',
    IconComponent: CustomerSupportIcon,
    title: i18next.t('Customer support'),
    description: i18next.t(
      'Get help from our friendly Customer support for any questions or issues'
    ),
  });

  if (availablePlatformComponents.includes(platformComponents.ECOMMERCE)) {
    availableItems.push({
      id: 'ecommerce',
      IconComponent: EcommerceIcon,
      title: i18next.t('E-commerce'),
      description: i18next.t('Start selling your products online quickly and easily'),
    });
  }

  return availableItems;
};

export default getSubscriptionPromoItems;
