import React, { useMemo } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import user from 'src/js/modules/user';
import CopyrightFooter from '../components/copyright-footer';

const year = new Date().getFullYear();

function CopyrightFooterContainer() {
  const partnerName = useSelector(user.selectors.getPartnerName);
  const privacyUrl = useSelector(user.selectors.getPrivacyUrl);
  const termsUrl = useSelector(user.selectors.getTermsUrl);

  const links = useMemo(() => {
    const result = [];

    if (privacyUrl) {
      result.push({
        href: privacyUrl,
        target: '_blank',
        title: i18next.t('Privacy Policy'),
      });
    }

    if (termsUrl) {
      result.push({
        href: termsUrl,
        target: '_blank',
        title: i18next.t('Terms of Service'),
      });
    }

    return result;
  }, [privacyUrl, termsUrl]);

  return (
    <CopyrightFooter
      captions={{
        copyright: i18next.t('Copyright {year} {partnerName}. All rights reserved', {
          year,
          partnerName,
        }),
      }}
      links={links}
    />
  );
}

export default CopyrightFooterContainer;
