import { useSelector } from 'react-redux';
import i18n from 'src/js/modules/i18n';

const defaultOptions = { day: 'numeric', month: 'short', year: 'numeric' };

const useLocaleDate = (date, options = defaultOptions) => {
  const locale = useSelector(i18n.selectors.getLocale);

  return new Date(date).toLocaleDateString(locale, options);
};

export default useLocaleDate;
