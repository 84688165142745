import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { Panel, PanelGroup, Modal, designSystem } from '@yola/ws-ui';
import sites from 'src/js/modules/sites';
import error from 'src/js/modules/error';
import DialogFooter from '../components/dialog-footer';
import actions from '../actions';

const { Box, Heading, Paragraph, ActionButton, InputGroup, DialogHeader, DialogHeaderTitle } =
  designSystem;

const MODAL_WIDTH = 480;
const CONFIRMATION_KEY_WORLD = 'DELETE';

function DeleteSiteDialogContainer(props) {
  const { siteId } = props;
  const [inputValue, setInputValue] = useState('');
  const [isDeleting, setDeletingState] = useState(false);
  const dispatch = useDispatch();

  const closeDialog = useCallback(() => {
    dispatch(actions.hide());
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    setDeletingState(true);

    dispatch(sites.thunks.deleteSite(siteId))
      .then(() => {
        closeDialog();
      })
      .catch((err) => {
        dispatch(error.actions.setCriticalError({ error: err }));
      });
  }, [siteId, closeDialog, dispatch]);

  return (
    <Modal
      isBodyScrollDisabled
      width={MODAL_WIDTH}
      maxWidth={MODAL_WIDTH}
      minWidth={MODAL_WIDTH}
      height="auto"
      centered
      resizable={false}
      draggable={false}
      overlay="visible"
    >
      <PanelGroup height="100%">
        <Panel height="48" align="middle" corners="squared" style={{ flexShrink: '0' }}>
          <DialogHeader>
            <DialogHeaderTitle>{i18next.t('Delete site?')}</DialogHeaderTitle>
          </DialogHeader>
        </Panel>
        <Panel height="100%" scrollable>
          <Box
            margintTop="spacing-s"
            margintBottom="spacing-s"
            margintLeft="spacing-m"
            margintRight="spacing-m"
          >
            <Heading type="heading-6">{i18next.t('You are about to delete this site')}</Heading>
            <Box margintTop="spacing-4-xs">
              <Paragraph appearance="medium-emphasis" size="small">
                {i18next.t('Type')} `
                <strong style={{ color: 'var(--token-C031--color-text--high-emphasis)' }}>
                  {CONFIRMATION_KEY_WORLD}
                </strong>
                ` {i18next.t('to confirm:')}
              </Paragraph>
            </Box>
            <Box margintTop="spacing-2-xs">
              <InputGroup
                placeholder={CONFIRMATION_KEY_WORLD}
                value={inputValue}
                onChange={setInputValue}
                disabled={isDeleting}
              />
            </Box>
            <Box margintTop="spacing-3-xs">
              <Paragraph appearance="medium-emphasis" size="small">
                {i18next.t(
                  'This deletes the site with all its content. This action cannot be undone.'
                )}
              </Paragraph>
            </Box>
          </Box>
        </Panel>
        <Panel>
          <DialogFooter>
            <ActionButton
              label={i18next.t('Cancel')}
              appearance="default"
              onClick={closeDialog}
              disabled={isDeleting}
            />

            <ActionButton
              label={i18next.t('Delete site')}
              disabled={inputValue.toUpperCase() !== CONFIRMATION_KEY_WORLD}
              appearance="danger"
              format="solid"
              onClick={onSubmit}
              isLoading={isDeleting}
            />
          </DialogFooter>
        </Panel>
      </PanelGroup>
    </Modal>
  );
}

DeleteSiteDialogContainer.propTypes = {
  siteId: PropTypes.string.isRequired,
};

export default DeleteSiteDialogContainer;
