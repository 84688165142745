import actionTypes from '../constants/action-types';

const resetStatus = (name) => ({
  type: actionTypes.RESET_STATUS,
  payload: {
    name,
  },
});

export default resetStatus;
