import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import SectionInfo from 'src/js/common/components/section-info';
import fieldNames from 'src/js/modules/profile/constants/field-names';

const {
  ControlGroup,
  ActionButton,
  Col,
  Row,
  Stack,
  Box,
  NotificationMessage,
  InputGroupField,
  SimpleSelectField,
} = designSystem;

const { NAME, SURNAME, LOCATION, PRIMARY_PHONE, MOBILE_PHONE, OTHER_PHONE } = fieldNames;

function PersonalInfoSection(props) {
  const {
    captions,
    control,
    locationOptions,
    isLoading,
    arePhonesAvailable,
    onSubmit,
    notification,
    firstNameRules,
    lastNameRules,
    locationRules,
    phoneRules,
  } = props;

  return (
    <SectionInfo title={captions.title}>
      <Stack gap="spacing-m">
        {notification && (
          <NotificationMessage appearance={notification.appearance} title={notification.title} />
        )}
        <ControlGroup title={captions[NAME].label}>
          <InputGroupField
            control={control}
            name={NAME}
            placeholder={captions[NAME].placeholder}
            size="large"
            rules={firstNameRules}
          />
        </ControlGroup>
        <ControlGroup title={captions[SURNAME].label}>
          <InputGroupField
            control={control}
            name={SURNAME}
            placeholder={captions[SURNAME].placeholder}
            size="large"
            rules={lastNameRules}
          />
        </ControlGroup>
        <ControlGroup title={captions[LOCATION].label}>
          <SimpleSelectField
            control={control}
            name={LOCATION}
            options={locationOptions}
            size="large"
            rules={locationRules}
          />
        </ControlGroup>
        {arePhonesAvailable && (
          <Row className="g-3">
            <Col>
              <ControlGroup title={captions[PRIMARY_PHONE].label}>
                <InputGroupField
                  control={control}
                  name={PRIMARY_PHONE}
                  size="large"
                  rules={phoneRules}
                />
              </ControlGroup>
            </Col>
            <Col>
              <ControlGroup title={captions[MOBILE_PHONE].label}>
                <InputGroupField
                  control={control}
                  name={MOBILE_PHONE}
                  size="large"
                  rules={phoneRules}
                />
              </ControlGroup>
            </Col>
            <Col>
              <ControlGroup title={captions[OTHER_PHONE].label}>
                <InputGroupField
                  control={control}
                  name={OTHER_PHONE}
                  size="large"
                  rules={phoneRules}
                />
              </ControlGroup>
            </Col>
          </Row>
        )}
        <Box>
          <ActionButton
            size="large"
            appearance="accent"
            format="solid"
            isLoading={isLoading}
            fontWeight="regular"
            onClick={onSubmit}
            label={captions.submit}
          />
        </Box>
      </Stack>
    </SectionInfo>
  );
}

PersonalInfoSection.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
    [NAME]: PropTypes.shape({
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
    }).isRequired,
    [SURNAME]: PropTypes.shape({
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
    }).isRequired,
    [LOCATION]: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
    [PRIMARY_PHONE]: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
    [MOBILE_PHONE]: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
    [OTHER_PHONE]: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  control: PropTypes.object.isRequired,
  locationOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string.isRequired, label: PropTypes.string.isRequired })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  arePhonesAvailable: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  notification: PropTypes.shape({
    title: PropTypes.string,
    appearance: PropTypes.string,
  }),
  firstNameRules: PropTypes.object,
  lastNameRules: PropTypes.object,
  locationRules: PropTypes.object,
  phoneRules: PropTypes.object,
};

PersonalInfoSection.defaultProps = {
  notification: null,
  firstNameRules: {},
  lastNameRules: {},
  locationRules: {},
  phoneRules: {},
};

export default PersonalInfoSection;
