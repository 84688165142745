import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ src, alt, width, height, onClick, href }) => {
  const Component = href ? 'a' : 'div';

  return (
    <Component onClick={onClick} href={href} className="ws-my-navbar__logo">
      <img src={src} width={width} height={height} alt={alt} />
    </Component>
  );
};

Logo.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
};

Logo.defaultProps = {
  alt: '',
  onClick: null,
  href: null,
};

export default Logo;
