import { serviceProvider } from '@yola/subscription-manager-js';
import status from 'src/js/modules/status';
import statusNames from '../constants/status-names';

const cancelSubscription = (subscriptionId) => async (dispatch) => {
  dispatch(status.actions.setStatus(statusNames.CANCEL_SUBSCRIPTION, status.constants.LOADING));

  try {
    await serviceProvider.cancelSubscription(subscriptionId);
    dispatch(status.actions.setStatus(statusNames.CANCEL_SUBSCRIPTION, status.constants.SUCCEEDED));
  } catch (error) {
    console.error(error);
    dispatch(status.actions.setStatus(statusNames.CANCEL_SUBSCRIPTION, status.constants.FAILED));

    throw error;
  }
};

export default cancelSubscription;
