import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import user from 'src/js/modules/user';

const { platformComponents } = user.constants;
const { ActionButton, Heading, Paragraph } = designSystem;

function Site(props) {
  const {
    siteData,
    onDelete,
    onBuyDomain,
    publishSiteUrl,
    availablePlatformComponents,
    onPreviewClick,
    onDomainSettingsClick,
  } = props;

  const { previewUrl, sitebuilderUrl, name, displayVhost, domainUpsell } = siteData;

  const stopPropagation = (event) => event.stopPropagation();

  const handleDelete = (event) => {
    stopPropagation(event);
    onDelete();
  };

  return (
    <div className="ws-site">
      <div className="ws-site__container">
        <div className="ws-site__preview" onClick={onPreviewClick}>
          {previewUrl && <img src={previewUrl} alt="template-preview" />}
        </div>

        <div className="ws-site__preview-actions">
          <ActionButton
            size="small"
            target="_self"
            href={sitebuilderUrl}
            label={i18next.t('Edit site')}
            onClick={stopPropagation}
          />

          <ActionButton size="small" iconGlyph="trash" appearance="danger" onClick={handleDelete} />
        </div>
      </div>

      <div className="ws-site__details">
        <div className="ws-site__details-site-name">
          <Heading type="heading-5">{name}</Heading>
        </div>

        <div className="ws-site__details-domain">
          {displayVhost && (
            <React.Fragment>
              <Paragraph appearance="accent" isClipped>
                <a href={`https://${displayVhost}`} style={{ textDecoration: 'none' }}>
                  {displayVhost}
                </a>
              </Paragraph>

              {availablePlatformComponents.includes(platformComponents.DOMAINS) && (
                <React.Fragment>
                  <Paragraph appearance="medium-emphasis">-</Paragraph>

                  <ActionButton
                    size="small"
                    format="text"
                    onClick={() => onDomainSettingsClick(siteData)}
                    label={i18next.t('Settings')}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          {!displayVhost && (
            <React.Fragment>
              <Paragraph appearance="medium-emphasis">{i18next.t('Not published')}</Paragraph>

              {publishSiteUrl && (
                <React.Fragment>
                  <Paragraph appearance="medium-emphasis">-</Paragraph>

                  <ActionButton
                    size="small"
                    format="text"
                    href={publishSiteUrl}
                    label={i18next.t('Publish site')}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>

      {domainUpsell && availablePlatformComponents.includes(platformComponents.DOMAINS) && (
        <div className="ws-site__upsell">
          <div className="ws-site__upsell-details">
            <Heading type="heading-6" appearance="accent" singleLine>
              www.{domainUpsell}.com
            </Heading>
            <Paragraph>{i18next.t('might be available')}</Paragraph>
          </div>

          <ActionButton
            appearance="accent"
            format="solid"
            label={i18next.t('Buy now')}
            onClick={() => onBuyDomain(domainUpsell)}
          />
        </div>
      )}
    </div>
  );
}

Site.propTypes = {
  availablePlatformComponents: PropTypes.arrayOf(PropTypes.string),
  onPreviewClick: PropTypes.func,
  onDelete: PropTypes.func,
  onBuyDomain: PropTypes.func,
  siteData: PropTypes.shape({
    previewUrl: PropTypes.string,
    sitebuilderUrl: PropTypes.string,
    name: PropTypes.string,
    displayVhost: PropTypes.string,
    publishSiteUrl: PropTypes.string,
    domainUpsell: PropTypes.string,
  }).isRequired,
  publishSiteUrl: PropTypes.string,
  onDomainSettingsClick: PropTypes.func.isRequired,
};

Site.defaultProps = {
  availablePlatformComponents: [],
  onPreviewClick: Function.prototype,
  onDelete: Function.prototype,
  onBuyDomain: Function.prototype,
  publishSiteUrl: null,
};

export default Site;
