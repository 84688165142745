const OTHER_METHOD = 'other';

function computePaymentMethodTrait(method) {
  // methods which analytics validation expects
  const supportedMethods = [
    'visa',
    'mastercard',
    'paypal',
    'amex',
    'discover',
    'boleto',
    'unionpay',
    'jcb',
    'diners',
  ];

  if (supportedMethods.includes(method)) {
    return method;
  }

  return OTHER_METHOD;
}

export default computePaymentMethodTrait;
