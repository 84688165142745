import store from 'src/js/store';
import selectors from '../selectors';

const getNumberOfSites = () => {
  const state = store.getState();
  const sites = selectors.getSites(state);

  const { numberOfLegacySites, numberOfLatitudeSites } = sites.reduce(
    (acc, siteData) => {
      if (siteData.isWs) {
        acc.numberOfLatitudeSites += 1;
      } else {
        acc.numberOfLegacySites += 1;
      }
      return acc;
    },
    {
      numberOfLegacySites: 0,
      numberOfLatitudeSites: 0,
    }
  );

  return {
    numberOfLegacySites,
    numberOfLatitudeSites,
    total: sites.length,
  };
};

export default getNumberOfSites;
