import i18next from 'i18next';
import ICU from 'i18next-icu';
import en from 'i18next-icu/locale-data/en';
import de from 'i18next-icu/locale-data/de';
import es from 'i18next-icu/locale-data/es';
import fr from 'i18next-icu/locale-data/fr';
import pt from 'i18next-icu/locale-data/pt';
import ru from 'i18next-icu/locale-data/ru';

const initializeI18next = () =>
  i18next.use(ICU).init({
    i18nFormat: {
      localeData: [en, de, es, fr, pt, ru],
    },
    keySeparator: false,
    lowerCaseLng: true,
    nsSeparator: false,
  });

export default initializeI18next;
