import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Heading, Box, Divider } = designSystem;

function SectionInfo(props) {
  const { children, title, titleAppearance } = props;

  return (
    <div className="ws-section-info">
      <Box margin="spacing-m" marginTop="spacing-xs" marginBottom="spacing-xs">
        <Heading type="heading-5" appearance={titleAppearance}>
          {title}
        </Heading>
      </Box>
      <Divider />
      <Box margin="spacing-m" marginTop="spacing-s" marginBottom="spacing-s">
        {children}
      </Box>
    </div>
  );
}

SectionInfo.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  titleAppearance: PropTypes.oneOf(['default', 'danger']),
};

SectionInfo.defaultProps = {
  titleAppearance: 'default',
};

export default SectionInfo;
