import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import products from '../../products';
import dialogs from '../../dialogs';
import segment from '../../analytics/segment';
import getUserPreferences from '../../user/selectors/user-preferences';
import fieldNames, { defaultFormData } from '../constants/form-field-names';
import { dialogIds } from '../constants/analytics';

const {
  track,
  constants: { events, triggerIds },
} = segment;

const AddPaymentMethodRoute = ({ location, closeUrl: defaultCloseUrl, isFirstPaymentMethod }) => {
  const dispatch = useDispatch();
  const { location: userCountry } = useSelector(getUserPreferences, shallowEqual);
  const closeUrl = get(location, 'state.closeUrl', defaultCloseUrl);
  const successUrl = get(location, 'state.successUrl', defaultCloseUrl);
  const closeUrlState = get(location, 'state.closeState', {});

  const submitAddPaymentMethod = async (formData) => {
    const response = await dispatch(products.thunks.createBillingProfile(formData));

    track(events.PAYMENT_METHOD_DIALOG_SUBMITTED, {
      dialogId: dialogIds.ADD_NEW_PAYMENT_METHOD,
      triggerId: triggerIds.ADD_PAYMENT_METHOD,
      default: response.isDefault,
      paymentMethod: response.cardType || response.type,
    });

    dispatch(push(successUrl, closeUrlState));
  };

  const cancelAddPaymentMethod = () => {
    track(events.PAYMENT_METHOD_DIALOG_CANCELLED, {
      dialogId: dialogIds.ADD_NEW_PAYMENT_METHOD,
      triggerId: triggerIds.ADD_PAYMENT_METHOD,
    });

    dispatch(push(closeUrl, closeUrlState));
  };

  useEffect(() => {
    const formData = {
      ...defaultFormData,
      [fieldNames.COUNTRY_CODE]: userCountry,
    };
    const disabledFields = [];

    if (isFirstPaymentMethod) {
      formData[fieldNames.MAKE_DEFAULT] = true;
      disabledFields.push(fieldNames.MAKE_DEFAULT);
    }

    dispatch(
      dialogs.actions.show(dialogs.dialogTypes.PAYMENT_METHOD_FORM, {
        onSave: submitAddPaymentMethod,
        onCancel: cancelAddPaymentMethod,
        formData,
        statusName: products.statusNames.CREATE_BILLING_PROFILE,
        disabledFields,
      })
    );

    track(events.PAYMENT_METHOD_DIALOG_DISPLAYED, {
      dialogId: dialogIds.ADD_NEW_PAYMENT_METHOD,
      triggerId: triggerIds.ADD_PAYMENT_METHOD,
    });

    return () => dispatch(dialogs.actions.hide());
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return null;
};

AddPaymentMethodRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({
      isCloseModalOnRouteChange: PropTypes.bool,
    }),
  }).isRequired,
  closeUrl: PropTypes.string.isRequired,
  isFirstPaymentMethod: PropTypes.bool,
};

AddPaymentMethodRoute.defaultProps = {
  isFirstPaymentMethod: false,
};

export default AddPaymentMethodRoute;
