const fieldNames = {
  CARD_NUMBER: 'cardNumber',
  EXPIRATION_DATE: 'expirationDate',
  VERIFICATION: 'verification',
  NAME: 'name',
  COUNTRY_CODE: 'countryCode',
  STATE: 'state',
  CITY: 'city',
  ADDRESS1: 'address1',
  POSTAL_CODE: 'postalCode',
  MAKE_DEFAULT: 'makeDefault',
};

export const defaultFormData = {
  [fieldNames.CARD_NUMBER]: '',
  [fieldNames.EXPIRATION_DATE]: '',
  [fieldNames.VERIFICATION]: '',
  [fieldNames.NAME]: '',
  [fieldNames.COUNTRY_CODE]: 'US',
  [fieldNames.STATE]: '',
  [fieldNames.CITY]: '',
  [fieldNames.ADDRESS1]: '',
  [fieldNames.POSTAL_CODE]: '',
  [fieldNames.MAKE_DEFAULT]: false,
};

export default fieldNames;
