import actionTypes from '../constants/action-types';

const setLocale = (locale) => ({
  type: actionTypes.SET_LOCALE,
  payload: {
    locale,
  },
});

export default setLocale;
