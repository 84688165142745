import { batch } from 'react-redux';
import yousersjs from '@yola/yousersjs';
import utils from 'src/js/utils';
import user from 'src/js/modules/user';
import status from 'src/js/modules/status';
import { snakeCaseObjectToCamelCase } from 'src/js/utils/convert-case';
import config from 'src/js/modules/config';
import analytics from 'src/js/modules/analytics';
import statusNames from '../constants/status-names';

const fetchUserData = () => async (dispatch, getState) => {
  try {
    dispatch(status.actions.setStatus(statusNames.USER_DATA, status.constants.LOADING));

    const response = await yousersjs.authenticate();
    const data = snakeCaseObjectToCamelCase(response.body);

    analytics.sentry.instance.setUserId(data.id);
    batch(() => {
      dispatch(status.actions.setStatus(statusNames.USER_DATA, status.constants.SUCCEEDED));
      dispatch(user.actions.setUserData(data));
    });
  } catch (e) {
    dispatch(status.actions.setStatus(statusNames.USER_DATA, status.constants.FAILED));

    if (e.response) {
      if (e.response.xhr.status === 401) {
        const loginUrl = config.selectors.getLoginUrl(getState());
        const wsLoginUrlWithParams = new URL(loginUrl);
        const urlToRedirect = window.location.href;
        wsLoginUrlWithParams.searchParams.append('next', urlToRedirect);

        utils.redirectToUrl(wsLoginUrlWithParams.toString());
      }
    }

    throw e;
  }
};

export default fetchUserData;
