import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { designSystem } from '@yola/ws-ui';
import { selectors, constants, verifiers } from '@yola/subscription-manager-js';
import user from '../../user';
import products from '../../products';
import { PER_MONTH, PER_YEAR } from '../../common/constants/terms';
import useDomainsExtraPrice from '../../domain-selector/hooks/use-domains-extra-price';
import useFeatureFlags from '../../feature-flags/hooks/use-feature-flags';
import bannerTypes from '../constants/banner-types';
import registeredBanners from '../helpers/banners-map';
import registerBanners from '../initializers/register-banners';
import extraPriceTypes from '../../domain-selector/constants/extra-price-types';

const { Stack } = designSystem;
const { platformComponents } = user.constants;
const { B2C_ECOMMERCE_UNLIMITED, WL_ECOMMERCE_UNLIMITED } = constants.SubscriptionType;

const LARGEST_ECOMMERCE_SUBSCRIPTION_TYPES = [B2C_ECOMMERCE_UNLIMITED, WL_ECOMMERCE_UNLIMITED];

const UpsellBannersContainer = () => {
  const availablePlatformComponents = user.hooks.useAvailablePlatformComponents();
  const prices = useSelector(products.selectors.getPrices, shallowEqual);
  const activeSubscriptions = useSelector(selectors.getActiveSubscriptions, shallowEqual);
  const domainPurchaseAvailable = useSelector(
    user.selectors.getDomainPurchaseAvailable,
    shallowEqual
  );
  const isB2C = useSelector(user.selectors.getIsB2C);
  const userSubscriptionType = useSelector(selectors.getCurrentPackageType);

  const extraPrice = useDomainsExtraPrice();

  const [featureFlags] = useFeatureFlags(['freedomain']);

  const activeBanners = useMemo(() => {
    const banners = [];
    const { term: subscriptionTerm } =
      activeSubscriptions.find(({ type }) => type === userSubscriptionType) || {};

    if (!Object.keys(featureFlags).length) {
      return banners;
    }

    if (availablePlatformComponents.includes(platformComponents.PAYMENTS)) {
      if (verifiers.isFreeHostingPackageType(userSubscriptionType)) {
        banners.push([bannerTypes.UPGRADE_SUBSCRIPTION_BANNER]);
      } else {
        const isAnnualSubscriptionAvailable = Boolean(prices[userSubscriptionType]?.[PER_YEAR]);

        if (subscriptionTerm === PER_MONTH && isAnnualSubscriptionAvailable) {
          banners.push([
            bannerTypes.SWITCH_TO_ANNUAL_BANNER,
            { subscriptionType: userSubscriptionType, isB2C },
          ]);
        }
      }
    }

    if (availablePlatformComponents.includes(platformComponents.ECOMMERCE)) {
      const hasLargestEcommerceSubscription = activeSubscriptions.some(({ type }) =>
        LARGEST_ECOMMERCE_SUBSCRIPTION_TYPES.includes(type)
      );

      if (!hasLargestEcommerceSubscription) {
        banners.push([bannerTypes.ONLINE_STORE_BANNER]);
      }
    }

    if (
      availablePlatformComponents.includes(platformComponents.DOMAINS) &&
      domainPurchaseAvailable
    ) {
      const isFreeDomainPromoAvailable = extraPrice === extraPriceTypes.NO_DISCOUNTS;

      banners.push([bannerTypes.CUSTOM_DOMAIN_BANNER, { isFreeDomainPromoAvailable }]);
    }

    return banners;
  }, [
    activeSubscriptions,
    availablePlatformComponents,
    domainPurchaseAvailable,
    extraPrice,
    isB2C,
    prices,
    userSubscriptionType,
    featureFlags,
  ]);

  if (!activeBanners.length) return null;

  return (
    <div className="ws-upsell-banners-container">
      <Stack gap="spacing-xs">
        {activeBanners.map(([bannerType, bannerProps = {}]) => {
          const BannerComponent = registeredBanners.get(bannerType);
          return BannerComponent && <BannerComponent key={bannerType} {...bannerProps} />;
        })}
      </Stack>
    </div>
  );
};

registerBanners();

export default React.memo(UpsellBannersContainer);
