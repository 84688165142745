import React from 'react';

const DifmIcon = () => (
  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="96" height="96" rx="48" fill="#F8F3C5" />
    <path
      d="M76.8001 40.0002H19.2002V24.0002C19.2002 21.349 21.349 19.2002 24.0002 19.2002H72.0001C74.6513 19.2002 76.8001 21.349 76.8001 24.0002V40.0002Z"
      fill="#455A64"
    />
    <path
      d="M19.2002 35.2002H76.8001V72.0002C76.8001 74.6514 74.6513 76.8001 72.0001 76.8001H24.0002C21.349 76.8001 19.2002 74.6514 19.2002 72.0002V35.2002Z"
      fill="#BBDEFB"
    />
    <path
      d="M27.2 24C26.3513 24 25.5374 24.3371 24.9373 24.9373C24.3371 25.5374 24 26.3513 24 27.2C24 28.0487 24.3371 28.8626 24.9373 29.4627C25.5374 30.0629 26.3513 30.4 27.2 30.4C28.0487 30.4 28.8626 30.0629 29.4627 29.4627C30.0629 28.8626 30.4 28.0487 30.4 27.2C30.4 26.3513 30.0629 25.5374 29.4627 24.9373C28.8626 24.3371 28.0487 24 27.2 24Z"
      fill="#8BC34A"
    />
    <path
      d="M36.8001 24C35.9514 24 35.1375 24.3371 34.5374 24.9373C33.9372 25.5374 33.6001 26.3513 33.6001 27.2C33.6001 28.0487 33.9372 28.8626 34.5374 29.4627C35.1375 30.0629 35.9514 30.4 36.8001 30.4C37.6488 30.4 38.4627 30.0629 39.0628 29.4627C39.6629 28.8626 40.0001 28.0487 40.0001 27.2C40.0001 26.3513 39.6629 25.5374 39.0628 24.9373C38.4627 24.3371 37.6488 24 36.8001 24Z"
      fill="#FFEB3B"
    />
    <path
      d="M72.0003 28.8C72.0003 29.6848 71.2851 30.4 70.4003 30.4H46.4003C45.5155 30.4 44.8003 29.6848 44.8003 28.8V25.6C44.8003 24.7152 45.5155 24 46.4003 24H70.4003C71.2851 24 72.0003 24.7152 72.0003 25.6V28.8Z"
      fill="#FAFAFA"
    />
    <path
      d="M24 40H51.2V57.6H24V40ZM24 62.4H51.2V72H24V62.4ZM56 40H71.9999V72H56V40Z"
      fill="#90CAF9"
    />
  </svg>
);

export default DifmIcon;
