import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function SiteList(props) {
  const { children, noSites } = props;

  return (
    <div className="ws-site-list">
      <div
        className={classNames('ws-site-list__grid', {
          'ws-site-list__grid--no-sites': noSites,
        })}
      >
        {children}
      </div>
    </div>
  );
}

SiteList.propTypes = {
  noSites: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

SiteList.defaultProps = {
  noSites: null,
};

export default SiteList;
