import { utils } from '@yola/webapp-plugins';

const generatePaymentUrl = ({ products, term, backUrl, ...restParams }, isB2C) =>
  utils.generatePaymentUrl(
    {
      products,
      term,
      ...(backUrl && {
        back_url: backUrl,
        fail_url: backUrl,
        success_url: backUrl,
        close_url: backUrl,
      }),
      ...restParams,
    },
    isB2C
  );

export default generatePaymentUrl;
