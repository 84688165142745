import actionTypes from '../constants/action-types';

const setPrices = (prices) => ({
  type: actionTypes.SET_PRICES,
  payload: {
    prices,
  },
});

export default setPrices;
