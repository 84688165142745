import get from 'lodash.get';
import isB2C from './is-b2c';

const getOnlineStoreUrl = (rootState) => {
  if (isB2C(rootState)) {
    return get(rootState, 'config.common.onlinestore.url');
  }

  return get(rootState, 'config.common.onlinestore.wl_url');
};

export default getOnlineStoreUrl;
