import React from 'react';
import { designSystem } from '@yola/ws-ui';
import useLocaleDate from 'src/js/modules/common/hooks/use-locale-date';
import { statusToAppearance, billingItemPropTypes } from './constants';
import { getCardIconType } from '../../helpers/process-billing-profile';

const { Box, Paragraph, PaymentMethod, Divider, Heading, Icon, List, ListItem } = designSystem;

const BillingItem = (props) => {
  const { date, description, paymentMethod, status, amount, currency, statusLabel } = props;
  const statusAppearance = statusToAppearance[status];
  const billingDate = useLocaleDate(date);
  const iconType = getCardIconType(paymentMethod.cardType || paymentMethod);

  return (
    <React.Fragment>
      <Box paddingTop="spacing-xs" paddingBottom="spacing-xs">
        <div className="ws-billing-item">
          <Paragraph>{billingDate}</Paragraph>
          {description.length > 1 ? (
            <List>
              {description.map((item) => (
                <ListItem key={item}>
                  <Heading type="heading-6">{item}</Heading>
                </ListItem>
              ))}
            </List>
          ) : (
            <Heading type="heading-6">{description[0]}</Heading>
          )}
          {paymentMethod ? (
            <div className="ws-billing-item__payment-method">
              <PaymentMethod type={iconType} size="tiny" />
              {paymentMethod.cardLastDigits && (
                <Paragraph>**** {paymentMethod.cardLastDigits}</Paragraph>
              )}
            </div>
          ) : (
            <Box paddingLeft="spacing-4-xs">
              <Icon glyph="minus" type="light" />
            </Box>
          )}
          <Heading type="heading-6" appearance={statusAppearance}>
            {statusLabel}
          </Heading>
          <Heading type="heading-6" align="right">
            {`${currency} ${amount}`}
          </Heading>
        </div>
      </Box>
      <Divider />
    </React.Fragment>
  );
};

BillingItem.propTypes = billingItemPropTypes;

BillingItem.defaultProps = {
  paymentMethod: '',
  statusLabel: '',
};

export default BillingItem;
