import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import getSpecifyTitle from './get-specify-title';
import getElaborateTitle from './get-elaborate-title';
import validateCheckboxGroup from './validate-checkbox-group';
import resetOtherSubReason from './reset-other-sub-reason';
import { fieldNames, primaryReasonValues, SUB_FIELD_OTHER_VALUE } from '../constants/common';

const { TextAreaField, SimpleSelectField, CheckboxGroupField } = designSystem;

const getHostingPrimaryReasonFields = ({ setFieldValue }) => ({
  name: fieldNames.PRIMARY_REASON,
  placeholder: `${i18next.t('Select a reason')}...`,
  rules: { required: true },
  Component: SimpleSelectField,
  controlGroup: {
    title: i18next.t(`What's the reason for your cancellation?`),
    required: true,
  },
  onChange: () => {
    setFieldValue(fieldNames.OTHER_PRIMARY_REASON, null);
    setFieldValue(fieldNames.SUB_REASONS, []);
    setFieldValue(fieldNames.OTHER_SUB_REASON, null);
  },
  options: [
    {
      label: i18next.t('No longer need the website'),
      value: primaryReasonValues.NO_NEED_WEBSITE,
    },
    { label: i18next.t('Too expensive'), value: primaryReasonValues.TOO_EXPENSIVE },
    {
      label: i18next.t('Can’t build a site by myself'),
      value: primaryReasonValues.CANT_BUILD_WEBSITE,
    },
    {
      label: i18next.t('Issues with Sitebuilder'),
      value: primaryReasonValues.ISSUE_WITH_BUILDER,
    },
    {
      label: i18next.t('Not seeing the results I wanted'),
      value: primaryReasonValues.NO_RESULTS,
    },
    { label: i18next.t('Missing feature(s)'), value: primaryReasonValues.MISSING_FEATURES },
    { label: `${i18next.t('Other')}...`, value: primaryReasonValues.OTHER },
  ],
  subFields: {
    [primaryReasonValues.NO_NEED_WEBSITE]: {
      name: fieldNames.SUB_REASONS,
      rules: { validate: validateCheckboxGroup },
      Component: CheckboxGroupField,
      controlGroup: {
        title: getElaborateTitle(),
        required: true,
      },
      onChange: (options) => resetOtherSubReason(setFieldValue, options),
      options: [
        {
          label: i18next.t('I needed a temporary website'),
          value: 'I needed a temporary website',
        },
        {
          label: i18next.t('I’m closing my business'),
          value: 'I’m closing my business',
        },
        {
          label: i18next.t('My business no longer needs to be online'),
          value: 'My business no longer needs to be online',
        },
        {
          label: `${i18next.t('Other')}...`,
          value: SUB_FIELD_OTHER_VALUE,
        },
      ],
      subFields: {
        [SUB_FIELD_OTHER_VALUE]: {
          name: fieldNames.OTHER_SUB_REASON,
          placeholder: i18next.t('Please, write your reason'),
          statusText: i18next.t('Requires at least {length} characters', {
            length: 10,
          }),
          isSubField: true,
          rules: { minLength: 10, required: true },
          Component: TextAreaField,
        },
      },
    },
    [primaryReasonValues.TOO_EXPENSIVE]: {
      name: fieldNames.SUB_REASONS,
      rules: { validate: validateCheckboxGroup },
      Component: CheckboxGroupField,
      controlGroup: {
        title: getElaborateTitle(),
        required: true,
      },
      onChange: (options) => resetOtherSubReason(setFieldValue, options),
      options: [
        {
          label: i18next.t('I can’t afford the cost of a website'),
          value: 'I can’t afford the cost of a website',
        },
        { label: i18next.t('I want a cheaper plan'), value: 'I want a cheaper plan' },
        {
          label: i18next.t('The plan is too expensive for the value provided'),
          value: 'The plan is too expensive for the value provided',
        },
        {
          label: i18next.t('The plan is too expensive for my current needs'),
          value: 'The plan is too expensive for my current needs',
        },
        { label: `${i18next.t('Other')}...`, value: SUB_FIELD_OTHER_VALUE },
      ],
      subFields: {
        [SUB_FIELD_OTHER_VALUE]: {
          name: fieldNames.OTHER_SUB_REASON,
          placeholder: i18next.t('Please, write your reason'),
          statusText: i18next.t('Requires at least {length} characters', {
            length: 10,
          }),
          isSubField: true,
          rules: { minLength: 10, required: true },
          Component: TextAreaField,
        },
      },
    },
    [primaryReasonValues.CANT_BUILD_WEBSITE]: {
      name: fieldNames.SUB_REASONS,
      rules: { validate: validateCheckboxGroup },
      Component: CheckboxGroupField,
      controlGroup: {
        title: getElaborateTitle(),
        required: true,
      },
      onChange: (options) => resetOtherSubReason(setFieldValue, options),
      options: [
        {
          label: i18next.t('I don’t have a vision of my website'),
          value: 'I don’t have a vision of my website',
        },
        { label: i18next.t('I need written content'), value: 'I need written content' },
        { label: i18next.t('I need images'), value: 'I need images' },
        { label: i18next.t('I don’t have enough time'), value: 'I don’t have enough time' },
        {
          label: i18next.t('I need to grow an audience first'),
          value: 'I need to grow an audience first',
        },
        { label: `${i18next.t('Other')}...`, value: SUB_FIELD_OTHER_VALUE },
      ],
      subFields: {
        [SUB_FIELD_OTHER_VALUE]: {
          name: fieldNames.OTHER_SUB_REASON,
          placeholder: i18next.t('Please, write your reason'),
          statusText: i18next.t('Requires at least {length} characters', {
            length: 10,
          }),
          isSubField: true,
          rules: { minLength: 10, required: true },
          Component: TextAreaField,
        },
      },
    },
    [primaryReasonValues.ISSUE_WITH_BUILDER]: {
      name: fieldNames.SUB_REASONS,
      rules: { validate: validateCheckboxGroup },
      Component: CheckboxGroupField,
      controlGroup: {
        title: getElaborateTitle(),
        required: true,
      },
      onChange: (options) => resetOtherSubReason(setFieldValue, options),
      options: [
        { label: i18next.t('Sitebuilder is hard to use'), value: 'Sitebuilder is hard to use' },
        {
          label: i18next.t('Sitebuilder doesn’t have enough customization options'),
          value: 'Sitebuilder doesn’t have enough customization options',
        },
        {
          label: i18next.t('Sitebuilder doesn’t provide the template I need'),
          value: 'Sitebuilder doesn’t provide the template I need',
        },
        {
          label: i18next.t('Customer support isn’t helpful'),
          value: 'Customer support isn’t helpful',
        },
        { label: i18next.t('Help Center isn’t helpful'), value: 'Help Center isn’t helpful' },
        { label: i18next.t('The platform is not stable'), value: 'The platform is not stable' },
        { label: `${i18next.t('Other')}...`, value: SUB_FIELD_OTHER_VALUE },
      ],
      subFields: {
        [SUB_FIELD_OTHER_VALUE]: {
          name: fieldNames.OTHER_SUB_REASON,
          placeholder: i18next.t('Please, write your reason'),
          statusText: i18next.t('Requires at least {length} characters', {
            length: 10,
          }),
          isSubField: true,
          rules: { minLength: 10, required: true },
          Component: TextAreaField,
        },
      },
    },
    [primaryReasonValues.NO_RESULTS]: {
      name: fieldNames.SUB_REASONS,
      rules: { validate: validateCheckboxGroup },
      Component: CheckboxGroupField,
      controlGroup: {
        title: getElaborateTitle(),
        required: true,
      },
      onChange: (options) => resetOtherSubReason(setFieldValue, options),
      options: [
        {
          label: i18next.t('Not seeing the results I wanted'),
          value: 'Not seeing the results I wanted',
        },
        { label: i18next.t('I can’t figure out SEO'), value: 'I can’t figure out SEO' },
        { label: i18next.t('SEO isn’t effective'), value: 'SEO isn’t effective' },
        {
          label: i18next.t('My website doesn’t get enough traffic'),
          value: 'My website doesn’t get enough traffic',
        },
        {
          label: i18next.t('Not getting enough leads/sales'),
          value: 'Not getting enough leads/sales',
        },
        { label: `${i18next.t('Other')}...`, value: SUB_FIELD_OTHER_VALUE },
      ],
      subFields: {
        [SUB_FIELD_OTHER_VALUE]: {
          name: fieldNames.OTHER_SUB_REASON,
          placeholder: i18next.t('Please, write your reason'),
          statusText: i18next.t('Requires at least {length} characters', {
            length: 10,
          }),
          isSubField: true,
          rules: { minLength: 10, required: true },
          Component: TextAreaField,
        },
      },
    },
    [primaryReasonValues.MISSING_FEATURES]: {
      name: fieldNames.SUB_REASONS,
      rules: { validate: validateCheckboxGroup },
      Component: CheckboxGroupField,
      controlGroup: {
        title: getSpecifyTitle(),
        required: true,
      },
      onChange: (options) => resetOtherSubReason(setFieldValue, options),
      options: [
        {
          label: i18next.t('CRM features (managing customers)'),
          value: 'CRM features (managing customers)',
        },
        { label: i18next.t('Appointment functionality'), value: 'Appointment functionality' },
        { label: i18next.t('SEO features'), value: 'SEO features' },
        { label: i18next.t('Donation/Payment option'), value: 'Donation/Payment option' },
        { label: i18next.t('Design customization'), value: 'Design customization' },
        { label: i18next.t('Block layouts'), value: 'Block layouts' },
        { label: i18next.t('Email marketing features'), value: 'Email marketing features' },
        { label: i18next.t('Social media integration'), value: 'Social media integration' },
        { label: i18next.t('Membership feature'), value: 'Membership feature' },
        { label: i18next.t('Slider functionality'), value: 'Slider functionality' },
        { label: i18next.t('Password protection'), value: 'Password protection' },
        { label: i18next.t('File management'), value: 'File management' },
        { label: i18next.t('Contact form functionality'), value: 'Contact form functionality' },
        { label: i18next.t('E-commerce functionality'), value: 'E-commerce functionality' },
        { label: `${i18next.t('Other')}...`, value: SUB_FIELD_OTHER_VALUE },
      ],
      subFields: {
        [SUB_FIELD_OTHER_VALUE]: {
          name: fieldNames.OTHER_SUB_REASON,
          placeholder: i18next.t('Please, write your reason'),
          statusText: i18next.t('Requires at least {length} characters', {
            length: 10,
          }),
          rules: { minLength: 10, required: true },
          Component: TextAreaField,
        },
      },
    },
    [primaryReasonValues.OTHER]: {
      title: i18next.t('Tell us more'),
      name: fieldNames.OTHER_PRIMARY_REASON,
      placeholder: `${i18next.t('Write your reason')}...`,
      statusText: i18next.t('The message should include more than {length} symbols', {
        length: 20,
      }),
      rules: { minLength: 20, required: true },
      Component: TextAreaField,
    },
  },
});

export default getHostingPrimaryReasonFields;
