import React, { useMemo, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import getStripePromise from '../helpers/get-start-promise';
import getStripeAppearanceConfig from '../helpers/get-stripe-appearance-config';
import i18n from '../../i18n';
import config from '../../config';
import StripeForm from '../components/stripe-form';
import errorSource from '../constants/error-source';

const FONT_URL =
  'https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,latin-ext';

const StripeContainer = React.forwardRef((props, ref) => {
  const { onReady, onLoaderror, currency } = props;
  const locale = useSelector(i18n.selectors.getLocale);
  const publicKey = useSelector(config.selectors.getPublishableStripeKey);
  const formRef = useRef(null);
  const appearance = useMemo(getStripeAppearanceConfig, []);
  const stripeLoaderOptions = {
    locale,
  };
  const paymentElementOptions = {
    fields: {
      billingDetails: {
        email: 'never',
        phone: 'never',
        name: 'never',
        address: 'never',
      },
    },
  };

  useImperativeHandle(ref, () => {
    const validate = () => {
      const elements = formRef.current.getElements();

      return elements.submit();
    };

    return {
      validate,

      clear: () => {
        const elements = formRef.current.getElements();
        const paymentElement = elements.getElement('payment');

        paymentElement.clear();
      },

      submit: (data = {}) =>
        validate().then((result) => {
          if (result.error) {
            return {
              source: errorSource.CLIENT,
              ...result,
            };
          }

          const elements = formRef.current.getElements();
          const stripe = formRef.current.getStripe();
          const {
            country,
            city = null,
            line1 = null,
            line2 = null,
            postalCode = null,
            state = null,
            name = null,
          } = data;

          return stripe
            .createPaymentMethod({
              elements,
              params: {
                billing_details: {
                  name,
                  email: null,
                  phone: null,
                  address: {
                    country,
                    city,
                    line1,
                    line2,
                    postal_code: postalCode,
                    state,
                  },
                },
              },
            })
            .then((stripeResponse) => {
              if (stripeResponse.error) {
                return {
                  source: errorSource.SERVER,
                  ...stripeResponse,
                };
              }

              return stripeResponse;
            });
        }),
    };
  });

  return (
    <Elements
      stripe={getStripePromise(publicKey, stripeLoaderOptions)}
      options={{
        fonts: [
          {
            cssSrc: FONT_URL,
          },
        ],
        mode: 'payment',
        amount: 1099,
        paymentMethodCreation: 'manual',
        currency,
        appearance,
      }}
    >
      <StripeForm
        ref={formRef}
        options={paymentElementOptions}
        onReady={onReady}
        onLoaderror={onLoaderror}
      />
    </Elements>
  );
});

StripeContainer.displayName = 'StripeContainer';

StripeContainer.propTypes = {
  onReady: PropTypes.func,
  onLoaderror: PropTypes.func,
  currency: PropTypes.string,
};

StripeContainer.defaultProps = {
  onReady: Function.prototype,
  onLoaderror: Function.prototype,
  currency: 'usd',
};

export default StripeContainer;
