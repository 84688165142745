import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { designSystem } from '@yola/ws-ui';
import i18next from 'i18next';
import routesMap from '../../../router/helpers/routes-map';
import segment from '../../analytics/segment';

const { CtaBanner } = designSystem;
const {
  constants: { triggerIds, events },
  track,
} = segment;

const getCaptions = (isFreeDomainPromoAvailable) => {
  if (isFreeDomainPromoAvailable) {
    return {
      title: i18next.t('Claim your free domain!'),
      subtitle: i18next.t('Use your free 1 year domain special offer'),
      label: i18next.t('Get a free domain'),
    };
  }
  return {
    title: i18next.t('Buy a custom domain'),
    subtitle: i18next.t('Improve the credibility of your site with a custom domain'),
    label: i18next.t('Buy now'),
  };
};

const CustomDomainBannerContainer = ({ isFreeDomainPromoAvailable }) => {
  const dispatch = useDispatch();
  const captions = getCaptions(isFreeDomainPromoAvailable);

  const redirectToDomainSelector = () => {
    const domainSelectorUrl = routesMap.domainSelector.url({
      query: { triggerId: triggerIds.BANNER, paymentBackUrl: window.location.href },
    });
    track(events.BUY_A_DOMAIN_TRIGGER_CLICKED, {
      triggerId: triggerIds.BANNER,
      freeDomainPromo: isFreeDomainPromoAvailable,
    });
    dispatch(push(domainSelectorUrl));
  };

  return (
    <CtaBanner
      title={captions.title}
      subtitle={captions.subtitle}
      ctaButton={{
        label: captions.label,
        onClick: redirectToDomainSelector,
      }}
      iconGlyph="domain"
      appearance="accent"
      layout="mobile"
      animate={false}
    />
  );
};

CustomDomainBannerContainer.propTypes = {
  isFreeDomainPromoAvailable: PropTypes.bool,
};

CustomDomainBannerContainer.defaultProps = {
  isFreeDomainPromoAvailable: false,
};

export default CustomDomainBannerContainer;
