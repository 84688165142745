import { serviceProvider } from '@yola/subscription-manager-js';
import status from 'src/js/modules/status';
import user from 'src/js/modules/user';
import statusNames from '../constants/status-names';

const fetchAvailablePackages = () => async (dispatch, getState) => {
  const state = getState();
  const partnerData = user.selectors.getPartnerData(state);
  const { id: partnerId } = partnerData;

  dispatch(status.actions.setStatus(statusNames.AVAILABLE_PACKAGES, status.constants.LOADING));

  try {
    await serviceProvider.fetchPackages(partnerId);
    dispatch(status.actions.setStatus(statusNames.AVAILABLE_PACKAGES, status.constants.SUCCEEDED));
  } catch (error) {
    console.error(error);
    dispatch(status.actions.setStatus(statusNames.AVAILABLE_PACKAGES, status.constants.FAILED));
  }
};

export default fetchAvailablePackages;
