import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Heading, ActionButton } = designSystem;

const PaymentMethodsHeader = ({ captions, onAddPaymentMethod, restrictAdding }) => (
  <div className="ws-payment-methods-header">
    <Heading type="heading-5">{captions.title}</Heading>
    {!restrictAdding && (
      <ActionButton
        format="solid"
        appearance="accent"
        iconGlyph="plus-for-button"
        label={captions.buttonLabel}
        onClick={onAddPaymentMethod}
      />
    )}
  </div>
);

PaymentMethodsHeader.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
    buttonLabel: PropTypes.string,
  }).isRequired,
  onAddPaymentMethod: PropTypes.func,
  restrictAdding: PropTypes.bool,
};

PaymentMethodsHeader.defaultProps = {
  onAddPaymentMethod: Function.prototype,
  restrictAdding: false,
};

export default PaymentMethodsHeader;
