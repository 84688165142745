import getAnalyticsUrl from './analytics-url';
import getConfig from './config';
import getApiUrl from './api-url';
import getDomain from './domain';
import getIsB2C from './is-b2c';
import getLatitudeTemplateGalleryUrl from './latitude-template-gallery-url';
import getLegacyTemplateGalleryUrl from './legacy-template-gallery-url';
import getYolaUrl from './yola-url';
import getMyyolaUrl from './myyola-url';
import getBaseLanguageCodes from './base-language-codes';
import getLocaleCookie from './locale-cookie';
import getOnlineStoreUrl from './online-store-url';
import getSegmentIoKey from './segment-io-key';
import getStaticPrefix from './static-prefix';
import getLoginUrl from './login-url';
import getLogoutUrl from './logout-url';
import getSentryDSN from './sentry-dsn';
import getZendeskWidgetUrl from './zendesk-widget-url';
import getSignUpUrl from './signup-url';
import getPublishableStripeKey from './publishable-stripe-key';

export default {
  getAnalyticsUrl,
  getApiUrl,
  getConfig,
  getDomain,
  getIsB2C,
  getLatitudeTemplateGalleryUrl,
  getLegacyTemplateGalleryUrl,
  getYolaUrl,
  getMyyolaUrl,
  getBaseLanguageCodes,
  getLocaleCookie,
  getOnlineStoreUrl,
  getSegmentIoKey,
  getStaticPrefix,
  getLoginUrl,
  getSignUpUrl,
  getLogoutUrl,
  getSentryDSN,
  getZendeskWidgetUrl,
  getPublishableStripeKey,
};
