import React from 'react';
import PropTypes from 'prop-types';

const HeaderRight = ({ children }) => <div className="ws-my-navbar__right">{children}</div>;

HeaderRight.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default HeaderRight;
