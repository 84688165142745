import React from 'react';
import { designSystem } from '@yola/ws-ui';

const { SkeletonFill, TextSkeleton, Heading, Paragraph, ButtonSkeleton } = designSystem;

function SiteSkeleton() {
  return (
    <div className="ws-site ws-site--loading">
      <div className="ws-site__preview">
        <SkeletonFill />
      </div>
      <div className="ws-site__details">
        <div style={{ width: '215px' }}>
          <Heading type="heading-5">
            <TextSkeleton />
          </Heading>
        </div>
        <div className="ws-site__details-domain">
          <div style={{ width: '118px' }}>
            <Paragraph>
              <TextSkeleton />
            </Paragraph>
          </div>
          <div style={{ width: '52px' }}>
            <Paragraph>
              <TextSkeleton />
            </Paragraph>
          </div>
        </div>
      </div>
      <div className="ws-site__upsell">
        <div style={{ width: '135px' }}>
          <div style={{ width: '100%' }}>
            <Paragraph>
              <TextSkeleton />
            </Paragraph>
          </div>
          <div style={{ width: '83%' }}>
            <Paragraph>
              <TextSkeleton />
            </Paragraph>
          </div>
        </div>
        <div style={{ width: '94px' }}>
          <ButtonSkeleton />
        </div>
      </div>
    </div>
  );
}

export default SiteSkeleton;
