const fieldNames = {
  NAME: 'name',
  SURNAME: 'surname',
  LOCATION: 'location',
  PRIMARY_PHONE: 'primaryPhone',
  MOBILE_PHONE: 'mobilePhone',
  OTHER_PHONE: 'otherPhone',
  EMAIL: 'email',
  LOCALE: 'locale',
  TELEPHONE_NUMBERS: 'telephoneNumbers',
};

export default fieldNames;
