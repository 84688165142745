export const premiumFeatures = {
  DEFAULT: 'default',
  POINTED_DOMAIN: 'pointed_domain',
  CUSTOM_DOMAIN: 'custom_domain',
  TRANSFER_DOMAIN: 'transfer_domain',
  ECOMMERCE: 'online_store',
};

export const purchaseFlowTypes = {
  B2C: 'b2c',
  IN_APP: 'in_app',
  WL: 'wl',
};

export const productCategories = {
  LATITUDE: 'latitude',
  ECOMMERCE: 'online_store',
};

export const ecommercePremiumFeatures = {
  DEFAULT: 'default',
};
