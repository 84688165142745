import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Paragraph, Link } = designSystem;

function CopyrightFooter(props) {
  const { captions, links } = props;
  return (
    <div className="ws-copyright-footer">
      <div className="ws-copyright-footer__content">
        <Paragraph size="small" appearance="medium-emphasis">
          {captions.copyright}
        </Paragraph>

        {Boolean(links.length) && (
          <div className="ws-copyright-footer__links">
            {links.map((link) => (
              <Link
                key={link.title}
                href={link.href}
                target={link.target}
                size="small"
                appearance="accent"
              >
                {link.title}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

CopyrightFooter.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
      target: PropTypes.string,
    })
  ),
  captions: PropTypes.shape({
    copyright: PropTypes.string,
  }).isRequired,
};

CopyrightFooter.defaultProps = {
  links: [],
};

export default CopyrightFooter;
