import actionTypes from '../constants/action-types';
import { defaultStatusState } from '../reducers/status';

const setStatus = (name, status) => {
  if (!(name in defaultStatusState)) {
    console.error(`[WARNING] Initial state for "${name}" status is missing`);
  }

  return {
    type: actionTypes.SET_STATUS,
    payload: {
      status,
      name,
    },
  };
};

export default setStatus;
