// http://www.usps.com/ncsc/lookups/abbr_state.txt
const getStates = () => ({
  US: {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District of Columbia',
    FM: 'Federated States of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
    AE: 'Armed Forces Africa',
    AA: 'Armed Forces Americas',
    AP: 'Armed Forces Pacific',
  },
  CA: {
    NT: 'Northwest Territories',
    NL: 'Newfoundland and Labrador',
    BC: 'British Columbia',
    ON: 'Ontario',
    NS: 'Nova Scotia',
    MB: 'Manitoba',
    QC: 'Quebec',
    YK: 'Yukon',
    NU: 'Nunavut',
    PE: 'Prince Edward Island',
    SK: 'Saskatchewan',
    NB: 'New Brunswick',
    AB: 'Alberta',
  },
  // http://www.commondatahub.com/live/geography/state_province_region/iso_3166_2_state_codes
  ES: {
    Araba: 'Araba',
    Albacete: 'Albacete',
    Alicante: 'Alicante',
    Almeria: 'Almeria',
    Avila: 'Avila',
    Badajoz: 'Badajoz',
    'Illes Balears': 'Illes Balears',
    Barcelona: 'Barcelona',
    Burgos: 'Burgos',
    Caceres: 'Caceres',
    Cadiz: 'Cadiz',
    Castellon: 'Castellon',
    'Ciudad Real': 'Ciudad Real',
    Cordoba: 'Cordoba',
    'Coruña, A': 'Coruña, A',
    Cuenca: 'Cuenca',
    Girona: 'Girona',
    Granada: 'Granada',
    Guadalajara: 'Guadalajara',
    Gipuzkoa: 'Gipuzkoa',
    Huelva: 'Huelva',
    Huesca: 'Huesca',
    Jaen: 'Jaen',
    Leon: 'Leon',
    Lleida: 'Lleida',
    'Rioja, La': 'Rioja, La',
    Lugo: 'Lugo',
    Madrid: 'Madrid',
    Malaga: 'Malaga',
    Murcia: 'Murcia',
    Navarra: 'Navarra',
    Ourense: 'Ourense',
    Asturias: 'Asturias',
    Palencia: 'Palencia',
    'Palmas, Las': 'Palmas, Las',
    Pontevedra: 'Pontevedra',
    Salamanca: 'Salamanca',
    'Santa Cruz de Tenerife': 'Santa Cruz de Tenerife',
    Cantabria: 'Cantabria',
    Segovia: 'Segovia',
    Sevilla: 'Sevilla',
    Soria: 'Soria',
    Tarragona: 'Tarragona',
    Teruel: 'Teruel',
    Toledo: 'Toledo',
    Valencia: 'Valencia',
    Valladolid: 'Valladolid',
    Bizkaia: 'Bizkaia',
    Zamora: 'Zamora',
    Zaragoza: 'Zaragoza',
    Ceuta: 'Ceuta',
    Melilla: 'Melilla',
  },
  NL: {
    DR: 'Drenthe',
    FL: 'Flevoland',
    FR: 'Friesland',
    GE: 'Gelderland',
    GR: 'Groningen',
    LI: 'Limburg',
    NB: 'Noord-Brabant',
    NH: 'Noord-Holland',
    OV: 'Overijssel',
    UT: 'Utrecht',
    ZE: 'Zeeland',
    ZH: 'Zuid-Holland',
  },
  // http://www.commondatahub.com/live/geography/state_province_region/iso_3166_2_state_codes
  IT: {
    AG: 'Agrigento',
    AL: 'Alessandria',
    AN: 'Ancona',
    AO: 'Aosta',
    AR: 'Arezzo',
    AP: 'Ascoli Piceno',
    AT: 'Asti',
    AV: 'Avellino',
    BA: 'Bari',
    BT: 'Barletta-Andria-Trani',
    BL: 'Belluno',
    BN: 'Benevento',
    BG: 'Bergamo',
    BI: 'Biella',
    BO: 'Bologna',
    BZ: 'Bolzano',
    BS: 'Brescia',
    BR: 'Brindisi',
    CA: 'Cagliari',
    CL: 'Caltanissetta',
    CB: 'Campobasso',
    CI: 'Carbonia-Iglesias',
    CE: 'Caserta',
    CT: 'Catania',
    CZ: 'Catanzaro',
    CH: 'Chieti',
    CO: 'Como',
    CS: 'Cosenza',
    CR: 'Cremona',
    KR: 'Crotone',
    CN: 'Cuneo',
    EN: 'Enna',
    FM: 'Fermo',
    FE: 'Ferrara',
    FI: 'Firenze',
    FG: 'Foggia',
    FC: 'Forli-Cesena',
    FR: 'Frosinone ',
    GE: 'Genova',
    GO: 'Gorizia',
    GR: 'Grosseto',
    IM: 'Imperia',
    IS: 'Isernia',
    AQ: "L'Aquila",
    SP: 'La Spezia',
    LT: 'Latina',
    LE: 'Lecce',
    LC: 'Lecco',
    LI: 'Livorno',
    LO: 'Lodi',
    LU: 'Lucca',
    MC: 'Macerata',
    MN: 'Mantova',
    MS: 'Massa-Carrara',
    MT: 'Matera',
    VS: 'Medio Campidano',
    ME: 'Messina',
    MI: 'Milano',
    MO: 'Modena',
    MB: 'Monza e Brianza',
    NA: 'Napoli',
    NO: 'Novara',
    NU: 'Nuoro',
    OG: 'Ogliastra',
    OT: 'Olbia-Tempio',
    OR: 'Oristano',
    PD: 'Padova',
    PA: 'Palermo',
    PR: 'Parma',
    PV: 'Pavia',
    PG: 'Perugia',
    PU: 'Pesaro e Urbino',
    PE: 'Pescara',
    PC: 'Piacenza',
    PI: 'Pisa',
    PT: 'Pistoia',
    PN: 'Pordenone',
    PZ: 'Potenza',
    PO: 'Prato',
    RG: 'Ragusa',
    RA: 'Ravenna',
    RC: 'Reggio Calabria',
    RE: 'Reggio Emilia',
    RI: 'Rieti',
    RN: 'Rimini',
    RM: 'Roma',
    RO: 'Rovigo',
    SA: 'Salerno',
    SS: 'Sassari',
    SV: 'Savona',
    SI: 'Siena',
    SR: 'Siracusa',
    SO: 'Sondrio',
    TA: 'Taranto',
    TE: 'Teramo',
    TR: 'Terni',
    TO: 'Torino',
    TP: 'Trapani',
    TN: 'Trento',
    TV: 'Treviso',
    TS: 'Trieste',
    UD: 'Udine',
    VA: 'Varese',
    VE: 'Venezia',
    VB: 'Verbano-Cusio-Ossola',
    VC: 'Vercelli',
    VR: 'Verona',
    VV: 'Vibo Valentia',
    VI: 'Vicenza',
    VT: 'Viterbo',
  },
  // http://www.commondatahub.com/live/geography/state_province_region/iso_3166_2_state_codes
  BR: {
    AC: 'Acre',
    AL: 'Alagoas',
    AP: 'Amapá',
    AM: 'Amazonas',
    BA: 'Bahia',
    CE: 'Ceará',
    DF: 'Distrito Federal',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MT: 'Mato Grosso',
    MS: 'Mato Grosso do Sul',
    MG: 'Minas Gerais',
    PR: 'Paraná',
    PB: 'Paraíba',
    PA: 'Pará',
    PE: 'Pernambuco',
    PI: 'Piauí',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul',
    RO: 'Rondônia',
    RR: 'Roraima',
    SC: 'Santa Catarina',
    SE: 'Sergipe',
    SP: 'São Paulo',
    TO: 'Tocantins',
  },
});

export default getStates;
