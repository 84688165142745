import React from 'react';

const FreeDomainIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
    <g clipPath="url(#a)">
      <path
        fill="#005ED9"
        fillOpacity=".03"
        d="M16 30.8C7.84 30.8 1.2 24.16 1.2 16S7.84 1.2 16 1.2 30.8 7.84 30.8 16 24.16 30.8 16 30.8Z"
      />
      <path
        fill="#005ED9"
        d="M16 1.6c7.94 0 14.4 6.46 14.4 14.4S23.94 30.4 16 30.4 1.6 23.94 1.6 16 8.06 1.6 16 1.6Zm0-.8C7.606.8.8 7.606.8 16c0 8.395 6.806 15.2 15.2 15.2 8.394 0 15.2-6.805 15.2-15.2C31.2 7.606 24.394.8 16 .8Z"
      />
      <path
        fill="#005ED9"
        d="M2.449 9.6h27.15l-.817-.8H3.266l-.817.8ZM29.55 22.4H2.4l.818.8h25.515l.818-.8Z"
      />
      <path
        stroke="#005ED9"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth=".8"
        d="M25.134 10C23.574 4.823 20.07 1.2 16 1.2S8.426 4.823 6.867 10m0 12c1.56 5.177 5.063 8.8 9.133 8.8 4.07 0 7.574-3.623 9.134-8.8"
      />
      <path
        stroke="#005ED9"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth=".8"
        d="M12.724 22c.614 5.384 2 8.8 3.276 8.8 1.277 0 2.663-3.416 3.276-8.8m0-12c-.613-5.384-2-8.8-3.276-8.8-1.277 0-2.662 3.416-3.276 8.8"
      />
      <path
        fill="#005ED9"
        d="M9.831 12.8h1.275l-1.482 6.4h-1.4l-.93-4.105a3.88 3.88 0 0 1-.09-.72h-.016a4.946 4.946 0 0 1-.102.719L6.132 19.2H4.674L3.2 12.8h1.38l.79 4.262c.034.182.058.426.074.732h.023c.01-.228.048-.479.114-.75L6.598 12.8H7.95l.92 4.298c.034.157.064.387.09.687h.015a5.16 5.16 0 0 1 .083-.715l.773-4.27ZM17.904 17.071c-.044.241-.071.48-.082.715h-.016c-.027-.3-.056-.53-.09-.688l-.918-4.298h-1.353l-1.017 4.244c-.066.271-.102.52-.114.75h-.023a5.75 5.75 0 0 0-.074-.732l-.789-4.262h-1.38l1.475 6.4h1.458l.954-4.105c.044-.188.079-.428.102-.719h.016c.01.265.04.504.09.719l.928 4.105h1.4l1.482-6.4h-1.274l-.775 4.271ZM27.526 12.8l-.775 4.271c-.044.241-.071.48-.082.715h-.016c-.027-.3-.056-.53-.09-.688l-.918-4.298h-1.353l-1.017 4.244c-.066.271-.102.52-.113.75h-.024a5.75 5.75 0 0 0-.074-.732l-.79-4.262h-1.38l1.475 6.4h1.458l.954-4.105c.045-.188.079-.428.102-.719h.016c.01.265.04.504.09.719l.93 4.105h1.4l1.48-6.4h-1.273Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default FreeDomainIcon;
