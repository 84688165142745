import fieldNames from '../../../constants/form-field-names';
import { snakeCaseObjectToCamelCase } from '../../../../../utils/convert-case';

const processServiceValidationErrors = (errors, errorMsg = '') =>
  Object.entries(snakeCaseObjectToCamelCase(errors)).reduce((acc, [field]) => {
    if (field === 'expirationMonth' || field === 'expirationYear') {
      acc[fieldNames.EXPIRATION_DATE] = errorMsg;
    } else {
      acc[field] = errorMsg;
    }
    return acc;
  }, {});

export default processServiceValidationErrors;
