const EMAIL_PATTERN =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line no-control-regex
const NON_ASCII_PATTERN = /[^\x00-\x7F]/;

const isEmail = (email) => {
  const emailString = String(email).toLowerCase();
  return !NON_ASCII_PATTERN.test(emailString) && EMAIL_PATTERN.test(emailString);
};

export default isEmail;
