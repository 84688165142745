import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { MAP_READABLE_NAME_TO_PERIOD } from 'src/js/modules/common/constants/terms';
import supportedSearchParams from 'src/js/modules/common/constants/supported-search-params';
import segment from '../../modules/analytics/segment';
import EcommercePaywallContainer from '../../modules/paywall/containers/ecommerce-paywall-container';
import useSupportedSearchParams from '../hooks/use-supported-search-params';
import routesMap from '../helpers/routes-map';

const {
  constants: { triggerIds },
} = segment;

function EcommercePaywallRoute() {
  const searchParams = useSupportedSearchParams();
  const dispatch = useDispatch();

  if (!searchParams) return null;

  const {
    initiator,
    products = [],
    term,
    premiumFeature,
    triggerId,
    back,
    periodToggle,
    paymentBackUrl,
  } = searchParams;
  const hasProducts = Boolean(products.length);

  const propsFromSearchParams = {
    ...(initiator && { initiator }),
    ...(premiumFeature && { premiumFeature }),
    ...(hasProducts && { extraProducts: products }),
    ...(term && { term }),
    ...(triggerId && { triggerId }),
    ...(back && { backUrl: back }),
    ...(paymentBackUrl && { paymentBackUrl }),
    periodToggle: periodToggle !== false,
  };

  const showHostingSelector = (selectedPlan, selectedTerm) => {
    const productsParam = [...products, selectedPlan].join();

    const query = {
      products: productsParam,
      term: MAP_READABLE_NAME_TO_PERIOD[selectedTerm],
      triggerId: triggerIds.ECOMMERCE_PAYWALL,
      [supportedSearchParams.DOMAIN_PROPOSAL]: '0',
      ...(paymentBackUrl && { paymentBackUrl }),
    };

    dispatch(
      push(
        routesMap.paywall.url({
          query,
        })
      )
    );
  };

  return (
    <EcommercePaywallContainer
      showHostingSelector={showHostingSelector}
      {...propsFromSearchParams}
    />
  );
}

export default EcommercePaywallRoute;
