import React, { Fragment } from 'react';
import i18next from 'i18next';

const getElaborateTitle = () => (
  <Fragment>
    <b>{i18next.t('Please elaborate on this.')}</b> {i18next.t('Select all that apply')}
  </Fragment>
);

export default getElaborateTitle;
