import useMatchBreakpoint from './use-match-breakpoint';
import { breakpoints } from '../constants';

const useMatchLGBreakpoint = () => {
  const matches = useMatchBreakpoint(breakpoints.lg);

  return matches;
};

export default useMatchLGBreakpoint;
