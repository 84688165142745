import React from 'react';
import PropTypes from 'prop-types';

function PageHeading(props) {
  const { children } = props;

  return <div className="ws-page-heading">{children}</div>;
}

PageHeading.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageHeading;
