import { useSelector, shallowEqual } from 'react-redux';
import { selectors } from '@yola/subscription-manager-js';
import { PER_YEAR } from 'src/js/modules/common/constants/terms';

const useEcommerceTermConfig = (term = PER_YEAR, periodToggle = true) => {
  const activeSubscription = useSelector(selectors.getActiveHostingSubscription, shallowEqual);

  if (activeSubscription) {
    const { term: hostingSubscriptionTerm } = activeSubscription;

    return {
      term: hostingSubscriptionTerm,
      annualAvailable: periodToggle,
    };
  }

  return {
    term,
    annualAvailable: periodToggle,
  };
};

export default useEcommerceTermConfig;
