import i18next from 'i18next';

const CHECKBOX_GROUP_NAME = 'email-subscriptions';

const emailSubscriptionFieldNames = {
  PRODUCT_UPDATES: 'emailProductUpdatesEnabled',
  SPECIAL_OFFERS: 'emailSpecialOffersEnabled',
  SURVEYS: 'emailSurveysEnabled',
  WELCOME_SERIES: 'emailWelcomeSeriesEnabled',
  UNSUBSCRIBE_ALL: 'unsubscribeAll',
};

const getEmailSubscriptionOptions = () => [
  {
    value: emailSubscriptionFieldNames.PRODUCT_UPDATES,
    label: i18next.t('Products updates'),
  },
  {
    value: emailSubscriptionFieldNames.SPECIAL_OFFERS,
    label: i18next.t('Special offers'),
  },
  {
    value: emailSubscriptionFieldNames.SURVEYS,
    label: i18next.t('Surveys'),
  },
  {
    value: emailSubscriptionFieldNames.WELCOME_SERIES,
    label: i18next.t('Tips and Best Practices'),
  },
  {
    value: emailSubscriptionFieldNames.UNSUBSCRIBE_ALL,
    label: i18next.t('Unsubscribe from all'),
  },
];

export { emailSubscriptionFieldNames, CHECKBOX_GROUP_NAME };
export default getEmailSubscriptionOptions;
