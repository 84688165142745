import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import { selectors } from '@yola/subscription-manager-js';
import user from 'src/js/modules/user';
import routersMap from 'src/js/router/helpers/routes-map';
import redirectToUrl from 'src/js/utils/redirect-to-url';

function InitiateUpgradeRoute() {
  const dispatch = useDispatch();
  const upgradeUrl = useSelector(user.selectors.getUpgradeUrl);
  const upgradeExist = useSelector(selectors.getHostingUpgradeAvailability);
  const isB2C = useSelector(user.selectors.getIsB2C);
  const availablePlatformComponents = user.hooks.useAvailablePlatformComponents();

  useEffect(() => {
    if (!upgradeExist) {
      // TODO: remove this condition after release new dashboard for b2c
      if (isB2C) {
        redirectToUrl(routersMap.home.url());
      } else {
        dispatch(replace(routersMap.home.url()));
      }
    }

    if (availablePlatformComponents.includes(user.constants.platformComponents.PAYMENTS)) {
      dispatch(
        replace(
          routersMap.paywall.url({
            query: window.location.search,
          })
        )
      );
    } else if (upgradeUrl) {
      redirectToUrl(upgradeUrl);
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default InitiateUpgradeRoute;
