import { serviceProvider } from '@yola/subscription-manager-js';
import status from 'src/js/modules/status';
import statusNames from '../constants/status-names';

const fetchCurrentPackage = () => async (dispatch) => {
  try {
    await serviceProvider.fetchCurrentPackage();
    dispatch(status.actions.setStatus(statusNames.CURRENT_PACKAGE, status.constants.SUCCEEDED));
  } catch (error) {
    console.error(error);
    dispatch(status.actions.setStatus(statusNames.CURRENT_PACKAGE, status.constants.FAILED));
    throw error;
  }
};

export default fetchCurrentPackage;
