import React, { Fragment, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { designSystem } from '@yola/ws-ui';
import i18next from 'i18next';
import { selectors, verifiers } from '@yola/subscription-manager-js';
import useGalleryUrl from 'src/js/modules/config/hooks/use-gallery-url';
import {
  PageHeading,
  PageHeadingLeft,
  PageHeadingRight,
} from 'src/js/common/components/page-container';
import dialogs from 'src/js/modules/dialogs';
import subscriptions from '../../subscriptions';
import user from '../../user';
import sites from '../../sites';
import status from '../../status';
import segment from '../../analytics/segment';
import SitesListContainer from './sites-list-container';
import redirectToUrl from '../../../utils/redirect-to-url';

const { Heading, ActionButton } = designSystem;
const {
  constants: { events },
  trackers: { trackEvent, trackAsyncEvent },
} = segment;

function DashboardPageContainer() {
  const galleryUrl = useGalleryUrl();
  const activeSubscriptions = useSelector(selectors.getActiveSubscriptions, shallowEqual);
  const sitesStatus = useSelector(sites.selectors.getSitesStatus);
  const { isLatestUserAgreementAccepted } = useSelector(user.selectors.getUserData);

  const dispatch = useDispatch();

  const handleAddNewSiteClick = async () => {
    const subscriptionAutoRenew = subscriptions.accessors.getHostingAutoRenewStatus();
    const onlineStoreAutoRenew = subscriptions.accessors.getEcommerceAutoRenewStatus();
    const { numberOfLegacySites, numberOfLatitudeSites } = sites.accessors.getNumberOfSites();

    await trackAsyncEvent(events.ADD_NEW_SITE_TRIGGER_CLICKED, {
      subscriptionAutoRenew,
      onlineStoreAutoRenew,
      numberOfLegacySites,
      numberOfLatitudeSites,
    });

    redirectToUrl(galleryUrl);
  };

  useEffect(() => {
    if (sitesStatus === status.constants.SUCCEEDED) {
      const subscriptionAutoRenew = subscriptions.accessors.getHostingAutoRenewStatus();
      const onlineStoreAutoRenew = subscriptions.accessors.getEcommerceAutoRenewStatus();
      const { numberOfLegacySites, numberOfLatitudeSites } = sites.accessors.getNumberOfSites();
      const activeDomainSubscriptions = subscriptions.accessors.getActiveDomainSubscriptions();

      trackEvent(events.DASHBOARD_PAGE_DISPLAYED, {
        subscriptionAutoRenew,
        onlineStoreAutoRenew,
        numberOfLegacySites,
        numberOfLatitudeSites,
        numberOfCustomDomains: activeDomainSubscriptions.length,
      });
    }
  }, [sitesStatus]);

  useEffect(() => {
    const hasExpiredPremiumSubscription =
      activeSubscriptions && verifiers.hasExpiredPaidSubscription(activeSubscriptions);

    if (hasExpiredPremiumSubscription && isLatestUserAgreementAccepted) {
      dispatch(dialogs.actions.show(dialogs.dialogTypes.SUBSCRIPTION_EXPIRED));
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [isLatestUserAgreementAccepted]);

  return (
    <Fragment>
      <PageHeading>
        <PageHeadingLeft>
          <Heading type="heading-2">{i18next.t('My Sites')}</Heading>
        </PageHeadingLeft>
        <PageHeadingRight>
          <ActionButton
            appearance="accent"
            format="solid"
            target="_self"
            iconGlyph="plus-for-button"
            onClick={handleAddNewSiteClick}
            label={i18next.t('Add New Site')}
          />
        </PageHeadingRight>
      </PageHeading>

      <SitesListContainer onAddNewSiteClick={handleAddNewSiteClick} />
    </Fragment>
  );
}

export default DashboardPageContainer;
