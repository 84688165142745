import { batch } from 'react-redux';
import { snakeCaseObjectToCamelCase } from 'src/js/utils/convert-case';
import status from 'src/js/modules/status';
import setSites from '../actions/set-sites';
import statusNames from '../constants/status-names';

const SITES_URL = '/pb/sites';

const loadSites = () => async (dispatch) => {
  try {
    dispatch(status.actions.setStatus(statusNames.SITES, status.constants.LOADING));

    const response = await fetch(SITES_URL);
    const sitesList = await response.json();
    const data = snakeCaseObjectToCamelCase(sitesList);

    batch(() => {
      dispatch(setSites(data));
      dispatch(status.actions.setStatus(statusNames.SITES, status.constants.SUCCEEDED));
    });
  } catch (error) {
    console.error('Load sites list error: ', error);
    dispatch(status.actions.setStatus(statusNames.SITES, status.constants.FAILED));
  }
};

export default loadSites;
