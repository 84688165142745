import React from 'react';
import PropTypes from 'prop-types';

import { NavbarHeaderProvider } from './context';
import HeaderLeft from './components/header-left';
import HeaderRight from './components/header-right';
import MenuWrapper from './components/menu-wrapper';
import Menu from './components/menu';
import Logo from './components/logo';
import Nav from './components/nav';
import NavItem from './components/nav-item';
import Divider from './components/divider';
import UpgradeButton from './components/upgrade-button';

function getHeight() {
  return parseFloat(getComputedStyle(document.body).getPropertyValue('--ws-navbar-height'));
}

const NavbarHeader = ({ children }) => (
  <NavbarHeaderProvider>
    <header className="ws-my-navbar">{children}</header>
  </NavbarHeaderProvider>
);

NavbarHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

NavbarHeader.HeaderLeft = HeaderLeft;
NavbarHeader.HeaderRight = HeaderRight;
NavbarHeader.MenuWrapper = MenuWrapper;
NavbarHeader.Menu = Menu;
NavbarHeader.Logo = Logo;
NavbarHeader.Nav = Nav;
NavbarHeader.NavItem = NavItem;
NavbarHeader.Divider = Divider;
NavbarHeader.UpgradeButton = UpgradeButton;

NavbarHeader.getHeight = getHeight;

export default NavbarHeader;
