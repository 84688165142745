import React from 'react';
import PropTypes from 'prop-types';
import Divider from './divider';

const MenuWrapper = ({ children }) => (
  <div className="ws-my-navbar__menu-wrapper">
    {children}
    <Divider />
  </div>
);

MenuWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default MenuWrapper;
