import { paymentMethods, paymentIconGlyphs } from '../constants/payment-methods';

const paymentMethodsMap = new Map([
  [
    paymentMethods.STRIPE,
    [paymentIconGlyphs.VISA, paymentIconGlyphs.MASTER_CARD, paymentIconGlyphs.AMERICAN_EXPRESS],
  ],
  [paymentMethods.PAYPAL, [paymentIconGlyphs.PAYPAL]],
]);

export default paymentMethodsMap;
