import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { selectors, verifiers } from '@yola/subscription-manager-js';
import useFeatureFlags from '../../feature-flags/hooks/use-feature-flags';
import extraPriceTypes from '../constants/extra-price-types';
import user from '../../user';

function useDomainsExtraPrice(isSubscriptionSelected) {
  const subscriptionsList = useSelector(selectors.getSubscriptionList, shallowEqual);
  const activeSubscriptions = useSelector(selectors.getActiveSubscriptions, shallowEqual);
  const [featureFlags] = useFeatureFlags(['freedomain']);
  const { freedomain: isFreeDomainFlagEnabled } = featureFlags;
  const isB2C = useSelector(user.selectors.getIsB2C);

  const extraPrice = useMemo(() => {
    if (!subscriptionsList || !activeSubscriptions) return null;

    const activeSubscriptionTypes = activeSubscriptions.map(({ type }) => type);
    const hasPaidSubscription = verifiers.hasPaidHostingPackageType(activeSubscriptionTypes);
    const hasAnnualPaidHostingSubscription =
      verifiers.hasAnnualPaidHostingSubscription(activeSubscriptions);
    const hasEverHadDomainSubscription = subscriptionsList.some(({ type }) =>
      verifiers.isDomainPackageType(type)
    );

    if (!isB2C) return null;

    if (
      (isSubscriptionSelected &&
        !hasAnnualPaidHostingSubscription &&
        !hasEverHadDomainSubscription) ||
      (!hasPaidSubscription && !hasEverHadDomainSubscription)
    ) {
      return extraPriceTypes.FREE_COM_DOMAIN;
    }

    if (
      (isSubscriptionSelected || isFreeDomainFlagEnabled) &&
      hasAnnualPaidHostingSubscription &&
      !hasEverHadDomainSubscription
    ) {
      return extraPriceTypes.NO_DISCOUNTS;
    }

    return null;
  }, [
    isB2C,
    activeSubscriptions,
    isFreeDomainFlagEnabled,
    isSubscriptionSelected,
    subscriptionsList,
  ]);

  return extraPrice;
}

export default useDomainsExtraPrice;
