import React from 'react';
import NavbarHeader from 'src/js/common/components/navbar-header';
import HelpMenuContainer from './help-menu-container';
import UpgradeContainer from './upgrade-container';
import LogoContainer from './logo-container';
import NavigationContainer from './navigation-container';
import AccountSettingsContainer from './account-settings-container';
import SiteListContainer from './sites-list-container';

function NavbarContainer() {
  return (
    <NavbarHeader>
      <NavbarHeader.HeaderLeft>
        <NavbarHeader.MenuWrapper>
          <LogoContainer />
          <NavbarHeader.Divider />
          <SiteListContainer />
        </NavbarHeader.MenuWrapper>
        <NavbarHeader.Nav>
          <NavigationContainer />
        </NavbarHeader.Nav>
      </NavbarHeader.HeaderLeft>
      <NavbarHeader.HeaderRight>
        <HelpMenuContainer />
        <AccountSettingsContainer />
        <UpgradeContainer />
      </NavbarHeader.HeaderRight>
    </NavbarHeader>
  );
}

export default NavbarContainer;
