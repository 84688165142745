import get from 'lodash.get';
import store from 'src/js/store';
import serviceClient from '../service-client';
import user from '../../user';
import i18n from '../../i18n';
import selectors from '../selectors';
import { PER_MONTH } from '../../common/constants/terms';

const getFormattedSubscriptionPrice = (subscriptionType, term = PER_MONTH) => {
  const state = store.getState();
  const yoproducts = serviceClient.get();

  const prices = selectors.getPrices(state);
  const { currency } = user.selectors.getUserPreferences(state);
  const locale = i18n.selectors.getLocale(state);
  const price = get(prices, `${subscriptionType}.${term}.prices.current.${currency}`, null);

  return (
    price &&
    yoproducts.actions.formatPrice(price, locale, currency, {
      leadingCurrency: true,
    })
  );
};

export default getFormattedSubscriptionPrice;
