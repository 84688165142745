import getFreeHostingSubscriptionType from './get-free-hosting-subscription-type';
import getHostingAutoRenewStatus from './get-hosting-auto-renew-status';
import getActiveDomainSubscriptions from './get-active-domain-subscriptions';
import getEcommerceAutoRenewStatus from './get-ecommerce-auto-renew-status';

export default {
  getFreeHostingSubscriptionType,
  getHostingAutoRenewStatus,
  getActiveDomainSubscriptions,
  getEcommerceAutoRenewStatus,
};
