import PropTypes from 'prop-types';
import fieldNames from '../../constants/form-field-names';

export const formDataPropTypes = PropTypes.shape({
  [fieldNames.CARD_NUMBER]: PropTypes.string,
  [fieldNames.EXPIRATION_DATE]: PropTypes.string,
  [fieldNames.VERIFICATION]: PropTypes.string,
  [fieldNames.NAME]: PropTypes.string,
  [fieldNames.COUNTRY_CODE]: PropTypes.string,
  [fieldNames.STATE]: PropTypes.string,
  [fieldNames.CITY]: PropTypes.string,
  [fieldNames.ADDRESS1]: PropTypes.string,
  [fieldNames.POSTAL_CODE]: PropTypes.string,
  [fieldNames.MAKE_DEFAULT]: PropTypes.bool,
});
