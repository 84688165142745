import { serviceProvider } from '@yola/subscription-manager-js';
import status from 'src/js/modules/status';
import statusNames from '../constants/status-names';

const fetchSubscriptions = () => async (dispatch) => {
  dispatch(status.actions.setStatus(statusNames.SUBSCRIPTIONS, status.constants.LOADING));

  try {
    await serviceProvider.fetchSubscriptions();
    dispatch(status.actions.setStatus(statusNames.SUBSCRIPTIONS, status.constants.SUCCEEDED));
  } catch (e) {
    console.error(e);
    dispatch(status.actions.setStatus(statusNames.SUBSCRIPTIONS, status.constants.FAILED));
  }
};

export default fetchSubscriptions;
