import React, { Fragment } from 'react';
import i18next from 'i18next';

const getSpecifyTitle = () => (
  <Fragment>
    <b>{i18next.t('Please specify.')}</b> {i18next.t('Select all that apply')}
  </Fragment>
);

export default getSpecifyTitle;
