import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { selectors, verifiers } from '@yola/subscription-manager-js';

const useActiveUserSubscriptions = () => {
  const currentPackage = useSelector(selectors.getCurrentPackage, shallowEqual);
  const currentEcommercePlan = useSelector(selectors.getCurrentEcommercePlan, shallowEqual);
  const activeHostingSubscription = useSelector(
    selectors.getActiveHostingSubscription,
    shallowEqual
  );
  const activeEcommerceSubscription = useSelector(
    selectors.getActiveEcommerceSubscription,
    shallowEqual
  );

  return useMemo(() => {
    const userSubscriptions = [];

    if (activeHostingSubscription) {
      userSubscriptions.push({
        name: currentPackage.name,
        subscriptionMeta: activeHostingSubscription,
        isEcommerce: false,
      });
    }

    if (
      activeEcommerceSubscription &&
      !verifiers.isFreeEcommercePlanType(activeEcommerceSubscription.type)
    ) {
      userSubscriptions.push({
        name: currentEcommercePlan.name,
        subscriptionMeta: activeEcommerceSubscription,
        isEcommerce: true,
      });
    }

    return userSubscriptions;
  }, [
    activeEcommerceSubscription,
    activeHostingSubscription,
    currentEcommercePlan,
    currentPackage,
  ]);
};

export default useActiveUserSubscriptions;
