import fetchPrices from './fetch-prices';
import createBillingProfile from './create-billing-profile';
import updateBillingProfile from './update-billing-profile';
import deleteBillingProfile from './delete-billing-profile';
import fetchBillingProfiles from './fetch-billing-profiles';
import fetchBaskets from './fetch-baskets';
import replaceBillingProfileWithExisting from './replace-billing-profile-with-existing';
import replaceBillingProfileWithNew from './replace-billing-profile-with-new';
import setBillingProfileAsDefault from './set-billing-profile-as-default';
import fetchPackageCancellationCoupon from './fetch-package-cancellation-coupon';
import createCancellationCoupon from './create-cancellation-coupon';

export default {
  fetchPrices,
  createBillingProfile,
  updateBillingProfile,
  deleteBillingProfile,
  fetchBillingProfiles,
  fetchBaskets,
  replaceBillingProfileWithExisting,
  replaceBillingProfileWithNew,
  setBillingProfileAsDefault,
  fetchPackageCancellationCoupon,
  createCancellationCoupon,
};
