import React, { useState } from 'react';
import i18next from 'i18next';
import { designSystem, Modal } from '@yola/ws-ui';
import { useDispatch } from 'react-redux';
import dialogs from 'src/js/modules/dialogs';
import status from 'src/js/modules/status';
import statusNames from 'src/js/modules/user/constants/status-names';
import user from 'src/js/modules/user';
import ErrorNotificationContainer from 'src/js/common/containers/error-notification-container';
import segment from 'src/js/modules/analytics/segment';
import sectionIds from '../../constants/section-ids';

const {
  constants: { events },
  track,
} = segment;

const { Box, ActionButton, DialogHeader, DialogHeaderTitle, Paragraph, Divider, ModalButtonGroup } =
  designSystem;

const MODAL_WIDTH = 336;

const getCaptions = () => ({
  title: i18next.t('Last chance...'),
  errorMessage: i18next.t('Something went wrong'),
  description: i18next.t(
    'Deleting your account is irreversible. This action will permanently erase your account, along with all associated sites and their content.'
  ),
  deleteButton: i18next.t('Delete'),
  cancelButton: i18next.t('Cancel'),
});

function DeleteAccountDialog() {
  const dispatch = useDispatch();
  const [serviceError, setServiceError] = useState(false);
  const targetStatus = status.hooks.useStatus(statusNames.DELETE_ACCOUNT);
  const isLoading = targetStatus === status.constants.LOADING;
  const captions = getCaptions();

  const handleCancel = () => {
    dispatch(dialogs.actions.hide());
    track(events.DELETE_ACCOUNT_DIALOG_CANCELLED, {
      dialogId: dialogs.dialogTypes.DELETE_ACCOUNT,
    });
  };

  const handleSubmit = async () => {
    try {
      await dispatch(user.thunks.deleteAccount());
      track(events.DELETE_ACCOUNT_DIALOG_SUBMITTED, {
        dialogId: dialogs.dialogTypes.DELETE_ACCOUNT,
      });
    } catch (e) {
      setServiceError(true);
      track(events.PROFILE_PAGE_ERROR_DISPLAYED, {
        section: sectionIds.ACCOUNT_CHANGES_INFO,
      });
    }
  };

  return (
    <Modal
      width={MODAL_WIDTH}
      maxWidth={MODAL_WIDTH}
      minWidth={MODAL_WIDTH}
      height="auto"
      centered
      isBodyScrollDisabled
      resizable={false}
      draggable={false}
      overlay="visible"
      className="ws-delete-account-modal"
    >
      <DialogHeader>
        <DialogHeaderTitle>{captions.title}</DialogHeaderTitle>
      </DialogHeader>
      <Divider />
      {serviceError && (
        <Box margintLeft="spacing-m" margintRight="spacing-m" margintTop="spacing-s">
          <ErrorNotificationContainer title={captions.errorMessage} />
        </Box>
      )}
      <Box
        margintTop="spacing-s"
        margintBottom="spacing-s"
        margintLeft="spacing-m"
        margintRight="spacing-m"
      >
        <Paragraph>{captions.description}</Paragraph>
      </Box>
      <ModalButtonGroup direction="right">
        <ActionButton
          label={captions.deleteButton}
          appearance="danger"
          format="solid"
          isLoading={isLoading}
          onClick={handleSubmit}
        />
        <ActionButton label={captions.cancelButton} onClick={handleCancel} />
      </ModalButtonGroup>
    </Modal>
  );
}

export default DeleteAccountDialog;
