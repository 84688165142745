import actionTypes from '../constants/action-types';

const partnerData = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_PARTNER_DATA:
      return action.payload.partnerData;
    default:
      return state;
  }
};

export default partnerData;
