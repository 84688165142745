import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { designSystem } from '@yola/ws-ui';

const { Heading, Paragraph, Box, Stack } = designSystem;

const CancelPlanDiscountItem = (props) => {
  const { price, active, caption, term } = props;

  const classes = classNames('ws-cancel-plan-discount-item', {
    'ws-cancel-plan-discount-item--active': active,
  });

  return (
    <div className={classes}>
      <Box padding="spacing-l">
        {active ? (
          <Stack gap="spacing-4-xs">
            <Heading type="heading-6">{caption}</Heading>
            <Heading type="heading-3" appearance="cta">
              {`${price}/${term}`}
            </Heading>
          </Stack>
        ) : (
          <Stack gap="spacing-4-xs">
            <Paragraph appearance="medium-emphasis">{caption}</Paragraph>
            <Heading type="heading-3" appearance="low-emphasis">
              <s className="ws-cancel-plan-discount-item--strikethrough-text">{`${price}/${term}`}</s>
            </Heading>
          </Stack>
        )}
      </Box>
    </div>
  );
};

CancelPlanDiscountItem.propTypes = {
  price: PropTypes.string.isRequired,
  active: PropTypes.bool,
  caption: PropTypes.string.isRequired,
  term: PropTypes.string.isRequired,
};

CancelPlanDiscountItem.defaultProps = {
  active: false,
};

export default CancelPlanDiscountItem;
