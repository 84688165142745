import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import i18next from 'i18next';
import { constants, selectors } from '@yola/subscription-manager-js';
import redirectToUrl from 'src/js/utils/redirect-to-url';
import dialogs from 'src/js/modules/dialogs';
import segment from 'src/js/modules/analytics/segment';
import SubscriptionExpiredDialog from '../components/subscription-expired-dialog';
import user from '../../user';
import useActiveUserSubscriptions from '../hooks/use-active-user-subscriptions';
import utils from '../../webapp-plugins/utils';

const {
  SubscriptionType: { B2C_HOSTING_FREE, WL_HOSTING_FREE },
  SubscriptionStatus: { EXPIRED },
} = constants;
const { platformComponents } = user.constants;
const {
  constants: { events, triggerIds },
  track,
  trackAsync,
} = segment;
const CUSTOM_CARE_LIMITED = 'limited';

const getCaptions = () => ({
  title: i18next.t('Subscription expired'),
  cta: i18next.t('{cta} to keep your site up and running', {
    cta: `<b>${i18next.t('Renew now')}</b>`,
  }),
  ecommerceCta: i18next.t('{cta} and don’t miss out on potential revenue!', {
    cta: `<b>${i18next.t('Renew now')}</b>`,
  }),
  buttonLabel: i18next.t('Renew'),
  goOffline: i18next.t('Your website will go offline'),
  domainWillBeDisconnected: i18next.t('Custom domain will be disconnected'),
  noPremiumSupport: i18next.t('You will have no access to Premium support'),
  loseOnlineStore: i18next.t('You will lose access to Online store:'),
  ecommerceDescription: i18next.t(
    'Renewing will allow your visitors to continue browsing your Online store catalog and submit new orders'
  ),
  onlineStore: i18next.t('Online store'),
});

const SubscriptionExpiredDialogContainer = () => {
  const availablePackages = useSelector(selectors.getHostingPackages, shallowEqual);
  const isB2C = useSelector(user.selectors.getIsB2C);
  const availablePlatformComponents = user.hooks.useAvailablePlatformComponents();

  const freeSubscriptionType = isB2C ? B2C_HOSTING_FREE : WL_HOSTING_FREE;
  const customCareFeature = availablePackages[freeSubscriptionType]?.features?.custom_care;

  const userSubscriptions = useActiveUserSubscriptions();
  const hostingSubscription = userSubscriptions.find(({ isEcommerce }) => !isEcommerce);
  const ecommerceSubscription = userSubscriptions.find(({ isEcommerce }) => isEcommerce);
  const {
    name: hostingSubscriptionName,
    subscriptionMeta: {
      type: hostingType,
      term: hostingTerm,
      status: hostingStatus,
      auto_renew: subscriptionAutoRenew,
    } = {},
  } = hostingSubscription || {};
  const {
    name: ecommerceSubscriptionName,
    subscriptionMeta: {
      type: ecommerceType,
      term: ecommerceTerm,
      status: ecommerceStatus,
      auto_renew: onlineStoreAutoRenew,
    } = {},
  } = ecommerceSubscription || {};

  const isHostingPlusEcommerce =
    ecommerceSubscription && ecommerceStatus === EXPIRED && hostingStatus === EXPIRED;
  const isOnlyEcommerce = ecommerceStatus === EXPIRED && hostingStatus !== EXPIRED;
  const eventTraits = isOnlyEcommerce
    ? { type: ecommerceType, term: ecommerceTerm, onlineStoreAutoRenew }
    : {
        type: hostingType,
        term: hostingTerm,
        subscriptionAutoRenew,
        ...(isHostingPlusEcommerce && { ecommerceType, onlineStoreAutoRenew }),
      };

  const dispatch = useDispatch();

  const captions = useMemo(() => {
    const staticCaptions = getCaptions();

    if (isOnlyEcommerce) {
      return {
        title: staticCaptions.title,
        description: staticCaptions.ecommerceDescription,
        cta: staticCaptions.ecommerceCta,
        primaryButtonLabel: `${staticCaptions.buttonLabel} ${staticCaptions.onlineStore}`,
      };
    }

    const availabilitiesList = [staticCaptions.goOffline];
    const renewButtonLabel = `${staticCaptions.buttonLabel} ${hostingSubscriptionName}`;

    if (availablePlatformComponents.includes(platformComponents.DOMAINS)) {
      availabilitiesList.push(staticCaptions.domainWillBeDisconnected);
    }

    if (customCareFeature !== CUSTOM_CARE_LIMITED) {
      availabilitiesList.push(staticCaptions.noPremiumSupport);
    }

    if (isHostingPlusEcommerce) {
      availabilitiesList.push(`${staticCaptions.loseOnlineStore} ${ecommerceSubscriptionName}`);

      return {
        title: staticCaptions.title,
        list: availabilitiesList,
        cta: staticCaptions.cta,
        primaryButtonLabel: `${renewButtonLabel} + ${staticCaptions.onlineStore}`,
        secondaryButtonLabel: renewButtonLabel,
      };
    }

    return {
      title: staticCaptions.title,
      list: availabilitiesList,
      cta: staticCaptions.cta,
      primaryButtonLabel: renewButtonLabel,
    };
  }, [
    customCareFeature,
    ecommerceSubscriptionName,
    hostingSubscriptionName,
    isHostingPlusEcommerce,
    isOnlyEcommerce,
    availablePlatformComponents,
  ]);

  const handleClose = () => {
    track(events.SUBSCRIPTION_EXPIRED_DIALOG_CANCELLED, {
      ...eventTraits,
    });

    dispatch(dialogs.actions.hide());
  };

  const renewSubscription = async (category, targetSubscription, withBundledEcommerce = false) => {
    const { type, term, status } = targetSubscription.subscriptionMeta;
    const products = [type];

    if (withBundledEcommerce) {
      products.push(ecommerceType);
    }

    const paymentUrl = utils.generatePaymentUrl(
      { products, term, backUrl: window.location.href },
      isB2C
    );

    await trackAsync(events.RENEW_TRIGGER_CLICKED, {
      triggerId: triggerIds.SUBSCRIPTION_EXPIRED_DIALOG,
      category,
      type,
      term,
      status,
      ...(category === triggerIds.HOSTING ? { subscriptionAutoRenew } : { onlineStoreAutoRenew }),
      ...(withBundledEcommerce && { ecommerceType, onlineStoreAutoRenew }),
    });

    redirectToUrl(paymentUrl);
  };

  const handlePrimaryButtonClick = () => {
    if (isHostingPlusEcommerce) {
      renewSubscription(triggerIds.HOSTING, hostingSubscription, true);
      return;
    }

    if (isOnlyEcommerce) {
      renewSubscription(triggerIds.ONLINE_STORE, ecommerceSubscription);
      return;
    }

    renewSubscription(triggerIds.HOSTING, hostingSubscription);
  };

  const handleSecondaryButtonClick = () => {
    renewSubscription(triggerIds.HOSTING, hostingSubscription);
  };

  useEffect(() => {
    track(events.SUBSCRIPTION_EXPIRED_DIALOG_DISPLAYED, {
      ...eventTraits,
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <SubscriptionExpiredDialog
      captions={captions}
      onClose={handleClose}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      {...(isHostingPlusEcommerce && { onSecondaryButtonClick: handleSecondaryButtonClick })}
    />
  );
};

export default SubscriptionExpiredDialogContainer;
