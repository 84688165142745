import { shallowEqual, useSelector } from 'react-redux';
import user from '../../user';
import paymentMethodsMap from '../helpers/payment-methods-map';

const usePaymentMethods = () => {
  const paymentMethods = useSelector(user.selectors.getAvailablePaymentMethods, shallowEqual);

  return paymentMethods.reduce(
    (availableMethods, method) => [...availableMethods, ...(paymentMethodsMap.get(method) || [])],
    []
  );
};

export default usePaymentMethods;
