import actionTypes from '../constants/action-types';

const setServiceConfig = (configData) => ({
  type: actionTypes.SET_SERVICE_CONFIG,
  payload: {
    configData,
  },
});

export default setServiceConfig;
