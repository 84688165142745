import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import getSpecifyTitle from './get-specify-title';
import getElaborateTitle from './get-elaborate-title';
import validateCheckboxGroup from './validate-checkbox-group';
import resetOtherSubReason from './reset-other-sub-reason';
import {
  fieldNames,
  ecommercePrimaryReasonValues,
  noNeedOnlineStoreValues,
  SUB_FIELD_OTHER_VALUE,
} from '../constants/common';

const { InputGroupField, TextAreaField, SimpleSelectField, CheckboxGroupField } = designSystem;

const getEcommercePrimaryReasonFields = ({ setFieldValue }) => ({
  name: fieldNames.PRIMARY_REASON,
  placeholder: `${i18next.t('Select a reason')}...`,
  rules: { required: true },
  Component: SimpleSelectField,
  controlGroup: {
    title: i18next.t(`What's the reason for your cancellation?`),
    required: true,
  },
  onChange: () => {
    setFieldValue(fieldNames.OTHER_PRIMARY_REASON, null);
    setFieldValue(fieldNames.BETTER_ALTERNATIVE, null);
    setFieldValue(fieldNames.SUB_REASONS, []);
    setFieldValue(fieldNames.OTHER_SUB_REASON, null);
  },
  options: [
    {
      label: i18next.t('No longer need an Online store'),
      value: ecommercePrimaryReasonValues.NO_NEED_ONLINE_STORE,
    },
    { label: i18next.t('Too expensive'), value: ecommercePrimaryReasonValues.TOO_EXPENSIVE },
    {
      label: i18next.t('Difficulty managing the store'),
      value: ecommercePrimaryReasonValues.DIFFICULTY_MANAGING,
    },
    {
      label: i18next.t('Issues with Online Store'),
      value: ecommercePrimaryReasonValues.ISSUES_WITH_ONLINE_STORE,
    },
    {
      label: i18next.t('Limited design options'),
      value: ecommercePrimaryReasonValues.LIMITED_DESIGN,
    },
    {
      label: i18next.t('Missing feature(s)'),
      value: ecommercePrimaryReasonValues.MISSING_FEATURES,
    },
    {
      label: i18next.t('Better alternative'),
      value: ecommercePrimaryReasonValues.BETTER_ALTERNATIVE,
    },
    { label: `${i18next.t('Other')}...`, value: ecommercePrimaryReasonValues.OTHER },
  ],
  subFields: {
    [ecommercePrimaryReasonValues.NO_NEED_ONLINE_STORE]: {
      name: fieldNames.SUB_REASONS,
      rules: { validate: validateCheckboxGroup },
      Component: CheckboxGroupField,
      onChange: (options) => resetOtherSubReason(setFieldValue, options),
      options: [
        {
          label: i18next.t('Online Store is hard to use'),
          value: noNeedOnlineStoreValues.HARD_TO_USE,
        },
        {
          label: i18next.t('Online Store doesn’t provide enough customization options'),
          value: noNeedOnlineStoreValues.NOT_ENOUGH_CUSTOMIZATION,
        },
        {
          label: i18next.t('Customer support isn’t helpful'),
          value: noNeedOnlineStoreValues.SUPPORT_IS_NOT_HELPFUL,
        },
        {
          label: i18next.t('Help Center isn’t helpful'),
          value: noNeedOnlineStoreValues.HELPER_CENTER_IS_NOT_HELPFUL,
        },
        {
          label: i18next.t('The Store platform is not stable'),
          value: noNeedOnlineStoreValues.PLATFORM_IS_NOT_STABLE,
        },
        { label: `${i18next.t('Other')}...`, value: noNeedOnlineStoreValues.OTHER },
      ],
      controlGroup: {
        title: getElaborateTitle(),
        required: true,
      },
      subFields: {
        [SUB_FIELD_OTHER_VALUE]: {
          name: fieldNames.OTHER_SUB_REASON,
          placeholder: i18next.t('Please, write your reason'),
          statusText: i18next.t('Requires at least {length} characters', {
            length: 10,
          }),
          isSubField: true,
          rules: { minLength: 10, required: true },
          resize: true,
          Component: TextAreaField,
        },
      },
    },
    [ecommercePrimaryReasonValues.TOO_EXPENSIVE]: {
      name: fieldNames.SUB_REASONS,
      rules: { validate: validateCheckboxGroup },
      Component: CheckboxGroupField,
      onChange: (options) => resetOtherSubReason(setFieldValue, options),
      options: [
        {
          label: i18next.t('Subscription cost is too high'),
          value: 'Subscription cost is too high',
        },
        {
          label: i18next.t('Store revenue is not enough to pay for the subscription'),
          value: 'Store revenue is not enough to pay for the subscription',
        },
        {
          label: i18next.t('I want a less expensive subscription'),
          value: 'I want a less expensive subscription',
        },
        { label: `${i18next.t('Other')}...`, value: SUB_FIELD_OTHER_VALUE },
      ],
      controlGroup: {
        title: getElaborateTitle(),
        required: true,
      },
      subFields: {
        [SUB_FIELD_OTHER_VALUE]: {
          name: fieldNames.OTHER_SUB_REASON,
          placeholder: i18next.t('Please, write your reason'),
          statusText: i18next.t('Requires at least {length} characters', {
            length: 10,
          }),
          isSubField: true,
          rules: { minLength: 10, required: true },
          resize: true,
          Component: TextAreaField,
        },
      },
    },
    [ecommercePrimaryReasonValues.DIFFICULTY_MANAGING]: {
      name: fieldNames.SUB_REASONS,
      rules: { validate: validateCheckboxGroup },
      Component: CheckboxGroupField,
      onChange: (options) => resetOtherSubReason(setFieldValue, options),
      options: [
        {
          label: i18next.t('The setup is confusing'),
          value: 'The setup is confusing',
        },
        {
          label: i18next.t('The dashboard management is complicated'),
          value: 'The dashboard management is complicated',
        },
        {
          label: i18next.t('The functionalities are limited'),
          value: 'The functionalities are limited',
        },
        {
          label: i18next.t('Inventory management is difficult'),
          value: 'Inventory management is difficult',
        },
        {
          label: i18next.t('Shipping and handling options are limited'),
          value: 'Shipping and handling options are limited',
        },
        {
          label: i18next.t('Customer management is difficult'),
          value: 'Customer management is difficult',
        },
        {
          label: i18next.t('Payment processing is confusing or problematic'),
          value: 'Payment processing is confusing or problematic',
        },
        {
          label: i18next.t('Integrating with other tools and platforms is challenging'),
          value: 'Integrating with other tools and platforms is challenging',
        },
        { label: `${i18next.t('Other')}...`, value: SUB_FIELD_OTHER_VALUE },
      ],
      controlGroup: {
        title: getElaborateTitle(),
        required: true,
      },
      subFields: {
        [SUB_FIELD_OTHER_VALUE]: {
          name: fieldNames.OTHER_SUB_REASON,
          placeholder: i18next.t('Please, write your reason'),
          statusText: i18next.t('Requires at least {length} characters', {
            length: 10,
          }),
          isSubField: true,
          rules: { minLength: 10, required: true },
          resize: true,
          Component: TextAreaField,
        },
      },
    },
    [ecommercePrimaryReasonValues.ISSUES_WITH_ONLINE_STORE]: {
      name: fieldNames.SUB_REASONS,
      rules: { validate: validateCheckboxGroup },
      Component: CheckboxGroupField,
      onChange: (options) => resetOtherSubReason(setFieldValue, options),
      options: [
        {
          label: i18next.t('Online Store is hard to use'),
          value: noNeedOnlineStoreValues.HARD_TO_USE,
        },
        {
          label: i18next.t('Online Store doesn’t provide enough customization options'),
          value: noNeedOnlineStoreValues.NOT_ENOUGH_CUSTOMIZATION,
        },
        {
          label: i18next.t('Customer support isn’t helpful'),
          value: noNeedOnlineStoreValues.SUPPORT_IS_NOT_HELPFUL,
        },
        {
          label: i18next.t('Help Center isn’t helpful'),
          value: noNeedOnlineStoreValues.HELPER_CENTER_IS_NOT_HELPFUL,
        },
        {
          label: i18next.t('The Store platform is not stable'),
          value: noNeedOnlineStoreValues.PLATFORM_IS_NOT_STABLE,
        },
        { label: `${i18next.t('Other')}...`, value: noNeedOnlineStoreValues.OTHER },
      ],
      controlGroup: {
        title: getElaborateTitle(),
        required: true,
      },
      subFields: {
        [SUB_FIELD_OTHER_VALUE]: {
          name: fieldNames.OTHER_SUB_REASON,
          placeholder: i18next.t('Please, write your reason'),
          statusText: i18next.t('Requires at least {length} characters', {
            length: 10,
          }),
          isSubField: true,
          rules: { minLength: 10, required: true },
          resize: true,
          Component: TextAreaField,
        },
      },
    },
    [ecommercePrimaryReasonValues.LIMITED_DESIGN]: {
      name: fieldNames.SUB_REASONS,
      rules: { validate: validateCheckboxGroup },
      Component: CheckboxGroupField,
      onChange: (options) => resetOtherSubReason(setFieldValue, options),
      options: [
        {
          label: i18next.t('Lack of customization options'),
          value: 'Lack of customization options',
        },
        {
          label: i18next.t('Can’t find the right design for my store'),
          value: 'Can’t find the right design for my store',
        },
        { label: i18next.t('Limited color options'), value: 'Limited color options' },
        {
          label: i18next.t('Limited font options'),
          value: 'Limited font options',
        },
        {
          label: i18next.t('Can’t add my own custom design elements'),
          value: 'Can’t add my own custom design elements',
        },
        { label: `${i18next.t('Other')}...`, value: SUB_FIELD_OTHER_VALUE },
      ],
      controlGroup: {
        title: getElaborateTitle(),
        required: true,
      },
      subFields: {
        [SUB_FIELD_OTHER_VALUE]: {
          name: fieldNames.OTHER_SUB_REASON,
          placeholder: i18next.t('Please, write your reason'),
          statusText: i18next.t('Requires at least {length} characters', {
            length: 10,
          }),
          isSubField: true,
          rules: { minLength: 10, required: true },
          resize: true,
          Component: TextAreaField,
        },
      },
    },
    [ecommercePrimaryReasonValues.MISSING_FEATURES]: {
      name: fieldNames.SUB_REASONS,
      rules: { validate: validateCheckboxGroup },
      Component: CheckboxGroupField,
      onChange: (options) => resetOtherSubReason(setFieldValue, options),
      options: [
        {
          label: i18next.t('Lack of inventory management tools'),
          value: 'Lack of inventory management tools',
        },
        {
          label: i18next.t('No integration with shipping carriers'),
          value: 'No integration with shipping carriers',
        },
        {
          label: i18next.t('No support for multi-currency transactions'),
          value: 'No support for multi-currency transactions',
        },
        {
          label: i18next.t('Limited product options and customization'),
          value: 'Limited product options and customization',
        },
        {
          label: i18next.t('No mobile optimization for customer purchasing experience'),
          value: 'No mobile optimization for customer purchasing experience',
        },
        { label: `${i18next.t('Other')}...`, value: SUB_FIELD_OTHER_VALUE },
      ],
      controlGroup: {
        title: getSpecifyTitle(),
        required: true,
      },
      subFields: {
        [SUB_FIELD_OTHER_VALUE]: {
          name: fieldNames.OTHER_SUB_REASON,
          placeholder: i18next.t('Please, write your reason'),
          statusText: i18next.t('Requires at least {length} characters', {
            length: 10,
          }),
          isSubField: true,
          rules: { minLength: 10, required: true },
          resize: true,
          Component: TextAreaField,
        },
      },
    },
    [ecommercePrimaryReasonValues.BETTER_ALTERNATIVE]: {
      name: fieldNames.BETTER_ALTERNATIVE,
      placeholder: i18next.t('Name of the website builder'),
      rules: { required: true },
      Component: InputGroupField,
      controlGroup: {
        title: i18next.t('Which one?'),
      },
    },
    [ecommercePrimaryReasonValues.OTHER]: {
      name: fieldNames.OTHER_PRIMARY_REASON,
      placeholder: `${i18next.t('Write your reason')}...`,
      statusText: i18next.t('The message should include more than {length} symbols', {
        length: 20,
      }),
      rules: { minLength: 20, required: true },
      resize: true,
      Component: TextAreaField,
      controlGroup: {
        title: i18next.t('Tell us more'),
        required: true,
      },
    },
  },
});

export default getEcommercePrimaryReasonFields;
