import useMatchBreakpoint from './use-match-breakpoint';
import { breakpoints } from '../constants';

const useMatchXLBreakpoint = () => {
  const matches = useMatchBreakpoint(breakpoints.xl);

  return matches;
};

export default useMatchXLBreakpoint;
