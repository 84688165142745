import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { designSystem } from '@yola/ws-ui';

const { ActionButton } = designSystem;

const NavItem = ({ className, ...props }) => (
  <div className={classNames('ws-my-navbar__nav-item', className)}>
    <ActionButton {...props} format="text" size="medium" fontWeight="regular" appearance="accent" />
  </div>
);

NavItem.propTypes = {
  className: PropTypes.string,
};

NavItem.defaultProps = {
  className: null,
};

export default NavItem;
