/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { constants, selectors } from '@yola/subscription-manager-js';
import { reactHookForm } from '@yola/ws-ui';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import getCountries from 'src/js/common/helpers/get-countries';
import PersonalInfoSection from 'src/js/modules/profile/components/personal-info-section';
import user from 'src/js/modules/user';
import fieldNames from 'src/js/modules/profile/constants/field-names';
import segment from 'src/js/modules/analytics/segment';
import isPhoneNumber from '../../../utils/is-phone-number';
import { snakeCaseObjectToCamelCase } from '../../../utils/convert-case';
import sectionIds from '../constants/section-ids';
import { appearanceTypes } from '../constants/common';
import errorCodes from '../constants/error-codes';

const {
  constants: { events },
  track,
} = segment;
const { useForm } = reactHookForm;
const { ACTIVE, CANCELED } = constants.SubscriptionStatus;
const { NAME, SURNAME, PRIMARY_PHONE, MOBILE_PHONE, OTHER_PHONE, LOCATION } = fieldNames;

const CURRENT_SECTION_ID = sectionIds.PERSONAL_INFO;

const getCaptions = () => ({
  title: i18next.t('Personal info'),
  notificationMessage: i18next.t('Your personal information has been successfully updated'),
  serviceError: i18next.t('Oops! Something went wrong. Please try again later.'),
  submit: i18next.t('Save'),
  [NAME]: {
    label: i18next.t('First name'),
    placeholder: i18next.t('Enter first name'),
  },
  [SURNAME]: {
    label: i18next.t('Last name'),
    placeholder: i18next.t('Enter last name'),
  },
  [LOCATION]: {
    label: i18next.t('Location'),
  },
  [PRIMARY_PHONE]: {
    label: i18next.t('Primary phone'),
  },
  [MOBILE_PHONE]: {
    label: i18next.t('Additional phone 1'),
  },
  [OTHER_PHONE]: {
    label: i18next.t('Additional phone 2'),
  },
  validationErrors: {
    required: i18next.t('Please fill in this field'),
    invalid: i18next.t('The field is invalid'),
    invalidPhone: i18next.t('This field should be a valid phone number'),
    minLength: (minLength) =>
      i18next.t('Ensure this field has at least {minLength} characters', { minLength }),
    maxLength: (maxLength) =>
      i18next.t('Ensure this field has no more than {maxLength} characters', { maxLength }),
  },
});

function PersonalInfoContainer({ notification, setNotification }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector(user.selectors.getUserData, shallowEqual);
  const userPreferences = useSelector(user.selectors.getUserPreferences, shallowEqual);
  const activeHostingSubscription = useSelector(
    selectors.getActiveHostingSubscription,
    shallowEqual
  );

  const captions = useMemo(getCaptions, []);

  const locationOptions = useMemo(
    () =>
      Object.entries(getCountries()).map(([value, { name }]) => ({
        value: value.toUpperCase(),
        label: name,
      })),
    []
  );

  const arePhonesAvailable = useMemo(() => {
    if (!activeHostingSubscription) return false;
    if (activeHostingSubscription.status === ACTIVE) return true;
    if (activeHostingSubscription.status !== CANCELED) return false;

    const currentDate = new Date();
    const expiryDate = new Date(activeHostingSubscription.expiry_date || null);

    return expiryDate >= currentDate;
  }, [activeHostingSubscription]);

  const { control, handleSubmit, setError, getValues } = useForm({
    defaultValues: {
      [NAME]: userData.name || '',
      [SURNAME]: userData.surname || '',
      [LOCATION]: userPreferences.location || locationOptions[0]?.value || '',
      [PRIMARY_PHONE]: userData.telephoneNumbers?.primaryPhone || '',
      [MOBILE_PHONE]: userData.telephoneNumbers?.mobilePhone || '',
      [OTHER_PHONE]: userData.telephoneNumbers?.otherPhone || '',
    },
  });

  const handleServiceValidationError = (fields = {}) => {
    const invalidFields = Object.entries(fields).reduce(
      (acc, [fieldName, fieldValue]) =>
        fieldName === fieldNames.TELEPHONE_NUMBERS
          ? [...acc, ...Object.keys(fieldValue)]
          : [...acc, fieldName],
      []
    );

    if (!invalidFields.length) {
      setNotification({
        sectionId: CURRENT_SECTION_ID,
        title: captions.serviceError,
        appearance: appearanceTypes.DANGER,
      });

      return;
    }

    invalidFields.forEach((fieldName) => {
      setError(fieldName, { type: 'custom', message: captions.validationErrors.invalid });
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      await dispatch(
        user.thunks.updateUserData({
          name: data[NAME],
          surname: data[SURNAME],
          location: data[LOCATION],
          ...(arePhonesAvailable && {
            telephoneNumbers: {
              primaryPhone: data[PRIMARY_PHONE],
              mobilePhone: data[MOBILE_PHONE],
              otherPhone: data[OTHER_PHONE],
            },
          }),
        })
      );

      setNotification({
        sectionId: CURRENT_SECTION_ID,
        title: captions.notificationMessage,
        appearance: appearanceTypes.SUCCESS,
      });

      track(events.PROFILE_PAGE_PERSONAL_INFO_UPDATED, {
        firstName: !!data[NAME],
        lastName: !!data[SURNAME],
        location: data[LOCATION],
        phone: !!data[PRIMARY_PHONE],
      });
    } catch (error) {
      const { code, fields } = error || {};

      if (code === errorCodes.FIELD_VALIDATION_FAILURE) {
        handleServiceValidationError(snakeCaseObjectToCamelCase(fields));
      } else {
        setNotification({
          sectionId: CURRENT_SECTION_ID,
          title: captions.serviceError,
          appearance: appearanceTypes.DANGER,
        });
      }

      track(events.PROFILE_PAGE_ERROR_DISPLAYED, {
        section: CURRENT_SECTION_ID,
      });
    }

    setIsLoading(false);
  };

  const validatePhoneNumber = useCallback(
    (value) => !value || isPhoneNumber(value) || captions.validationErrors.invalidPhone,
    [captions]
  );

  const nameRules = {
    maxLength: {
      value: 150,
      message: captions.validationErrors.maxLength(150),
    },
  };

  const firstNameRules = {
    ...nameRules,
    required: captions.validationErrors.required,
  };

  const locationRules = {
    required: captions.validationErrors.required,
  };

  const phoneRules = {
    minLength: {
      value: 6,
      message: captions.validationErrors.minLength(6),
    },
    maxLength: {
      value: 30,
      message: captions.validationErrors.maxLength(30),
    },
    validate: validatePhoneNumber,
  };

  return (
    <PersonalInfoSection
      control={control}
      captions={captions}
      locationOptions={locationOptions}
      isLoading={isLoading}
      arePhonesAvailable={arePhonesAvailable}
      onSubmit={handleSubmit(onSubmit)}
      firstNameRules={firstNameRules}
      lastNameRules={nameRules}
      locationRules={locationRules}
      phoneRules={phoneRules}
      {...(notification?.sectionId === CURRENT_SECTION_ID && {
        notification,
      })}
    />
  );
}

PersonalInfoContainer.propTypes = {
  notification: PropTypes.shape({
    sectionId: PropTypes.string,
    title: PropTypes.string,
    appearance: PropTypes.string,
  }),
  setNotification: PropTypes.func.isRequired,
};

PersonalInfoContainer.defaultProps = {
  notification: null,
};

export default PersonalInfoContainer;
