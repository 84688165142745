import axios from 'axios';
import i18next from 'i18next';
import config from 'src/js/modules/config';
import user from 'src/js/modules/user';
import getCookie from 'src/js/utils/get-cookie';
import actions from '../actions';
import constants from '../constants';

const loadTranslations = () => async (dispatch, getState) => {
  const state = getState();
  const localeCookie = config.selectors.getLocaleCookie(state);
  const supportedLocales = config.selectors.getBaseLanguageCodes(state);
  const userPreferences = user.selectors.getUserPreferences(state);
  const localeFromCookie = getCookie(localeCookie);
  const localeFromPreferences = supportedLocales.includes(userPreferences.locale)
    ? userPreferences.locale
    : constants.DEFAULT_LOCALE;
  const locale = localeFromPreferences || localeFromCookie;

  if (i18next.hasResourceBundle(locale, constants.DEFAULT_I18NEXT_NAMESPACE)) {
    dispatch(actions.setLocale(locale));
    await i18next.changeLanguage(locale);
    return;
  }

  try {
    const response = await axios.get(`/static/locale/${locale}.json`);
    i18next.addResourceBundle(
      locale,
      constants.DEFAULT_I18NEXT_NAMESPACE,
      response.data,
      true,
      true
    );
    dispatch(actions.setLocale(locale));
    await i18next.changeLanguage(locale);
  } catch (e) {
    const response = await axios.get(`/static/locale/${constants.DEFAULT_LOCALE}.json`);
    i18next.addResourceBundle(
      constants.DEFAULT_LOCALE,
      constants.DEFAULT_I18NEXT_NAMESPACE,
      response.data,
      true,
      true
    );
    dispatch(actions.setLocale(constants.DEFAULT_LOCALE));
    await i18next.changeLanguage(constants.DEFAULT_LOCALE);
  }
};

export default loadTranslations;
