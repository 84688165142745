import dialogTypes from '../constants/dialog-types';
import registerDialog from '../helpers/register-dialog';
import DeleteSiteDialogContainer from '../containers/delete-site-dialog-container';
import PendingDialog from '../components/pending-dialog';
import TermsOfUseDialogContainer from '../containers/terms-of-use-dialog-container';
import DomainSearchFailureDialog from '../../domain-selector/components/domain-search-failure-dialog';
import PaymentMethodFormDialog from '../../account-management/components/payment-method-form-dialog';
import SubscriptionExpiredDialogContainer from '../../account-management/containers/subscription-expired-dialog-container';
import DeletePaymentMethodDialog from '../../account-management/components/delete-payment-method-dialog';
import DeleteDefaultPaymentMethodDialog from '../../account-management/components/delete-default-payment-method-dialog';
import SetDefaultPaymentMethodDialog from '../../account-management/components/set-default-payment-method-dialog';
import CancelPlanDialogContainer from '../../account-management/containers/cancel-plan-dialog-container';
import DifmPromoServicesDialogContainer from '../../difm-promo/containers/difm-promo-services-dialog-container';
import DifmPromoSurveyDialogContainer from '../../difm-promo/containers/difm-promo-survey-dialog-container';
import DifmPromoPackagesDialogContainer from '../../difm-promo/containers/difm-promo-packages-dialog-container';
import DifmPromoFinishDialogContainer from '../../difm-promo/containers/difm-promo-finish-dialog-container';
import DeleteAccountDialog from '../../profile/components/delete-account-dialog';
import ChangePasswordDialogContainer from '../../profile/containers/change-password-dialog-container';
import StripeLoadingErrorContainer from '../../stripe/containers/stripe-loading-error-container';

function registerDialogs() {
  registerDialog(dialogTypes.DELETE_SITE, DeleteSiteDialogContainer);
  registerDialog(dialogTypes.PENDING, PendingDialog);
  registerDialog(dialogTypes.DOMAIN_SEARCH_FAILURE, DomainSearchFailureDialog);
  registerDialog(dialogTypes.PAYMENT_METHOD_FORM, PaymentMethodFormDialog);
  registerDialog(dialogTypes.SUBSCRIPTION_EXPIRED, SubscriptionExpiredDialogContainer);
  registerDialog(dialogTypes.DELETE_PAYMENT_METHOD, DeletePaymentMethodDialog);
  registerDialog(dialogTypes.DELETE_DEFAULT_PAYMENT_METHOD, DeleteDefaultPaymentMethodDialog);
  registerDialog(dialogTypes.SET_DEFAULT_PAYMENT_METHOD, SetDefaultPaymentMethodDialog);
  registerDialog(dialogTypes.CANCEL_PLAN, CancelPlanDialogContainer);
  registerDialog(dialogTypes.DIFM_PROMO_SERVICES, DifmPromoServicesDialogContainer);
  registerDialog(dialogTypes.DIFM_PROMO_SURVEY, DifmPromoSurveyDialogContainer);
  registerDialog(dialogTypes.DIFM_PROMO_PACKAGES, DifmPromoPackagesDialogContainer);
  registerDialog(dialogTypes.DIFM_PROMO_FINISH, DifmPromoFinishDialogContainer);
  registerDialog(dialogTypes.DELETE_ACCOUNT, DeleteAccountDialog);
  registerDialog(dialogTypes.CHANGE_PASSWORD, ChangePasswordDialogContainer);
  registerDialog(dialogTypes.TERMS_OF_USE_DIALOG, TermsOfUseDialogContainer);
  registerDialog(dialogTypes.STRIPE_LOADING_ERROR, StripeLoadingErrorContainer);
}

export default registerDialogs;
