import React, { Fragment, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import products from '../../products';
import dialogs from '../../dialogs';
import segment from '../../analytics/segment';
import processBillingProfile from '../helpers/process-billing-profile';
import { PAYMENT_METHOD_ITEM_PROP_TYPES } from '../components/payment-method-item/constants';
import computePaymentMethodTrait from '../../analytics/segment/helpers/compute-payment-method-trait';

const {
  track,
  constants: { events },
} = segment;

const getCaptions = () => ({
  replaceWithExistingNotificationTitle: i18next.t('Your default payment method has been updated'),
  replaceNotificationDescription: i18next.t(
    "Your auto-bill service agreement will continue until you cancel. You can cancel at any time and won't be billed for the next cycle"
  ),
});

const SetAsDefaultPaymentMethodRoute = ({
  match,
  closeUrl,
  paymentMethods,
  setServiceError,
  setNotificationMessage,
}) => {
  const { methodId } = match.params;
  const dispatch = useDispatch();
  const captions = getCaptions();

  const targetMethod = useMemo(
    () => paymentMethods.find(({ id }) => id === methodId),
    [paymentMethods, methodId]
  );

  const eventTraits = { paymentMethod: computePaymentMethodTrait(targetMethod.type) };

  const submitSetDefault = async () => {
    try {
      const response = await dispatch(products.thunks.setBillingProfileAsDefault(methodId));
      const updatedDefaultMethod = processBillingProfile(response);
      const {
        captions: { title },
      } = updatedDefaultMethod;

      setNotificationMessage({
        title: (
          <Fragment>
            {captions.replaceWithExistingNotificationTitle} <mark>({title})</mark>
          </Fragment>
        ),
        description: captions.replaceNotificationDescription,
      });

      track(events.SET_DEFAULT_PAYMENT_METHOD_DIALOG_SUBMITTED, eventTraits);
    } catch (e) {
      setServiceError(true);
    }

    dispatch(push(closeUrl));
  };

  const cancelSetDefault = () => {
    track(events.SET_DEFAULT_PAYMENT_METHOD_DIALOG_CANCELED, eventTraits);

    dispatch(push(closeUrl));
  };

  useEffect(() => {
    if (!targetMethod) {
      dispatch(push(closeUrl));
      return null;
    }

    dispatch(
      dialogs.actions.show(dialogs.dialogTypes.SET_DEFAULT_PAYMENT_METHOD, {
        paymentMethod: targetMethod,
        statusName: products.statusNames.SET_BILLING_PROFILE_AS_DEFAULT,
        onSubmit: submitSetDefault,
        onCancel: cancelSetDefault,
      })
    );

    track(events.SET_DEFAULT_PAYMENT_METHOD_DIALOG_DISPLAYED, eventTraits);

    return () => dispatch(dialogs.actions.hide());
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return null;
};

SetAsDefaultPaymentMethodRoute.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  closeUrl: PropTypes.string.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape(PAYMENT_METHOD_ITEM_PROP_TYPES)).isRequired,
  setServiceError: PropTypes.func,
  setNotificationMessage: PropTypes.func,
};

SetAsDefaultPaymentMethodRoute.defaultProps = {
  setServiceError: Function.prototype,
  setNotificationMessage: Function.prototype,
};

export default SetAsDefaultPaymentMethodRoute;
