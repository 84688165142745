import actionTypes from '../constants/action-types';

const setCriticalError = ({ error, logSentry, ...rest }) => ({
  type: actionTypes.SET_CRITICAL_ERROR,
  payload: {
    error: {
      error,
      logSentry,
      ...rest,
    },
  },
});

export default setCriticalError;
