import { configure } from '@yola/webapp-plugins';
import config from '../../config';
import i18n from '../../i18n';

const configuration = (store) => (next) => (action) => {
  switch (action.type) {
    case config.actionTypes.SET_SERVICE_CONFIG: {
      next(action);

      const state = store.getState();
      const { common: commonConfig } = config.selectors.getConfig(state);
      configure({ commonConfig });
      break;
    }
    case i18n.actionTypes.SET_LOCALE: {
      next(action);
      const { locale } = action.payload;
      configure({ locale });
      break;
    }
    default: {
      next(action);
    }
  }
};

export default configuration;
