import React from 'react';
import { useDispatch } from 'react-redux';
import { AlertDialog } from '@yola/ws-ui';
import i18next from 'i18next';
import { push } from 'connected-react-router';
import routesMap from '../../../router/helpers/routes-map';
import supportedAccountTabs from '../../account-management/constants/supported-tabs';
import segment from '../../analytics/segment';

const {
  constants: { triggerIds },
} = segment;

function StripeLoadingErrorContainer() {
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(
      push(routesMap.accountManagement.url({ tabId: supportedAccountTabs.PAYMENT_METHODS }), {
        triggerId: triggerIds.ACCOUNT_SETTINGS,
      })
    );
  };

  return (
    <AlertDialog
      captions={{
        title: i18next.t('Something went wrong'),
        description: i18next.t('Try again'),
        dismiss: i18next.t('Dismiss'),
      }}
      glyph="alert-circle"
      heigh="auto"
      onDismiss={handleDismiss}
    />
  );
}

export default StripeLoadingErrorContainer;
