import PropTypes from 'prop-types';
import React from 'react';
import { designSystem } from '@yola/ws-ui';

const { IconButton } = designSystem;

const Menu = ({ glyph, ...props }) => (
  <div className="ws-my-navbar__menu">
    <IconButton {...props} glyph={glyph} />
  </div>
);

Menu.propTypes = {
  glyph: PropTypes.string,
};

Menu.defaultProps = {
  glyph: 'floating-menu',
};

export default Menu;
