import { serviceProvider } from '@yola/subscription-manager-js';
import status from 'src/js/modules/status';
import statusNames from '../constants/status-names';

const reactivateSubscription = (subscriptionId) => async (dispatch) => {
  dispatch(status.actions.setStatus(statusNames.REACTIVATE_SUBSCRIPTION, status.constants.LOADING));

  try {
    await serviceProvider.reactivateSubscription(subscriptionId);
    dispatch(
      status.actions.setStatus(statusNames.REACTIVATE_SUBSCRIPTION, status.constants.SUCCEEDED)
    );
  } catch (error) {
    console.error(error);
    dispatch(
      status.actions.setStatus(statusNames.REACTIVATE_SUBSCRIPTION, status.constants.FAILED)
    );
  }
};

export default reactivateSubscription;
