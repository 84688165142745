import { SCRIPT_ID } from '../constants/common';

const injectZendeskScript = (url) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.id = SCRIPT_ID;
    script.onload = resolve;
    script.onerror = reject;
    script.src = url;

    document.body.appendChild(script);
  });

export default injectZendeskScript;
