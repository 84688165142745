import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import config from 'src/js/modules/config';
import user from 'src/js/modules/user';
import legacyUrlsMap from 'src/js/router/helpers/legacy-urls-map';
import routesMap from 'src/js/router/helpers/routes-map';
import MainMenu from 'src/js/common/components/main-menu';
import useAvailablePlatformComponents from 'src/js/modules/user/hooks/use-available-platform-components';
import NavbarHeader from 'src/js/common/components/navbar-header';
import isUrlMatchesRoutes from '../helpers/is-url-matches-routes';

const { platformComponents } = user.constants;

function NavigationContainer() {
  const availablePlatformComponents = useAvailablePlatformComponents();
  const onlineStoreUrl = useSelector(config.selectors.getOnlineStoreUrl);
  const analyticsUrl = useSelector(config.selectors.getAnalyticsUrl);
  const location = useLocation();
  const dispatch = useDispatch();

  const navigateToDashboard = () => {
    dispatch(push(routesMap.dashboard.url()));
  };

  const { pathname = '' } = location;

  const siteTrafficMenu = useMemo(() => {
    const menu = [];

    if (availablePlatformComponents.includes(platformComponents.ANALYTICS)) {
      menu.push({
        title: i18next.t('Analytics'),
        href: analyticsUrl,
      });
    }

    if (availablePlatformComponents.includes(platformComponents.TRAFFIC_BUILDER)) {
      menu.push({
        title: i18next.t('Traffic Builder'),
        href: legacyUrlsMap.trafficBuilder(),
      });
    }

    if (availablePlatformComponents.includes(platformComponents.SITEWIT)) {
      menu.push({
        title: i18next.t('SiteWit'),
        href: legacyUrlsMap.sitewit(),
      });
    }

    return menu;
  }, [availablePlatformComponents, analyticsUrl]);

  return (
    <React.Fragment>
      <NavbarHeader.NavItem
        selected={isUrlMatchesRoutes(pathname, routesMap.dashboard.patterns)}
        onClick={navigateToDashboard}
        label={i18next.t('Dashboard')}
      />
      {availablePlatformComponents.includes(platformComponents.DOMAINS) && (
        <NavbarHeader.NavItem
          target="_self"
          href={legacyUrlsMap.domains()}
          label={i18next.t('Domains')}
        />
      )}
      {availablePlatformComponents.includes(platformComponents.EMAIL) && (
        <NavbarHeader.NavItem
          target="_self"
          href={legacyUrlsMap.email()}
          label={i18next.t('Email')}
        />
      )}
      {availablePlatformComponents.includes(platformComponents.ECOMMERCE) && (
        <NavbarHeader.NavItem href={onlineStoreUrl} label={i18next.t('Online Store')} />
      )}
      {Boolean(siteTrafficMenu.length) && (
        <MainMenu
          captions={{
            name: i18next.t('Site Traffic'),
          }}
          className="ws-mainmenu--site-traffic"
          items={siteTrafficMenu}
        />
      )}
    </React.Fragment>
  );
}

export default NavigationContainer;
