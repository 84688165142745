import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next';
import { push } from 'connected-react-router';
import routesMap from 'src/js/router/helpers/routes-map';
import redirectToUrl from '../../utils/redirect-to-url';
import config from '../../modules/config';
import user from '../../modules/user';

function useBackButton(backUrl) {
  const baseUrl = useSelector(config.selectors.getMyyolaUrl);
  const isB2c = useSelector(user.selectors.getIsB2C);
  const dispatch = useDispatch();
  const shouldRedirectToHome = !document.referrer;

  const onBackButtonClick = useCallback(() => {
    if (backUrl) {
      redirectToUrl(backUrl);
      return;
    }

    if (shouldRedirectToHome) {
      if (isB2c) {
        redirectToUrl(baseUrl);
      } else {
        dispatch(push(routesMap.home.url()));
      }
    } else {
      window.history.back();
    }
  }, [dispatch, baseUrl, shouldRedirectToHome, isB2c, backUrl]);

  return {
    backButtonLabel: i18next.t('Back'),
    onBackButtonClick,
  };
}

export default useBackButton;
