import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import i18next from 'i18next';
import throttle from 'lodash.throttle';
import { MultipurposePaywall } from '@yola/webapp-plugins';
import { selectors, verifiers } from '@yola/subscription-manager-js';
import redirectToUrl from 'src/js/utils/redirect-to-url';
import useBackButton from 'src/js/router/hooks/use-back-button';
import useFeatureFlags from '../../feature-flags/hooks/use-feature-flags';
import segment from '../../analytics/segment';
import user from '../../user';
import dialogs from '../../dialogs';
import config from '../../config';
import {
  premiumFeatures,
  productCategories,
  purchaseFlowTypes,
} from '../../common/constants/premium-features';

import { MAP_TERM_TO_READABLE_NAME, PERIOD_VALUES } from '../../common/constants/terms';
import { DEFAULT_INITIATOR } from '../../common/constants';
import usePaymentMethods from '../hooks/use-payment-methods';

const {
  constants: { events, triggerIds },
  track,
  trackAsync,
} = segment;

function PaywallContainer({
  initiator,
  premiumFeature,
  domain,
  periodToggle,
  domainProposal,
  extraProducts,
  term,
  triggerId,
  showDomainSelector,
  backUrl,
  paymentBackUrl,
}) {
  const currentPlanType = useSelector(selectors.getCurrentPackageType);
  const { currency } = useSelector(user.selectors.getUserPreferences, shallowEqual);
  const { id: userId } = useSelector(user.selectors.getUserData, shallowEqual);
  const baseUrl = useSelector(config.selectors.getMyyolaUrl);
  const subscriptionsList = useSelector(selectors.getSubscriptionList, shallowEqual);
  const availablePlans = useSelector(selectors.getHostingPackages, shallowEqual);
  const paymentMethods = usePaymentMethods();
  const partnerData = useSelector(user.selectors.getPartnerData, shallowEqual);
  const moneyBackGuarantee = useSelector(user.selectors.getMoneyBackGuarantee);
  const { backButtonLabel, onBackButtonClick } = useBackButton(backUrl);
  const hasExtraProducts = Boolean(extraProducts.length);
  const isB2C = useSelector(user.selectors.getIsB2C);
  const [featureFlags] = useFeatureFlags([
    'ai_site_wizard',
    'ai_page_wizard',
    'ai_block_generator',
    'ai_text_assistant',
    'ai_text_assistant_rewrite',
    'ai_text_assistant_write',
    'site_export',
    'advanced_color_customization',
  ]);

  const defaultB2CDescription = {
    ...(isB2C && {
      first: i18next.t('Select premium features that you need to build a professional website.'),
      second: i18next.t('Get visitors, sell products, and grow your business online'),
    }),
  };

  const ecommerceDependentDescription = {
    first: i18next.t(
      'To unlock your Online store’s paid subscription, you must first upgrade your hosting plan'
    ),
  };

  const defaultDescription = isB2C ? defaultB2CDescription : {};

  const captions = {
    backButtonLabel,
    description:
      triggerId === triggerIds.ECOMMERCE_PAYWALL
        ? ecommerceDependentDescription
        : defaultDescription,
  };

  const dispatch = useDispatch();

  const trackingData = useMemo(
    () => ({
      siteId: null,
      templateBuildSlug: null,
      premiumFeature,
      purchaseFlowType: isB2C ? purchaseFlowTypes.B2C : purchaseFlowTypes.WL,
      productCategory: productCategories.LATITUDE,
    }),
    [premiumFeature, isB2C]
  );

  const paywallProps = useMemo(
    () => ({
      initiator: initiator || DEFAULT_INITIATOR,
      ...(isB2C && domain && { domainName: domain }),
      ...(term && { defaultPaymentPeriod: PERIOD_VALUES[term] }),
      ...(paymentBackUrl && {
        closeUrl: paymentBackUrl,
        successUrl: paymentBackUrl,
        backUrl: paymentBackUrl,
        failUrl: paymentBackUrl,
      }),
      ...(premiumFeature === premiumFeatures.TRANSFER_DOMAIN && {
        closeUrl: urlJoin(baseUrl, '/domains/#transfer-domain'),
        successUrl: urlJoin(baseUrl, '/domains/#transfer-domain'),
        backUrl: urlJoin(baseUrl, '/domains/#transfer-domain'),
        failUrl: urlJoin(baseUrl, '/domains/#transfer-domain'),
      }),
      ...(hasExtraProducts && { products: extraProducts }),
      ...(periodToggle === false && { periodTogglerHidden: true }),
    }),
    [
      initiator,
      domain,
      term,
      premiumFeature,
      baseUrl,
      hasExtraProducts,
      extraProducts,
      isB2C,
      periodToggle,
      paymentBackUrl,
    ]
  );

  const handleBackButtonClick = async () => {
    await trackAsync(events.PLAN_SELECTOR_BACK_BUTTON_CLICKED, trackingData);

    dispatch(dialogs.actions.hide());
    onBackButtonClick();
  };

  const handlePeriodChange = (selectedPlan, selectedTerm) => {
    track(events.PLAN_SELECTOR_TERM_TOGGLE_CLICKED, {
      ...trackingData,
      selectedTerm,
    });
  };

  const handleTooltipHover = (featureTooltip) => {
    track(events.PLAN_SELECTOR_TOOLTIP_DISPLAYED, {
      ...trackingData,
      featureTooltip,
    });
  };

  useEffect(() => {
    track(events.PLAN_SELECTOR_DISPLAYED, trackingData);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const throttledPageScroll = throttle(() => {
      const documentHeight = document.body.offsetHeight;
      const currentScroll = window.scrollY + window.innerHeight;

      if (currentScroll >= documentHeight) {
        track(events.PLAN_SELECTOR_SCROLLED_TO_BOTTOM, trackingData);
      }
    }, 100);

    document.addEventListener('scroll', throttledPageScroll);

    return () => document.removeEventListener('scroll', throttledPageScroll);
  }, [trackingData]);

  const handleSelect = async (paymentUrl, selectedPlan, selectedTerm) => {
    const hadDomainSubscription = subscriptionsList.some(({ type }) =>
      verifiers.isDomainPackageType(type)
    );
    const shouldShowDomainSelector =
      domainProposal &&
      isB2C &&
      !domain &&
      selectedTerm === MAP_TERM_TO_READABLE_NAME.perYear &&
      !hadDomainSubscription;

    dispatch(dialogs.actions.show(dialogs.dialogTypes.PENDING));

    await trackAsync(events.PLAN_SELECTOR_PLAN_SELECT_BUTTON_CLICKED, {
      ...trackingData,
      selectedTerm,
      selectedPlan,
    });

    if (shouldShowDomainSelector) {
      dispatch(dialogs.actions.hide());
      showDomainSelector(selectedPlan, selectedTerm);

      return;
    }

    redirectToUrl(paymentUrl);
  };

  return (
    <MultipurposePaywall
      captions={captions}
      view="content"
      currentPlan={currentPlanType}
      userId={userId}
      currency={currency}
      onSelect={handleSelect}
      onBackButtonClick={handleBackButtonClick}
      onTooltipHover={handleTooltipHover}
      onPeriodChange={handlePeriodChange}
      isB2C={isB2C}
      availablePlans={availablePlans}
      moneyBackGuarantee={moneyBackGuarantee}
      paymentMethods={paymentMethods}
      partnerId={partnerData.id}
      partnerName={partnerData.name}
      featureFlags={featureFlags}
      {...paywallProps}
    />
  );
}

PaywallContainer.propTypes = {
  initiator: PropTypes.string,
  premiumFeature: PropTypes.string,
  domain: PropTypes.string,
  extraProducts: PropTypes.arrayOf(PropTypes.string),
  term: PropTypes.string,
  backUrl: PropTypes.string,
  triggerId: PropTypes.string,
  showDomainSelector: PropTypes.func.isRequired,
  periodToggle: PropTypes.bool,
  domainProposal: PropTypes.bool,
  paymentBackUrl: PropTypes.string,
};

PaywallContainer.defaultProps = {
  initiator: '',
  premiumFeature: premiumFeatures.DEFAULT,
  domain: '',
  extraProducts: [],
  term: '',
  backUrl: '',
  triggerId: '',
  periodToggle: true,
  domainProposal: true,
  paymentBackUrl: '',
};

export default PaywallContainer;
