import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import SectionInfo from 'src/js/common/components/section-info';
import IconStatus from 'src/js/modules/profile/components/icon-status';

const { ActionButton, Stack, Box, CheckboxGroup, Checkbox, Heading, Paragraph } = designSystem;
const CHECKBOX_ID = 'verify-account-deletion';

function AccountChangesSection(props) {
  const { captions, onDelete, isDeletingDisabled, onCancellationTextClick } = props;
  const [isVerified, setVerifiedState] = useState(false);

  const toggleVerifiedState = () => {
    setVerifiedState((prevState) => !prevState);
  };

  return (
    <SectionInfo title={captions.title} titleAppearance="danger">
      <Stack gap="spacing-m">
        <Stack>
          {!isDeletingDisabled && (
            <Stack>
              <Heading type="heading-5">{captions.heading}</Heading>
              <Paragraph appearance="medium-emphasis">{captions.description}</Paragraph>
            </Stack>
          )}
          <Box marginTop="spacing-3-xs">
            {isDeletingDisabled ? (
              <IconStatus iconGlyph="close" iconType="error">
                <Paragraph>
                  <span
                    // eslint-disable-next-line yola/react-hooks/exhaustive-deps,yola/react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: `${captions.unavailableText} ${captions.unavailableTextDetails}`,
                    }}
                    onClick={onCancellationTextClick}
                  />
                </Paragraph>
              </IconStatus>
            ) : (
              <IconStatus iconGlyph="check" iconType="success">
                <Paragraph>{captions.availableText}</Paragraph>
              </IconStatus>
            )}
          </Box>
        </Stack>
        <Stack>
          <CheckboxGroup>
            <Checkbox
              onChange={toggleVerifiedState}
              id={CHECKBOX_ID}
              value={CHECKBOX_ID}
              label={captions.verifyLabel}
              isLabelBold={false}
              checked={isVerified}
              disabled={isDeletingDisabled}
            />
          </CheckboxGroup>
          <Box marginTop="spacing-2-xs">
            <ActionButton
              label={captions.deleteButton}
              format="solid"
              onClick={onDelete}
              appearance="danger"
              size="large"
              disabled={isDeletingDisabled || !isVerified}
            />
          </Box>
        </Stack>
      </Stack>
    </SectionInfo>
  );
}

AccountChangesSection.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    verifyLabel: PropTypes.string.isRequired,
    deleteButton: PropTypes.string.isRequired,
    availableText: PropTypes.string.isRequired,
    unavailableText: PropTypes.string.isRequired,
    unavailableTextDetails: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  isDeletingDisabled: PropTypes.bool.isRequired,
  onCancellationTextClick: PropTypes.func.isRequired,
};

export default AccountChangesSection;
