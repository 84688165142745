import { batch } from 'react-redux';
import { camelCaseObjectToSnakeCase, snakeCaseObjectToCamelCase } from 'src/js/utils/convert-case';
import yousersjs from '@yola/yousersjs';
import user from 'src/js/modules/user';
import status from 'src/js/modules/status';
import statusNames from 'src/js/modules/user/constants/status-names';

const updateUserData = (userData) => async (dispatch) => {
  try {
    dispatch(status.actions.setStatus(statusNames.UPDATE_USER_DATA, status.constants.LOADING));
    const response = await yousersjs.user().update(camelCaseObjectToSnakeCase(userData));
    const updatedUserData = snakeCaseObjectToCamelCase(response.body);

    batch(() => {
      dispatch(user.actions.setUserData(updatedUserData));
      dispatch(status.actions.setStatus(statusNames.UPDATE_USER_DATA, status.constants.SUCCEEDED));
    });

    return updatedUserData;
  } catch (e) {
    dispatch(status.actions.setStatus(statusNames.UPDATE_USER_DATA, status.constants.FAILED));
    throw e.response.body;
  }
};

export default updateUserData;
