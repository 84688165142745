import React from 'react';
import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import { constants } from '@yola/subscription-manager-js';
import { PER_MONTH } from 'src/js/modules/common/constants/terms';
import user from 'src/js/modules/user';
import { contentTypes } from '../components/plan-features-row/constants';
import extraPriceTypes from '../../domain-selector/constants/extra-price-types';

const { Mark, Icon } = designSystem;
const { platformComponents } = user.constants;
const { hostingPackageFeatureNames: featureNames } = constants;

const fromMbToGb = (mb) => mb / 1024;
const getCustomerCareText = (type) =>
  ({
    limited: i18next.t('Limited Customer Care'),
    standard: i18next.t('24/7 Customer Care'),
    priority: i18next.t('Priority Customer Care'),
  })[type];

const getSiteLanguagesText = (count) => {
  if (typeof count === 'number') return i18next.t('{count} languages', { count });

  return i18next.t('Unlimited languages');
};

const orderedFeatures = [
  featureNames.UPLOAD_SIZE,
  featureNames.SITE_STORAGE_QUOTA,
  featureNames.SITE_COUNT,
  featureNames.FREE_DOMAIN_PROMO,
  featureNames.PAGE_COUNT,
  featureNames.PASSWORD_PROTECTED_PAGES,
  featureNames.PREMIUM_BLOCKS,
  featureNames.PREMIUM_ICONS,
  featureNames.ADVANCED_COLOR_CUSTOMIZATION,
  featureNames.PARTNER_ADS,
  featureNames.ANALYTICS,
  featureNames.SITE_LANGUAGES,
  featureNames.SITE_AUTO_BACKUPS,
  featureNames.TRAFFIC_BUILDER,
  featureNames.CUSTOMER_CARE,
];

const getFeatureRowData = ({
  term,
  sitesCount,
  availablePlatformComponents,
  features,
  name,
  extraPrice,
  domainPurchaseAvailable,
  onFreeDomainPromoClick,
}) => {
  const featureValue = features[name];

  switch (name) {
    case featureNames.UPLOAD_SIZE: {
      return {
        title: i18next.t('Maximum upload file size'),
        contentType: contentTypes.TEXT,
        text: (
          <React.Fragment>
            {`${featureValue.toFixed(1)} MB /`}&nbsp;
            <Mark appearance="medium-emphasis">{i18next.t('file')}</Mark>
          </React.Fragment>
        ),
      };
    }
    case featureNames.SITE_STORAGE_QUOTA: {
      return {
        title: i18next.t('Maximum storage per site'),
        contentType: contentTypes.TEXT,
        text: `${fromMbToGb(featureValue).toFixed(1)} GB`,
      };
    }
    case featureNames.SITE_COUNT: {
      return {
        title: i18next.t('Number of sites'),
        contentType: contentTypes.TEXT,
        text: i18next.t('{count} of {limit}', {
          count: sitesCount,
          limit: featureValue,
        }),
      };
    }
    case featureNames.FREE_DOMAIN_PROMO: {
      if (
        !domainPurchaseAvailable &&
        !availablePlatformComponents.includes(platformComponents.DOMAINS)
      )
        return null;

      let text;
      let buttonLabel;

      if (term === PER_MONTH) {
        text = i18next.t('Not included');
        buttonLabel =
          extraPrice === extraPriceTypes.FREE_COM_DOMAIN
            ? i18next.t('Switch to annual and get a free domain')
            : '';
      } else {
        text = extraPrice ? (
          i18next.t('Included')
        ) : (
          <Mark appearance="success">
            <Icon glyph="check" type="success" /> {i18next.t('Added')}
          </Mark>
        );
        buttonLabel =
          extraPrice === extraPriceTypes.NO_DISCOUNTS ? i18next.t('Get a free domain now') : '';
      }

      return {
        title: i18next.t('Free custom domain'),
        contentType: contentTypes.TEXT,
        text,
        buttonLabel,
        onButtonClick: onFreeDomainPromoClick,
      };
    }
    case featureNames.PAGE_COUNT: {
      return {
        title: i18next.t('Pages on website'),
        contentType: contentTypes.TEXT,
        text: featureValue,
      };
    }
    case featureNames.ADVANCED_COLOR_CUSTOMIZATION: {
      return {
        title: i18next.t('Advanced design customization'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    }
    case featureNames.PASSWORD_PROTECTED_PAGES: {
      return {
        title: i18next.t('Page password protection'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    }
    case featureNames.PREMIUM_BLOCKS: {
      return {
        title: i18next.t('Premium blocks'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    }
    case featureNames.PREMIUM_ICONS: {
      return {
        title: i18next.t('Premium icons'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    }
    case featureNames.PARTNER_ADS: {
      return {
        title: i18next.t('Remove Ads'),
        contentType: !featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    }
    case featureNames.ANALYTICS: {
      if (!availablePlatformComponents.includes(platformComponents.ANALYTICS)) return null;

      return {
        title: i18next.t('Visitors Analytics'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    }
    case featureNames.SITE_LANGUAGES: {
      return {
        title: i18next.t('Multilanguage site'),
        contentType: contentTypes.TEXT,
        text: getSiteLanguagesText(featureValue),
      };
    }
    case featureNames.SITE_AUTO_BACKUPS: {
      return {
        title: i18next.t('Automatic site backups'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    }
    case featureNames.TRAFFIC_BUILDER: {
      if (!availablePlatformComponents.includes(platformComponents.TRAFFIC_BUILDER)) return null;

      return {
        title: i18next.t('Automatic SEO monitoring'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    }
    case featureNames.CUSTOMER_CARE: {
      return {
        title: i18next.t('Customer care'),
        contentType: contentTypes.TEXT,
        text: getCustomerCareText(featureValue),
      };
    }
    default:
      return null;
  }
};

const getHostingPlanFeatures = (props) =>
  orderedFeatures
    .filter((name) => Object.keys(props.features).includes(name))
    .reduce((acc, name) => {
      const featureRowData = getFeatureRowData({ name, ...props });

      return featureRowData ? [...acc, featureRowData] : acc;
    }, []);

export default getHostingPlanFeatures;
