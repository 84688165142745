import React, { useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { push } from 'connected-react-router';
import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import { constants } from '@yola/subscription-manager-js';
import routesMap from 'src/js/router/helpers/routes-map';
import { PER_MONTH } from 'src/js/modules/common/constants/terms';
import LoaderWithText from 'src/js/common/components/loader-with-text';
import useDomainsExtraPrice from 'src/js/modules/domain-selector/hooks/use-domains-extra-price';
import redirectToUrl from 'src/js/utils/redirect-to-url';
import user from 'src/js/modules/user';
import sites from 'src/js/modules/sites';
import status from 'src/js/modules/status';
import products from 'src/js/modules/products';
import subscriptions from 'src/js/modules/subscriptions';
import PlanFeaturesRow from '../components/plan-features-row';
import PlanPaymentDetails from '../components/plan-payment-details';
import PlanActionItem from '../components/plan-action-item';
import getHostingPlanFeatures from '../helpers/get-hosting-plan-features';
import getEcommercePlanFeatures from '../helpers/get-ecommerce-plan-features';
import useSubscriptionDetailsCaptions from '../hooks/use-subscription-details-captions';
import SUBSCRIPTION_META_PROP_SHAPE from '../constants/subscription-meta-prop-shape';
import useQuery from '../../../common/hooks/use-query';
import useSupportedSearchParams from '../../../router/hooks/use-supported-search-params';
import segment from '../../analytics/segment';
import { getCardIconType } from '../helpers/process-billing-profile';
import ErrorNotificationContainer from '../../../common/containers/error-notification-container';
import useActiveUserSubscriptions from '../hooks/use-active-user-subscriptions';
import getPlanDescriptionByType from '../helpers/get-plan-description-by-type';
import utils from '../../webapp-plugins/utils';
import supportedSearchParams from '../../common/constants/supported-search-params';

const { Stack, NotificationMessage, Divider, ActionButton, Box, Paragraph } = designSystem;
const { ACTIVE, CANCELED, EXPIRED } = constants.SubscriptionStatus;
const {
  constants: { events, triggerIds, sourceIds },
  track,
  trackAsync,
} = segment;
const triggerId = triggerIds.SUBSCRIPTION_DETAILS;

const SHOW_LESS_COUNT = 4;

const getCaptions = () => ({
  showLess: i18next.t('Show less'),
  showMore: i18next.t('Show more'),
  upgradePlan: i18next.t('Upgrade plan'),
  renewalPrice: i18next.t('Renewal price'),
  planRenewal: i18next.t('Plan renewal'),
  paymentMethod: i18next.t('Payment method'),
  cancelButton: i18next.t('Cancel'),
  rescheduleButton: i18next.t('Turn on'),
});

const SubscriptionDetailsContainer = ({
  name,
  subscriptionMeta,
  isHostingSubscription,
  features,
  topAvailablePackageType,
  hasActivatedDiscount,
  hasEverHadDiscount,
  isCancelButtonEnabled,
  isLoading,
  isJustDiscountActivated = true,
}) => {
  const { type, term, status: subscriptionStatus, id, auto_renew: autoRenew } = subscriptionMeta;
  const [isExpanded, setExpandedStatus] = useState(false);

  const availablePlatformComponents = user.hooks.useAvailablePlatformComponents();
  const sitesList = useSelector(sites.selectors.getSites, shallowEqual);
  const domainPurchaseAvailable = useSelector(user.selectors.getDomainPurchaseAvailable);
  const reactivationStatus = useSelector(subscriptions.selectors.getSubscriptionReactivationStatus);
  const cancelStatus = useSelector(subscriptions.selectors.getCancelSubscriptionStatus);
  const isB2C = useSelector(user.selectors.getIsB2C);
  const rescheduleStatus = useSelector(subscriptions.selectors.getSubscriptionRescheduleStatus);

  const { data: billingProfiles, isLoading: isBillingProfilesLoading } = useQuery({
    name: products.statusNames.BILLING_PROFILES,
    thunk: products.thunks.fetchBillingProfiles,
    selector: products.selectors.getBillingProfiles,
  });

  const { data: paymentMethods } = useQuery({
    name: products.statusNames.BILLING_PROFILES,
    thunk: products.thunks.fetchBillingProfiles,
    selector: products.selectors.getBillingProfiles,
  });

  const dispatch = useDispatch();

  const serchParams = useSupportedSearchParams() || {};
  const { [supportedSearchParams.RESCHEDULE_SUBSCRIPTION]: subscriptionToReschedule } = serchParams;

  const userSubscriptions = useActiveUserSubscriptions();
  const extraPrice = useDomainsExtraPrice(true);

  const defaultBillingProfile = billingProfiles
    ? billingProfiles.find(({ isDefault }) => isDefault)
    : null;
  const paymentMethod = defaultBillingProfile?.cardType || defaultBillingProfile?.type;
  const isReactivating = reactivationStatus === status.constants.LOADING;
  const isError = reactivationStatus === status.constants.FAILED;
  const isJustCanceled = cancelStatus === status.constants.SUCCEEDED;
  const currentLocation = window.location.href;

  const analyticsParams = {
    triggerId,
    type,
    term,
    category: isHostingSubscription ? triggerIds.HOSTING : triggerIds.ONLINE_STORE,
  };

  const switchToAnnual = useCallback(async () => {
    await trackAsync(events.SWITCH_TO_ANNUAL_TRIGGER_CLICKED, {
      triggerId,
    });
    redirectToUrl(
      utils.generateAnnualPaymentUrl({ products: [type], backUrl: currentLocation }, isB2C)
    );
  }, [type, isB2C, currentLocation]);

  const getFreeDomain = useCallback(() => {
    track(events.BUY_A_DOMAIN_TRIGGER_CLICKED, {
      triggerId,
      freeDomainPromo: true,
    });

    dispatch(
      push(
        routesMap.domainSelector.url({
          query: { paymentBackUrl: currentLocation },
        })
      )
    );
  }, [dispatch, currentLocation]);

  const redirectToPaywall = () => {
    track(events.UPGRADE_TRIGGER_CLICKED, {
      triggerId,
    });
    dispatch(
      push(
        isHostingSubscription
          ? routesMap.paywall.url({ query: { triggerId, paymentBackUrl: currentLocation } })
          : routesMap.ecommercePaywall.url({
              query: { triggerId, paymentBackUrl: currentLocation },
            })
      )
    );
  };
  const onShowMoreClick = () => setExpandedStatus((bool) => !bool);
  const renewSubscription = async () => {
    await trackAsync(events.RENEW_TRIGGER_CLICKED, {
      ...analyticsParams,
      status: subscriptionStatus,
    });
    redirectToUrl(
      utils.generatePaymentUrl({ products: [type], term, backUrl: currentLocation }, isB2C)
    );
  };
  const onCancel = (e) => {
    e.preventDefault();
    dispatch(
      push(
        routesMap.cancelSubscription.url({
          subId: id,
        }),
        { sourceId: sourceIds.CANCEL_BUTTON }
      )
    );
    track(events.CANCEL_PLAN_TRIGGER_CLICKED, {
      ...analyticsParams,
      discountActivated: hasEverHadDiscount,
      discountExpired: hasEverHadDiscount && !hasActivatedDiscount,
    });
  };
  const reactivateSubscription = () => {
    track(events.REACTIVATE_TRIGGER_CLICKED, analyticsParams);

    if (!isHostingSubscription) {
      const hostingSubscription = userSubscriptions.find(({ isEcommerce }) => !isEcommerce);
      const { subscriptionMeta: hostingSubscriptionMeta } = hostingSubscription || {};

      if (hostingSubscriptionMeta && hostingSubscriptionMeta.status === CANCELED) {
        dispatch(subscriptions.thunks.reactivateSubscription(hostingSubscriptionMeta.id));
      }
    }

    dispatch(subscriptions.thunks.reactivateSubscription(id));
  };
  const onNotificationClick = () => {
    if (subscriptionStatus === CANCELED) {
      reactivateSubscription();
    } else {
      renewSubscription();
    }
  };
  const onJustCanceledNotificationClose = () => {
    dispatch(status.actions.resetStatus(subscriptions.statusNames.CANCEL_SUBSCRIPTION));
    track(events.SUBSCRIPTION_DETAILS_PAGE_NOTIFICATION_CLOSED);
  };
  const clearError = () => {
    dispatch(status.actions.resetStatus(subscriptions.statusNames.REACTIVATE_SUBSCRIPTION));
  };

  const rescheduleSubscription = async (e) => {
    e.preventDefault();

    track(events.AUTO_RENEW_TURN_ON_TRIGGER_CLICKED, {
      ...analyticsParams,
      status: subscriptionStatus,
    });

    if (paymentMethods && paymentMethods.length) {
      await dispatch(subscriptions.thunks.rescheduleSubscription(id));

      return;
    }

    const closeUrl = routesMap.subscriptionDetails.url({ subId: id });
    const successUrl = `${closeUrl}?${supportedSearchParams.RESCHEDULE_SUBSCRIPTION}=${id}`;

    dispatch(
      push(routesMap.addPaymentMethod.url(), {
        triggerId: triggerIds.RESCHEDULE_SUBSCRIPTION,
        closeUrl,
        successUrl,
      })
    );
  };

  const featuresList = useMemo(
    () =>
      isHostingSubscription
        ? getHostingPlanFeatures({
            term,
            sitesCount: sitesList ? sitesList.length : 'X',
            availablePlatformComponents,
            features,
            extraPrice,
            domainPurchaseAvailable,
            onFreeDomainPromoClick: term === PER_MONTH ? switchToAnnual : getFreeDomain,
          })
        : getEcommercePlanFeatures(features),
    [
      term,
      sitesList,
      availablePlatformComponents,
      features,
      extraPrice,
      isHostingSubscription,
      domainPurchaseAvailable,
      switchToAnnual,
      getFreeDomain,
    ]
  );
  const captions = getCaptions();
  const dynamicCaptions = useSubscriptionDetailsCaptions({
    subscriptionMeta,
    defaultBillingProfile,
    paymentMethod,
    isJustCanceled,
    isHostingSubscription,
    userSubscriptions,
    isJustDiscountActivated,
  });
  const planDescriptionCaption = useMemo(() => getPlanDescriptionByType(type, name), [type, name]);

  const showUpgradePlan = type !== topAvailablePackageType;
  const isShowMoreVisible = featuresList.length > SHOW_LESS_COUNT;
  const shouldBeShownDiscount = hasActivatedDiscount && isHostingSubscription;
  const paymentMethodIcon = getCardIconType(paymentMethod);

  let notificationAppearance = '';
  let notificationIconGlyph = '';

  if (subscriptionStatus === CANCELED) {
    notificationAppearance = isJustCanceled ? 'warning' : 'cta';
    notificationIconGlyph = isJustCanceled ? 'attention' : 'renew';
  } else if (subscriptionStatus === EXPIRED) {
    notificationAppearance = 'danger';
    notificationIconGlyph = 'attention';
  } else if (isJustDiscountActivated) {
    notificationAppearance = 'success';
    notificationIconGlyph = 'sale';
  }

  let paymentDetailsButtonProps;

  if (subscriptionStatus === ACTIVE && isCancelButtonEnabled && autoRenew) {
    paymentDetailsButtonProps = {
      caption: captions.cancelButton,
      onClick: onCancel,
      isLoading: cancelStatus === status.constants.LOADING,
    };
  } else if (!autoRenew) {
    paymentDetailsButtonProps = {
      caption: captions.rescheduleButton,
      onClick: rescheduleSubscription,
      isLoading: rescheduleStatus === status.constants.LOADING,
    };
  }

  useEffect(() => {
    if (subscriptionToReschedule) {
      dispatch(subscriptions.thunks.rescheduleSubscription(id));
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [subscriptionToReschedule]);

  useEffect(
    () => dispatch(status.actions.resetStatus(products.statusNames.CREATE_CANCELLATION_COUPON)),
    [dispatch]
  );

  if (isLoading || isBillingProfilesLoading) {
    return <LoaderWithText />;
  }

  return (
    <Stack gap="spacing-m">
      {isError && <ErrorNotificationContainer onClose={clearError} />}
      {dynamicCaptions.notification && (
        <NotificationMessage
          appearance={notificationAppearance}
          iconGlyph={notificationIconGlyph}
          title={dynamicCaptions.notification.title}
          description={dynamicCaptions.notification.description}
          actionButtonLabel={dynamicCaptions.notification.buttonLabel}
          isActionButtonLoading={isReactivating}
          {...(isJustCanceled
            ? { onClose: onJustCanceledNotificationClose }
            : { onActionButtonClick: onNotificationClick })}
        />
      )}
      {planDescriptionCaption && <Paragraph>{planDescriptionCaption}</Paragraph>}
      <Stack gap="spacing-2-xs">
        {(isExpanded ? featuresList : featuresList.slice(0, SHOW_LESS_COUNT)).map((feature) => (
          <React.Fragment key={feature.title}>
            <PlanFeaturesRow {...feature} />
            <Divider />
          </React.Fragment>
        ))}
        {isShowMoreVisible && (
          <Box marginTop="spacing-4-xs">
            <ActionButton
              iconGlyph={isExpanded ? 'up' : 'down'}
              format="text"
              iconPlacement="after"
              label={isExpanded ? captions.showLess : captions.showMore}
              onClick={onShowMoreClick}
            />
          </Box>
        )}
      </Stack>
      <PlanPaymentDetails
        captions={{
          price: dynamicCaptions.price,
          basePrice: dynamicCaptions.basePrice,
          discountExpiry: dynamicCaptions.discountExpiry,
          dateLabel: dynamicCaptions.dateLabel,
          date: dynamicCaptions.date,
          paymentMethodTitle: dynamicCaptions.paymentMethodTitle,
          renewalPrice: captions.renewalPrice,
          planRenewal: captions.planRenewal,
          paymentMethod: captions.paymentMethod,
        }}
        paymentMethod={paymentMethodIcon}
        shouldBeShownDiscount={shouldBeShownDiscount}
        buttonProps={paymentDetailsButtonProps}
      />
      <Stack gap="spacing-2-xs">
        <PlanActionItem
          type="renew"
          title={dynamicCaptions.renewTitle}
          onClick={onNotificationClick}
          loading={isReactivating}
        />
        {showUpgradePlan && (
          <PlanActionItem type="upgrade" title={captions.upgradePlan} onClick={redirectToPaywall} />
        )}
      </Stack>
    </Stack>
  );
};

SubscriptionDetailsContainer.defaultProps = {
  isLoading: false,
  isCancelButtonEnabled: false,
};

SubscriptionDetailsContainer.propTypes = {
  name: PropTypes.string.isRequired,
  isHostingSubscription: PropTypes.bool.isRequired,
  subscriptionMeta: SUBSCRIPTION_META_PROP_SHAPE.isRequired,
  features: PropTypes.shape({}).isRequired,
  topAvailablePackageType: PropTypes.string.isRequired,
  hasActivatedDiscount: PropTypes.bool.isRequired,
  hasEverHadDiscount: PropTypes.bool.isRequired,
  isCancelButtonEnabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isJustDiscountActivated: PropTypes.bool.isRequired,
};

export default SubscriptionDetailsContainer;
