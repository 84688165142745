import { batch } from 'react-redux';
import serviceClient from '../service-client';
import { snakeCaseObjectToCamelCase } from '../../../utils/convert-case';
import statusNames from '../constants/status-names';
import status from '../../status';
import actions from '../actions';
import resolveServiceError from '../../../common/helpers/resolve-service-error';

const fetchBillingProfiles = () => async (dispatch) => {
  const yoproducts = serviceClient.get();
  let responseData;

  dispatch(status.actions.setStatus(statusNames.BILLING_PROFILES, status.constants.LOADING));

  try {
    const response = await yoproducts.requests.getBillingProfiles();
    responseData = snakeCaseObjectToCamelCase(response);

    batch(() => {
      dispatch(actions.setBillingProfiles(responseData));
      dispatch(status.actions.setStatus(statusNames.BILLING_PROFILES, status.constants.SUCCEEDED));
    });
  } catch (e) {
    const error = await resolveServiceError(e);

    console.error(error);
    dispatch(status.actions.setStatus(statusNames.BILLING_PROFILES, status.constants.FAILED));

    throw error;
  }

  return responseData;
};

export default fetchBillingProfiles;
