function convertToValidDomainName(name) {
  return (name || '')
    .trim()
    .replace(/\s/g, '-')
    .replace(/_+/g, '-')
    .replace(/[^\w.-]/g, '')
    .replace(/-+/g, '-')
    .toLowerCase();
}

export default convertToValidDomainName;
