import i18next from 'i18next';
import { constants } from '@yola/subscription-manager-js';

const { WL_ECOMMERCE_FREE, B2C_ECOMMERCE_FREE } = constants.SubscriptionType;

const getCancelEcommercePlanAdditionalChanges = ({ isB2C, ecommercePackages, name }) => {
  const freeEcommercePlan = isB2C ? B2C_ECOMMERCE_FREE : WL_ECOMMERCE_FREE;

  const {
    features: { productLimit, categories },
  } = ecommercePackages[freeEcommercePlan];

  return [
    i18next.t('No access to <b>Online store {name}</b>', { name }),
    i18next.t('You won’t be able to sell your products on our platform anymore'),
    i18next.t('Product limit will be reduced to {limit}', { limit: productLimit }),
    i18next.t('Product categories will be reduced to {limit}', { limit: categories }),
  ];
};

export default getCancelEcommercePlanAdditionalChanges;
