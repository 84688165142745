import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import yousers from '@yola/yousersjs';
import { DifmBannerPackagesContainer } from '@yola/webapp-plugins';
import dialogs from 'src/js/modules/dialogs';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from '../../dialogs/constants/dialog-types';

const {
  track,
  constants: { events, triggerIds },
} = segment;
const triggerId = triggerIds.CANCELLATION;
const dialogId = dialogTypes.DIFM_PROMO_PACKAGES;

const DifmPromoPackagesDialogContainer = ({ values }) => {
  const { serviceName: selectedService } = values;
  const dispatch = useDispatch();

  const onSelect = (selectedPackage) => {
    track(events.DIFM_PROMO_DIALOG_PACKAGE_SELECTED, {
      selectedService,
      selectedPackage,
      dialogId,
      triggerId,
    });
  };
  const onBack = (updatedValues) => {
    track(events.DIFM_PROMO_DIALOG_BACK_BUTTON_CLICKED, {
      dialogId,
    });

    dispatch(
      dialogs.actions.show(dialogTypes.DIFM_PROMO_SURVEY, {
        values: updatedValues,
      })
    );
  };
  const onNext = ({ selectedPackage }) => {
    track(events.DIFM_PROMO_DIALOG_SUBMITTED, {
      dialogId,
      triggerId,
      selectedService,
      selectedPackage,
    });

    dispatch(
      dialogs.actions.show(dialogTypes.DIFM_PROMO_FINISH, {
        selectedService,
        selectedPackage,
      })
    );
  };
  const onClose = () => {
    yousers.requests.declineDifm();
    track(events.DIFM_PROMO_DIALOG_CLOSED, {
      dialogId,
      selectedService,
    });
    dispatch(dialogs.actions.hide());
  };

  useEffect(() => {
    track(events.DIFM_PROMO_DIALOG_DISPLAYED, {
      dialogId,
      triggerId,
      selectedService,
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <DifmBannerPackagesContainer
      values={values}
      onBack={onBack}
      onNext={onNext}
      onClose={onClose}
      onSelect={onSelect}
    />
  );
};

DifmPromoPackagesDialogContainer.propTypes = {
  values: PropTypes.shape({ serviceName: PropTypes.string }).isRequired,
};

export default DifmPromoPackagesDialogContainer;
