import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import user from 'src/js/modules/user';
import NavbarHeader from 'src/js/common/components/navbar-header';
import routesMap from '../../../router/helpers/routes-map';

function LogoContainer() {
  const branding = useSelector(user.selectors.getPartnerBranding, shallowEqual);

  if (!branding || !branding.companyLogo) return null;

  const { companyLogo, companyLogoWidth } = branding;

  return (
    <NavbarHeader.Logo
      src={companyLogo}
      width={companyLogoWidth}
      height="auto"
      href={routesMap.dashboard.url()}
    />
  );
}

export default LogoContainer;
