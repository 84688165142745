import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { reactHookForm } from '@yola/ws-ui';
import ChangePasswordDialog from 'src/js/modules/profile/components/change-password-dialog';
import dialogs from 'src/js/modules/dialogs';
import user from 'src/js/modules/user';
import status from 'src/js/modules/status';
import statusNames from 'src/js/modules/user/constants/status-names';
import segment from 'src/js/modules/analytics/segment';
import sectionIds from '../constants/section-ids';
import { appearanceTypes } from '../constants/common';
import passwordFieldNames from '../constants/password-field-names';

const {
  constants: { events },
  track,
} = segment;

const { useForm } = reactHookForm;

const INVALID_OLD_PASSWORD_SERVICE_ERROR = 'invalid';
const Z_KEY = 'z';

const getCaptions = () => ({
  title: i18next.t('Change your password'),
  oldPasswordLabel: i18next.t('Old password'),
  newPasswordLabel: i18next.t('New password'),
  confirmPasswordLabel: i18next.t('Confirm password'),
  submit: i18next.t('Change password'),
  cancel: i18next.t('Cancel'),
  serviceError: i18next.t('Oops! Something went wrong. Please try again later.'),
  validationErrors: {
    required: i18next.t('Please fill in this field'),
    notEqual: i18next.t('The values in new password and confirm password are not equal'),
    incorrectPassword: i18next.t('The password you entered is incorrect'),
    minLength: (minLength) =>
      i18next.t('Ensure this field has at least {minLength} characters', { minLength }),
    maxLength: (maxLength) =>
      i18next.t('Ensure this field has no more than {maxLength} characters', { maxLength }),
  },
});

function ChangePasswordDialogContainer({ triggerId, onSubmit }) {
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [notification, setNotification] = useState(null);
  const modalContentRef = useRef();

  const targetStatus = status.hooks.useStatus(statusNames.CHANGE_PASSWORD);
  const isLoading = targetStatus === status.constants.LOADING;

  const dispatch = useDispatch();
  const captions = getCaptions();

  const analyticsParams = {
    triggerId,
    dialogId: dialogs.dialogTypes.CHANGE_PASSWORD,
    hiddenPasswordAdjusted: isConfirmPasswordVisible,
  };

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    getFieldState,
    trigger: triggerValidation,
  } = useForm({
    defaultValues: {
      [passwordFieldNames.OLD]: '',
      [passwordFieldNames.NEW]: '',
      [passwordFieldNames.CONFIRM]: '',
    },
  });

  const handleCancel = () => {
    dispatch(dialogs.actions.hide());

    track(events.CHANGE_PASSWORD_DIALOG_CANCELLED, analyticsParams);
  };

  const handleChangePassword = async (data) => {
    const {
      [passwordFieldNames.OLD]: oldPasswordValue,
      [passwordFieldNames.NEW]: newPasswordValue,
    } = data;
    setNotification(null);

    try {
      await dispatch(user.thunks.changePassword(oldPasswordValue, newPasswordValue));
      onSubmit();
      dispatch(dialogs.actions.hide());

      track(events.CHANGE_PASSWORD_DIALOG_SUBMITTED, analyticsParams);
    } catch (error) {
      const fieldError = error?.fields?.[passwordFieldNames.OLD];

      if (
        fieldError &&
        (fieldError === INVALID_OLD_PASSWORD_SERVICE_ERROR ||
          fieldError.includes(INVALID_OLD_PASSWORD_SERVICE_ERROR))
      ) {
        setError(passwordFieldNames.OLD, {
          type: 'custom',
          message: captions.validationErrors.incorrectPassword,
        });
      } else {
        setNotification({
          title: captions.serviceError,
          appearance: appearanceTypes.DANGER,
        });
      }

      track(events.PROFILE_PAGE_ERROR_DISPLAYED, {
        section: sectionIds.CHANGE_PASSWORD,
      });
    }
  };

  const undoWorkaround = (event) => {
    const { ctrlKey, key, metaKey, shiftKey, target } = event;
    const isUndoEvent = key.toLowerCase() === Z_KEY && (ctrlKey || metaKey) && !shiftKey;
    if (!isUndoEvent) return;
    const isFocusInsideModal = target && modalContentRef.current.contains(target);
    if (isFocusInsideModal) return;
    event.preventDefault();
  };

  const handlePasswordVisibility = (id) => {
    switch (id) {
      case passwordFieldNames.OLD:
        setIsOldPasswordVisible((prevState) => !prevState);
        break;
      case passwordFieldNames.NEW:
        setIsNewPasswordVisible((prevState) => !prevState);
        break;
      case passwordFieldNames.CONFIRM:
        setIsConfirmPasswordVisible((prevState) => !prevState);
        break;
      default:
        break;
    }
  };

  const handleDependantFieldChange = (dependantField) => {
    const fieldState = getFieldState(dependantField);

    if (fieldState.invalid) {
      triggerValidation(dependantField);
    }
  };

  const shouldBeEqualTo = (dependantField) => (value) => {
    const dependantValue = getValues()[dependantField];
    return dependantValue === value || captions.validationErrors.notEqual;
  };

  const currentPasswordRules = {
    required: captions.validationErrors.required,
  };

  const newPasswordRules = {
    required: captions.validationErrors.required,
    minLength: {
      value: 8,
      message: captions.validationErrors.minLength(8),
    },
    maxLength: {
      value: 100,
      message: captions.validationErrors.maxLength(100),
    },
  };

  const passwordInputs = [
    {
      name: passwordFieldNames.OLD,
      title: captions.oldPasswordLabel,
      control,
      isVisible: isOldPasswordVisible,
      onVisibilityToggle: handlePasswordVisibility,
      rules: currentPasswordRules,
    },
    {
      name: passwordFieldNames.NEW,
      title: captions.newPasswordLabel,
      control,
      isVisible: isNewPasswordVisible,
      onVisibilityToggle: handlePasswordVisibility,
      rules: { ...newPasswordRules, validate: shouldBeEqualTo(passwordFieldNames.CONFIRM) },
      onChange: () => handleDependantFieldChange(passwordFieldNames.CONFIRM),
    },
    {
      name: passwordFieldNames.CONFIRM,
      title: captions.confirmPasswordLabel,
      control,
      isVisible: isConfirmPasswordVisible,
      onVisibilityToggle: handlePasswordVisibility,
      rules: { ...newPasswordRules, validate: shouldBeEqualTo(passwordFieldNames.NEW) },
      onChange: () => handleDependantFieldChange(passwordFieldNames.NEW),
    },
  ];

  return (
    <ChangePasswordDialog
      captions={captions}
      passwordInputs={passwordInputs}
      isLoading={isLoading}
      onSubmit={handleSubmit(handleChangePassword)}
      onCancel={handleCancel}
      modalContentRef={modalContentRef}
      onKeyDown={undoWorkaround}
      {...(notification && {
        notification,
      })}
    />
  );
}

ChangePasswordDialogContainer.propTypes = {
  triggerId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ChangePasswordDialogContainer;
