import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import LoaderWithText from '../../../../common/components/loader-with-text';
import PaymentMethodItem from '../payment-method-item';
import { PAYMENT_METHOD_ITEM_PROP_TYPES } from '../payment-method-item/constants';
import ErrorNotificationContainer from '../../../../common/containers/error-notification-container';

const { Stack, Paragraph, NotificationMessage } = designSystem;

const PaymentMethodsContent = ({
  isLoading,
  isFailed,
  error,
  clearError,
  notificationMessage,
  clearNotificationMessage,
  paymentMethods,
  onEdit,
  onDelete,
  onSetAsDefault,
  captions,
}) => {
  const { emptyState, ...restCaptions } = captions;
  const { title: notificationTitle, description: notificationDescription = '' } =
    notificationMessage;

  if (isLoading) {
    return (
      <div className="ws-account-management-loader-wrapper">
        <LoaderWithText />
      </div>
    );
  }

  if (isFailed) {
    return <ErrorNotificationContainer />;
  }

  return (
    <Stack gap="spacing-2-xs">
      {error && <ErrorNotificationContainer onClose={clearError} />}
      {notificationTitle && (
        <NotificationMessage
          appearance="success"
          onClose={clearNotificationMessage}
          title={
            // eslint-disable-next-line yola/react/jsx-wrap-multilines
            <Paragraph appearance="success">
              <b>{notificationTitle}</b>
            </Paragraph>
          }
          description={notificationDescription}
        />
      )}
      {paymentMethods.map((data) => (
        <PaymentMethodItem
          key={data.id}
          {...data}
          captions={{ ...data.captions, ...restCaptions }}
          onEdit={onEdit}
          onDelete={onDelete}
          onSetAsDefault={onSetAsDefault}
        />
      ))}
      {paymentMethods.length === 0 && <Paragraph size="large">{emptyState}</Paragraph>}
    </Stack>
  );
};

PaymentMethodsContent.propTypes = {
  isLoading: PropTypes.bool,
  isFailed: PropTypes.bool,
  error: PropTypes.bool,
  clearError: PropTypes.func.isRequired,
  notificationMessage: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.string,
  }),
  clearNotificationMessage: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape(PAYMENT_METHOD_ITEM_PROP_TYPES)).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSetAsDefault: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    emptyState: PropTypes.string.isRequired,
    default: PropTypes.string.isRequired,
    secondary: PropTypes.string.isRequired,
    setDefaultButton: PropTypes.string.isRequired,
    editButton: PropTypes.string.isRequired,
  }).isRequired,
};

PaymentMethodsContent.defaultProps = {
  isLoading: false,
  isFailed: false,
  error: false,
  notificationMessage: {},
};

export default PaymentMethodsContent;
