import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';

const { Heading, Stack, Paragraph, Box, InputGroup, ActionButton } = designSystem;

const DomainSearch = ({ onSubmit }) => {
  const [domainName, setDomain] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit(domainName);
  };

  return (
    <form className="ws-domain-search" onSubmit={handleSubmit}>
      <Stack gap="spacing-3-xs">
        <Heading type="heading-4" align="center">
          {i18next.t('Add your custom domain')}
        </Heading>
        <Paragraph align="center">
          {i18next.t('Make your business look more professional with a custom domain')}
        </Paragraph>
      </Stack>
      <Box marginTop="spacing-l" marginBottom="spacing-xs">
        <div className="ws-domain-search__search-group">
          <div className="ws-domain-search__input">
            <InputGroup
              size="medium"
              prefixText="www."
              placeholder="yourdomain.com"
              value={domainName}
              onChange={setDomain}
            />
          </div>
          <ActionButton format="solid" iconGlyph="search" type="submit" />
        </div>
      </Box>
    </form>
  );
};

DomainSearch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default DomainSearch;
