import { constants } from '@yola/subscription-manager-js';
import store from 'src/js/store';
import getIsB2C from '../../user/selectors/is-b2c';

const { B2C_HOSTING_FREE, WL_HOSTING_FREE } = constants.SubscriptionType;

const getFreeHostingSubscriptionType = () => {
  const state = store.getState();
  const isB2C = getIsB2C(state);

  return isB2C ? B2C_HOSTING_FREE : WL_HOSTING_FREE;
};

export default getFreeHostingSubscriptionType;
