export default {
  CAPTURED: 'C',
  CANCELLED: 'X',
  FAILED: 'F',
  ERROR: 'E',
  NEW: 'N',
  PENDING: 'P',
  AUTHORIZED: 'A',
  REFUNDED: 'R',
  PARTIAL_REFUND: 'S',
};
