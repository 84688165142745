import React from 'react';
import { designSystem } from '@yola/ws-ui';
import { CANCEL_PLAN_PROPOSALS_ITEM_PROP_TYPES } from './constants';

const { Heading, Paragraph, Stack, ActionButton } = designSystem;

const CancelPlanProposalsItem = (props) => {
  const { title, description, button, IconComponent } = props;
  const { label, format, appearance, onClick } = button;

  return (
    <div className="ws-cancel-plan-proposals-item">
      <IconComponent />
      <Stack gap="spacing-xs">
        <Stack>
          <Heading type="heading-4">{title}</Heading>
          <Paragraph>{description}</Paragraph>
        </Stack>
        <div>
          <ActionButton label={label} format={format} appearance={appearance} onClick={onClick} />
        </div>
      </Stack>
    </div>
  );
};

CancelPlanProposalsItem.propTypes = CANCEL_PLAN_PROPOSALS_ITEM_PROP_TYPES;

export default CancelPlanProposalsItem;
