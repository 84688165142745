import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DifmBannerFinish } from '@yola/webapp-plugins';
import dialogs from 'src/js/modules/dialogs';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from '../../dialogs/constants/dialog-types';

const {
  track,
  constants: { events },
} = segment;
const dialogId = dialogTypes.DIFM_PROMO_FINISH;

const DifmPromoFinishDialogContainer = ({ selectedService, selectedPackage }) => {
  const dispatch = useDispatch();

  const onSubmit = () => {
    track(events.DIFM_PROMO_DIALOG_CLOSED, {
      dialogId,
      selectedService,
    });
    dispatch(dialogs.actions.hide());
  };

  useEffect(() => {
    track(events.DIFM_PROMO_DIALOG_FINISH_DISPLAYED, {
      dialogId,
      selectedService,
      selectedPackage,
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return <DifmBannerFinish onSubmit={onSubmit} />;
};

DifmPromoFinishDialogContainer.propTypes = {
  selectedService: PropTypes.string.isRequired,
  selectedPackage: PropTypes.string.isRequired,
};

export default DifmPromoFinishDialogContainer;
