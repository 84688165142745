import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import get from 'lodash.get';
import i18next from 'i18next';
import BillingHistoryContent from '../components/billing-history-content';
import routesMap from '../../../router/helpers/routes-map';
import products from '../../products';
import useQuery from '../../../common/hooks/use-query';
import segment from '../../analytics/segment';

const {
  track,
  constants: { events, triggerIds },
} = segment;

const { basketStatusTypes: statusTypes } = products.constants;

const getCaptions = () => ({
  emptyState: i18next.t('Your billing history is empty.'),
  headerItems: [
    i18next.t('Date'),
    i18next.t('Details'),
    i18next.t('Payment method'),
    i18next.t('Status'),
    i18next.t('Amount'),
  ],
  status: {
    [statusTypes.CAPTURED]: i18next.t('Captured'),
    [statusTypes.CANCELLED]: i18next.t('Canceled'),
    [statusTypes.FAILED]: i18next.t('Failed'),
    [statusTypes.ERROR]: i18next.t('Error'),
    [statusTypes.NEW]: i18next.t('New'),
    [statusTypes.PENDING]: i18next.t('Pending'),
    [statusTypes.AUTHORIZED]: i18next.t('Authorized'),
    [statusTypes.REFUNDED]: i18next.t('Refunded'),
    [statusTypes.PARTIAL_REFUND]: i18next.t('Partial refund'),
  },
});

const BillingHistoryContainer = () => {
  const dispatch = useDispatch();
  const { pageId = 1 } = useParams();
  const location = useLocation();
  const captions = getCaptions();

  const onChangePage = (targetPage) => {
    dispatch(push(routesMap.billingHistory.url({ pageId: targetPage })));
  };

  const { data, isLoading, error } = useQuery({
    name: products.statusNames.BASKETS,
    thunk: products.thunks.fetchBaskets,
    args: [pageId],
  });

  const [billings = [], count = 0] = data || [];
  const isFailed = Boolean(error);

  useEffect(() => {
    track(events.BILLING_HISTORY_PAGE_DISPLAYED, {
      triggerId: get(location, 'state.triggerId', triggerIds.ACCOUNT_SETTINGS),
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <BillingHistoryContent
      isLoading={isLoading}
      isFailed={isFailed}
      billingsList={billings}
      captions={captions}
      pagesCount={count}
      currentPage={Number(pageId)}
      onChangePage={onChangePage}
    />
  );
};

export default BillingHistoryContainer;
