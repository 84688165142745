import actionTypes from '../constants/action-types';

const deleteSiteData = (siteId) => ({
  type: actionTypes.DELETE_SITE_DATA,
  payload: {
    siteId,
  },
});

export default deleteSiteData;
