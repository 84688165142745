export default {
  DOMAINS_PAGE: 'domains-page',
  ACCOUNT_PAGE: 'account-status',
  EMAIL_SETUP_PAGE: 'email-setup-page',
  SECURE_DOMAIN: 'secure-domain',
  DASHBOARD_DOMAIN_SEARCH: 'dashboard-domain-search',
  REDIRECT: 'redirect',
  PAYWALL: 'paywall',
  ECOMMERCE_PAYWALL: 'ecommerce-paywall',
  DOMAIN_SELECTOR: 'domain-selector',
  BANNER: 'banner',
  SUBSCRIPTIONS: 'subscriptions',
  SUBSCRIPTION_EXPIRED_DIALOG: 'subscription-expired-dialog',
  SUBSCRIPTION_DETAILS: 'subscription-details',
  SUBSCRIPTION_NOTIFICATION: 'subscription-notification',
  TABS: 'tabs',
  ACCOUNT_SETTINGS: 'account-settings',
  HOSTING: 'hosting',
  ONLINE_STORE: 'online-store',
  ADD_PAYMENT_METHOD: 'add-payment-method',
  EDIT_PAYMENT_METHOD: 'edit-payment-method',
  UPDATE_PAYMENT_METHOD: 'update-payment-method',
  REMOVING_PAYMENT_METHOD: 'removing-payment-method',
  SAVE_AND_REMOVE: 'save-and-remove',
  REMOVE: 'remove',
  CANCEL_DISCOUNT_DIALOG: 'cancel-discount-dialog',
  CANCELLATION: 'cancellation',
  CANCEL_SUBSCRIPTION_PAGE: 'cancel-subscription-page',
  CANCEL_BUTTON: 'cancel-button',
  KEEP_MY_PLAN_ACTIVE: 'keep-my-plan-active',
  CLOSE_BUTTON: 'close-button',
  BUTTON: 'button',
  INPUT: 'input',
  RESCHEDULE_SUBSCRIPTION: 'reschedule-subscription',
};
