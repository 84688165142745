import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useMatchLGBreakpoint from './hooks/use-match-lg-breakpoint';
import useMatchMDBreakpoint from './hooks/use-match-md-breakpoint';
import useMatchXLBreakpoint from './hooks/use-match-xl-breakpoint';

const NavbarHeaderContext = createContext({});

export const NavbarHeaderProvider = ({ children }) => {
  const isLg = useMatchLGBreakpoint();
  const isMd = useMatchMDBreakpoint();
  const isXl = useMatchXLBreakpoint();

  return (
    <NavbarHeaderContext.Provider value={{ isLg, isMd, isXl }}>
      {children}
    </NavbarHeaderContext.Provider>
  );
};

NavbarHeaderProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

NavbarHeaderProvider.defaultProps = {
  children: null,
};

export const useNavbarHeaderContext = () => {
  const context = useContext(NavbarHeaderContext);

  if (context === undefined) {
    throw new Error('useNavbarHeaderContext should be used within NavnarHeaderProvider');
  }

  return context;
};
