import get from 'lodash.get';
import getIsB2C from './is-b2c';

const getLoginUrl = (rootState) => {
  if (getIsB2C(rootState)) {
    return get(rootState, 'config.common.auth-gateway.url', '');
  }

  return get(rootState, 'config.common.auth-gateway.wl_url', '');
};

export default getLoginUrl;
