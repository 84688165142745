import PropTypes from 'prop-types';

export const PROPOSALS_TYPES = {
  difm: 'difm',
  support: 'support',
};

export const CANCEL_PLAN_PROPOSALS_ITEM_PROP_TYPES = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    format: PropTypes.string,
    appearance: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  IconComponent: PropTypes.elementType.isRequired,
};
