import thunk from 'redux-thunk';
import reduxCatch from 'redux-catch';
import { routerMiddleware } from 'connected-react-router';
import catchReduxError from 'src/js/modules/error/helpers/catch-redux-error';
import history from 'src/js/browser-history';
import webappPlugins from './modules/webapp-plugins';
import featureFlags from './modules/feature-flags';
import analytics from './modules/analytics';
import products from './modules/products';

export default [
  thunk,
  reduxCatch(catchReduxError),
  routerMiddleware(history),
  webappPlugins.middleware.configuration,
  featureFlags.middleware.clientLoader,
  products.middleware.clientLoader,
  analytics.segment.middleware.segmentInitializer,
];
