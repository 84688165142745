import React, { useEffect } from 'react';
import CancelSubscriptionsPage from 'src/js/modules/account-management/pages/cancel-subscriptions-page';
import useAvailablePlatformComponents from 'src/js/modules/user/hooks/use-available-platform-components';
import useFeatureFlags from 'src/js/modules/feature-flags/hooks/use-feature-flags';
import user from 'src/js/modules/user';
import LoaderWithText from 'src/js/common/components/loader-with-text';
import redirectToUrl from 'src/js/utils/redirect-to-url';
import legacyUrlsMap from 'src/js/router/helpers/legacy-urls-map';

const { platformComponents } = user.constants;

const CancelSubscriptionsRoute = () => {
  const [featureFlags] = useFeatureFlags(['cancellation_flow']);
  const availablePlatformComponents = useAvailablePlatformComponents();

  const { cancellation_flow: isCancellationFlowEnabled } = featureFlags;
  const isCancellationFlowFlagLoaded = typeof isCancellationFlowEnabled === 'boolean';
  const shouldRedirectToLegacyCancelPage =
    isCancellationFlowFlagLoaded &&
    (!isCancellationFlowEnabled ||
      !availablePlatformComponents.includes(platformComponents.PAYMENTS));

  useEffect(() => {
    if (shouldRedirectToLegacyCancelPage) {
      redirectToUrl(legacyUrlsMap.cancelSubscriptions());
    }
  }, [shouldRedirectToLegacyCancelPage]);

  if (!isCancellationFlowFlagLoaded || shouldRedirectToLegacyCancelPage) {
    return <LoaderWithText />;
  }

  return <CancelSubscriptionsPage />;
};

export default CancelSubscriptionsRoute;
