import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import supportedSearchParams from '../../modules/common/constants/supported-search-params';

function useSupportedSearchParams() {
  const [params, setParams] = useState(null);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const filterParams = Object.values(supportedSearchParams).reduce((acc, paramName) => {
      let paramValue = searchParams.get(paramName);

      searchParams.delete(paramName);

      if (!paramValue) return acc;

      switch (paramName) {
        case supportedSearchParams.PRODUCTS:
          paramValue = paramValue.split(',').map((product) => product.trim());
          break;

        case supportedSearchParams.DOMAIN_PROPOSAL:
        case supportedSearchParams.PERIOD_TOGGLE:
          paramValue = !!parseInt(paramValue, 10);
          break;
        default:
      }

      return {
        ...acc,
        [paramName]: paramValue,
      };
    }, {});

    history.replace({
      search: searchParams.toString(),
    });

    setParams(filterParams);

    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return params;
}

export default useSupportedSearchParams;
