import i18next from 'i18next';
import { constants } from '@yola/subscription-manager-js';
import { PER_MONTH } from '../../common/constants/terms';
import getFormattedSubscriptionPrice from '../../products/accessors/get-formatted-subscription-price';

const { CANCELED } = constants.SubscriptionStatus;

const getCancelPlans = (userSubscriptions, isHostingSubscription) =>
  userSubscriptions
    .filter(
      ({ isEcommerce, subscriptionMeta }) =>
        subscriptionMeta.status !== CANCELED && (isHostingSubscription || isEcommerce)
    )
    .map(({ isEcommerce, subscriptionMeta: { type, term, id }, name: subscriptionName }) => {
      const price = getFormattedSubscriptionPrice(type, term);

      return {
        id,
        name: `${
          isEcommerce ? i18next.t('Online store') : i18next.t('Hosting plan')
        }: ${subscriptionName}`,
        renew: term === PER_MONTH ? i18next.t('Renews monthly') : i18next.t('Renews annually'),
        price,
        notification:
          isHostingSubscription && isEcommerce
            ? i18next.t(
                'Note that cancelling your account will also cancel your online store. You won’t be able to sell your products on our platform anymore.'
              )
            : '',
      };
    });

export default getCancelPlans;
