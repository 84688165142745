import { batch } from 'react-redux';
import status from 'src/js/modules/status';
import user from 'src/js/modules/user';
import statusNames from '../constants/status-names';
import setPrices from '../actions/set-prices';
import serviceClient from '../service-client';

const fetchPrices =
  (discount = '') =>
  async (dispatch, getState) => {
    const state = getState();
    const { partnerId } = user.selectors.getUserData(state);
    const yoproducts = serviceClient.get();

    dispatch(status.actions.setStatus(statusNames.PRICES, status.constants.LOADING));

    try {
      const pricesData = await yoproducts.requests.partnerProductsTree({ partnerId }, { discount });

      batch(() => {
        dispatch(setPrices(pricesData));
        dispatch(status.actions.setStatus(statusNames.PRICES, status.constants.SUCCEEDED));
      });
    } catch (error) {
      console.error(error);
      dispatch(status.actions.setStatus(statusNames.PRICES, status.constants.FAILED));
    }
  };

export default fetchPrices;
