import React from 'react';
import PropTypes from 'prop-types';
import { B2CDomainSelector } from '@yola/webapp-plugins';

function DomainSelector(props) {
  const {
    domainName,
    currency,
    captionsOverrides,
    onSelect,
    onDomainSearchFailure,
    onClose,
    onNext,
    onSearchStart,
    onSearchPerformed,
    onDomainFieldChange,
    additionalPaymentParams,
    extraPrice,
  } = props;

  return (
    <div className="ws-domain-selector">
      <B2CDomainSelector
        view="content"
        domainName={domainName}
        currency={currency}
        captionsOverrides={captionsOverrides}
        onSelect={onSelect}
        onDomainSearchFailure={onDomainSearchFailure}
        onClose={onClose}
        onNext={onNext}
        onSearchStart={onSearchStart}
        onSearchPerformed={onSearchPerformed}
        onDomainFieldChange={onDomainFieldChange}
        additionalPaymentParams={additionalPaymentParams}
        extraPrice={extraPrice}
      />
    </div>
  );
}

DomainSelector.propTypes = {
  domainName: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  captionsOverrides: PropTypes.shape().isRequired,
  onSelect: PropTypes.func.isRequired,
  onDomainSearchFailure: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onSearchStart: PropTypes.func.isRequired,
  onSearchPerformed: PropTypes.func.isRequired,
  onDomainFieldChange: PropTypes.func.isRequired,
  additionalPaymentParams: PropTypes.shape().isRequired,
  extraPrice: PropTypes.string.isRequired,
};

export default DomainSelector;
