import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';

const { Heading, Paragraph } = designSystem;

function SiteListError(props) {
  const { contactSupportUrl } = props;

  return (
    <div className="ws-site-list-error">
      <Heading type="heading-3">
        {i18next.t('Oops, an error has prevented your sites from loading.')}
      </Heading>
      <Paragraph appearance="medium-emphasis">
        {i18next.t(
          'This might have been caused by a temporary service interruption. Please try refreshing the page.'
        )}
        {contactSupportUrl && (
          <React.Fragment>
            {' '}
            {i18next.t("If you're still seeing this message (hi again!), please contact our")}{' '}
            <a href={contactSupportUrl}>{i18next.t('friendly support team')}</a>.
          </React.Fragment>
        )}
      </Paragraph>
    </div>
  );
}

SiteListError.propTypes = {
  contactSupportUrl: PropTypes.string.isRequired,
};

export default SiteListError;
