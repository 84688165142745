import serviceClient from '../service-client';
import {
  camelCaseObjectToSnakeCase,
  snakeCaseObjectToCamelCase,
} from '../../../utils/convert-case';
import statusNames from '../constants/status-names';
import status from '../../status';
import selectors from '../selectors';
import actions from '../actions';
import getUpdatedBillingProfiles from '../helpers/get-updated-billing-profiles';
import resolveServiceError from '../../../common/helpers/resolve-service-error';

const handleBillingProfilesUpdate = (deletedId, newProfile, dispatch, state) => {
  const currentProfiles = selectors.getBillingProfiles(state);
  const newProfiles = getUpdatedBillingProfiles(currentProfiles, {
    toAdd: newProfile,
    toRemove: { id: deletedId },
  });
  dispatch(actions.setBillingProfiles(newProfiles));
};

const replaceBillingProfileWithNew = (id, data) => async (dispatch, getState) => {
  const state = getState();
  const yoproducts = serviceClient.get();
  const formattedData = camelCaseObjectToSnakeCase(data);
  let responseData;

  dispatch(
    status.actions.setStatus(statusNames.REPLACE_BILLING_PROFILE_WITH_NEW, status.constants.LOADING)
  );

  try {
    const response = await yoproducts.requests.replaceBillingProfileWithNew(id, formattedData);
    responseData = snakeCaseObjectToCamelCase(response);

    dispatch(
      status.actions.setStatus(
        statusNames.REPLACE_BILLING_PROFILE_WITH_NEW,
        status.constants.SUCCEEDED
      )
    );

    const billingProfilesStatus = selectors.getBillingProfilesStatus(state);

    if (billingProfilesStatus === status.constants.SUCCEEDED) {
      handleBillingProfilesUpdate(id, responseData, dispatch, state);
    }
  } catch (e) {
    const error = await resolveServiceError(e);

    console.error(error);
    dispatch(
      status.actions.setStatus(
        statusNames.REPLACE_BILLING_PROFILE_WITH_NEW,
        status.constants.FAILED
      )
    );

    throw error;
  }

  return responseData;
};

export default replaceBillingProfileWithNew;
