import redirectToUrl from './redirect-to-url';
import openInNewTab from './open-in-new-tab';
import isEmail from './is-email';
import isPhoneNumber from './is-phone-number';

export default {
  redirectToUrl,
  openInNewTab,
  isEmail,
  isPhoneNumber,
};
