import get from 'lodash.get';
import getIsB2C from './is-b2c';

const getSignUpUrl = (rootState) => {
  if (getIsB2C(rootState)) {
    return get(rootState, 'config.common.auth-gateway.signup_url', '');
  }

  return get(rootState, 'config.common.auth-gateway.signup_wl_url', '');
};

export default getSignUpUrl;
