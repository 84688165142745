import getAvailablePaymentMethods from './available-payment-methods';
import getAvailablePlatformComponents from './available-platform-components';
import getAvailableSubscriptionTypes from './available-subscription-types';
import getContactSupportUrl from './contact-support-url';
import getDomainPurchaseAvailable from './domain-purchase-available';
import getMoneyBackGuarantee from './money-back-guarantee';
import getPartnerBranding from './partner-branding';
import getPartnerData from './partner-data';
import getPartnerName from './partner-name';
import getPlatformAccess from './platform-access';
import getPrivacyUrl from './privacy-url';
import getPricingUrl from './pricing-url';
import getSupportUrl from './support-url';
import getTermsUrl from './terms-url';
import getUpgradeUrl from './upgrade-url';
import getUserData from './user-data';
import getUserPreferences from './user-preferences';
import getIsB2C from './is-b2c';

export default {
  getAvailablePaymentMethods,
  getAvailablePlatformComponents,
  getAvailableSubscriptionTypes,
  getContactSupportUrl,
  getDomainPurchaseAvailable,
  getMoneyBackGuarantee,
  getPartnerBranding,
  getPartnerData,
  getPartnerName,
  getPlatformAccess,
  getPrivacyUrl,
  getPricingUrl,
  getSupportUrl,
  getTermsUrl,
  getUpgradeUrl,
  getUserData,
  getUserPreferences,
  getIsB2C,
};
