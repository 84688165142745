import i18next from 'i18next';
import { PROPOSALS_TYPES } from '../components/cancel-plan-proposals-item/constants';
import icons from '../components/cancel-plan-proposals-item/icons';

const { DifmIcon, ContactSupportIcon } = icons;

const getAvailableProposalsItems = (onContactSupportClick, onDifmClick, isDifmLatitudeEnabled) => {
  const availableItems = [
    {
      id: PROPOSALS_TYPES.support,
      title: i18next.t('Get technical support'),
      description: i18next.t('Our Support Team is ready to help you resolve any issues'),
      button: {
        label: i18next.t('Contact support'),
        onClick: onContactSupportClick,
      },
      IconComponent: ContactSupportIcon,
    },
  ];

  if (isDifmLatitudeEnabled) {
    availableItems.unshift({
      id: PROPOSALS_TYPES.difm,
      title: i18next.t('Let us build your website'),
      description: i18next.t('Our team will handle everything for you!'),
      button: {
        label: i18next.t('Get started'),
        format: 'solid',
        appearance: 'cta',
        onClick: onDifmClick,
      },
      IconComponent: DifmIcon,
    });
  }

  return availableItems;
};

export default getAvailableProposalsItems;
