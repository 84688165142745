import setPartnerData from './set-partner-data';
import setUserData from './set-user-data';
import setUserPreferences from './set-user-preferences';
import updateUserData from './update-user-data';

export default {
  setPartnerData,
  setUserData,
  setUserPreferences,
  updateUserData,
};
