/* eslint-disable yola/react/jsx-tag-spacing */
import React from 'react';

const SitebuilderIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
    <path fill="#005ED9" fillOpacity=".2" d="M13.2 23h5.867v3.667H13.2V23Z" />
    <path fill="#005ED9" d="M18.4 23.2v3.1h-4.8v-3.1h4.8Zm.8-.8h-6.4v4.7h6.4v-4.7Z" />
    <path fill="#fff" d="M1.2 3.6h29.6v20.8H1.2V3.6Z" />
    <path fill="#005ED9" d="M30.4 4v20H1.6V4h28.8Zm.8-.8H.8v21.6h30.4V3.2Z" />
    <path fill="#005ED9" fillOpacity=".2" d="M1.2 21.2h29.6v3.2H1.2v-3.2Z" />
    <path fill="#005ED9" d="M30.4 21.6V24H1.6v-2.4h28.8Zm.8-.8H.8v4h30.4v-4Z" />
    <path
      fill="#005ED9"
      fillOpacity=".2"
      d="M7.6 29.2v-.4c0-1.102.898-2 2-2h12.8c1.103 0 2 .898 2 2v.4H7.6Z"
    />
    <path
      fill="#005ED9"
      d="M22.4 27.2c.882 0 1.6.718 1.6 1.6H8c0-.883.718-1.6 1.6-1.6h12.8Zm0-.8H9.6a2.4 2.4 0 0 0-2.4 2.4v.8h17.6v-.8a2.4 2.4 0 0 0-2.4-2.4Z"
    />
    <path fill="#fff" d="M29.2 23.2h-1.6a.4.4 0 0 1 0-.8h1.6a.4.4 0 0 1 0 .8Z" />
    <path
      fill="#005ED9"
      fillOpacity=".2"
      d="M5 18a.663.663 0 0 1-.474-.205.412.412 0 0 1-.126-.302l.002-10.16a.432.432 0 0 1 .116-.342.668.668 0 0 1 .483-.191H11c.331 0 .6.239.6.533v10.134c0 .294-.269.534-.6.534L5 18Z"
    />
    <path
      fill="#005ED9"
      d="M11 7.2c.114 0 .2.07.2.133v10.134c0 .063-.085.133-.2.133H5.002c-.108 0-.192-.076-.202-.083l.002-.025V7.3L4.8 7.277c.006-.019.08-.077.2-.077h6Zm0-.8H5.002c-.552 0-1.032.389-1 .933v10.134c-.032.486.447.933 1 .933H11c.552 0 1-.418 1-.934V7.333c0-.516-.449-.933-1-.933Z"
    />
    <path fill="#005ED9" fillOpacity=".03" d="M4.4 9.2h7.2v6.4H4.4V9.2Z" />
    <path fill="#005ED9" d="M11.2 9.6v5.6H4.8V9.6h6.4Zm.8-.8H4V16h8V8.8Z" />
    <path
      fill="#005ED9"
      fillOpacity=".2"
      d="M16.2 18a.626.626 0 0 1-.45-.201.52.52 0 0 1-.151-.375V7.4a.53.53 0 0 1 .14-.411.633.633 0 0 1 .46-.19H27a.6.6 0 0 1 .6.6v10a.6.6 0 0 1-.6.6H16.2Z"
    />
    <path
      fill="#005ED9"
      d="M27 7.2c.11 0 .2.09.2.2v10a.2.2 0 0 1-.2.2H16.2a.229.229 0 0 1-.159-.076c-.033-.036-.043-.067-.043-.077l.002-.023V7.357c-.003-.041.006-.068.03-.094.03-.03.09-.063.17-.063H27Zm0-.8H16.2c-.552 0-1.031.417-1 1v10c-.031.52.448 1 1 1H27a1 1 0 0 0 1-1v-10a1 1 0 0 0-1-1Z"
    />
    <path fill="#005ED9" fillOpacity=".03" d="M15.6 9.2h12v6.4h-12V9.2Z" />
    <path fill="#005ED9" d="M27.2 9.6v5.6H16V9.6h11.2Zm.8-.8H15.2V16H28V8.8Z" />
  </svg>
);

export default SitebuilderIcon;
