// we use such a hack as window.open(url, '_blank') would not work outside actual event handler
// and we expect to use it via integration settings callback

const openInNewTab = (href) => {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href,
  }).click();
};

export default openInNewTab;
