import { accessors } from '@yola/subscription-manager-js';
import trackAsync from '../helpers/track-async';
import subscriptions from '../../../subscriptions';

const { getFreeHostingSubscriptionType } = subscriptions.accessors;

const trackAsyncEvent = async (event, traits = {}) => {
  const hostingSubscription = accessors.getActiveHostingSubscription();
  const ecommerceSubscription = accessors.getActiveEcommerceSubscription();

  const {
    type: paidSubscriptionType = null,
    term: subscriptionTerm = null,
    status: subscriptionStatus = null,
  } = hostingSubscription || {};

  const {
    type: onlineStoreType = null,
    term: onlineStoreTerm = null,
    status: onlineStoreStatus = null,
  } = ecommerceSubscription || {};

  const subscriptionType = paidSubscriptionType || getFreeHostingSubscriptionType();

  await trackAsync(event, {
    subscriptionType,
    subscriptionTerm,
    subscriptionStatus,
    onlineStoreType,
    onlineStoreTerm,
    onlineStoreStatus,
    ...traits,
  });
};

export default trackAsyncEvent;
