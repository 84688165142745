import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Paragraph } = designSystem;

const SubscriptionsListHeader = ({ items }) => (
  <div className="ws-subscriptions-list-header">
    {items.map((item) => (
      <Paragraph key={item} size="small" appearance="medium-emphasis">
        {item}
      </Paragraph>
    ))}
  </div>
);

SubscriptionsListHeader.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SubscriptionsListHeader;
