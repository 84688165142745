import React from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { selectors } from '@yola/subscription-manager-js';
import routersMap from 'src/js/router/helpers/routes-map';
import NavbarHeader from 'src/js/common/components/navbar-header';

function UpgradeContainer() {
  const dispatch = useDispatch();
  const upgradeExists = useSelector(selectors.getHostingUpgradeAvailability);

  if (!upgradeExists) return null;

  const onUpgradeClick = () => {
    dispatch(
      push(
        routersMap.paywall.url({
          query: {
            triggerId: 'header',
            paymentBackUrl: window.location.href,
          },
        })
      )
    );
  };

  return (
    <React.Fragment>
      <NavbarHeader.Divider />
      <NavbarHeader.UpgradeButton onClick={onUpgradeClick} label={i18next.t('Upgrade')} />
    </React.Fragment>
  );
}

export default UpgradeContainer;
