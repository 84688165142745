import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TermsOfUseDialog } from '@yola/webapp-plugins';
import actions from '../actions';
import user from '../../user';
import config from '../../config';

const TermsOfUseDialogContainer = () => {
  const dispatch = useDispatch();

  const termsOfUseUrl = useSelector(user.selectors.getTermsUrl);
  const privacyPolicyUrl = useSelector(user.selectors.getPrivacyUrl);
  const logoutUrl = useSelector(config.selectors.getLogoutUrl);

  const handleSubmit = () => {
    dispatch(
      user.actions.updateUserData(user.constants.userData.IS_LATEST_USER_AGREEMENT_ACCEPTED, true)
    );
    dispatch(actions.hide());
  };

  return (
    <TermsOfUseDialog
      termsOfUseUrl={termsOfUseUrl}
      privacyPolicyUrl={privacyPolicyUrl}
      logoutUrl={logoutUrl}
      onSubmit={handleSubmit}
    />
  );
};

export default TermsOfUseDialogContainer;
