import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import i18next from 'i18next';

const { ActionButton, Paragraph, Heading } = designSystem;

function SiteCreation(props) {
  const { imageUrl, onAddNewSiteClick } = props;

  return (
    <div className="ws-site-creation">
      <div className="ws-site-creation__preview">
        <img alt="preview" src={imageUrl} />
      </div>
      <div className="ws-site-creation__container">
        <div className="ws-site-creation__description">
          <Heading type="heading-3">{i18next.t('Ready to build your new site?')}</Heading>
          <Paragraph appearance="medium-emphasis">
            {i18next.t(
              'Turn your idea into success, regardless of your tech skills. Have complete control over your website updates and design changes whenever and wherever.'
            )}
            <br />
            {i18next.t('Start building your site now!')}
          </Paragraph>
        </div>
        <ActionButton
          appearance="accent"
          format="solid"
          iconGlyph="plus-for-button"
          onClick={onAddNewSiteClick}
          label={i18next.t('Add New Site')}
        />
      </div>
    </div>
  );
}

SiteCreation.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  onAddNewSiteClick: PropTypes.func.isRequired,
};

export default SiteCreation;
