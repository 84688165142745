import i18next from 'i18next';
import { constants } from '@yola/subscription-manager-js';
import { contentTypes } from '../components/plan-features-row/constants';

const { ecommercePlanFeatureNames: featureNames } = constants;

const orderedFeatures = [
  featureNames.PRODUCT_LIMIT,
  featureNames.CATEGORIES,
  featureNames.E_GOODS_MAX_SIZE,
  featureNames.GOOGLE_SALES,
  featureNames.FACEBOOK_SALES,
  featureNames.INSTAGRAM_SALES,
  featureNames.AMAZON_SALES,
  featureNames.EBAY_SALES,
  featureNames.FACEBOOK_ADVERTISING,
  featureNames.GOOGLE_ADVERTISING,
  featureNames.TIKTOK_ADVERTISING,
  featureNames.INVENTORY_TRACKING,
  featureNames.ABANDONED_CART_RECOVERY,
  featureNames.MULTILINGUAL,
  featureNames.TAX_CALCULATIONS,
  featureNames.MARKETING_EMAILS,
  featureNames.PRE_ORDERS,
  featureNames.SUBSCRIPTIONS,
  featureNames.GIFT_CARDS,
  featureNames.COUPONS,
];

const getFeatureRowData = (name, featureValue) => {
  switch (name) {
    case featureNames.PRODUCT_LIMIT:
      return {
        title: i18next.t('Product limit'),
        contentType: contentTypes.TEXT,
        text: featureValue,
      };
    case featureNames.CATEGORIES:
      return {
        title: i18next.t('Categories'),
        contentType: contentTypes.TEXT,
        text: featureValue,
      };
    case featureNames.E_GOODS_MAX_SIZE:
      return {
        title: i18next.t('E-goods'),
        contentType: contentTypes.TEXT,
        text: `${featureValue.toFixed(1)} MB`,
      };
    case featureNames.GOOGLE_SALES:
      return {
        title: i18next.t('Google Shopping'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.FACEBOOK_SALES:
      return {
        title: i18next.t('Sell on Facebook'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.INSTAGRAM_SALES:
      return {
        title: i18next.t('Sell on Instagram'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.AMAZON_SALES:
      return {
        title: i18next.t('Sell on Amazon'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.EBAY_SALES:
      return {
        title: i18next.t('Sell on eBay'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.FACEBOOK_ADVERTISING:
      return {
        title: i18next.t('Automated advertising on Facebook'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.GOOGLE_ADVERTISING:
      return {
        title: i18next.t('Automated advertising on Google'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.TIKTOK_ADVERTISING:
      return {
        title: i18next.t('Automated advertising on TikTok'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.INVENTORY_TRACKING:
      return {
        title: i18next.t('Inventory tracking'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.ABANDONED_CART_RECOVERY:
      return {
        title: i18next.t('Automatic abandoned cart recovery'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.MULTILINGUAL:
      return {
        title: i18next.t('Multilingual Store'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.TAX_CALCULATIONS:
      return {
        title: i18next.t('Automatic tax calculations'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.MARKETING_EMAILS:
      return {
        title: i18next.t('Automated email marketing'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.PRE_ORDERS:
      return {
        title: i18next.t('Pre-orders'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.SUBSCRIPTIONS:
      return {
        title: i18next.t('Subscriptions'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.GIFT_CARDS:
      return {
        title: i18next.t('Gift cards'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    case featureNames.COUPONS:
      return {
        title: i18next.t('Discount coupons'),
        contentType: featureValue ? contentTypes.ICON_CHECK : contentTypes.ICON_MINUS,
      };
    default:
      return null;
  }
};

const getEcommercePlanFeatures = (features) =>
  orderedFeatures
    .filter((name) => Object.keys(features).includes(name))
    .reduce((acc, name) => {
      const featureRowData = getFeatureRowData(name, features[name]);

      return featureRowData ? [...acc, featureRowData] : acc;
    }, []);

export default getEcommercePlanFeatures;
