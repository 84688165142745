import React, { Fragment, useState, useEffect } from 'react';
import { designSystem } from '@yola/ws-ui';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import i18next from 'i18next';
import { constants } from '@yola/subscription-manager-js';
import { subscriptionIcons } from '../components/subscription-item/constants';
import SubscriptionItemContainer from '../containers/subscription-item-container';
import segment from '../../analytics/segment';
import routesMap from '../../../router/helpers/routes-map';
import useActiveUserSubscriptions from '../hooks/use-active-user-subscriptions';

const { Stack, Box, Paragraph, Heading, ActionButton } = designSystem;
const { CANCELED } = constants.SubscriptionStatus;
const {
  constants: { events, sourceIds, triggerIds },
  trackers: { trackEvent },
} = segment;

const ECOMMERCE_TOOLTIP_ID = 'eccomerce-tooltip-id';
const getCaptions = () => ({
  title: i18next.t(`Select the subscription you'd like to cancel:`),
  continue: i18next.t('Continue'),
  keepActive: i18next.t('Keep my plan active'),
  emptyState: i18next.t(`You don't have active subscriptions`),
  ecommerceTooltip: i18next.t('If you cancel account, you will not be able to keep Online Store'),
});

const CancelSubscriptionsPage = () => {
  const [subId, setSubId] = useState(null);

  const dispatch = useDispatch();

  const userSubscriptions = useActiveUserSubscriptions();

  const captions = getCaptions();

  const getSelectedIconProps = (id, isEcommerce, status) => {
    if (subId === id) return { type: subscriptionIcons.SELECTED };

    return subId && isEcommerce && status !== CANCELED
      ? {
          type: subscriptionIcons.SELECTED,
          disabled: true,
          tooltip: { text: captions.ecommerceTooltip, id: ECOMMERCE_TOOLTIP_ID },
        }
      : null;
  };

  const onKeepActiveClick = () => {
    trackEvent(events.KEEP_MY_PLAN_TRIGGER_CLICKED, {
      triggerId: triggerIds.KEEP_MY_PLAN_ACTIVE,
    });

    dispatch(push(routesMap.dashboard.url()));
  };
  const onContinue = () =>
    dispatch(
      push(
        routesMap.cancelSubscription.url({
          subId,
        }),
        { sourceId: sourceIds.CANCEL_SUBSCRIPTION_PAGE }
      )
    );
  const onSubscriptionClick = (subscriptionId) => setSubId(subscriptionId);

  useEffect(() => {
    trackEvent(events.CANCEL_SUBSCRIPTION_PAGE_DISPLAYED);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Box marginBottom="spacing-2-xl">
        <Heading type="heading-2" align="center">
          {captions.title}
        </Heading>
      </Box>
      <div className="ws-cancel-subscriptions-content">
        {userSubscriptions.length ? (
          <Fragment>
            <Stack gap="spacing-2-xs">
              {userSubscriptions.map(({ name, isEcommerce, subscriptionMeta }) => (
                <SubscriptionItemContainer
                  key={subscriptionMeta.id}
                  name={name}
                  subscriptionMeta={subscriptionMeta}
                  isEcommerce={isEcommerce}
                  onClick={onSubscriptionClick}
                  icon={getSelectedIconProps(
                    subscriptionMeta.id,
                    isEcommerce,
                    subscriptionMeta.status
                  )}
                  disabled={subscriptionMeta.status === CANCELED}
                  withNotification={false}
                />
              ))}
            </Stack>
            <Box marginTop="spacing-m">
              <div className="ws-cancel-subscriptions-content__footer">
                <ActionButton
                  appearance="danger"
                  label={captions.continue}
                  onClick={onContinue}
                  disabled={!subId}
                />
                <ActionButton
                  appearance="accent"
                  format="solid"
                  label={captions.keepActive}
                  onClick={onKeepActiveClick}
                />
              </div>
            </Box>
          </Fragment>
        ) : (
          <Paragraph size="large">{captions.emptyState}</Paragraph>
        )}
      </div>
    </Fragment>
  );
};

export default CancelSubscriptionsPage;
