import yousersjs from '@yola/yousersjs';
import status from 'src/js/modules/status';
import statusNames from 'src/js/modules/user/constants/status-names';
import config from 'src/js/modules/config';
import utils from 'src/js/utils';

const deleteAccount = () => async (dispatch, getState) => {
  try {
    dispatch(status.actions.setStatus(statusNames.DELETE_ACCOUNT, status.constants.LOADING));
    await yousersjs.user().delete();
    await yousersjs.requests.logout();

    const signUpUrl = config.selectors.getSignUpUrl(getState());
    utils.redirectToUrl(signUpUrl);
  } catch (e) {
    dispatch(status.actions.setStatus(statusNames.DELETE_ACCOUNT, status.constants.FAILED));
    throw e;
  }
};

export default deleteAccount;
