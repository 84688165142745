import React from 'react';
import PropTypes from 'prop-types';

function SiteListItem(props) {
  const { children } = props;

  return <div className="ws-site-list__grid-item">{children}</div>;
}

SiteListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SiteListItem;
