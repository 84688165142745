import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { designSystem } from '@yola/ws-ui';
import i18next from 'i18next';
import routesMap from 'src/js/router/helpers/routes-map';
import segment from '../../analytics/segment';

const { CtaBanner } = designSystem;
const {
  constants: { triggerIds, events },
  track,
} = segment;

const getCaptions = () => ({
  title: i18next.t('Upgrade Online store'),
  subtitle: i18next.t('Unlock your store’s true potential'),
  label: i18next.t('Upgrade now!'),
});

const OnlineStoreBannerContainer = () => {
  const dispatch = useDispatch();
  const captions = getCaptions();

  const redirectToOnlineStore = () => {
    track(events.ONLINE_STORE_TRIGGER_CLICKED, {
      triggerId: triggerIds.BANNER,
      siteId: null,
    });
    dispatch(
      push(
        routesMap.ecommercePaywall.url({
          query: { triggerId: triggerIds.BANNER, paymentBackUrl: window.location.href },
        })
      )
    );
  };

  return (
    <CtaBanner
      title={captions.title}
      subtitle={captions.subtitle}
      ctaButton={{
        label: captions.label,
        onClick: redirectToOnlineStore,
      }}
      iconGlyph="online-store"
      layout="mobile"
      animate={false}
    />
  );
};

export default OnlineStoreBannerContainer;
