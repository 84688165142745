import React from 'react';
import PropTypes from 'prop-types';
import { Modal, designSystem } from '@yola/ws-ui';
import i18next from 'i18next';
import status from '../../../status';

const { DialogHeader, DialogHeaderTitle, ActionButton, ModalButtonGroup, Box, Paragraph, Divider } =
  designSystem;

const MODAL_WIDTH = 336;

const getCaptions = ({ name, ...captionsOverrides }) => ({
  title: i18next.t('Remove payment method'),
  description: i18next.t(
    'The payment method {name} will be removed from your account. This action cannot be undone.',
    { name: `<b>${name}</b>` }
  ),
  deleteButton: i18next.t('Remove'),
  cancelButton: i18next.t('Cancel'),
  ...captionsOverrides,
});

const DeletePaymentMethodDialog = (props) => {
  const { captions: captionsProps, onDelete, onCancel, statusName } = props;
  const targetStatus = status.hooks.useStatus(statusName);
  const isLoading = targetStatus === status.constants.LOADING;

  const captions = getCaptions(captionsProps);

  return (
    <Modal
      isBodyScrollDisabled
      width={MODAL_WIDTH}
      maxWidth={MODAL_WIDTH}
      minWidth={MODAL_WIDTH}
      height="auto"
      centered
      resizable={false}
      draggable={false}
      overlay="visible"
      className="ws-account-management-modal"
    >
      <DialogHeader>
        <DialogHeaderTitle>{captions.title}</DialogHeaderTitle>
      </DialogHeader>
      <Divider />
      <Box
        marginTop="spacing-s"
        marginRight="spacing-m"
        marginBottom="spacing-s"
        marginLeft="spacing-m"
      >
        <Paragraph>
          <span
            // eslint-disable-next-line yola/react/no-danger
            dangerouslySetInnerHTML={{ __html: captions.description }}
          />
        </Paragraph>
      </Box>
      <ModalButtonGroup direction="right">
        <ActionButton
          appearance="danger"
          format="solid"
          label={captions.deleteButton}
          onClick={onDelete}
          isLoading={isLoading}
        />
        <ActionButton label={captions.cancelButton} disabled={isLoading} onClick={onCancel} />
      </ModalButtonGroup>
    </Modal>
  );
};

DeletePaymentMethodDialog.propTypes = {
  captions: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    deleteButton: PropTypes.string,
    cancelButton: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  statusName: PropTypes.string.isRequired,
};

export default DeletePaymentMethodDialog;
