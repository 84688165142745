import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { statusToAppearance, statusToIconGlyph, PAYMENT_METHOD_ITEM_PROP_TYPES } from './constants';
import triggerIds from '../../../analytics/segment/constants/trigger-ids';

const { Paragraph, Heading, NotificationMessage, Box, PaymentMethod, ActionButton } = designSystem;

const PaymentMethodItem = (props) => {
  const {
    id,
    iconType,
    status,
    captions,
    isDefault,
    isCardPaymentMethod,
    onEdit,
    onDelete,
    onSetAsDefault,
  } = props;

  const handleEdit = () => onEdit(id, triggerIds.EDIT_PAYMENT_METHOD);

  const handleUpdate = () => onEdit(id, triggerIds.UPDATE_PAYMENT_METHOD);

  const handleDelete = () => onDelete(id);

  const handleSetAsDefault = () => onSetAsDefault(id);

  return (
    <div className="ws-payment-method-item">
      <Box padding="spacing-xs">
        <div className="ws-payment-method-item__content">
          <PaymentMethod type={iconType} />
          <div>
            <Heading type="heading-6">{captions.title}</Heading>
            <Paragraph appearance="medium-emphasis">{captions.payerName}</Paragraph>
            {captions.billingInfo && (
              <Paragraph appearance="medium-emphasis">{captions.billingInfo}</Paragraph>
            )}
          </div>
          <div>
            {isDefault ? (
              <Paragraph appearance="medium-emphasis">{captions.default}</Paragraph>
            ) : (
              <Fragment>
                <Paragraph appearance="medium-emphasis">{captions.secondary}</Paragraph>
                <ActionButton
                  format="text"
                  label={captions.setDefaultButton}
                  onClick={handleSetAsDefault}
                />
              </Fragment>
            )}
          </div>
          <div className="ws-payment-method-item__buttons">
            {isCardPaymentMethod && (
              <ActionButton size="small" label={captions.editButton} onClick={handleEdit} />
            )}
            <ActionButton
              appearance="danger"
              size="small"
              iconGlyph="trash"
              onClick={handleDelete}
            />
          </div>
        </div>
        {!!captions.notification && isCardPaymentMethod && (
          <Box marginTop="spacing-3-xs">
            <NotificationMessage
              appearance={statusToAppearance[status]}
              iconGlyph={statusToIconGlyph[status]}
              title={
                // eslint-disable-next-line yola/react/jsx-wrap-multilines
                <Paragraph appearance={statusToAppearance[status]}>
                  <b>{captions.notification.title}</b>
                </Paragraph>
              }
              actionButtonLabel={captions.notification.buttonLabel}
              onActionButtonClick={handleUpdate}
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

PaymentMethodItem.propTypes = {
  ...PAYMENT_METHOD_ITEM_PROP_TYPES,
  captions: PropTypes.shape({
    ...PAYMENT_METHOD_ITEM_PROP_TYPES.captions,
    default: PropTypes.string.isRequired,
    secondary: PropTypes.string.isRequired,
    setDefaultButton: PropTypes.string.isRequired,
    editButton: PropTypes.string.isRequired,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSetAsDefault: PropTypes.func.isRequired,
};

PaymentMethodItem.defaultProps = {
  // eslint-disable-next-line yola/react/default-props-match-prop-types
  isDefault: false,
  // eslint-disable-next-line yola/react/default-props-match-prop-types
  isCardPaymentMethod: false,
};

export default PaymentMethodItem;
