import config from '../../config';
import i18n from '../../i18n';
import user from '../../user';
import segment from '../../analytics/segment';
import configureZendeskSnippet from '../helpers/configure-zendesk-snippet';
import injectZendeskScript from '../helpers/inject-zendesk-script';
import { DIALOG_ID, SOURCE_ID } from '../constants/common';

const {
  constants: { events },
  track,
} = segment;

const initializeZendesk = async (store) => {
  const state = store.getState();
  const widgetUrl = config.selectors.getZendeskWidgetUrl(state);
  const currentLocale = i18n.selectors.getLocale(state);
  const userData = user.selectors.getUserData(state);
  const isB2C = user.selectors.getIsB2C(state);

  if (!isB2C) return;

  configureZendeskSnippet();
  await injectZendeskScript(widgetUrl);

  window.zE(() => {
    window.zE('webWidget', 'prefill', {
      email: {
        value: userData.email,
        readOnly: true,
      },
      name: {
        value: userData.name,
        readOnly: true,
      },
    });

    window.zE.setLocale(currentLocale);

    window.zE('webWidget:on', 'open', () => {
      track(events.HELP_DISPLAYED, {
        dialogId: DIALOG_ID,
        sourceId: SOURCE_ID,
      });
    });
  });
};

export default initializeZendesk;
