import serviceClient from '../service-client';
import { snakeCaseObjectToCamelCase } from '../../../utils/convert-case';
import statusNames from '../constants/status-names';
import status from '../../status';
import fetchPrices from './fetch-prices';

const createCancellationCoupon = () => async (dispatch) => {
  const yoproducts = serviceClient.get();
  let responseData;

  dispatch(
    status.actions.setStatus(statusNames.CREATE_CANCELLATION_COUPON, status.constants.LOADING)
  );

  try {
    const response = await yoproducts.requests.createCancellationCoupon();
    responseData = snakeCaseObjectToCamelCase(response);

    await dispatch(fetchPrices());

    dispatch(
      status.actions.setStatus(statusNames.CREATE_CANCELLATION_COUPON, status.constants.SUCCEEDED)
    );
  } catch (error) {
    console.error(error);
    dispatch(
      status.actions.setStatus(statusNames.CREATE_CANCELLATION_COUPON, status.constants.FAILED)
    );
  }

  return responseData;
};

export default createCancellationCoupon;
