import CustomerSupportIcon from './customer-support-icon';
import EcommerceIcon from './ecommerce-icon';
import FreeDomainIcon from './free-domain-icon';
import SeoToolsIcon from './seo-tools-icon';
import SitebuilderIcon from './sitebuilder-icon';

export default {
  CustomerSupportIcon,
  EcommerceIcon,
  FreeDomainIcon,
  SeoToolsIcon,
  SitebuilderIcon,
};
