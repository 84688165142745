import traqing from '@yola/traqing.js';
import config from 'src/js/modules/config';
import user from 'src/js/modules/user';

const segmentInitializer = (store) => (next) => (action) => {
  switch (action.type) {
    case config.actionTypes.SET_SERVICE_CONFIG: {
      next(action);

      const state = store.getState();
      const key = config.selectors.getSegmentIoKey(state);
      const publicApiUrl = config.selectors.getApiUrl(state);

      traqing.segment.init(key, publicApiUrl);

      traqing.segment.useIntegration(traqing.segment.integrations.GOOGLE_CLIENT_ID);
      traqing.segment.useIntegration(traqing.segment.integrations.YOLA_COOKIES, {
        names: ['Yola'],
      });

      break;
    }

    case user.actionTypes.SET_USER_DATA: {
      next(action);

      const { id: userId } = action.payload.userData;
      traqing.segment.identify(userId);

      break;
    }

    default: {
      next(action);
    }
  }
};

export default segmentInitializer;
