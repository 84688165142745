import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import i18next from 'i18next';
import sites from 'src/js/modules/sites';
import MainMenu from 'src/js/common/components/main-menu';
import useGalleryUrl from 'src/js/modules/config/hooks/use-gallery-url';
import { useNavbarHeaderContext } from '../../../common/components/navbar-header/context';

function SitesListContainer() {
  const sitesList = useSelector(sites.selectors.getSites, shallowEqual) || [];
  const galleryUrl = useGalleryUrl();
  const { isXl } = useNavbarHeaderContext();

  const sitesMenuItems = useMemo(() => {
    const sitesItems = sitesList.reduce((accum, site) => {
      if (!site.hidden) {
        accum.push({
          title: site.name,
          href: site.sitebuilderUrl,
          heading: site.name,
        });
      }

      return accum;
    }, []);

    if (sitesItems.length) {
      sitesItems.push({
        type: 'divider',
      });
    }

    sitesItems.push({
      title: i18next.t('Create new site...'),
      href: galleryUrl,
    });

    return sitesItems;
  }, [sitesList, galleryUrl]);

  const captions = useMemo(
    () => ({
      name: i18next.t('My websites'),
    }),
    []
  );

  if (!isXl) {
    return null;
  }

  return (
    <MainMenu captions={captions} className="ws-mainmenu--sites-list" items={sitesMenuItems} />
  );
}

export default SitesListContainer;
