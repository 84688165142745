import { accessors, constants } from '@yola/subscription-manager-js';

const { DOMAIN_SUBSCRIPTION } = constants.SubscriptionType;

const getActiveDomainSubscriptions = () => {
  const activeSubscriptions = accessors.getActiveSubscriptions();

  if (!activeSubscriptions || !activeSubscriptions.length) {
    return [];
  }

  return activeSubscriptions.filter(({ type }) => type === DOMAIN_SUBSCRIPTION);
};

export default getActiveDomainSubscriptions;
