import serviceClient from '../service-client';
import { snakeCaseObjectToCamelCase } from '../../../utils/convert-case';
import statusNames from '../constants/status-names';
import status from '../../status';
import selectors from '../selectors';
import actions from '../actions';
import getUpdatedBillingProfiles from '../helpers/get-updated-billing-profiles';
import resolveServiceError from '../../../common/helpers/resolve-service-error';

const handleBillingProfilesUpdate = (updatedProfile, dispatch, state) => {
  const currentProfiles = selectors.getBillingProfiles(state);
  let toUpdate = [updatedProfile];

  const prevDefaultProfile = currentProfiles.find(({ isDefault }) => isDefault);

  if (prevDefaultProfile) {
    toUpdate = [...toUpdate, { ...prevDefaultProfile, isDefault: false }];
  }

  const newProfiles = getUpdatedBillingProfiles(currentProfiles, { toUpdate });
  dispatch(actions.setBillingProfiles(newProfiles));
};

const setBillingProfileAsDefault = (id) => async (dispatch, getState) => {
  const state = getState();
  const yoproducts = serviceClient.get();
  let responseData;

  dispatch(
    status.actions.setStatus(statusNames.SET_BILLING_PROFILE_AS_DEFAULT, status.constants.LOADING)
  );

  try {
    const response = await yoproducts.requests.setBillingProfileAsDefault(id);
    responseData = snakeCaseObjectToCamelCase(response);

    dispatch(
      status.actions.setStatus(
        statusNames.SET_BILLING_PROFILE_AS_DEFAULT,
        status.constants.SUCCEEDED
      )
    );

    const billingProfilesStatus = selectors.getBillingProfilesStatus(state);

    if (billingProfilesStatus === status.constants.SUCCEEDED) {
      handleBillingProfilesUpdate(responseData, dispatch, state);
    }
  } catch (e) {
    const error = await resolveServiceError(e);

    console.error(error);
    dispatch(
      status.actions.setStatus(statusNames.SET_BILLING_PROFILE_AS_DEFAULT, status.constants.FAILED)
    );

    throw error;
  }

  return responseData;
};

export default setBillingProfileAsDefault;
