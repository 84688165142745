import i18next from 'i18next';
import { constants } from '@yola/subscription-manager-js';
import user from '../../user';

const {
  SubscriptionType: { WL_HOSTING_FREE, B2C_HOSTING_FREE },
  SubscriptionStatus: { CANCELED },
  hostingPackageFeatureNames: featureNames,
} = constants;
const { platformComponents } = user.constants;

const CUSTOM_CARE_LIMITED = 'limited';
const fromMbToGb = (mb) => mb / 1024;

const getCancelHostingPlanAdditionalChanges = ({
  isB2C,
  availablePackages,
  userSubscriptions,
  availablePlatformComponents,
}) => {
  const { name: ecommerceName } =
    userSubscriptions.find(
      ({ isEcommerce, subscriptionMeta }) => subscriptionMeta.status !== CANCELED && isEcommerce
    ) || {};
  const freeHostingPlan = isB2C ? B2C_HOSTING_FREE : WL_HOSTING_FREE;

  const { features: featuresList } = availablePackages[freeHostingPlan];
  const {
    pageCount,
    siteCount,
    siteStorageQuota,
    customerCare,
    partnerAds: isAdsEnabled,
  } = featuresList;
  const noPremiumSupport = customerCare === CUSTOM_CARE_LIMITED;
  const noCustomDomain =
    featureNames.CUSTOM_DOMAIN in featuresList &&
    availablePlatformComponents.includes(platformComponents.DOMAINS);

  return [
    noCustomDomain && i18next.t('No custom domain publishing'),
    i18next.t('Site number reduced to {siteCount}', { siteCount }),
    noPremiumSupport && i18next.t('No premium support'),
    i18next.t('Page limit reduce to {pageCount}', { pageCount }),
    i18next.t('Storage limited to {storageLimit} GB', {
      storageLimit: fromMbToGb(siteStorageQuota),
    }),
    isAdsEnabled && i18next.t('Site will display ads'),
    ecommerceName && i18next.t('No access to <b>Online store {name}</b>', { name: ecommerceName }),
  ].filter(Boolean);
};

export default getCancelHostingPlanAdditionalChanges;
