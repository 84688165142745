import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import { fieldNames, kindOfWebsiteValues } from '../constants/common';

const { InputGroupField, SimpleSelectField } = designSystem;

const getKindOfWebsiteFields = ({ setFieldValue }) => ({
  name: fieldNames.KIND_OF_WEBSITE,
  placeholder: `${i18next.t('Select')}...`,
  rules: { required: true },
  Component: SimpleSelectField,
  controlGroup: {
    title: i18next.t('What kind of website do you have?'),
    required: true,
  },
  onChange: () => {
    setFieldValue(fieldNames.OTHER_KIND_OF_WEBSITE, null);
  },
  options: [
    { label: i18next.t('Personal website'), value: kindOfWebsiteValues.PERSONAL },
    { label: i18next.t('Business website'), value: kindOfWebsiteValues.BUSINESS },
    {
      label: i18next.t('A website for a project or study'),
      value: kindOfWebsiteValues.FOR_PROJECT_OR_STUDY,
    },
    { label: i18next.t('Non-profit organization'), value: kindOfWebsiteValues.NON_PROFIT },
    { label: i18next.t('Membership building'), value: kindOfWebsiteValues.MEMBERSHIP },
    { label: i18next.t('Portfolio'), value: kindOfWebsiteValues.PORTFOLIO },
    { label: `${i18next.t('Other')}...`, value: kindOfWebsiteValues.OTHER },
  ],
  subFields: {
    [kindOfWebsiteValues.OTHER]: {
      name: fieldNames.OTHER_KIND_OF_WEBSITE,
      placeholder: `${i18next.t('Write your kind of website')}`,
      rules: { required: true },
      Component: InputGroupField,
    },
  },
});

export default getKindOfWebsiteFields;
