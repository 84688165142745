const formatCreditCardNumber = (value) => {
  const cardGroups = value
    .replace(/\s+/g, '')
    .replace(/[^0-9]/gi, '')
    .substr(0, 16)
    .match(/\d{1,4}/g);

  return cardGroups ? cardGroups.join(' ') : '';
};

export default formatCreditCardNumber;
