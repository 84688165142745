import PropTypes from 'prop-types';
import { constants } from '@yola/subscription-manager-js';
import { PER_MONTH, PER_YEAR } from 'src/js/modules/common/constants/terms';

const { ACTIVE_STATUSES } = constants;

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  term: PropTypes.oneOf([PER_MONTH, PER_YEAR]).isRequired,
  status: PropTypes.oneOf(ACTIVE_STATUSES).isRequired,
  billing_date: PropTypes.string.isRequired,
  expiry_date: PropTypes.string.isRequired,
  deprovision_date: PropTypes.string.isRequired,
  auto_renew: PropTypes.bool.isRequired,
});
