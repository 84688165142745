import React from 'react';
import { designSystem } from '@yola/ws-ui';
import i18next from 'i18next';
import { CANCEL_PLAN_TERMS_OF_USE_PROP_TYPES } from './constants';

const { Paragraph, Mark, Link } = designSystem;

const CancelPlanTermsOfUse = ({ expiryDate, termsOfUseUrl }) => (
  <Paragraph size="small" appearance="medium-emphasis">
    {i18next.t('If you choose to cancel, your upgraded features will remain active until')}{' '}
    <Mark appearance="high-emphasis">
      <b>{expiryDate}</b>
    </Mark>
    . {i18next.t('In accordance with our')}{' '}
    <Link href={termsOfUseUrl} isUnderlined>
      {i18next.t('Terms and Conditions')}
    </Link>
    , {i18next.t('you are still responsible for any charges already incurred')}.
  </Paragraph>
);

CancelPlanTermsOfUse.propTypes = CANCEL_PLAN_TERMS_OF_USE_PROP_TYPES;

export default CancelPlanTermsOfUse;
