import { useSelector, shallowEqual } from 'react-redux';
import { selectors, prioritizer } from '@yola/subscription-manager-js';

const useMinEcommercePlan = (minWishingPlan) => {
  const deprovisionedEcommerceSubscription = useSelector(
    selectors.getLastDeprovisionedEcommerceSubscription,
    shallowEqual
  );

  if (deprovisionedEcommerceSubscription) {
    if (minWishingPlan) {
      return prioritizer.getPriority(deprovisionedEcommerceSubscription.type) >
        prioritizer.getPriority(minWishingPlan)
        ? deprovisionedEcommerceSubscription.type
        : minWishingPlan;
    }

    return deprovisionedEcommerceSubscription.type;
  }

  return minWishingPlan || '';
};

export default useMinEcommercePlan;
