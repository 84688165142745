import { useSelector, shallowEqual } from 'react-redux';
import { selectors, verifiers } from '@yola/subscription-manager-js';
import user from '../../user';
import getPrices from '../selectors/prices';
import { PER_MONTH, PER_YEAR } from '../../common/constants/terms';

const useAnnualDiscount = () => {
  const { currency } = useSelector(user.selectors.getUserPreferences, shallowEqual);
  const prices = useSelector(getPrices, shallowEqual);
  const packageType = useSelector(selectors.getCurrentPackageType);
  const isPaidHostingSubscription = verifiers.isPaidHostingPackageType(packageType);

  if (!isPaidHostingSubscription) return 0;

  const product = prices[packageType];

  if (!product) return 0;

  const annual = product[PER_YEAR].prices.current[currency];
  const month = product[PER_MONTH].prices.current[currency];
  const discount = Math.round(((12 * month - annual) * 100) / (12 * month));

  return discount;
};

export default useAnnualDiscount;
