import * as Sentry from '@sentry/browser';

let sentryIsInitialized = false;

const init = (dsn) => {
  Sentry.init({
    dsn,
  });
  sentryIsInitialized = true;
};
const isInit = () => sentryIsInitialized;
const getInstance = () => Sentry;

const setUserId = (id) => {
  if (id) {
    Sentry.configureScope((scope) => {
      scope.setUser({ id });
      // eslint-disable-next-line no-console
      console.info(`Sentry set user ID: ${id}`);
    });
  }
};

export default {
  init,
  getInstance,
  isInit,
  setUserId,
};
