import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import getDialogByType from '../accessors/get-dialog-by-type';
import getDialog from '../selectors/dialog';
import onDialogMainAction from '../helpers/on-dialog-main-action';
import onDialogCancel from '../helpers/on-dialog-cancel';
import onDialogDisplayed from '../helpers/on-dialog-displayed';
import actions from '../actions';

function DialogsContainer({ location }) {
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog, shallowEqual);
  const DialogComponent = getDialogByType(dialog.type);

  const [sharedData, setSharedData] = useState({});

  const getSharedData = useCallback(() => sharedData, [sharedData]);

  const resolveSharedData = useCallback(
    (data) => {
      const currentData = getSharedData();

      if (typeof data === 'function') {
        setSharedData(data(currentData));
        return;
      }

      setSharedData({ ...currentData, ...data });
    },
    [getSharedData]
  );

  const modalProps = dialog.modalProps || {};
  const { pathname, state: { isCloseModalOnRouteChange = true } = {} } = location;

  const { onDialogMainAction: onDialogMainActionProp, onDialogCancel: onDialogCancelProp } =
    modalProps;

  modalProps.onDialogMainAction = (props) => {
    if (onDialogMainActionProp) {
      onDialogMainActionProp(props);
    }

    const { dialogType, ...traits } = props || {};
    onDialogMainAction({ dialogType: dialogType || dialog.type, ...traits });
  };

  modalProps.onDialogCancel = (props) => {
    if (onDialogCancelProp) {
      onDialogCancelProp(props);
    }

    const { dialogType, ...traits } = props || {};
    onDialogCancel({ dialogType: dialogType || dialog.type, ...traits });
  };

  useEffect(() => {
    if (!dialog.type) {
      if (!modalProps.preserveSharedData) {
        setSharedData({});
      }
      return;
    }

    onDialogDisplayed(dialog);
  }, [modalProps.preserveSharedData, dialog]);

  useEffect(() => {
    if (DialogComponent && isCloseModalOnRouteChange) {
      dispatch(actions.hide());
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [pathname]);

  if (!DialogComponent) {
    return null;
  }

  return (
    <DialogComponent
      getSharedData={getSharedData}
      resolveSharedData={resolveSharedData}
      {...modalProps}
    />
  );
}

DialogsContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({
      isCloseModalOnRouteChange: PropTypes.bool,
    }),
  }).isRequired,
};

export default withRouter(DialogsContainer);
