import React, { useMemo } from 'react';
import i18next from 'i18next';
import { constants } from '@yola/subscription-manager-js';
import { designSystem } from '@yola/ws-ui';
import { PER_MONTH } from 'src/js/modules/common/constants/terms';
import useLocaleDate from 'src/js/modules/common/hooks/use-locale-date';
import products from 'src/js/modules/products';
import cardTypes from '../constants/card-types';

const { ACTIVE, CANCELED, EXPIRED } = constants.SubscriptionStatus;
const { Paragraph, Mark, Heading } = designSystem;

const getCaptions = () => ({
  month: i18next.t('month'),
  year: i18next.t('year'),
  expiresOn: i18next.t('Expires on:'),
  reactivate: i18next.t('Reactivate'),
  expiresDate: i18next.t('Expiry date:'),
  subscriptionExpiredOn: (deprovisionDate) =>
    i18next.t('Subscription expired. Renew your plan by {deprovisionDate} to avoid cancellation', {
      deprovisionDate,
    }),
  renewNow: i18next.t('Renew now'),
  youWillBeBilled: i18next.t('You will be billed on'),
  canceledSubscription: (name) =>
    i18next.t('You have canceled your {name} subscription', {
      name,
    }),
  canceledOnlineStore: (name) => i18next.t('You have canceled your Online store: {name}', { name }),
  andOnlineStore: (name) => i18next.t('and Online store: {name}', { name }),
  subscriptionWillExpireOn: i18next.t('Your subscription will expire on'),
  downgradedTo: i18next.t('and you will be downgraded to'),
  freeAccount: i18next.t('Free account'),
  notAbleToSell: i18next.t('and you won’t be able to sell your products on our platform anymore.'),
  receiveEmail: i18next.t('You will receive email with confirmation'),
  getBenefits: i18next.t('and get back to enjoying all the benefits'),
  discountNotificationTitle: i18next.t('Thank you for staying with us!'),
  discountNotificationMessage: i18next.t('Your discount has been successfully applied'),
  monthlyDiscountExpiry: i18next.t('For the next 2 months'),
  annualDiscountExpiry: i18next.t('For next renewal'),
  reactivateSubscription: i18next.t(
    'Reactivate your subscription to keep your website up and running'
  ),
  autoRenewIsOff: i18next.t('Your auto-renew is off'),
  turnOn: i18next.t('Turn on'),
});

const useSubscriptionDetailsCaptions = ({
  subscriptionMeta,
  defaultBillingProfile,
  paymentMethod,
  isJustCanceled,
  isHostingSubscription,
  userSubscriptions,
  isJustDiscountActivated,
}) => {
  const { type, status: subscriptionStatus, term, auto_renew: autoRenew } = subscriptionMeta;

  const { currentPrice: price, basePrice } = products.hooks.useFormattedSubscriptionPrice(
    type,
    term
  );
  const billingDate = useLocaleDate(subscriptionMeta.billing_date);
  const expireDate = useLocaleDate(subscriptionMeta.expiry_date);
  const deprovisionDate = useLocaleDate(subscriptionMeta.deprovision_date, {
    day: 'numeric',
    month: 'short',
  });

  const dynamicCaptions = useMemo(() => {
    const captions = getCaptions();
    let paymentMethodTitle = '';

    if (defaultBillingProfile) {
      paymentMethodTitle =
        paymentMethod === cardTypes.PAYPALL
          ? defaultBillingProfile.paypalEmail
          : `**** ${defaultBillingProfile.cardLastDigits}`;
    }

    const defaultCaptions = {
      price: price ? `${price}/${term === PER_MONTH ? captions.month : captions.year}` : '—',
      basePrice: basePrice
        ? `${basePrice}/${term === PER_MONTH ? captions.month : captions.year}`
        : '',
      discountExpiry:
        term === PER_MONTH ? captions.monthlyDiscountExpiry : captions.annualDiscountExpiry,
      paymentMethodTitle,
    };

    switch (subscriptionStatus) {
      case CANCELED: {
        const hostingSubscription = userSubscriptions.find(({ isEcommerce }) => !isEcommerce);
        const ecommerceSubscription = userSubscriptions.find(({ isEcommerce }) => isEcommerce);
        let canceledTitle = '';

        if (hostingSubscription && hostingSubscription.subscriptionMeta.status === CANCELED) {
          canceledTitle = captions.canceledSubscription(hostingSubscription.name);
        }

        if (ecommerceSubscription && ecommerceSubscription.subscriptionMeta.status === CANCELED) {
          const hostingUpdatedAt = new Date(
            hostingSubscription.subscriptionMeta.updated_at
          ).getTime();
          const ecommerceUpdatedAt = new Date(
            ecommerceSubscription.subscriptionMeta.updated_at
          ).getTime();

          if (hostingUpdatedAt === ecommerceUpdatedAt) {
            canceledTitle = `${canceledTitle} ${captions.andOnlineStore(
              ecommerceSubscription.name
            )}`;
          }

          if (hostingUpdatedAt < ecommerceUpdatedAt) {
            canceledTitle = captions.canceledOnlineStore(ecommerceSubscription.name);
          }
        }

        const canceledDescription = (
          <Paragraph size="small" appearance="medium-emphasis">
            {captions.subscriptionWillExpireOn}{' '}
            <Mark appearance="high-emphasis">
              <b>{expireDate}</b>
            </Mark>
            {isHostingSubscription ? (
              <React.Fragment>
                , {captions.downgradedTo}{' '}
                <Mark appearance="high-emphasis">
                  <b>{captions.freeAccount}</b>
                </Mark>
                .
              </React.Fragment>
            ) : (
              `, ${captions.notAbleToSell}`
            )}
            {/* TODO: uncomment the `receiveEmail` if https://github.com/yola/production/issues/11343 is completed  */}
            {/* {' '} */}
            {/* {captions.receiveEmail} */}
          </Paragraph>
        );

        return {
          ...defaultCaptions,
          dateLabel: captions.expiresOn,
          date: expireDate,
          notification: isJustCanceled
            ? {
                title: (
                  <Paragraph appearance="high-emphasis">
                    <b>{canceledTitle}</b>
                  </Paragraph>
                ),
                description: canceledDescription,
              }
            : {
                title: (
                  <Paragraph appearance="cta">
                    <b>{captions.reactivateSubscription}</b>
                  </Paragraph>
                ),
                buttonLabel: captions.reactivate,
              },
          renewTitle: captions.reactivate,
        };
      }
      case EXPIRED:
        return {
          ...defaultCaptions,
          dateLabel: captions.expiresDate,
          date: expireDate,
          notification: {
            title: (
              <Paragraph appearance="danger">
                <b>{captions.subscriptionExpiredOn(deprovisionDate)}</b>
              </Paragraph>
            ),
            buttonLabel: captions.renewNow,
          },
          renewTitle: captions.renewNow,
        };
      case ACTIVE:
      default: {
        const discountNotification = {
          title: (
            <Heading type="heading-6" appearance="success">
              {captions.discountNotificationTitle}{' '}
              <Mark appearance="high-emphasis">{captions.discountNotificationMessage}</Mark>
            </Heading>
          ),
        };

        if (autoRenew) {
          return {
            ...defaultCaptions,
            dateLabel: captions.youWillBeBilled,
            date: billingDate,
            renewTitle: captions.renewNow,
            ...(isJustDiscountActivated && { notification: discountNotification }),
          };
        }
        return {
          ...defaultCaptions,
          dateLabel: captions.autoRenewIsOff,
          renewTitle: captions.renewNow,
          ...(isJustDiscountActivated && { notification: discountNotification }),
        };
      }
    }
  }, [
    billingDate,
    expireDate,
    deprovisionDate,
    price,
    basePrice,
    subscriptionStatus,
    term,
    defaultBillingProfile,
    paymentMethod,
    isJustCanceled,
    isHostingSubscription,
    userSubscriptions,
    autoRenew,
    isJustDiscountActivated,
  ]);

  return dynamicCaptions;
};

export default useSubscriptionDetailsCaptions;
