import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import { constants } from '@yola/subscription-manager-js';
import useLocaleDate from 'src/js/modules/common/hooks/use-locale-date';
import { PER_MONTH } from 'src/js/modules/common/constants/terms';
import products from '../../products';
import { subscriptionIcons } from '../components/subscription-item/constants';
import SubscriptionItem from '../components/subscription-item';
import SUBSCRIPTION_META_PROP_SHAPE from '../constants/subscription-meta-prop-shape';

const { Mark } = designSystem;
const { ACTIVE, CANCELED, EXPIRED } = constants.SubscriptionStatus;

const SubscriptionItemContainer = (props) => {
  const {
    name: subscriptionName,
    subscriptionMeta,
    isEcommerce,
    onClick,
    onReactivate,
    onRenew,
    icon,
    withNotification,
    disabled,
    onReschedule,
  } = props;
  const { id, type, term, status, auto_renew: autoRenew } = subscriptionMeta;

  const [isActionButtonLoading, setActionButtonLoadingState] = useState(false);

  const { currentPrice: price } = products.hooks.useFormattedSubscriptionPrice(type, term);

  const billingDate = useLocaleDate(subscriptionMeta.billing_date);
  const expireDate = useLocaleDate(subscriptionMeta.expiry_date);
  const deprovisionDate = useLocaleDate(subscriptionMeta.deprovision_date, {
    day: 'numeric',
    month: 'short',
  });

  const captions = useMemo(() => {
    const name = `${
      isEcommerce ? i18next.t('Online store') : i18next.t('Hosting plan')
    }: ${subscriptionName}`;

    switch (status) {
      case CANCELED:
        return {
          name,
          status: i18next.t('Canceled'),
          dateLabel: (
            <Mark appearance="high-emphasis">
              <b>{i18next.t('Expires on:')}</b>
            </Mark>
          ),
          date: expireDate,
          notification: {
            title: i18next.t('Reactivate your subscription to keep your website up and running'),
            buttonLabel: i18next.t('Reactivate'),
          },
        };
      case EXPIRED:
        return {
          name,
          status: i18next.t('Expired'),
          dateLabel: (
            <Mark appearance="high-emphasis">
              <b>{i18next.t('Expiry date:')}</b>
            </Mark>
          ),
          date: expireDate,
          notification: {
            title: i18next.t(
              'Subscription expired. Renew your plan by {deprovisionDate} to avoid cancellation',
              { deprovisionDate }
            ),
            buttonLabel: i18next.t('Renew now'),
          },
        };
      case ACTIVE:
      default:
        if (autoRenew) {
          return {
            name,
            status: i18next.t('Active'),
            dateLabel: (
              <React.Fragment>
                {term === PER_MONTH ? i18next.t('Renews monthly') : i18next.t('Renews annually')}
                &nbsp;
                {price && (
                  <Mark appearance="high-emphasis">
                    <b>{price}</b>
                  </Mark>
                )}
              </React.Fragment>
            ),
            date: billingDate,
          };
        }
        return {
          name,
          status: i18next.t('Active'),
          dateLabel: i18next.t('Your auto-renew is off'),
          notification: {
            title: i18next.t(
              'Your auto-renew is off. The subscription will expire on {expireDate}',
              { expireDate }
            ),
            buttonLabel: i18next.t('Turn on'),
          },
        };
    }
  }, [
    billingDate,
    deprovisionDate,
    expireDate,
    isEcommerce,
    price,
    status,
    subscriptionName,
    term,
    autoRenew,
  ]);

  const handleClick = () => {
    if (!disabled) {
      onClick(id);
    }
  };

  const handleNotificationClick = async () => {
    setActionButtonLoadingState(true);

    if (status === CANCELED) {
      await onReactivate(id, type, term);
    } else if (status === EXPIRED) {
      await onRenew(type, term, status);
    } else if (!autoRenew) {
      await onReschedule(id, type, term, status);
    }

    setActionButtonLoadingState(false);
  };

  return (
    <SubscriptionItem
      captions={captions}
      status={status}
      onClick={handleClick}
      onNotificationClick={handleNotificationClick}
      isActionButtonLoading={isActionButtonLoading}
      icon={icon}
      withNotification={withNotification}
      disabled={disabled}
    />
  );
};

SubscriptionItemContainer.propTypes = {
  name: PropTypes.string.isRequired,
  subscriptionMeta: SUBSCRIPTION_META_PROP_SHAPE.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(subscriptionIcons)).isRequired,
      tooltip: PropTypes.shape({ text: PropTypes.string, id: PropTypes.string }),
      disabled: PropTypes.bool,
    }),
    PropTypes.oneOf([null]),
  ]),
  isEcommerce: PropTypes.bool,
  withNotification: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onReactivate: PropTypes.func,
  onRenew: PropTypes.func,
  onReschedule: PropTypes.func,
};

SubscriptionItemContainer.defaultProps = {
  isEcommerce: false,
  withNotification: true,
  disabled: false,
  icon: { type: subscriptionIcons.NEXT },
  onReactivate: Function.prototype,
  onRenew: Function.prototype,
  onReschedule: Function.prototype,
};

export default SubscriptionItemContainer;
