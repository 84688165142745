import React from 'react';
import PropTypes from 'prop-types';
import { designSystem, Modal } from '@yola/ws-ui';
import ErrorNotificationContainer from 'src/js/common/containers/error-notification-container';
import HotPriceIcon from './icons/hot-price-icon';
import CancelPlanDiscountItem from '../cancel-plan-discount-item';

const { Box, Stack, ActionButton, Heading, Paragraph, Mark } = designSystem;
const WIDTH = 677;
const overlaySpacing = { y: 50 };

const CancelPlanDiscount = (props) => {
  const {
    captions,
    basePrice,
    discountPrice,
    isLoading,
    isError,
    onBack,
    onNext,
    onActivateDiscountClick,
    clearError,
  } = props;
  const {
    title: { gift, discount },
    description,
    buttonLabels: { back, activateDiscount, next },
    discountItem: { price, discountPrice: discountPriceCaption, term },
  } = captions;

  return (
    <Modal
      isBodyScrollDisabled
      isContainerScrollable
      width={WIDTH}
      overlaySpacing={overlaySpacing}
      height="auto"
      centered
      resizable={false}
      draggable={false}
      overlay="visible"
      className="ws-cancel-plan-discount"
    >
      <Box
        paddingTop="spacing-2-xs"
        paddingRight="spacing-3-xs"
        paddingBottom="spacing-xl"
        paddingLeft="spacing-3-xs"
      >
        <ActionButton
          format="text"
          size="small"
          iconGlyph="back"
          label={back}
          disabled={isLoading}
          onClick={onBack}
        />
        <Stack gap="spacing-xl">
          <Box marginRight="spacing-m" marginLeft="spacing-m">
            {isError && <ErrorNotificationContainer onClose={clearError} />}
          </Box>
          <div className="ws-cancel-plan-discount__icon">
            <HotPriceIcon />
          </div>
          <Stack gap="spacing-2-xs">
            <Heading type="heading-3" align="center">
              {gift} <Mark appearance="cta">{discount}</Mark>
            </Heading>
            <Paragraph size="large" align="center">
              {description}
            </Paragraph>
          </Stack>
          <div className="ws-cancel-plan-discount__items">
            <CancelPlanDiscountItem caption={price} price={basePrice} term={term} />
            <CancelPlanDiscountItem
              caption={discountPriceCaption}
              price={discountPrice}
              term={term}
              active
            />
          </div>
          <div className="ws-cancel-plan-discount__buttons">
            <ActionButton
              format="text"
              fontWeight="regular"
              label={next}
              disabled={isLoading}
              onClick={onNext}
            />
            <ActionButton
              format="solid"
              appearance="cta"
              fontWeight="regular"
              size="large"
              label={activateDiscount}
              isLoading={isLoading}
              onClick={onActivateDiscountClick}
            />
          </div>
        </Stack>
      </Box>
    </Modal>
  );
};

CancelPlanDiscount.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.shape({
      gift: PropTypes.string.isRequired,
      discount: PropTypes.string.isRequired,
    }),
    description: PropTypes.string.isRequired,
    discountItem: PropTypes.shape({
      price: PropTypes.string.isRequired,
      discountPrice: PropTypes.string.isRequired,
      term: PropTypes.string.isRequired,
    }).isRequired,
    buttonLabels: PropTypes.shape({
      back: PropTypes.string.isRequired,
      activateDiscount: PropTypes.string.isRequired,
      next: PropTypes.string.isRequired,
    }),
  }).isRequired,
  basePrice: PropTypes.string.isRequired,
  discountPrice: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onActivateDiscountClick: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
};

CancelPlanDiscount.defaultProps = {
  isLoading: false,
  isError: false,
};

export default CancelPlanDiscount;
