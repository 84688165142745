import actionTypes from '../constants/action-types';

const billingProfiles = (state = [], action) => {
  if (action.type === actionTypes.SET_BILLING_PROFILES) {
    return action.payload.billingProfiles;
  }

  return state;
};

export default billingProfiles;
