import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { designSystem, Modal } from '@yola/ws-ui';

const {
  DialogHeader,
  DialogHeaderTitle,
  DialogHeaderControls,
  DialogHeaderIconButton,
  Divider,
  Box,
  ModalButtonGroup,
  ActionButton,
} = designSystem;

const WIDTH = 600;
const overlaySpacing = { y: 50 };

const CancelPLanModal = ({
  name,
  isLoading,
  onClose,
  onKeepActiveClick,
  onBack,
  next,
  onSubmit,
  children,
}) => (
  <Modal
    isBodyScrollDisabled
    isContainerScrollable
    width={WIDTH}
    height="auto"
    centered
    resizable={false}
    draggable={false}
    overlaySpacing={overlaySpacing}
    overlay="visible"
    className="ws-cancel-plan-modal"
  >
    <DialogHeader>
      <DialogHeaderTitle>{i18next.t('Cancel {name} plan', { name })}</DialogHeaderTitle>
      <DialogHeaderControls>
        <DialogHeaderIconButton onClick={onClose} disabled={isLoading} glyph="close" />
      </DialogHeaderControls>
    </DialogHeader>
    <Divider />
    <div className="ws-cancel-plan-modal__content">
      <Box
        paddingTop="spacing-s"
        paddingBottom="spacing-s"
        paddingLeft="spacing-m"
        paddingRight="spacing-m"
      >
        {children}
      </Box>
    </div>
    <ModalButtonGroup direction="right">
      <ActionButton
        appearance="accent"
        format="solid"
        label={i18next.t('Keep my plan active')}
        disabled={isLoading}
        onClick={onKeepActiveClick}
      />
      {next && (
        <ActionButton
          appearance="danger"
          label={i18next.t('Continue')}
          disabled={next.disabled}
          onClick={next.callback}
        />
      )}
      {onSubmit && (
        <ActionButton
          appearance="danger"
          label={i18next.t('Submit')}
          isLoading={isLoading}
          onClick={onSubmit}
        />
      )}
      {onBack && (
        <ActionButton
          label={i18next.t('Back')}
          disabled={isLoading}
          onClick={onBack}
          iconGlyph="back"
        />
      )}
    </ModalButtonGroup>
  </Modal>
);

CancelPLanModal.defaultProps = {
  isLoading: false,
  onBack: null,
  next: null,
  onSubmit: null,
};

CancelPLanModal.propTypes = {
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
  next: PropTypes.shape({ callback: PropTypes.func.isRequired, disabled: PropTypes.bool }),
  onClose: PropTypes.func.isRequired,
  onKeepActiveClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default CancelPLanModal;
