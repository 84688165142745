import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import user from 'src/js/modules/user';
import cancelPlanSteps from '../constants/cancel-plan-steps';

const useCancelPlanSteps = ({ isHostingSubscription, hasEverHadDiscount, contactSupportUrl }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const isB2C = useSelector(user.selectors.getIsB2C);

  const steps = useMemo(
    () =>
      [
        contactSupportUrl && cancelPlanSteps.PROPOSALS,
        isHostingSubscription && isB2C && !hasEverHadDiscount && cancelPlanSteps.DISCOUNT,
        cancelPlanSteps.EXIT_SURVEY,
        cancelPlanSteps.CONFIRMATION,
      ].filter(Boolean),
    [isHostingSubscription, hasEverHadDiscount, contactSupportUrl, isB2C]
  );

  const activeStep = steps[currentIndex];
  const isFirstStep = currentIndex === 0;
  const isLastStep = currentIndex === steps.length - 1;

  const onNext = () => {
    if (!isLastStep) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const onBack = () => {
    if (!isFirstStep) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return {
    activeStep,
    isFirstStep,
    isLastStep,
    onNext,
    onBack,
  };
};

export default useCancelPlanSteps;
