import { loadStripe } from '@stripe/stripe-js';

let stripePromise;

function getStripePromise(publicKey, options = {}) {
  stripePromise = stripePromise || loadStripe(publicKey, options);

  return stripePromise;
}

export default getStripePromise;
