import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { reactHookForm } from '@yola/ws-ui';
import AccountInfoSection from 'src/js/modules/profile/components/account-info-section';
import languageOptions from 'src/js/modules/profile/constants/language-options';
import status from 'src/js/modules/status';
import statusNames from 'src/js/modules/user/constants/status-names';
import user from 'src/js/modules/user';
import segment from 'src/js/modules/analytics/segment';
import dialogs from 'src/js/modules/dialogs';
import sectionIds from '../constants/section-ids';
import fieldNames from '../constants/field-names';
import { appearanceTypes } from '../constants/common';
import isEmail from '../../../utils/is-email';

const {
  constants: { events },
  track,
  trackAsync,
} = segment;
const { useForm } = reactHookForm;
const { EMAIL, LOCALE } = fieldNames;

const CURRENT_SECTION_ID = sectionIds.ACCOUNT_INFO;

const ALREADY_EXIST_SERVICE_ERROR = 'Duplicated email';

const getCaptions = () => ({
  title: i18next.t('Account info'),
  updateEmailButton: i18next.t('Update'),
  emailLabel: i18next.t('Account email'),
  passwordLabel: i18next.t('Password'),
  languageLabel: i18next.t('Language'),
  emailIsConfirmed: i18next.t('Email is confirmed'),
  emailIsNotConfirmed: i18next.t('Email not confirmed'),
  changePasswordButton: i18next.t('Change password'),
  changeLanguageButton: i18next.t('Change language'),
  sendVerificationCodeButton: i18next.t('Re-send a verification email'),
  messages: {
    successfullyUpdatedLanguage: i18next.t('Your language was successfully updated'),
    successfullyUpdatedPassword: i18next.t('Your password was successfully updated'),
    successfullyUpdatedUserDetails: i18next.t('Successfully updated user details'),
    successfullySentVerificationCode: i18next.t(
      'Confirmation email has been sent. Can’t find it? Check your spam.'
    ),
    serviceError: i18next.t('Oops! Something went wrong. Please try again later.'),
  },
  validationErrors: {
    required: i18next.t('Please fill in this field'),
    invalid: i18next.t('This field should be a valid email address'),
    alreadyExist: i18next.t('A user with that email address already exists'),
  },
});

function AccountInfoContainer({ notification, setNotification }) {
  const [isEmailControlLoading, setIsEmailControlLoading] = useState(false);
  const [isLanguageControlLoading, setIsLanguageControlLoading] = useState(false);
  const dispatch = useDispatch();
  const userPreferences = useSelector(user.selectors.getUserPreferences);
  const userData = useSelector(user.selectors.getUserData);
  const resendEmailStatus = status.hooks.useStatus(statusNames.SEND_VERIFICATION_CODE);
  const isResendEmailSuccess = resendEmailStatus === status.constants.SUCCEEDED;
  const isResendEmailLoading = resendEmailStatus === status.constants.LOADING;

  const captions = useMemo(getCaptions, []);

  const {
    control: emailControl,
    handleSubmit: handleEmailSubmit,
    setError: setEmailError,
  } = useForm({
    defaultValues: { [EMAIL]: userData.email || '' },
  });

  const { control: languageControl, handleSubmit: handleLanguageSubmit } = useForm({
    defaultValues: { [LOCALE]: userPreferences.locale || languageOptions[0].value || '' },
  });

  const validateEmail = useCallback(
    (value) => isEmail(value) || captions.validationErrors.invalid,
    [captions]
  );

  const emailRules = {
    required: captions.validationErrors.required,
    validate: validateEmail,
  };

  const handleServiceEmailError = (error) => {
    const message =
      error === ALREADY_EXIST_SERVICE_ERROR || error.includes(ALREADY_EXIST_SERVICE_ERROR)
        ? captions.validationErrors.alreadyExist
        : captions.validationErrors.invalid;

    setEmailError(EMAIL, { type: 'custom', message });
  };

  const onEmailSubmit = async (data) => {
    setIsEmailControlLoading(true);

    try {
      const updatedUserData = await dispatch(user.thunks.updateUserData(data));

      setNotification({
        sectionId: CURRENT_SECTION_ID,
        title: captions.messages.successfullyUpdatedUserDetails,
        appearance: appearanceTypes.SUCCESS,
      });

      track(events.PROFILE_PAGE_EMAIL_UPDATED, {
        emailVerified: updatedUserData.isEmailVerified,
      });
    } catch (error) {
      const fieldError = error?.fields?.[EMAIL];

      if (fieldError) {
        handleServiceEmailError(fieldError);
      } else {
        setNotification({
          sectionId: CURRENT_SECTION_ID,
          title: captions.messages.serviceError,
          appearance: appearanceTypes.DANGER,
        });
      }

      track(events.PROFILE_PAGE_ERROR_DISPLAYED, {
        section: CURRENT_SECTION_ID,
      });
    }

    setIsEmailControlLoading(false);
  };

  const onLanguageSubmit = async (data) => {
    const oldLanguage = userPreferences.locale;
    const newLanguage = data[LOCALE];

    if (oldLanguage === newLanguage) {
      return;
    }

    setIsLanguageControlLoading(true);

    try {
      await dispatch(user.thunks.updateUserPreferences(data));

      setNotification({
        sectionId: CURRENT_SECTION_ID,
        title: captions.messages.successfullyUpdatedLanguage,
        appearance: appearanceTypes.SUCCESS,
      });

      await trackAsync(events.PROFILE_PAGE_LANGUAGE_CHANGED, {
        oldLanguage,
        newLanguage,
      });

      window.location.reload();
    } catch (e) {
      setNotification({
        sectionId: CURRENT_SECTION_ID,
        title: captions.messages.serviceError,
        appearance: appearanceTypes.DANGER,
      });

      track(events.PROFILE_PAGE_ERROR_DISPLAYED, {
        section: CURRENT_SECTION_ID,
      });
    }

    setIsLanguageControlLoading(false);
  };

  const handleResendVerificationEmail = () => dispatch(user.thunks.sendVerificationCode());

  const onChangePasswordSuccess = () =>
    setNotification({
      sectionId: sectionIds.CHANGE_PASSWORD,
      title: captions.messages.successfullyUpdatedPassword,
      appearance: appearanceTypes.SUCCESS,
    });

  const handleOpenChangePasswordDialog = (triggerId) => {
    dispatch(
      dialogs.actions.show(dialogs.dialogTypes.CHANGE_PASSWORD, {
        onSubmit: onChangePasswordSuccess,
        triggerId,
      })
    );

    track(events.CHANGE_PASSWORD_DIALOG_DISPLAYED, {
      triggerId,
      dialogId: dialogs.dialogTypes.CHANGE_PASSWORD,
    });
  };

  useEffect(() => {
    if (isResendEmailSuccess) {
      setNotification({
        sectionId: sectionIds.ACCOUNT_INFO,
        title: captions.messages.successfullySentVerificationCode,
        appearance: appearanceTypes.SUCCESS,
      });
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [isResendEmailSuccess]);

  return (
    <AccountInfoSection
      captions={captions}
      emailControl={emailControl}
      isEmailControlLoading={isEmailControlLoading}
      isEmailConfirmed={userData.isEmailVerified}
      onResendEmailButtonClick={handleResendVerificationEmail}
      isResendEmailButtonLoading={isResendEmailLoading}
      onEmailSubmit={handleEmailSubmit(onEmailSubmit)}
      emailRules={emailRules}
      languageControl={languageControl}
      languageOptions={languageOptions}
      isLanguageControlLoading={isLanguageControlLoading}
      onLanguageSubmit={handleLanguageSubmit(onLanguageSubmit)}
      onChangePasswordClick={handleOpenChangePasswordDialog}
      {...(notification?.sectionId === CURRENT_SECTION_ID && {
        notification,
      })}
      {...(notification?.sectionId === sectionIds.CHANGE_PASSWORD && {
        changePasswordNotification: notification,
      })}
    />
  );
}

AccountInfoContainer.propTypes = {
  notification: PropTypes.shape({
    sectionId: PropTypes.string,
    title: PropTypes.string,
    appearance: PropTypes.string,
  }),
  setNotification: PropTypes.func.isRequired,
};

AccountInfoContainer.defaultProps = {
  notification: null,
};

export default AccountInfoContainer;
