import fieldNames from '../constants/form-field-names';

const processPaymentMethodToFormData = (data) => {
  const {
    address1,
    cardExpirationMonth,
    cardExpirationYear,
    cardLastDigits,
    city,
    countryCode,
    isDefault,
    name,
    postalCode,
    state,
  } = data;
  const expirationDate = `${cardExpirationMonth}/${cardExpirationYear.slice(-2)}`;
  const cardNumber = `**** **** **** ${cardLastDigits}`;
  const verification = '***';

  return {
    [fieldNames.EXPIRATION_DATE]: expirationDate,
    [fieldNames.ADDRESS1]: address1,
    [fieldNames.CITY]: city,
    [fieldNames.COUNTRY_CODE]: countryCode,
    [fieldNames.NAME]: name,
    [fieldNames.POSTAL_CODE]: postalCode,
    [fieldNames.STATE]: state,
    [fieldNames.CARD_NUMBER]: cardNumber,
    [fieldNames.VERIFICATION]: verification,
    [fieldNames.MAKE_DEFAULT]: isDefault,
  };
};

export default processPaymentMethodToFormData;
