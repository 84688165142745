const statusNames = {
  PRICES: 'prices',
  CREATE_BILLING_PROFILE: 'create_billing_profile',
  UPDATE_BILLING_PROFILE: 'update_billing_profile',
  BILLING_PROFILES: 'billing_profiles',
  BASKETS: 'baskets',
  DELETE_BILLING_PROFILE: 'delete_billing_profile',
  REPLACE_BILLING_PROFILE_WITH_EXISTING: 'replace_billing_profile_with_existing',
  REPLACE_BILLING_PROFILE_WITH_NEW: 'replace_billing_profile_with_new',
  SET_BILLING_PROFILE_AS_DEFAULT: 'set_billing_profile_as_default',
  CANCELLATION_COUPON: 'cancellation_coupon',
  CREATE_CANCELLATION_COUPON: 'create_cancellation_coupon',
};

export default statusNames;
