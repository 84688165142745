import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { STRIPE_CARD_TYPES } from '../../../constants/card-types';

const { Box, Heading, PaymentMethods } = designSystem;

const PaymentMethodsActionItem = ({ caption, onClick }) => (
  <div className="ws-payment-methods-action-item" onClick={onClick}>
    <Box padding="spacing-xs">
      <div className="ws-payment-methods-action-item__content">
        <Heading type="heading-6">{caption}</Heading>
        <PaymentMethods types={STRIPE_CARD_TYPES} size="tiny" />
      </div>
    </Box>
  </div>
);

PaymentMethodsActionItem.propTypes = {
  caption: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PaymentMethodsActionItem;
