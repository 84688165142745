import common from './common';
import events from './events';
import triggerIds from './trigger-ids';
import sourceIds from './source-ids';

export default {
  common,
  events,
  triggerIds,
  sourceIds,
};
