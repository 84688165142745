import i18next from 'i18next';
import { constants } from '@yola/subscription-manager-js';

const {
  B2C_HOSTING_GOLD,
  B2C_HOSTING_SILVER,
  B2C_HOSTING_BRONZE,
  B2C_ECOMMERCE_BASIC,
  B2C_ECOMMERCE_PROFESSIONAL,
  B2C_ECOMMERCE_UNLIMITED,
} = constants.SubscriptionType;

const getPlanDescriptionByType = (type, name) => {
  switch (type) {
    case B2C_HOSTING_BRONZE:
      return i18next.t(
        'The {name} plan is perfect for personal use, offering a simple yet effective website building solution. With the ability to customize your design and access basic analytics, you can easily create a website that suits your needs',
        { name }
      );
    case B2C_HOSTING_SILVER:
      return i18next.t(
        'The {name} plan is best suited for individuals, freelancers, or small businesses that require a website with a moderate amount of features and customization options',
        { name }
      );
    case B2C_HOSTING_GOLD:
      return i18next.t(
        "The {name} plan is designed for those who want to take their website to the next level. With unlimited bandwidth, advanced analytics, and access to premium features like SEO tools and custom domains, you'll have everything you need to grow your online presence",
        { name }
      );
    case B2C_ECOMMERCE_BASIC:
      return i18next.t(
        'Grow your business with extended features, including inventory tracking, gift cards, the ability to sell on Facebook, and more.',
        { name }
      );
    case B2C_ECOMMERCE_PROFESSIONAL:
      return i18next.t(
        'Take your store to the next level with advanced features, including discount coupons, product filters, and the ability to sell on Facebook, Instagram, and more',
        { name }
      );
    case B2C_ECOMMERCE_UNLIMITED:
      return i18next.t(
        'Expand your online business to brick and mortar with our point of sale integrations, extensive product catalog, and more. Take your sales to the next level with our powerful tools and features',
        { name }
      );
    default:
      return null;
  }
};

export default getPlanDescriptionByType;
