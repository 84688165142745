import get from 'lodash.get';
import getIsB2C from './is-b2c';

const getAnalyticsUrl = (rootState) => {
  if (getIsB2C(rootState)) {
    return get(rootState, 'config.common.analytics.url');
  }

  return get(rootState, 'config.common.analytics.wl_url');
};

export default getAnalyticsUrl;
