import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { designSystem } from '@yola/ws-ui';
import { types } from './constants';

const { Icon, Heading, ActionButton, Box } = designSystem;

const PlanActionItem = ({ onClick, type, title, loading }) => {
  const classes = classNames('ws-plan-action-item', { 'ws-plan-action-item--loading': loading });

  const handleClick = () => {
    if (loading) return;

    onClick();
  };

  return (
    <div className={classes} onClick={handleClick}>
      <Box padding="spacing-xs">
        <div className="ws-plan-action-item__container">
          <div className="ws-plan-action-item__type">
            <Icon glyph={type} type="cta" />
            <Heading type="heading-6">{title}</Heading>
          </div>
          <ActionButton
            iconGlyph="next"
            size="medium"
            isLoading={loading}
            format="text"
            onClick={handleClick}
          />
        </div>
      </Box>
    </div>
  );
};

PlanActionItem.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(types)).isRequired,
  onClick: PropTypes.func.isRequired,
};

PlanActionItem.defaultProps = {
  loading: false,
};

export default PlanActionItem;
