import serviceClient from '../service-client';
import { snakeCaseObjectToCamelCase } from '../../../utils/convert-case';
import statusNames from '../constants/status-names';
import status from '../../status';

const fetchPackageCancellationCoupon = () => async (dispatch) => {
  const yoproducts = serviceClient.get();
  let responseData;

  dispatch(status.actions.setStatus(statusNames.CANCELLATION_COUPON, status.constants.LOADING));

  try {
    const response = await yoproducts.requests.getPackageCancellationCoupon();
    responseData = snakeCaseObjectToCamelCase(response);

    dispatch(status.actions.setStatus(statusNames.CANCELLATION_COUPON, status.constants.SUCCEEDED));
  } catch (error) {
    console.error(error);
    dispatch(status.actions.setStatus(statusNames.CANCELLATION_COUPON, status.constants.FAILED));
  }

  return responseData;
};

export default fetchPackageCancellationCoupon;
