import React from 'react';

const ContactSupportIcon = () => (
  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="96" height="96" rx="48" fill="#1B9B4A" fillOpacity="0.2" />
    <path
      d="M35.2398 41.3301H73.5203C75.2828 41.3301 76.7104 42.7576 76.7104 44.5201V66.8504C76.7104 68.6129 75.2828 70.0404 73.5203 70.0404H71.9253V79.7414C71.9253 80.6234 70.7705 80.9536 70.3048 80.2055L63.9502 70.0404H35.2398C33.4773 70.0404 32.0498 68.6129 32.0498 66.8504V44.5201C32.0498 42.7576 33.4773 41.3301 35.2398 41.3301Z"
      fill="url(#paint0_linear_1181_4916)"
    />
    <path
      opacity="0.05"
      d="M62.3552 49.3052H32.0498V41.3301H65.5452V46.1151C65.5452 47.8776 64.1177 49.3052 62.3552 49.3052Z"
      fill="black"
    />
    <path
      opacity="0.07"
      d="M61.5577 48.5077H32.0498V41.3301H64.7477V45.3176C64.7477 47.0801 63.3202 48.5077 61.5577 48.5077Z"
      fill="black"
    />
    <path
      d="M60.7606 19H22.4801C20.7176 19 19.29 20.4275 19.29 22.19V44.5203C19.29 46.2828 20.7176 47.7104 22.4801 47.7104H24.0751V57.6904C24.0751 58.4911 25.1246 58.7926 25.5489 58.1131L32.0502 47.7104H60.7606C62.5231 47.7104 63.9506 46.2828 63.9506 44.5203V22.19C63.9506 20.4275 62.5231 19 60.7606 19Z"
      fill="#1B9B4A"
    />
    <path
      opacity="0.05"
      d="M29.6572 33.3557H53.5825C54.9032 33.3557 55.975 32.2838 55.975 30.9631V29.3681C55.975 28.0474 54.9032 26.9756 53.5825 26.9756H29.6572C28.3365 26.9756 27.2646 28.0474 27.2646 29.3681V30.9631C27.2646 32.2838 28.3365 33.3557 29.6572 33.3557Z"
      fill="black"
    />
    <path
      opacity="0.07"
      d="M29.6575 32.5575H53.5828C54.4633 32.5575 55.1778 31.843 55.1778 30.9625V29.3675C55.1778 28.487 54.4633 27.7725 53.5828 27.7725H29.6575C28.7771 27.7725 28.0625 28.487 28.0625 29.3675V30.9625C28.0625 31.843 28.7771 32.5575 29.6575 32.5575Z"
      fill="black"
    />
    <path
      d="M54.3797 29.3678V30.9628C54.3797 31.4031 54.0224 31.7604 53.5822 31.7604H29.6569C29.2167 31.7604 28.8594 31.4031 28.8594 30.9628V29.3678C28.8594 28.9276 29.2167 28.5703 29.6569 28.5703H53.5822C54.0224 28.5703 54.3797 28.9276 54.3797 29.3678Z"
      fill="white"
    />
    <path
      opacity="0.05"
      d="M29.6572 39.7355H50.3924C51.7131 39.7355 52.785 38.6637 52.785 37.343V35.748C52.785 34.4273 51.7131 33.3555 50.3924 33.3555H29.6572C28.3365 33.3555 27.2646 34.4273 27.2646 35.748V37.343C27.2646 38.6637 28.3365 39.7355 29.6572 39.7355Z"
      fill="black"
    />
    <path
      opacity="0.07"
      d="M29.6575 38.9374H50.3928C51.2732 38.9374 51.9878 38.2228 51.9878 37.3424V35.7474C51.9878 34.8669 51.2732 34.1523 50.3928 34.1523H29.6575C28.7771 34.1523 28.0625 34.8669 28.0625 35.7474V37.3424C28.0625 38.2228 28.7771 38.9374 29.6575 38.9374Z"
      fill="black"
    />
    <path
      d="M51.1897 35.7477V37.3427C51.1897 37.783 50.8324 38.1402 50.3921 38.1402H29.6569C29.2167 38.1402 28.8594 37.783 28.8594 37.3427V35.7477C28.8594 35.3075 29.2167 34.9502 29.6569 34.9502H50.3921C50.8324 34.9502 51.1897 35.3075 51.1897 35.7477Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1181_4916"
        x1="51.9876"
        y1="41.3301"
        x2="50.7913"
        y2="80.4081"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005ED9" />
        <stop offset="1" stopColor="#094ABA" />
      </linearGradient>
    </defs>
  </svg>
);

export default ContactSupportIcon;
