import config from 'src/js/modules/config';
import yousers from '@yola/yousersjs';

const configureYousersJS = (store) => {
  const state = store.getState();
  const apiURL = config.selectors.getApiUrl(state);
  yousers.setAPIRoot(apiURL);
};

export default configureYousersJS;
