import actionTypes from '../constants/action-types';

const setPartnerData = (partnerData) => ({
  type: actionTypes.SET_PARTNER_DATA,
  payload: {
    partnerData,
  },
});

export default setPartnerData;
