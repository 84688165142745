import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { contentTypes } from './constants';

const { Heading, ActionButton, Icon, Mark } = designSystem;

const PlanFeaturesRow = ({ title, contentType, text, buttonLabel, onButtonClick }) => {
  const getContent = () => {
    switch (contentType) {
      case contentTypes.ICON_CHECK:
        return <Icon glyph="check" type="success" />;
      case contentTypes.ICON_MINUS:
        return (
          <Mark appearance="medium-emphasis">
            <Icon glyph="minus" type="inherit" />
          </Mark>
        );
      case contentTypes.TEXT:
        return <Heading type="heading-6">{text}</Heading>;
      default:
        return null;
    }
  };

  return (
    <div className="ws-plan-features-list-row">
      <Heading type="heading-6">{title}</Heading>
      <div className="ws-plan-features-list-row__content">{getContent()}</div>
      {onButtonClick && buttonLabel && (
        <ActionButton label={buttonLabel} format="text" appearance="cta" onClick={onButtonClick} />
      )}
    </div>
  );
};

PlanFeaturesRow.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonLabel: PropTypes.string,
  contentType: PropTypes.oneOf(Object.values(contentTypes)).isRequired,
  onButtonClick: PropTypes.func,
};

PlanFeaturesRow.defaultProps = {
  text: '',
  buttonLabel: '',
  onButtonClick: null,
};

export default PlanFeaturesRow;
