/* eslint-disable yola/import/no-extraneous-dependencies */
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from 'src/js/reducer';
import middleware from 'src/js/middleware';
import history from 'src/js/browser-history';

const store = createStore(reducer(history), composeWithDevTools(applyMiddleware(...middleware)));

export default store;
