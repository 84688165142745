import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, designSystem } from '@yola/ws-ui';
import i18next from 'i18next';
import status from '../../../status';
import PaymentMethodsActionItem from './components/payment-methods-action-item';
import PaymentMethodRadioItem from './components/payment-method-radio-item';
import { PAYMENT_METHOD_ITEM_PROP_TYPES } from '../payment-method-item/constants';

const {
  DialogHeader,
  DialogHeaderTitle,
  ActionButton,
  ModalButtonGroup,
  Box,
  Paragraph,
  Stack,
  NotificationMessage,
  DialogHeaderControls,
  DialogHeaderIconButton,
  Divider,
  RadioButtonGroup,
} = designSystem;

const MODAL_WIDTH = 600;
const overlaySpacing = { y: 50 };

const getCaptions = (captions, canBeReplacedWithExisting) => {
  const { name, ...captionsOverrides } = captions;

  const defaultCaptions = {
    title: i18next.t('Remove payment method'),
    notification: i18next.t(
      'This payment method is linked to one or more subscription/auto-renewal agreements. To proceed, either select a new default payment method or add a new one'
    ),
    addNewItem: i18next.t('Add a new credit or debit card'),
    cancelButton: i18next.t('Cancel'),
  };

  if (canBeReplacedWithExisting) {
    return {
      ...defaultCaptions,
      radioItemsDescription: i18next.t('Choose another method before removing {name}', {
        name: `<b>${name}</b>`,
      }),
      addNewDescription: i18next.t('Or replace with a new payment method'),
      submitButton: i18next.t('Save and remove'),
      ...captionsOverrides,
    };
  }

  return {
    ...defaultCaptions,
    addNewDescription: i18next.t('Choose a new payment method before removing {name}', {
      name: `<b>${name}</b>`,
    }),
    submitButton: i18next.t('Add a new payment method'),
    ...captionsOverrides,
  };
};

const DeleteDefaultPaymentMethodDialog = (props) => {
  const {
    captions: captionsProps,
    availableMethods,
    onAddNew,
    onReplaceWithExisting,
    onCancel,
    statusName,
  } = props;
  const targetStatus = status.hooks.useStatus(statusName);
  const isLoading = targetStatus === status.constants.LOADING;
  const canBeReplacedWithExisting = !!availableMethods.length;
  const initialReplacerId = canBeReplacedWithExisting ? availableMethods[0].id : '';
  const [replacerId, setReplacerId] = useState(initialReplacerId);
  const captions = getCaptions(captionsProps, canBeReplacedWithExisting);

  const handleReplaceWithExisting = () => onReplaceWithExisting(replacerId);

  const handleSubmit = canBeReplacedWithExisting ? handleReplaceWithExisting : onAddNew;

  return (
    <Modal
      isContainerScrollable
      isBodyScrollDisabled
      width={MODAL_WIDTH}
      maxWidth={MODAL_WIDTH}
      minWidth={MODAL_WIDTH}
      overlaySpacing={overlaySpacing}
      height="auto"
      centered
      resizable={false}
      draggable={false}
      overlay="visible"
      className="ws-account-management-modal"
    >
      <DialogHeader>
        <DialogHeaderTitle>{captions.title}</DialogHeaderTitle>
        <DialogHeaderControls>
          <DialogHeaderIconButton onClick={onCancel} disabled={isLoading} glyph="close" />
        </DialogHeaderControls>
      </DialogHeader>
      <Divider />
      <Box
        paddingTop="spacing-s"
        paddingRight="spacing-m"
        paddingBottom="spacing-s"
        paddingLeft="spacing-m"
      >
        <Stack gap="spacing-m">
          <NotificationMessage
            appearance="warning"
            iconGlyph="info"
            title={captions.notification}
          />
          {canBeReplacedWithExisting && (
            <Stack gap="spacing-2-xs">
              <Paragraph>
                <span
                  // eslint-disable-next-line yola/react/no-danger
                  dangerouslySetInnerHTML={{ __html: captions.radioItemsDescription }}
                />
              </Paragraph>
              <RadioButtonGroup
                name="payment-method-radio-item-group"
                selectedValue={replacerId}
                onChange={setReplacerId}
              >
                <Stack gap="spacing-2-xs">
                  {availableMethods.map(({ id, captions: methodCaptions, iconType }) => (
                    <PaymentMethodRadioItem
                      key={id}
                      value={id}
                      type={iconType}
                      captions={methodCaptions}
                      onSelect={setReplacerId}
                    />
                  ))}
                </Stack>
              </RadioButtonGroup>
            </Stack>
          )}
          <Stack gap="spacing-2-xs">
            <Paragraph>
              <span
                // eslint-disable-next-line yola/react/no-danger
                dangerouslySetInnerHTML={{ __html: captions.addNewDescription }}
              />
            </Paragraph>
            <PaymentMethodsActionItem caption={captions.addNewItem} onClick={onAddNew} />
          </Stack>
        </Stack>
      </Box>
      <ModalButtonGroup direction="right">
        <ActionButton
          appearance="accent"
          format="solid"
          label={captions.submitButton}
          isLoading={isLoading}
          onClick={handleSubmit}
          {...(canBeReplacedWithExisting && {
            iconGlyph: 'lock',
          })}
        />
        <ActionButton label={captions.cancelButton} disabled={isLoading} onClick={onCancel} />
      </ModalButtonGroup>
    </Modal>
  );
};

DeleteDefaultPaymentMethodDialog.propTypes = {
  captions: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    notification: PropTypes.string,
    addNewItem: PropTypes.string,
    cancelButton: PropTypes.string,
    radioItemsDescription: PropTypes.string,
    addNewDescription: PropTypes.string,
    submitButton: PropTypes.string,
  }).isRequired,
  availableMethods: PropTypes.arrayOf(PropTypes.shape(PAYMENT_METHOD_ITEM_PROP_TYPES)),
  statusName: PropTypes.string.isRequired,
  onAddNew: PropTypes.func.isRequired,
  onReplaceWithExisting: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

DeleteDefaultPaymentMethodDialog.defaultProps = {
  availableMethods: [],
};

export default DeleteDefaultPaymentMethodDialog;
