import bannerTypes from '../constants/banner-types';
import registerBanner from '../helpers/register-banner';
import UpgradeSubscriptionBannerContainer from '../containers/upgrade-subscription-banner-container';
import SwitchToAnnualBannerContainer from '../containers/switch-to-annual-banner-container';
import OnlineStoreBannerContainer from '../containers/online-store-banner-container';
import CustomDomainBannerContainer from '../containers/custom-domain-banner-container';

function registerBanners() {
  registerBanner(bannerTypes.UPGRADE_SUBSCRIPTION_BANNER, UpgradeSubscriptionBannerContainer);
  registerBanner(bannerTypes.SWITCH_TO_ANNUAL_BANNER, SwitchToAnnualBannerContainer);
  registerBanner(bannerTypes.ONLINE_STORE_BANNER, OnlineStoreBannerContainer);
  registerBanner(bannerTypes.CUSTOM_DOMAIN_BANNER, CustomDomainBannerContainer);
}

export default registerBanners;
