import React from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { selectors } from '@yola/subscription-manager-js';
import routesMap from '../../../router/helpers/routes-map';
import supportedTabs from '../constants/supported-tabs';
import SubscriptionDetailsPage from '../pages/subscription-details-page';

const SubscriptionDetailsRoute = () => {
  const { subId } = useParams();
  const dispatch = useDispatch();

  const subscription = useSelector(
    (state) => selectors.getActiveSubscriptionById(state, subId),
    shallowEqual
  );

  if (!subscription) {
    dispatch(push(routesMap.accountManagement.url({ tabId: supportedTabs.SUBSCRIPTIONS })));
    return null;
  }

  return <SubscriptionDetailsPage subscription={subscription} />;
};

export default SubscriptionDetailsRoute;
