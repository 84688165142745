import actionTypes from '../constants/action-types';

function setSites(sites) {
  return {
    type: actionTypes.SET_SITES,
    payload: {
      sites,
    },
  };
}

export default setSites;
