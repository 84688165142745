import { useEffect, useContext } from 'react';
import FeatureFlagsContext from '../context';

const useFeatureFlags = (flags) => {
  const featureFlagsContext = useContext(FeatureFlagsContext);
  const { requestFeatureFlags, featureFlags } = featureFlagsContext;

  useEffect(() => {
    requestFeatureFlags(flags);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return [featureFlags, requestFeatureFlags];
};

export default useFeatureFlags;
