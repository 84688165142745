import { batch } from 'react-redux';
import yousersjs from '@yola/yousersjs';
import i18n from 'src/js/modules/i18n';
import status from 'src/js/modules/status';
import { snakeCaseObjectToCamelCase } from 'src/js/utils/convert-case';
import setPartnerData from '../actions/set-partner-data';
import statusNames from '../constants/status-names';

const fetchPartnerData = () => async (dispatch, getState) => {
  const state = getState();
  const propertiesLocale = i18n.selectors.getLocale(state);

  try {
    dispatch(status.actions.setStatus(statusNames.PARTNER_DATA, status.constants.LOADING));

    const { body } = await yousersjs.partner().get({
      propertiesLocale,
    });

    const data = snakeCaseObjectToCamelCase(body);

    batch(() => {
      dispatch(setPartnerData(data));
      dispatch(status.actions.setStatus(statusNames.PARTNER_DATA, status.constants.SUCCEEDED));
    });
  } catch (error) {
    dispatch(status.actions.setStatus(statusNames.PARTNER_DATA, status.constants.FAILED));
    throw error;
  }
};

export default fetchPartnerData;
