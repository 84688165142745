import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import SectionInfo from 'src/js/common/components/section-info';
import IconStatus from 'src/js/modules/profile/components/icon-status';
import triggerIds from 'src/js/modules/analytics/segment/constants/trigger-ids';
import fieldNames from '../../constants/field-names';

const {
  ControlGroup,
  InputGroup,
  ActionButton,
  Col,
  Row,
  Stack,
  Box,
  Divider,
  Paragraph,
  NotificationMessage,
  InputGroupField,
  SimpleSelectField,
} = designSystem;

function AccountInfoSection(props) {
  const {
    captions,
    emailControl,
    isEmailControlLoading,
    isEmailConfirmed,
    onResendEmailButtonClick,
    isResendEmailButtonLoading,
    onEmailSubmit,
    emailRules,
    languageControl,
    languageOptions,
    isLanguageControlLoading,
    onLanguageSubmit,
    onChangePasswordClick,
    notification,
    changePasswordNotification,
  } = props;

  const handlePasswordInputClick = (e) => {
    e.preventDefault();
    onChangePasswordClick(triggerIds.INPUT);
  };

  return (
    <SectionInfo title={captions.title}>
      <Stack gap="spacing-m">
        {notification && (
          <NotificationMessage title={notification.title} appearance={notification.appearance} />
        )}
        <ControlGroup title={captions.emailLabel}>
          <Row className="align-items-center gx-4 gy-0">
            <Col className="col-9">
              <InputGroupField
                name={fieldNames.EMAIL}
                control={emailControl}
                size="large"
                rules={emailRules}
              />
            </Col>
            <Col className="col-3">
              {isEmailConfirmed ? (
                <IconStatus iconGlyph="check" iconType="success" align="right">
                  <Paragraph appearance="success">{captions.emailIsConfirmed}</Paragraph>
                </IconStatus>
              ) : (
                <Fragment>
                  <IconStatus iconGlyph="close" iconType="error" align="right">
                    <Paragraph appearance="danger">{captions.emailIsNotConfirmed}</Paragraph>
                  </IconStatus>
                  <Paragraph align="right">
                    <ActionButton
                      format="text"
                      label={captions.sendVerificationCodeButton}
                      isLoading={isResendEmailButtonLoading}
                      onClick={onResendEmailButtonClick}
                    />
                  </Paragraph>
                </Fragment>
              )}
            </Col>
          </Row>
          <Box marginTop="spacing-xs">
            <ActionButton
              appearance="accent"
              format="solid"
              fontWeight="regular"
              onClick={onEmailSubmit}
              isLoading={isEmailControlLoading}
              label={captions.updateEmailButton}
              size="large"
            />
          </Box>
        </ControlGroup>
        <Divider />
        {changePasswordNotification && (
          <NotificationMessage
            title={changePasswordNotification.title}
            appearance={changePasswordNotification.appearance}
          />
        )}
        <ControlGroup title={captions.passwordLabel}>
          <Row className="gx-4 gy-0">
            <Col className="col-9">
              <InputGroup
                size="large"
                placeholder="****************"
                onChange={() => {}}
                defaultValue=""
                onMouseDown={handlePasswordInputClick}
              />
            </Col>
            <Col className="col-3">
              <Paragraph align="right">
                <ActionButton
                  size="large"
                  label={captions.changePasswordButton}
                  onClick={() => onChangePasswordClick(triggerIds.BUTTON)}
                />
              </Paragraph>
            </Col>
          </Row>
        </ControlGroup>
        <Divider />
        <ControlGroup title={captions.languageLabel}>
          <Row className="gx-4 gy-0">
            <Col className="col-9">
              <SimpleSelectField
                name={fieldNames.LOCALE}
                control={languageControl}
                options={languageOptions}
                size="large"
              />
            </Col>
            <Col className="col-3">
              <Paragraph align="right">
                <ActionButton
                  size="large"
                  label={captions.changeLanguageButton}
                  onClick={onLanguageSubmit}
                  isLoading={isLanguageControlLoading}
                />
              </Paragraph>
            </Col>
          </Row>
        </ControlGroup>
      </Stack>
    </SectionInfo>
  );
}

AccountInfoSection.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    emailLabel: PropTypes.string.isRequired,
    emailIsConfirmed: PropTypes.string.isRequired,
    emailIsNotConfirmed: PropTypes.string.isRequired,
    sendVerificationCodeButton: PropTypes.string.isRequired,
    updateEmailButton: PropTypes.string.isRequired,
    passwordLabel: PropTypes.string.isRequired,
    changePasswordButton: PropTypes.string.isRequired,
    languageLabel: PropTypes.string.isRequired,
    changeLanguageButton: PropTypes.string.isRequired,
  }).isRequired,
  emailControl: PropTypes.object.isRequired,
  isEmailControlLoading: PropTypes.bool.isRequired,
  isEmailConfirmed: PropTypes.bool.isRequired,
  onResendEmailButtonClick: PropTypes.func.isRequired,
  isResendEmailButtonLoading: PropTypes.bool.isRequired,
  onEmailSubmit: PropTypes.func.isRequired,
  emailRules: PropTypes.object,
  languageControl: PropTypes.object.isRequired,
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string.isRequired, label: PropTypes.string.isRequired })
  ).isRequired,
  isLanguageControlLoading: PropTypes.bool.isRequired,
  onLanguageSubmit: PropTypes.func.isRequired,
  onChangePasswordClick: PropTypes.func.isRequired,
  notification: PropTypes.shape({
    title: PropTypes.string,
    appearance: PropTypes.string,
  }),
  changePasswordNotification: PropTypes.shape({
    title: PropTypes.string,
    appearance: PropTypes.string,
  }),
};

AccountInfoSection.defaultProps = {
  emailRules: {},
  notification: null,
  changePasswordNotification: null,
};

export default AccountInfoSection;
