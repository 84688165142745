import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import i18next from 'i18next';
import CancelPlanProposalsItem from '../cancel-plan-proposals-item';
import CancelPlanTermsOfUse from '../cancel-plan-terms-of-use';
import { CANCEL_PLAN_TERMS_OF_USE_PROP_TYPES } from '../cancel-plan-terms-of-use/constants';
import {
  CANCEL_PLAN_PROPOSALS_ITEM_PROP_TYPES,
  PROPOSALS_TYPES,
} from '../cancel-plan-proposals-item/constants';

const { Heading, Stack } = designSystem;

const CancelPlanProposals = (props) => {
  const { expiryDate, termsOfUseUrl, proposalsItems } = props;

  return (
    <Stack gap="spacing-m">
      <Stack gap="spacing-2-xs">
        <Heading type="heading-5">{i18next.t('We can help you in other ways')}</Heading>
        {proposalsItems.map(({ id, ...item }) => (
          <CancelPlanProposalsItem key={id} {...item} />
        ))}
      </Stack>
      <CancelPlanTermsOfUse expiryDate={expiryDate} termsOfUseUrl={termsOfUseUrl} />
    </Stack>
  );
};

CancelPlanProposals.propTypes = {
  ...CANCEL_PLAN_TERMS_OF_USE_PROP_TYPES,
  proposalsItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOf(Object.values(PROPOSALS_TYPES)).isRequired,
      ...CANCEL_PLAN_PROPOSALS_ITEM_PROP_TYPES,
    })
  ).isRequired,
};

export default CancelPlanProposals;
