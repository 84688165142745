import actions from './actions';
import statusNames from './constants/status-names';
import actionTypes from './constants/action-types';
import reducers from './reducers';
import selectors from './selectors';
import thunks from './thunks';
import accessors from './accessors';

export default {
  actions,
  actionTypes,
  statusNames,
  reducers,
  selectors,
  thunks,
  accessors,
};
