import React from 'react';
import { useHistory } from 'react-router-dom';
import { verifiers } from '@yola/subscription-manager-js';
import segment from '../../modules/analytics/segment';
import { premiumFeatures } from '../../modules/common/constants/premium-features';
import DashboardPageContainer from '../../modules/dashboard/containers/dashboard-page-container';
import useSupportedSearchParams from '../hooks/use-supported-search-params';
import routesMap from '../helpers/routes-map';

const {
  constants: { triggerIds },
} = segment;

const DashboardRoute = () => {
  const searchParams = useSupportedSearchParams();
  const history = useHistory();

  if (!searchParams) return null;

  const { products = [], term, domain } = searchParams;
  const hasProducts = Boolean(products.length);

  if (domain) {
    history.push(
      routesMap.paywall.url({
        query: {
          domain,
          premiumFeature: premiumFeatures.CUSTOM_DOMAIN,
          triggerId: triggerIds.REDIRECT,
        },
      })
    );

    return null;
  }

  if (hasProducts) {
    const isPaidHostingSubscription = verifiers.hasPaidHostingPackageType(products);
    const query = {
      products,
      term,
      triggerId: triggerIds.REDIRECT,
    };
    const urlToRedirect = isPaidHostingSubscription
      ? routesMap.domainSelector.url({ query })
      : routesMap.paywall.url({ query });

    history.push(urlToRedirect);

    return null;
  }

  return <DashboardPageContainer />;
};

export default DashboardRoute;
