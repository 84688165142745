import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dropdown, DropdownTrigger, DropdownContent, designSystem } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import EditorHeader from '../navbar-header';

const { ActionListItem, ActionListDivider, ActionList, Paragraph, ActionListLink } = designSystem;

class MainMenu extends React.Component {
  static hide() {
    Dropdown.dispatchHide();
  }

  constructor(props) {
    super(props);

    this.state = {
      activeId: 0,
    };

    this.setActiveItem = this.setActiveItem.bind(this);
  }

  setActiveItem(e) {
    const activeId = Number(e.target.dataset.index);
    this.setState({
      activeId,
    });
  }

  renderItem(item, key) {
    const {
      onClick,
      href,
      target,
      disabled,
      type,
      title,
      description,
      labelFor,
      strong,
      noninteractive,
    } = item;

    if (type === 'divider') {
      return <ActionListDivider key={key} />;
    }

    const actionItemProps = {
      title,
      description,
      disabled,
      noninteractive,
      onClick,
      'data-index': key,
      onMouseEnter: this.setActiveItem,
      ...(strong && {
        component: ({ children }) => (
          <Paragraph isClipped>
            <strong>{children}</strong>
          </Paragraph>
        ),
      }),
    };

    if (labelFor) {
      return (
        <DropdownTrigger for={labelFor} key={key}>
          <ActionListItem {...actionItemProps} />
        </DropdownTrigger>
      );
    }

    if (href) {
      return <ActionListLink key={key} href={href} target={target} {...actionItemProps} />;
    }

    return <ActionListItem key={key} {...actionItemProps} />;
  }

  render() {
    const { items, className, captions, id, onShow, isHidden, href } = this.props;
    const { activeId } = this.state;
    const isTouchDevice = bowser.mobile || bowser.tablet;

    const mainMenuClasses = classNames('ws-mainmenu', className, {
      'ws-mainmenu--hidden': isHidden,
      'ws-mainmenu--submenu': !href && !isHidden,
    });

    return (
      <div className={mainMenuClasses}>
        {href && !isHidden && (
          <EditorHeader.NavItem href={href} target="blank" label={captions.name} />
        )}

        {!href && (
          <Dropdown
            drop="bottom-left"
            dropOn={isTouchDevice ? 'click' : 'hover'}
            onShow={onShow}
            id={id}
            removeElement
          >
            {!isHidden && (
              <DropdownTrigger>
                <EditorHeader.NavItem
                  label={captions.name}
                  iconGlyph="down-small"
                  iconPlacement="after"
                />
              </DropdownTrigger>
            )}
            {isHidden && <div className="ws-mainmenu__blank" />}
            <DropdownContent>
              <ActionList>
                {items.map((item, key) => this.renderItem(item, key, activeId))}
              </ActionList>
            </DropdownContent>
          </Dropdown>
        )}
      </div>
    );
  }
}

MainMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      heading: PropTypes.string,
      description: PropTypes.string,
      title: PropTypes.string,
      useEvent: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
  captions: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  onShow: PropTypes.func,
  isHidden: PropTypes.bool,
  href: PropTypes.string,
};

MainMenu.defaultProps = {
  items: [],
  className: null,
  id: null,
  onShow: Function.prototype,
  isHidden: false,
  href: null,
};

export default MainMenu;
