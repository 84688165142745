import { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import get from 'lodash.get';
import products from '../../products';
import dialogs from '../../dialogs';
import segment from '../../analytics/segment';
import processPaymentMethodToFormData from '../helpers/process-payment-method-to-form-data';
import fieldNames from '../constants/form-field-names';
import { dialogIds } from '../constants/analytics';
import statusTypes from '../constants/payment-method-status-types';
import { PAYMENT_METHOD_ITEM_PROP_TYPES } from '../components/payment-method-item/constants';
import computePaymentMethodTrait from '../../analytics/segment/helpers/compute-payment-method-trait';

const {
  track,
  constants: { events, triggerIds, sourceIds },
} = segment;

const getCaptions = () => ({
  title: i18next.t('Edit payment method'),
});

const statusToSourceIdMap = {
  [statusTypes.FAILED]: sourceIds.FAILED_PAYMENT_METHOD,
  [statusTypes.EXPIRED]: sourceIds.EXPIRED_PAYMENT_METHOD,
  [statusTypes.EXPIRY_SOON]: sourceIds.EXPIRY_SOON_PAYMENT_METHOD,
};

const EditPaymentMethodRoute = ({ location, match, closeUrl, paymentMethods }) => {
  const { methodId } = match.params;
  const triggerId = get(location, 'state.triggerId', triggerIds.EDIT_PAYMENT_METHOD);
  const dispatch = useDispatch();
  const captions = getCaptions();

  const targetMethod = useMemo(
    () => paymentMethods.find(({ id }) => id === methodId),
    [paymentMethods, methodId]
  );

  const submitEditPaymentMethod = async (formData) => {
    const { cardNumber, verification, ...restFormData } = formData;

    const response = await dispatch(products.thunks.updateBillingProfile(methodId, restFormData));

    track(events.PAYMENT_METHOD_DIALOG_SUBMITTED, {
      dialogId: dialogIds.EDIT_PAYMENT_METHOD,
      triggerId,
      default: response.isDefault,
      paymentMethod: computePaymentMethodTrait(response.cardType || response.type),
    });

    dispatch(push(closeUrl));
  };

  const cancelEditPaymentMethod = () => {
    track(events.PAYMENT_METHOD_DIALOG_CANCELLED, {
      dialogId: dialogIds.EDIT_PAYMENT_METHOD,
      triggerId,
    });

    dispatch(push(closeUrl));
  };

  useEffect(() => {
    if (!targetMethod) {
      dispatch(push(closeUrl));
      return null;
    }

    const formData = processPaymentMethodToFormData(targetMethod);
    const disabledFields = [fieldNames.CARD_NUMBER, fieldNames.VERIFICATION];

    if (formData[fieldNames.MAKE_DEFAULT]) {
      disabledFields.push(fieldNames.MAKE_DEFAULT);
    }

    dispatch(
      dialogs.actions.show(dialogs.dialogTypes.PAYMENT_METHOD_FORM, {
        captions: { title: captions.title },
        formData,
        onSave: submitEditPaymentMethod,
        onCancel: cancelEditPaymentMethod,
        disabledFields,
        statusName: products.statusNames.UPDATE_BILLING_PROFILE,
      })
    );

    if (triggerId === triggerIds.UPDATE_PAYMENT_METHOD) {
      const { status: targetMethodStatus, type: targetMethodType } = targetMethod;

      track(events.PAYMENT_METHOD_UPDATE_TRIGGER_CLICKED, {
        paymentMethod: computePaymentMethodTrait(targetMethodType),
        sourceId: statusToSourceIdMap[targetMethodStatus],
      });
    }

    track(events.PAYMENT_METHOD_DIALOG_DISPLAYED, {
      dialogId: dialogIds.EDIT_PAYMENT_METHOD,
      triggerId,
    });

    return () => dispatch(dialogs.actions.hide());
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return null;
};

EditPaymentMethodRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({
      isCloseModalOnRouteChange: PropTypes.bool,
    }),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  closeUrl: PropTypes.string.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape(PAYMENT_METHOD_ITEM_PROP_TYPES)).isRequired,
};

export default EditPaymentMethodRoute;
