import React from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { selectors, constants } from '@yola/subscription-manager-js';
import AccountChangesSection from 'src/js/modules/profile/components/account-changes-section';
import useLocaleDate from 'src/js/modules/common/hooks/use-locale-date';
import user from 'src/js/modules/user';
import dialogs from 'src/js/modules/dialogs';
import segment from 'src/js/modules/analytics/segment';

const {
  constants: { events },
  track,
  trackers: { trackEvent },
} = segment;

const { ACTIVE, CANCELED } = constants.SubscriptionStatus;

const CANCELATION_ARTICLE_B2C_URL =
  'https://helpcenter.yola.com/hc/en-us/articles/360012592259-Cancellations-How-to-proceed#h.nl08wejxmr53';

const getCaptions = (expiryDate, hasCanceledSubscription, isB2C) => {
  const defaultCaptions = {
    title: i18next.t('Account changes'),
    heading: i18next.t('Account deleting'),
    description: i18next.t('Before deleting your account, please make sure that:'),
    verifyLabel: i18next.t('Please confirm that you would like to delete your account'),
    deleteButton: i18next.t('Delete account'),
    availableText: i18next.t('You don’t have active subscription'),
    unavailableText: i18next.t('Unable to delete account due to active subscription(s).'),
  };

  if (hasCanceledSubscription) {
    return {
      ...defaultCaptions,
      unavailableTextDetails: i18next.t(
        'Please wait until <strong>{expiryDate}</strong> your subscription(s) have expired before to delete your account',
        {
          expiryDate,
        }
      ),
    };
  }

  const activeSubscriptionsText = isB2C
    ? i18next.t(
        'Please cancel all subscriptions before deleting account. See {cancellations} for more information.',
        {
          cancellations: `<a href="${CANCELATION_ARTICLE_B2C_URL}" target="_blank">${i18next.t(
            'Cancellations: How to proceed'
          )}</a>`,
        }
      )
    : i18next.t('Please cancel all subscriptions before deleting account.');

  return {
    ...defaultCaptions,
    unavailableTextDetails: activeSubscriptionsText,
  };
};

function AccountChangesContainer() {
  const dispatch = useDispatch();
  const activeHostingSubscription = useSelector(
    selectors.getActiveHostingSubscription,
    shallowEqual
  );
  const isB2C = useSelector(user.selectors.getIsB2C);

  const handleDeleteAccount = () => {
    dispatch(dialogs.actions.show(dialogs.dialogTypes.DELETE_ACCOUNT));
    track(events.DELETE_ACCOUNT_DIALOG_DISPLAYED, {
      dialogId: dialogs.dialogTypes.DELETE_ACCOUNT,
    });
  };

  const onCancellationTextClick = (e) => {
    if (e.target.tagName.toLowerCase() === 'a') {
      trackEvent(events.PROFILE_PAGE_HOW_TO_CANCEL_TUTORIAL_CLICKED);
    }
  };

  const hasActiveSubscription = activeHostingSubscription?.status === ACTIVE;
  const hasCanceledSubscription = activeHostingSubscription?.status === CANCELED;
  const subscriptionExpiryDate = activeHostingSubscription?.expiry_date || null;

  const currentDate = new Date();
  const expiryDate = new Date(subscriptionExpiryDate);
  const isCurrentDayBiggerThanExpiryDay = currentDate > expiryDate;

  const isDeletingDisabled =
    hasActiveSubscription || (hasCanceledSubscription && isCurrentDayBiggerThanExpiryDay);

  const captions = getCaptions(
    useLocaleDate(subscriptionExpiryDate),
    hasCanceledSubscription,
    isB2C
  );

  return (
    <AccountChangesSection
      captions={captions}
      onDelete={handleDeleteAccount}
      isDeletingDisabled={isDeletingDisabled}
      onCancellationTextClick={onCancellationTextClick}
    />
  );
}

export default AccountChangesContainer;
