export default {
  DOMAIN: 'domain',
  TERM: 'term',
  INITIATOR: 'initiator',
  PREMIUM_FEATURE: 'premiumFeature',
  PREFILL: 'prefill',
  PRODUCTS: 'products',
  TRIGGER_ID: 'triggerId',
  BACK: 'back',
  PERIOD_TOGGLE: 'periodToggle',
  DOMAIN_PROPOSAL: 'domainProposal',
  PAYMENT_BACK_URL: 'paymentBackUrl',
  RESCHEDULE_SUBSCRIPTION: 'rescheduleSubscription',
};
