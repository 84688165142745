import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import i18next from 'i18next';
import redirectToUrl from '../../../utils/redirect-to-url';
import segment from '../../analytics/segment';
import useAnnualDiscount from '../../products/hooks/use-annual-discount';
import utils from '../../webapp-plugins/utils';

const { CtaBanner } = designSystem;
const {
  constants: { triggerIds, events },
  trackAsync,
} = segment;

const getCaptions = (discount) => ({
  title: i18next.t('Save up to {discount}%', { discount }),
  subtitle: i18next.t('by switching to annual billing!'),
  label: i18next.t('Upgrade now!'),
});

const SwitchToAnnualBannerContainer = ({ subscriptionType, isB2C }) => {
  const discount = useAnnualDiscount();
  const captions = getCaptions(discount);

  const redirectToCheckout = async () => {
    await trackAsync(events.SWITCH_TO_ANNUAL_TRIGGER_CLICKED, { triggerId: triggerIds.BANNER });
    redirectToUrl(
      utils.generateAnnualPaymentUrl(
        { products: [subscriptionType], backUrl: window.location.href },
        isB2C
      )
    );
  };

  return (
    <CtaBanner
      title={captions.title}
      subtitle={captions.subtitle}
      ctaButton={{
        label: captions.label,
        onClick: redirectToCheckout,
      }}
      iconGlyph="sale"
      appearance="cta"
      layout="mobile"
      animate={false}
    />
  );
};

SwitchToAnnualBannerContainer.propTypes = {
  subscriptionType: PropTypes.string.isRequired,
  isB2C: PropTypes.bool.isRequired,
};

export default SwitchToAnnualBannerContainer;
