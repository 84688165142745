import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Box, Heading, PaymentMethod, Paragraph } = designSystem;

const PaymentMethodItem = ({ type, captions }) => (
  <div className="ws-set-default-payment-method-modal__item">
    <Box padding="spacing-xs">
      <div className="ws-set-default-payment-method-modal__item-content">
        <div className="ws-set-default-payment-method-modal__item-captions">
          <Heading type="heading-6">{captions.title}</Heading>
          <Paragraph appearance="medium-emphasis">{captions.payerName}</Paragraph>
          {captions.billingInfo && (
            <Paragraph appearance="medium-emphasis">{captions.billingInfo}</Paragraph>
          )}
        </div>
        <PaymentMethod type={type} size="tiny" />
      </div>
    </Box>
  </div>
);

PaymentMethodItem.propTypes = {
  type: PropTypes.string.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    payerName: PropTypes.string.isRequired,
    billingInfo: PropTypes.string,
  }).isRequired,
};

export default PaymentMethodItem;
