import actionTypes from '../constants/action-types';

const show = (type, modalProps) => ({
  type: actionTypes.SHOW_DIALOG,
  payload: {
    type,
    modalProps,
  },
});

export default show;
