import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import { fieldNames, planWithWebsiteValues } from '../constants/common';

const { InputGroupField, SimpleSelectField } = designSystem;

const getPlanWithWebsiteFields = ({ setFieldValue }) => ({
  name: fieldNames.PLAN_WITH_WEBSITE,
  placeholder: `${i18next.t('Select')}...`,
  rules: { required: true },
  Component: SimpleSelectField,
  controlGroup: {
    title: i18next.t('What do you plan to do next with your existing site?'),
    required: true,
  },
  onChange: () => {
    setFieldValue(fieldNames.BETTER_ALTERNATIVE, null);
  },
  options: [
    {
      label: i18next.t('I’ll keep it on the free plan'),
      value: planWithWebsiteValues.KEEP_ON_FREE,
    },
    { label: i18next.t('I’ll delete it'), value: planWithWebsiteValues.DELETE_IT },
    {
      label: i18next.t('I’ll migrate it to another website builder'),
      value: planWithWebsiteValues.MIGRATE,
    },
    {
      label: i18next.t(
        'I’ll move the content to an alternative platform (Facebook, Instagram, etc.)'
      ),
      value: planWithWebsiteValues.ALTERNATIVE_PLATFORM,
    },
    { label: i18next.t('I don’t know yet'), value: planWithWebsiteValues.DONT_KNOW },
  ],
  subFields: {
    [planWithWebsiteValues.MIGRATE]: {
      name: fieldNames.BETTER_ALTERNATIVE,
      placeholder: i18next.t('Name of the website builder'),
      rules: { required: true },
      Component: InputGroupField,
      controlGroup: {
        title: i18next.t('Which one?'),
      },
    },
  },
});

export default getPlanWithWebsiteFields;
