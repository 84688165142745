import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { designSystem } from '@yola/ws-ui';
import { constants } from '@yola/subscription-manager-js';
import { statusToAppearance, subscriptionIcons, appearanceTypes } from './constants';

const { Paragraph, IconButton, Heading, NotificationMessage, Box, SelectedIcon, Tooltip } =
  designSystem;
const {
  SubscriptionStatus: { CANCELED, EXPIRED },
  ACTIVE_STATUSES,
} = constants;

const getNotificationAppearance = (status) => {
  switch (status) {
    case CANCELED:
      return appearanceTypes.CTA;
    case EXPIRED:
      return appearanceTypes.DANGER;
    default:
      return appearanceTypes.WARNING;
  }
};

const getNotificationIconGlyph = (status) => {
  switch (status) {
    case CANCELED:
      return 'renew';
    case EXPIRED:
      return 'attention';
    default:
      return 'bell';
  }
};

const getNotificationTitleAppearance = (notificationAppearance) =>
  notificationAppearance === appearanceTypes.WARNING
    ? appearanceTypes.HIGH_EMPHASIS
    : notificationAppearance;

const SubscriptionItem = (props) => {
  const {
    captions,
    status,
    onClick,
    onNotificationClick,
    isActionButtonLoading,
    icon,
    withNotification,
    disabled,
  } = props;
  const hasNotificationMessage = withNotification && !!captions.notification;

  const classes = classNames('ws-subscription-item', {
    'ws-subscription-item--with-notification': hasNotificationMessage,
    'ws-subscription-item--disabled': disabled,
  });

  const statusAppearance = statusToAppearance[status];
  const notificationAppearance = getNotificationAppearance(status);
  const notificationIconGlyph = getNotificationIconGlyph(status);
  const notificationTitleAppearance = getNotificationTitleAppearance(notificationAppearance);

  return (
    <div className={classes}>
      <div className="ws-subscription-item__content" onClick={onClick}>
        <Heading type="heading-6">{captions.name}</Heading>
        <Heading type="heading-6" appearance={statusAppearance}>
          {captions.status}
        </Heading>
        <Paragraph appearance="medium-emphasis">
          {captions.dateLabel}
          {captions.date && (
            <Fragment>
              <br />
              {captions.date}
            </Fragment>
          )}
        </Paragraph>
        {icon && (
          <Fragment>
            {icon.type === subscriptionIcons.NEXT && (
              <IconButton glyph="next" size="medium" onClick={onClick} />
            )}
            {/* TODO: cleanup selected icon if https://github.com/yola/production/issues/11166 experiment is finished  */}
            {icon.type === subscriptionIcons.SELECTED && (
              <Fragment>
                {icon.tooltip ? (
                  <div data-for={icon.tooltip.id} data-tip>
                    <SelectedIcon disabled={icon.disabled} />
                    <Tooltip id={icon.tooltip.id} text={icon.tooltip.text} />
                  </div>
                ) : (
                  <SelectedIcon disabled={icon.disabled} />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
      {hasNotificationMessage && (
        <Box marginBottom="spacing-xs" marginRight="spacing-xs" marginLeft="spacing-xs">
          <NotificationMessage
            appearance={notificationAppearance}
            iconGlyph={notificationIconGlyph}
            title={
              // eslint-disable-next-line yola/react/jsx-wrap-multilines
              <Paragraph appearance={notificationTitleAppearance}>
                <b>{captions.notification.title}</b>
              </Paragraph>
            }
            actionButtonLabel={captions.notification.buttonLabel}
            onActionButtonClick={onNotificationClick}
            isActionButtonLoading={isActionButtonLoading}
          />
        </Box>
      )}
    </div>
  );
};

SubscriptionItem.propTypes = {
  captions: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    dateLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    date: PropTypes.string,
    notification: PropTypes.shape({
      title: PropTypes.string,
      buttonLabel: PropTypes.string,
    }),
  }).isRequired,
  status: PropTypes.oneOf(ACTIVE_STATUSES).isRequired,
  withNotification: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onNotificationClick: PropTypes.func,
  isActionButtonLoading: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(subscriptionIcons)).isRequired,
      tooltip: PropTypes.shape({ text: PropTypes.string, id: PropTypes.string }),
      disabled: PropTypes.bool,
    }),
    PropTypes.oneOf([null]),
  ]),
};

SubscriptionItem.defaultProps = {
  icon: null,
  onNotificationClick: null,
  withNotification: true,
  isActionButtonLoading: false,
  disabled: false,
};

export default SubscriptionItem;
