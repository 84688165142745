/* eslint-disable yola/react/jsx-tag-spacing */
import React from 'react';

const EcommerceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
    <g clipPath="url(#a)">
      <path
        fill="#005ED9"
        fillOpacity=".03"
        d="M10.329 20.4 7.876 6.264l22.818 3.082-2.774 10.17c-.142.52-.618.884-1.158.884H10.33Z"
      />
      <path
        fill="#005ED9"
        d="M8.363 6.734 30.188 9.68l-2.654 9.73a.801.801 0 0 1-.772.589H10.665L8.363 6.734Zm-.975-.94L9.992 20.8h16.77c.722 0 1.354-.483 1.544-1.18L31.2 9.01 7.388 5.794Z"
      />
      <path
        fill="#005ED9"
        fillOpacity=".03"
        d="M2.4 1.2a1.2 1.2 0 1 0 0 2.4 1.2 1.2 0 0 0 0-2.4Z"
      />
      <path
        fill="#005ED9"
        d="M2.4 1.6c.44 0 .8.36.8.8 0 .44-.36.8-.8.8-.44 0-.8-.36-.8-.8 0-.44.36-.8.8-.8Zm0-.8a1.6 1.6 0 1 0 0 3.2 1.6 1.6 0 0 0 0-3.2Z"
      />
      <path
        fill="#005ED9"
        fillOpacity=".2"
        d="M25.2 27.6a1.6 1.6 0 1 0 0 3.201 1.6 1.6 0 0 0 0-3.2Z"
      />
      <path
        fill="#005ED9"
        d="M25.2 28c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2Zm0-.8a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
      />
      <path
        fill="#005ED9"
        fillOpacity=".2"
        d="M14.8 27.6a1.6 1.6 0 1 0 0 3.2 1.6 1.6 0 0 0 0-3.2Z"
      />
      <path
        fill="#005ED9"
        d="M14.8 28c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2Zm0-.8a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
      />
      <path
        fill="#1B9B4A"
        fillOpacity=".03"
        d="M14.8 17.2v-5.6Zm3.2 0v-5.6Zm3.2 0v-5.6Zm3.2 0v-5.6Z"
      />
      <path
        stroke="#005ED9"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth=".8"
        d="M14.8 17.2v-5.6m3.2 5.6v-5.6m3.2 5.6v-5.6m3.2 5.6v-5.6M2.4 1.2h1.695a3.444 3.444 0 0 1 3.393 2.854l3.598 20.692A3.444 3.444 0 0 0 14.48 27.6H28.4"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default EcommerceIcon;
