import userStatusNames from 'src/js/modules/user/constants/status-names';
import subscriptionsStatusNames from 'src/js/modules/subscriptions/constants/status-names';
import sitesStatusNames from 'src/js/modules/sites/constants/status-names';
import featuresStatusNames from 'src/js/modules/features/constants/status-names';
import productsStatusNames from 'src/js/modules/products/constants/status-names';
import actionTypes from '../constants/action-types';
import constants from '../constants';

export const defaultStatusState = {
  [sitesStatusNames.SITES]: constants.IDLE,
  [subscriptionsStatusNames.SUBSCRIPTIONS]: constants.IDLE,
  [subscriptionsStatusNames.CURRENT_PACKAGE]: constants.IDLE,
  [userStatusNames.USER_DATA]: constants.IDLE,
  [userStatusNames.UPDATE_USER_DATA]: constants.IDLE,
  [userStatusNames.PARTNER_DATA]: constants.IDLE,
  [userStatusNames.USER_PREFERENCES]: constants.IDLE,
  [userStatusNames.UPDATE_USER_PREFERENCES]: constants.IDLE,
  [userStatusNames.DELETE_ACCOUNT]: constants.IDLE,
  [userStatusNames.CHANGE_PASSWORD]: constants.IDLE,
  [userStatusNames.SEND_VERIFICATION_CODE]: constants.IDLE,
  [featuresStatusNames.AVAILABLE_PACKAGES]: constants.IDLE,
  [featuresStatusNames.ECOMMERCE_PACKAGES]: constants.IDLE,
  [productsStatusNames.PRICES]: constants.IDLE,
  [productsStatusNames.BILLING_PROFILES]: constants.IDLE,
  [productsStatusNames.CREATE_BILLING_PROFILE]: constants.IDLE,
  [productsStatusNames.UPDATE_BILLING_PROFILE]: constants.IDLE,
  [productsStatusNames.BASKETS]: constants.IDLE,
  [productsStatusNames.DELETE_BILLING_PROFILE]: constants.IDLE,
  [productsStatusNames.REPLACE_BILLING_PROFILE_WITH_EXISTING]: constants.IDLE,
  [productsStatusNames.REPLACE_BILLING_PROFILE_WITH_NEW]: constants.IDLE,
  [productsStatusNames.SET_BILLING_PROFILE_AS_DEFAULT]: constants.IDLE,
  [productsStatusNames.CANCELLATION_COUPON]: constants.IDLE,
  [productsStatusNames.CREATE_CANCELLATION_COUPON]: constants.IDLE,
  [subscriptionsStatusNames.REACTIVATE_SUBSCRIPTION]: constants.IDLE,
  [subscriptionsStatusNames.CANCEL_SUBSCRIPTION]: constants.IDLE,
  [subscriptionsStatusNames.RESCHEDULE_SUBSCRIPTION]: constants.IDLE,
};

const status = (state = defaultStatusState, action) => {
  if (action.type === actionTypes.SET_STATUS) {
    const { status: statusValue, name } = action.payload;

    return {
      ...state,
      [name]: statusValue,
    };
  }
  if (action.type === actionTypes.RESET_STATUS) {
    const { name } = action.payload;

    return {
      ...state,
      [name]: constants.IDLE,
    };
  }

  return state;
};

export default status;
