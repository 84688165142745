import React, { Fragment, useMemo, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { selectors, verifiers } from '@yola/subscription-manager-js';
import user from '../../user';
import products from '../../products';
import dialogs from '../../dialogs';
import segment from '../../analytics/segment';
import processBillingProfile from '../helpers/process-billing-profile';
import fieldNames, { defaultFormData } from '../constants/form-field-names';
import { dialogIds } from '../constants/analytics';
import { PAYMENT_METHOD_ITEM_PROP_TYPES } from '../components/payment-method-item/constants';
import computePaymentMethodTrait from '../../analytics/segment/helpers/compute-payment-method-trait';

const {
  track,
  constants: { events, triggerIds },
} = segment;

const getCaptions = () => ({
  replaceWithNewNotificationTitle: i18next.t('A new default payment method has been added'),
  replaceWithExistingNotificationTitle: i18next.t('Your default payment method has been updated'),
  replaceNotificationDescription: i18next.t(
    "Your auto-bill service agreement will continue until you cancel. You can cancel at any time and won't be billed for the next cycle"
  ),
  deleteNotificationTitle: (name) =>
    i18next.t('The payment method {name} removed from your account', { name }),
});

const DeletePaymentMethodRoute = ({
  match,
  closeUrl,
  paymentMethods,
  setServiceError,
  setNotificationMessage,
}) => {
  const { methodId } = match.params;
  const dispatch = useDispatch();
  const captions = getCaptions();
  const { location: userCountry } = useSelector(user.selectors.getUserPreferences, shallowEqual);
  const activeSubscriptions = useSelector(selectors.getActiveSubscriptions, shallowEqual);

  const targetMethod = useMemo(
    () => paymentMethods.find(({ id }) => id === methodId),
    [paymentMethods, methodId]
  );

  const availableMethods = paymentMethods.filter(({ id }) => id !== methodId);
  const hasAvailablePaymentMethods = !!availableMethods.length;

  const submitDeletePaymentMethod = async () => {
    const {
      captions: { title: name },
    } = targetMethod;

    try {
      await dispatch(products.thunks.deleteBillingProfile(methodId));

      setNotificationMessage({
        title: captions.deleteNotificationTitle(name),
      });

      track(events.REMOVE_PAYMENT_METHOD_DIALOG_SUBMITTED, {
        dialogId: dialogIds.REMOVE_PAYMENT_METHOD,
      });
    } catch (e) {
      setServiceError(true);
    }

    dispatch(push(closeUrl));
  };

  const cancelDeletePaymentMethod = () => {
    track(events.REMOVE_PAYMENT_METHOD_DIALOG_CANCELLED, {
      dialogId: dialogIds.REMOVE_PAYMENT_METHOD,
    });

    dispatch(push(closeUrl));
  };

  const submitReplaceWithNew = () => {
    const initialFormData = {
      ...defaultFormData,
      [fieldNames.COUNTRY_CODE]: userCountry,
      [fieldNames.MAKE_DEFAULT]: true,
    };
    const disabledFields = [fieldNames.MAKE_DEFAULT];

    const handleSave = async (formData) => {
      const response = await dispatch(
        products.thunks.replaceBillingProfileWithNew(methodId, formData)
      );
      const newDefaultMethod = processBillingProfile(response);
      const {
        captions: { title },
      } = newDefaultMethod;

      setNotificationMessage({
        title: (
          <Fragment>
            {captions.replaceWithNewNotificationTitle} <mark>({title})</mark>
          </Fragment>
        ),
        description: captions.replaceNotificationDescription,
      });

      track(events.PAYMENT_METHOD_DIALOG_SUBMITTED, {
        dialogId: dialogIds.ADD_NEW_PAYMENT_METHOD,
        triggerId: triggerIds.REMOVING_PAYMENT_METHOD,
        default: response.isDefault,
        paymentMethod: computePaymentMethodTrait(response.cardType || response.type),
      });

      dispatch(push(closeUrl));
    };

    const handleCancel = () => {
      track(events.PAYMENT_METHOD_DIALOG_CANCELLED, {
        dialogId: dialogIds.ADD_NEW_PAYMENT_METHOD,
        triggerId: triggerIds.REMOVING_PAYMENT_METHOD,
      });

      dispatch(push(closeUrl));
    };

    dispatch(
      dialogs.actions.show(dialogs.dialogTypes.PAYMENT_METHOD_FORM, {
        onSave: handleSave,
        onCancel: handleCancel,
        formData: initialFormData,
        statusName: products.statusNames.REPLACE_BILLING_PROFILE_WITH_NEW,
        disabledFields,
      })
    );

    track(events.PAYMENT_METHOD_DIALOG_DISPLAYED, {
      dialogId: dialogIds.ADD_NEW_PAYMENT_METHOD,
      triggerId: triggerIds.REMOVING_PAYMENT_METHOD,
    });
  };

  const submitReplaceWithExisting = async (existingMethodId) => {
    try {
      const response = await dispatch(
        products.thunks.replaceBillingProfileWithExisting(methodId, existingMethodId)
      );
      const updatedDefaultMethod = processBillingProfile(response);
      const {
        captions: { title },
      } = updatedDefaultMethod;

      setNotificationMessage({
        title: (
          <Fragment>
            {captions.replaceWithExistingNotificationTitle} <mark>({title})</mark>
          </Fragment>
        ),
        description: captions.replaceNotificationDescription,
      });

      track(events.REMOVE_PAYMENT_METHOD_DIALOG_SUBMITTED, {
        dialogId: dialogIds.REPLACE_PAYMENT_METHOD,
        availablePaymentMethods: true,
      });
    } catch (e) {
      setServiceError(true);
    }

    dispatch(push(closeUrl));
  };

  const cancelReplacePaymentMethod = () => {
    track(events.REMOVE_PAYMENT_METHOD_DIALOG_CANCELLED, {
      dialogId: dialogIds.REPLACE_PAYMENT_METHOD,
      availablePaymentMethods: hasAvailablePaymentMethods,
    });

    dispatch(push(closeUrl));
  };

  useEffect(() => {
    if (!targetMethod) {
      dispatch(push(closeUrl));
      return null;
    }

    const {
      captions: { title: name },
      isDefault,
    } = targetMethod;
    const hasRebillingAgreements =
      activeSubscriptions && verifiers.hasRenewablePaidSubscription(activeSubscriptions);

    if (isDefault && (hasRebillingAgreements || hasAvailablePaymentMethods)) {
      dispatch(
        dialogs.actions.show(dialogs.dialogTypes.DELETE_DEFAULT_PAYMENT_METHOD, {
          captions: { name },
          statusName: products.statusNames.REPLACE_BILLING_PROFILE_WITH_EXISTING,
          availableMethods,
          onAddNew: submitReplaceWithNew,
          onReplaceWithExisting: submitReplaceWithExisting,
          onCancel: cancelReplacePaymentMethod,
        })
      );

      track(events.REMOVE_PAYMENT_METHOD_DIALOG_DISPLAYED, {
        dialogId: dialogIds.REPLACE_PAYMENT_METHOD,
        availablePaymentMethods: hasAvailablePaymentMethods,
      });
    } else {
      dispatch(
        dialogs.actions.show(dialogs.dialogTypes.DELETE_PAYMENT_METHOD, {
          captions: { name },
          statusName: products.statusNames.DELETE_BILLING_PROFILE,
          onDelete: submitDeletePaymentMethod,
          onCancel: cancelDeletePaymentMethod,
        })
      );

      track(events.REMOVE_PAYMENT_METHOD_DIALOG_DISPLAYED, {
        dialogId: dialogIds.REMOVE_PAYMENT_METHOD,
      });
    }

    return () => dispatch(dialogs.actions.hide());
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return null;
};

DeletePaymentMethodRoute.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  closeUrl: PropTypes.string.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape(PAYMENT_METHOD_ITEM_PROP_TYPES)).isRequired,
  setServiceError: PropTypes.func,
  setNotificationMessage: PropTypes.func,
};

DeletePaymentMethodRoute.defaultProps = {
  setServiceError: Function.prototype,
  setNotificationMessage: Function.prototype,
};

export default DeletePaymentMethodRoute;
