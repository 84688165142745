import React, { Fragment, useEffect, useState } from 'react';
import { designSystem } from '@yola/ws-ui';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import get from 'lodash.get';
import { PageHeading, PageHeadingLeft } from 'src/js/common/components/page-container';
import AccountChangesContainer from 'src/js/modules/profile/containers/account-changes-container';
import PersonalInfoContainer from 'src/js/modules/profile/containers/personal-info-container';
import EmailSubscriptionsContainer from 'src/js/modules/profile/containers/email-subscriptions-container';
import AccountInfoContainer from 'src/js/modules/profile/containers/account-info-container';
import segment from 'src/js/modules/analytics/segment';
import user from 'src/js/modules/user';

const { Heading, Paragraph, Stack } = designSystem;
const {
  constants: { events },
  trackers: { trackEvent },
} = segment;

function ProfilePageContainer() {
  const [notification, setNotification] = useState(null);
  const userData = useSelector(user.selectors.getUserData);

  useEffect(() => {
    trackEvent(events.PROFILE_PAGE_DISPLAYED, {
      emailVerified: get(userData, 'isEmailVerified', null),
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <PageHeading>
        <PageHeadingLeft>
          <Stack gap="spacing-2-xs">
            <Heading type="heading-2">{i18next.t('Edit your account information')}</Heading>
            <Paragraph size="large">
              {i18next.t(
                'Keep your profile up to date, change your password, or delete your account permanently.'
              )}
            </Paragraph>
          </Stack>
        </PageHeadingLeft>
      </PageHeading>
      <Stack gap="spacing-l">
        <PersonalInfoContainer notification={notification} setNotification={setNotification} />
        <AccountInfoContainer notification={notification} setNotification={setNotification} />
        <EmailSubscriptionsContainer
          notification={notification}
          setNotification={setNotification}
        />
        <AccountChangesContainer />
      </Stack>
    </Fragment>
  );
}

export default ProfilePageContainer;
