import get from 'lodash.get';
import getIsB2C from './is-b2c';

const getApiUrl = (rootState) => {
  if (getIsB2C(rootState)) {
    return get(rootState, 'config.common["public-api-gateway"].url');
  }

  return get(rootState, 'config.common["public-api-gateway"].wl_url');
};

export default getApiUrl;
