import React from 'react';
import PropTypes from 'prop-types';

function PageHeadingLeft(props) {
  const { children } = props;

  return <div className="ws-page-heading__left">{children}</div>;
}

PageHeadingLeft.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageHeadingLeft;
