import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, Switch, Route } from 'react-router-dom';
import { push } from 'connected-react-router';
import i18next from 'i18next';
import get from 'lodash.get';
import routesMap from '../../../router/helpers/routes-map';
import user from '../../user';
import products from '../../products';
import segment from '../../analytics/segment';
import useQuery from '../../../common/hooks/use-query';
import PaymentMethodsHeader from '../components/payment-methods-header';
import PaymentMethodsContent from '../components/payment-methods-content';
import AddPaymentMethodRoute from '../routes/add-payment-method-route';
import EditPaymentMethodRoute from '../routes/edit-payment-method-route';
import DeletePaymentMethodRoute from '../routes/delete-payment-method-route';
import SetAsDefaultPaymentMethodRoute from '../routes/set-as-default-payment-method-route';
import processBillingProfile from '../helpers/process-billing-profile';
import cardTypes from '../constants/card-types';
import supportedTabs from '../constants/supported-tabs';

const {
  track,
  constants: { events, triggerIds },
} = segment;

const getCaptions = () => ({
  content: {
    emptyState: i18next.t('You have no saved payment methods.'),
    default: i18next.t('Default'),
    secondary: i18next.t('Secondary'),
    setDefaultButton: i18next.t('Set as default'),
    editButton: i18next.t('Edit'),
  },
  header: {
    title: i18next.t('Manage your payment methods'),
    buttonLabel: i18next.t('Add a payment method'),
  },
});

const PaymentMethodsContainer = () => {
  const dispatch = useDispatch();
  const captions = getCaptions();
  const availablePaymentMethods = useSelector(
    user.selectors.getAvailablePaymentMethods,
    shallowEqual
  );
  const [notificationMessage, setNotificationMessage] = useState({});
  const [serviceError, setServiceError] = useState(false);
  const {
    data,
    isLoading,
    error: mainDataError,
  } = useQuery({
    name: products.statusNames.BILLING_PROFILES,
    thunk: products.thunks.fetchBillingProfiles,
    selector: products.selectors.getBillingProfiles,
  });
  const location = useLocation();
  const isFailed = Boolean(mainDataError);
  const isCardPaymentAvailable = availablePaymentMethods.includes(cardTypes.STRIPE);
  const closeModalUrl = routesMap.accountManagement.url({ tabId: supportedTabs.PAYMENT_METHODS });

  const paymentMethods = useMemo(
    () =>
      data && data.length
        ? data.map(processBillingProfile).sort(({ isDefault }) => (isDefault ? -1 : 1))
        : [],
    [data]
  );

  const clearNotificationMessage = () => setNotificationMessage({});

  const clearServiceError = () => {
    setServiceError(false);
  };

  const addPaymentMethod = () => dispatch(push(routesMap.addPaymentMethod.url()));

  const editPaymentMethod = (methodId, triggerId) =>
    dispatch(push(routesMap.editPaymentMethod.url(methodId), { triggerId }));

  const deletePaymentMethod = (methodId) =>
    dispatch(push(routesMap.deletePaymentMethod.url(methodId)));

  const setAsDefaultPaymentMethod = (methodId) =>
    dispatch(push(routesMap.setAsDefaultPaymentMethod.url(methodId)));

  useEffect(() => {
    if (!isLoading) {
      track(events.PAYMENT_METHODS_PAGE_DISPLAYED, {
        triggerId: get(location, 'state.triggerId', triggerIds.ACCOUNT_SETTINGS),
        addedPaymentMethods: paymentMethods.length,
      });
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <Fragment>
      <PaymentMethodsHeader
        onAddPaymentMethod={addPaymentMethod}
        restrictAdding={!isCardPaymentAvailable}
        captions={captions.header}
      />
      <PaymentMethodsContent
        isLoading={isLoading}
        isFailed={isFailed}
        error={serviceError}
        clearError={clearServiceError}
        notificationMessage={notificationMessage}
        clearNotificationMessage={clearNotificationMessage}
        paymentMethods={paymentMethods}
        onEdit={editPaymentMethod}
        onDelete={deletePaymentMethod}
        onSetAsDefault={setAsDefaultPaymentMethod}
        captions={captions.content}
      />
      <Switch>
        <Route
          path={routesMap.addPaymentMethod.patterns}
          render={(routeProps) => (
            <AddPaymentMethodRoute
              {...routeProps}
              closeUrl={closeModalUrl}
              isFirstPaymentMethod={!paymentMethods.length}
            />
          )}
        />
        <Route
          path={routesMap.editPaymentMethod.patterns}
          render={(routeProps) => (
            <EditPaymentMethodRoute
              {...routeProps}
              closeUrl={closeModalUrl}
              paymentMethods={paymentMethods}
            />
          )}
        />
        <Route
          path={routesMap.deletePaymentMethod.patterns}
          render={(routeProps) => (
            <DeletePaymentMethodRoute
              {...routeProps}
              closeUrl={closeModalUrl}
              paymentMethods={paymentMethods}
              setServiceError={setServiceError}
              setNotificationMessage={setNotificationMessage}
            />
          )}
        />
        <Route
          path={routesMap.setAsDefaultPaymentMethod.patterns}
          render={(routeProps) => (
            <SetAsDefaultPaymentMethodRoute
              {...routeProps}
              closeUrl={closeModalUrl}
              paymentMethods={paymentMethods}
              setServiceError={setServiceError}
              setNotificationMessage={setNotificationMessage}
            />
          )}
        />
      </Switch>
    </Fragment>
  );
};

export default PaymentMethodsContainer;
