function generatePublishUrl(siteInSitebuilderUrl) {
  // we generate publish url only for latitude sites
  if (siteInSitebuilderUrl.indexOf('editor') !== -1) {
    const sitebuiderUrl = new URL(siteInSitebuilderUrl);

    sitebuiderUrl.pathname = '/publish';

    return sitebuiderUrl.toString();
  }

  return null;
}

export default generatePublishUrl;
