import serviceClient from '../service-client';
import {
  camelCaseObjectToSnakeCase,
  snakeCaseObjectToCamelCase,
} from '../../../utils/convert-case';
import statusNames from '../constants/status-names';
import status from '../../status';
import selectors from '../selectors';
import actions from '../actions';
import getUpdatedBillingProfiles from '../helpers/get-updated-billing-profiles';
import resolveServiceError from '../../../common/helpers/resolve-service-error';

const handleBillingProfilesUpdate = (updatedProfile, dispatch, state) => {
  const currentProfiles = selectors.getBillingProfiles(state);
  let toUpdate = [updatedProfile];

  if (updatedProfile.isDefault) {
    const prevDefaultProfile = currentProfiles.find(({ isDefault }) => isDefault);

    if (prevDefaultProfile && prevDefaultProfile.id !== updatedProfile.id) {
      toUpdate = [...toUpdate, { ...prevDefaultProfile, isDefault: false }];
    }
  }

  const newProfiles = getUpdatedBillingProfiles(currentProfiles, { toUpdate });
  dispatch(actions.setBillingProfiles(newProfiles));
};

const updateBillingProfile = (id, data) => async (dispatch, getState) => {
  const state = getState();
  const yoproducts = serviceClient.get();
  const formattedData = camelCaseObjectToSnakeCase(data);
  let responseData;

  dispatch(status.actions.setStatus(statusNames.UPDATE_BILLING_PROFILE, status.constants.LOADING));

  try {
    const response = await yoproducts.requests.updateBillingProfile(id, formattedData);
    responseData = snakeCaseObjectToCamelCase(response);

    dispatch(
      status.actions.setStatus(statusNames.UPDATE_BILLING_PROFILE, status.constants.SUCCEEDED)
    );

    const billingProfilesStatus = selectors.getBillingProfilesStatus(state);

    if (billingProfilesStatus === status.constants.SUCCEEDED) {
      handleBillingProfilesUpdate(responseData, dispatch, state);
    }
  } catch (e) {
    const error = await resolveServiceError(e);

    console.error(error);
    dispatch(status.actions.setStatus(statusNames.UPDATE_BILLING_PROFILE, status.constants.FAILED));

    throw error;
  }

  return responseData;
};

export default updateBillingProfile;
