import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import user from 'src/js/modules/user';
import NavbarHeader from 'src/js/common/components/navbar-header';
import MainMenu from 'src/js/common/components/main-menu';

function HelpMenuContainer() {
  const supportUrl = useSelector(user.selectors.getSupportUrl);
  const contactSupportUrl = useSelector(user.selectors.getContactSupportUrl);
  const pricingUrl = useSelector(user.selectors.getPricingUrl);

  const helpMenuItems = useMemo(() => {
    const items = [];

    if (supportUrl) {
      items.push({
        title: i18next.t('Instant Help'),
        href: supportUrl,
      });
    }

    if (pricingUrl) {
      items.push({
        title: i18next.t('Pricing information'),
        href: pricingUrl,
      });
    }

    if (contactSupportUrl) {
      items.push({
        title: i18next.t('Contact support'),
        href: contactSupportUrl,
      });
    }

    return items;
  }, [pricingUrl, contactSupportUrl, supportUrl]);

  if (helpMenuItems.length) {
    return (
      <React.Fragment>
        <MainMenu
          captions={{
            name: i18next.t('Help'),
          }}
          className="ws-mainmenu--help-menu"
          items={helpMenuItems}
        />
        <NavbarHeader.Divider />
      </React.Fragment>
    );
  }

  return null;
}

export default HelpMenuContainer;
