import axios from 'axios';
import actions from '../actions';

const fetchConfig = () => async (dispatch) => {
  try {
    const config = await axios('/static/config.json?c=1');
    dispatch(actions.setServiceConfig(config.data));
  } catch (error) {
    console.error('Failed to load config: ', error);
  }
};

export default fetchConfig;
