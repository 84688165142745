import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducers as subscriptionManagerReducers } from '@yola/subscription-manager-js';
import config from './modules/config';
import user from './modules/user';
import i18n from './modules/i18n';
import sites from './modules/sites';
import dialogs from './modules/dialogs';
import error from './modules/error';
import status from './modules/status';
import products from './modules/products';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    config: config.reducers.serviceConfig,
    user: combineReducers(user.reducers),
    locale: i18n.reducers.locale,
    sites: sites.reducers.sites,
    dialog: dialogs.reducers.dialog,
    criticalError: error.reducers.criticalError,
    status: status.reducers.status,
    products: combineReducers(products.reducers),
    subscriptions: combineReducers(subscriptionManagerReducers),
  });

export default rootReducer;
