import actionTypes from '../constants/action-types';

const DEFAULT_STATE = {
  type: null,
};

const dialog = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes.SHOW_DIALOG:
      return action.payload;
    case actionTypes.HIDE_DIALOG:
      return { ...DEFAULT_STATE, ...action.payload };
    default:
      return state;
  }
};

export default dialog;
