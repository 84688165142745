import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Box, Heading, PaymentMethod, RadioButton, Paragraph } = designSystem;

const PaymentMethodRadioItem = ({ value, type, captions, onSelect }) => {
  const handleSelect = () => onSelect(value);

  return (
    <div className="ws-payment-method-radio-item" onClick={handleSelect}>
      <Box padding="spacing-xs">
        <div className="ws-payment-method-radio-item__content">
          <RadioButton id={value} value={value} label="" />
          <div className="ws-payment-method-radio-item__captions">
            <Heading type="heading-6">{captions.title}</Heading>
            <Paragraph appearance="medium-emphasis">{captions.payerName}</Paragraph>
            {captions.billingInfo && (
              <Paragraph appearance="medium-emphasis">{captions.billingInfo}</Paragraph>
            )}
          </div>
          <PaymentMethod type={type} size="tiny" />
        </div>
      </Box>
    </div>
  );
};

PaymentMethodRadioItem.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    payerName: PropTypes.string.isRequired,
    billingInfo: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default PaymentMethodRadioItem;
