import yosubscriptionsjs from '@yola/yosubscriptionsjs';
import yousersjs from '@yola/yousersjs';
import { configure } from '@yola/subscription-manager-js';
import config from '../../config';

const configureSubscriptionsManager = (store) => {
  const state = store.getState();
  const origin = config.selectors.getApiUrl(state);

  configure({
    store,
    statePath: 'subscriptions',
    subscriptionService: yosubscriptionsjs({ origin }),
    partnerService: yousersjs.partner(),
  });
};

export default configureSubscriptionsManager;
