import constants from '../constants';
import actionTypes from '../constants/action-types';

const locale = (state = constants.DEFAULT_LOCALE, action) => {
  if (action.type === actionTypes.SET_LOCALE) {
    return action.payload.locale;
  }

  return state;
};

export default locale;
