import PropTypes from 'prop-types';
import statusTypes from '../../constants/payment-method-status-types';
import cardTypes from '../../constants/card-types';

export const statusToAppearance = {
  [statusTypes.FAILED]: 'danger',
  [statusTypes.EXPIRY_SOON]: 'accent',
  [statusTypes.EXPIRED]: 'danger',
};

export const statusToIconGlyph = {
  [statusTypes.FAILED]: 'attention',
  [statusTypes.EXPIRY_SOON]: 'renew',
  [statusTypes.EXPIRED]: 'attention',
};

export const PAYMENT_METHOD_ITEM_PROP_TYPES = {
  id: PropTypes.string.isRequired,
  iconType: PropTypes.oneOf(Object.values(cardTypes)).isRequired,
  status: PropTypes.oneOf(Object.values(statusTypes)).isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    payerName: PropTypes.string.isRequired,
    billingInfo: PropTypes.string,
    notification: PropTypes.shape({
      title: PropTypes.string,
      buttonLabel: PropTypes.string,
    }),
  }).isRequired,
  isDefault: PropTypes.bool,
  isCardPaymentMethod: PropTypes.bool,
};
