import track from '../helpers/track';
import events from '../constants/events';
import routesMap from '../../../../router/helpers/routes-map';

const trackAppOpened = () => {
  let { pathname } = window.location;
  if (pathname === '/') {
    pathname = routesMap.dashboard.url();
  }
  track(events.APP_OPENED, { pagePath: pathname.replace(/\/$/, '') });
};

export default trackAppOpened;
