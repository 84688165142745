import PropTypes, { oneOfType } from 'prop-types';
import statusTypes from '../../../products/constants/basket-status-types';

export const statusToAppearance = {
  [statusTypes.CAPTURED]: 'success',
  [statusTypes.CANCELLED]: 'danger',
  [statusTypes.FAILED]: 'danger',
  [statusTypes.ERROR]: 'danger',
  [statusTypes.NEW]: 'medium-emphasis',
  [statusTypes.PENDING]: 'medium-emphasis',
  [statusTypes.AUTHORIZED]: 'medium-emphasis',
  [statusTypes.REFUNDED]: 'medium-emphasis',
  [statusTypes.PARTIAL_REFUND]: 'medium-emphasis',
};

export const billingItemPropTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.string).isRequired,
  paymentMethod: oneOfType([
    PropTypes.shape({
      cardLastDigits: PropTypes.string,
      cardType: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  status: PropTypes.oneOf(Object.values(statusTypes)).isRequired,
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  statusLabel: PropTypes.string,
};
