import { constants } from '@yola/subscription-manager-js';

const { SubscriptionTerm } = constants;

export const PER_MONTH = SubscriptionTerm.MONTHLY;
export const PER_YEAR = SubscriptionTerm.ANNUAL;

export const PERIOD_VALUES = {
  [PER_MONTH]: 'perMonth',
  [PER_YEAR]: 'perYear',
};

export const MAP_TERM_TO_READABLE_NAME = {
  perMonth: 'monthly',
  perYear: 'annual',
};

export const MAP_READABLE_NAME_TO_PERIOD = {
  annual: PER_YEAR,
  monthly: PER_MONTH,
};
