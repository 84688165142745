const statusNames = {
  PARTNER_DATA: 'partnerData',
  USER_DATA: 'userData',
  USER_PREFERENCES: 'userPreferences',
  UPDATE_USER_DATA: 'updateUserData',
  UPDATE_USER_PREFERENCES: 'updateUserPreferences',
  DELETE_ACCOUNT: 'deleteAccount',
  CHANGE_PASSWORD: 'changePassword',
  SEND_VERIFICATION_CODE: 'sendVerificationCode',
};

export default statusNames;
