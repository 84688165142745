import { batch } from 'react-redux';
import yousersjs from '@yola/yousersjs';
import status from 'src/js/modules/status';
import statusNames from 'src/js/modules/user/constants/status-names';

const sendVerificationCode = () => async (dispatch) => {
  try {
    dispatch(
      status.actions.setStatus(statusNames.SEND_VERIFICATION_CODE, status.constants.LOADING)
    );
    await yousersjs.requests.sendVerificationEmail();

    batch(() => {
      dispatch(
        status.actions.setStatus(statusNames.SEND_VERIFICATION_CODE, status.constants.SUCCEEDED)
      );
    });
  } catch (e) {
    dispatch(status.actions.setStatus(statusNames.SEND_VERIFICATION_CODE, status.constants.FAILED));
    throw e;
  }
};

export default sendVerificationCode;
