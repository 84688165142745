import yowsjs from '@yola/yowsjs';
import config from 'src/js/modules/config';
import deleteSiteData from '../actions/delete-site-data';

const deleteSite = (siteId) => (dispatch, getState) => {
  const state = getState();
  const apiUrl = config.selectors.getApiUrl(state);
  const service = yowsjs.initWith(apiUrl);

  return service.deleteSite(siteId).then((data) => {
    dispatch(deleteSiteData(siteId));
    return data;
  });
};

export default deleteSite;
