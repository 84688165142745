import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import get from 'lodash.get';
import { DifmBannerServicesContainer } from '@yola/webapp-plugins';
import yousers from '@yola/yousersjs';
import dialogs from 'src/js/modules/dialogs';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from '../../dialogs/constants/dialog-types';

const {
  track,
  constants: { events, triggerIds },
} = segment;
const triggerId = triggerIds.CANCELLATION;
const dialogId = dialogTypes.DIFM_PROMO_SERVICES;

const DifmPromoServicesDialogContainer = ({ values }) => {
  const serviceName = get(values, 'serviceName', null);

  const dispatch = useDispatch();

  const onSelect = (selectedService) => {
    track(events.DIFM_PROMO_DIALOG_SERVICE_SELECTED, {
      selectedService,
      dialogId,
      triggerId,
    });
  };
  const onNext = (updatedValues) => {
    dispatch(
      dialogs.actions.show(dialogTypes.DIFM_PROMO_SURVEY, {
        values: updatedValues,
      })
    );
  };
  const onClose = () => {
    yousers.requests.declineDifm();
    track(events.DIFM_PROMO_DIALOG_CLOSED, {
      dialogId,
      selectedService: serviceName,
    });
    dispatch(dialogs.actions.hide());
  };

  useEffect(() => {
    track(events.DIFM_PROMO_DIALOG_DISPLAYED, {
      dialogId,
      triggerId,
      selectedService: serviceName,
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <DifmBannerServicesContainer
      values={values}
      onNext={onNext}
      onClose={onClose}
      onSelect={onSelect}
    />
  );
};

DifmPromoServicesDialogContainer.defaultProps = {
  values: {},
};

DifmPromoServicesDialogContainer.propTypes = {
  values: PropTypes.shape({ serviceName: PropTypes.string }),
};

export default DifmPromoServicesDialogContainer;
