import { useSelector, shallowEqual } from 'react-redux';
import get from 'lodash.get';
import user from '../../user';
import i18n from '../../i18n';
import selectors from '../selectors';
import { PER_MONTH } from '../../common/constants/terms';
import serviceClient from '../service-client';

const useFormattedSubscriptionPrice = (subscriptionType, term = PER_MONTH) => {
  const yoproducts = serviceClient.get();

  const prices = useSelector(selectors.getPrices, shallowEqual);
  const { currency } = useSelector(user.selectors.getUserPreferences, shallowEqual);
  const locale = useSelector(i18n.selectors.getLocale);
  const packagePrices = get(prices, `${subscriptionType}.${term}.prices`, null);
  const currentPrice = get(packagePrices, `current.${currency}`, null);
  const basePrice = get(packagePrices, `base.${currency}`, null);
  const discountPrice = get(packagePrices, `discounted.${currency}`, null);

  return {
    currentPrice:
      currentPrice &&
      yoproducts.actions.formatPrice(currentPrice, locale, currency, {
        leadingCurrency: true,
      }),
    basePrice:
      basePrice &&
      yoproducts.actions.formatPrice(basePrice, locale, currency, {
        leadingCurrency: true,
      }),
    discountPrice:
      discountPrice &&
      yoproducts.actions.formatPrice(discountPrice, locale, currency, {
        leadingCurrency: true,
      }),
  };
};

export default useFormattedSubscriptionPrice;
