import actionTypes from '../constants/action-types';

const userPreferences = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_PREFERENCES:
      return action.payload.userPreferences;
    default:
      return state;
  }
};

export default userPreferences;
