import React from 'react';
import PropTypes from 'prop-types';

function PageHeadingRight(props) {
  const { children } = props;

  return <div className="ws-page-heading__right">{children}</div>;
}

PageHeadingRight.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageHeadingRight;
