import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import i18next from 'i18next';

const { Loader, Paragraph } = designSystem;

const getDefaultText = () => i18next.t('Loading...');

const LoaderWithText = ({ text }) => {
  const caption = text || getDefaultText();

  return (
    <div className="ws-loader-with-text">
      <Loader />
      <Paragraph appearance="medium-emphasis">{caption}</Paragraph>
    </div>
  );
};

LoaderWithText.propTypes = {
  text: PropTypes.string,
};

LoaderWithText.defaultProps = {
  text: '',
};

export default LoaderWithText;
