import actionTypes from '../constants/action-types';

function sites(state = [], action) {
  switch (action.type) {
    case actionTypes.SET_SITES: {
      return action.payload.sites;
    }

    case actionTypes.DELETE_SITE_DATA: {
      if (!state) return state;

      return state.filter((site) => site.id !== action.payload.siteId);
    }

    default:
      return state;
  }
}

export default sites;
