import serviceClient from '../service-client';
import { snakeCaseObjectToCamelCase } from '../../../utils/convert-case';
import statusNames from '../constants/status-names';
import status from '../../status';
import resolveServiceError from '../../../common/helpers/resolve-service-error';
import statusTypes from '../constants/basket-status-types';

const FILTERING_PARAM = 'status__in';
const TARGET_BASKETS = [
  statusTypes.CAPTURED,
  statusTypes.REFUNDED,
  statusTypes.PARTIAL_REFUND,
].join(',');

const fetchBaskets = (page) => async (dispatch) => {
  const yoproducts = serviceClient.get();
  let responseData;
  let count;

  dispatch(status.actions.setStatus(statusNames.BASKETS, status.constants.LOADING));

  try {
    const response = await yoproducts.requests.getBaskets({
      page,
      [FILTERING_PARAM]: TARGET_BASKETS,
    });
    ({ count } = response);
    responseData = snakeCaseObjectToCamelCase(response.results);

    dispatch(status.actions.setStatus(statusNames.BASKETS, status.constants.SUCCEEDED));
  } catch (e) {
    const error = await resolveServiceError(e);

    console.error(error);
    dispatch(status.actions.setStatus(statusNames.BASKETS, status.constants.FAILED));

    throw error;
  }

  return [responseData, count];
};

export default fetchBaskets;
