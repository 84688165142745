const getArray = (value) => (Array.isArray(value) ? value : [value]);

const getUpdatedBillingProfiles = (prevProfiles, config = {}) => {
  const { toAdd = [], toUpdate = [], toRemove = [] } = config;
  const listToAdd = getArray(toAdd);
  const listToUpdate = getArray(toUpdate);
  const listToRemove = getArray(toRemove);

  let newProfiles = [...prevProfiles, ...listToAdd];

  listToUpdate.forEach((updatedProfile) => {
    newProfiles = newProfiles.map((profile) =>
      profile.id === updatedProfile.id ? updatedProfile : profile
    );
  });

  listToRemove.forEach((removedProfile) => {
    newProfiles = newProfiles.filter(({ id }) => id !== removedProfile.id);
  });

  return newProfiles;
};

export default getUpdatedBillingProfiles;
