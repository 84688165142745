import { PER_YEAR } from 'src/js/modules/common/constants/terms';
import generatePaymentUrl from './generate-payment-url';

const generateAnnualPaymentUrl = (paymentParams, isB2C) =>
  generatePaymentUrl(
    {
      ...paymentParams,
      term: PER_YEAR,
      p1y_upgrade: true,
    },
    isB2C
  );

export default generateAnnualPaymentUrl;
