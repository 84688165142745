import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { ProgressDialog } from '@yola/ws-ui';

const getDefaultCaptions = () => ({
  title: i18next.t('Loading...'),
});

const DEFAULT_HEIGHT = 112;
const DEFAULT_WIDTH = 320;

function PendingDialog(props) {
  const { captions, height, width } = props;
  const dialogCaptions = useMemo(() => captions || getDefaultCaptions(), [captions]);

  return (
    <ProgressDialog
      overlay="visible"
      progressType="indeterminate"
      cancelable={false}
      captions={dialogCaptions}
      height={height}
      minHeight={height}
      width={width}
    />
  );
}

PendingDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
  }),
  height: PropTypes.number,
  width: PropTypes.number,
};

PendingDialog.defaultProps = {
  captions: null,
  height: DEFAULT_HEIGHT,
  width: DEFAULT_WIDTH,
};

export default PendingDialog;
