import React from 'react';
import PropTypes from 'prop-types';
import NavbarContainer from 'src/js/modules/navbar/containers/navbar-container';
import CopyrightFooterContainer from 'src/js/modules/copyright-footer/containers/copyright-footer-container';

function PageContainer(props) {
  const { children } = props;

  return (
    <div className="ws-page-container">
      <NavbarContainer />

      <main className="ws-page-content">{children}</main>

      <CopyrightFooterContainer />
    </div>
  );
}

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContainer;
