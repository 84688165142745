export default {
  PENDING: '@MYYOLA/DIALOG_TYPES/PENDING',
  DOMAIN_SEARCH_FAILURE: '@MYYOLA/DIALOG_TYPES/DOMAIN_SEARCH_FAILURE',
  DELETE_SITE: '@MYYOLA/DIALOG_TYPES/DELETE_SITE',
  ERROR: '@MYYOLA/DIALOG_TYPES/ERROR',
  DELETE_ACCOUNT: '@MYYOLA/DIALOG_TYPES/DELETE_ACCOUNT',
  CHANGE_PASSWORD: '@MYYOLA/DIALOG_TYPES/CHANGE_PASSWORD',
  PAYMENT_METHOD_FORM: '@MYYOLA/DIALOG_TYPES/PAYMENT_METHOD_FORM',
  SUBSCRIPTION_EXPIRED: '@MYYOLA/DIALOG_TYPES/SUBSCRIPTION_EXPIRED',
  DELETE_PAYMENT_METHOD: '@MYYOLA/DIALOG_TYPES/DELETE_PAYMENT_METHOD',
  DELETE_DEFAULT_PAYMENT_METHOD: '@MYYOLA/DIALOG_TYPES/DELETE_DEFAULT_PAYMENT_METHOD',
  SET_DEFAULT_PAYMENT_METHOD: '@MYYOLA/DIALOG_TYPES/SET_DEFAULT_PAYMENT_METHOD',
  CANCEL_PLAN: '@MYYOLA/DIALOG_TYPES/CANCEL_PLAN',
  DIFM_PROMO_FINISH: '@MYYOLA/DIALOG_TYPES/DIFM_PROMO_FINISH',
  DIFM_PROMO_SERVICES: '@MYYOLA/DIALOG_TYPES/DIFM_PROMO_SERVICES',
  DIFM_PROMO_SURVEY: '@MYYOLA/DIALOG_TYPES/DIFM_PROMO_SURVEY',
  DIFM_PROMO_PACKAGES: '@MYYOLA/DIALOG_TYPES/DIFM_PROMO_PACKAGES',
  TERMS_OF_USE_DIALOG: '@MYYOLA/DIALOG_TYPES/TERMS_OF_USE_DIALOG',
  STRIPE_LOADING_ERROR: '@MYYOLA/DIALOG_TYPES/STRIPE_LOADING_ERROR',
};
