const formatCardExpirationDate = (value) => {
  const dateMathes = value
    .replace(/\s+/g, '')
    .replace(/[^0-9]/gi, '')
    .substr(0, 4)
    .match(/\d{1,2}/g);

  return dateMathes ? dateMathes.join('/') : '';
};

export default formatCardExpirationDate;
