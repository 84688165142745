import React from 'react';

const SeoToolsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
    <path fill="#005ED9" fillOpacity=".03" d="M26 10.8h3.2v20H26v-20Z" />
    <path fill="#005ED9" d="M28.8 11.2v19.2h-2.4V11.2h2.4Zm.8-.8h-4v20.8h4V10.4Z" />
    <path fill="#005ED9" fillOpacity=".03" d="M16.4 14.8h3.2v16h-3.2v-16Z" />
    <path fill="#005ED9" d="M19.2 15.2v15.2h-2.4V15.2h2.4Zm.8-.8h-4v16.8h4V14.4Z" />
    <path fill="#005ED9" fillOpacity=".03" d="M11.6 20.4h3.2v10.4h-3.2V20.4Z" />
    <path fill="#005ED9" d="M14.4 20.8v9.6H12v-9.6h2.4Zm.8-.8h-4v11.2h4V20Z" />
    <path fill="#005ED9" fillOpacity=".03" d="M6.8 18H10v12.8H6.8V18Z" />
    <path fill="#005ED9" d="M9.6 18.4v12H7.2v-12h2.4Zm.8-.8h-4v13.6h4V17.6Z" />
    <path fill="#005ED9" fillOpacity=".03" d="M2 22h3.2v8.8H2V22Z" />
    <path fill="#005ED9" d="M4.8 22.4v8H2.4v-8h2.4Zm.8-.8h-4v9.6h4v-9.6Z" />
    <path fill="#005ED9" fillOpacity=".03" d="M21.2 17.2h3.2v13.6h-3.2V17.2Z" />
    <path
      fill="#005ED9"
      d="M24 17.6v12.8h-2.4V17.6H24Zm.8-.8h-4v14.4h4V16.8ZM28.395 3.266a.4.4 0 0 0-.33-.46l-3.55-.592a.4.4 0 1 0-.132.789l3.157.526-.526 3.156a.4.4 0 1 0 .789.132l.592-3.551ZM22.4 7.2l-.179.358.216.107.195-.14L22.4 7.2Zm-3.2-1.6.179-.358-.221-.11-.198.148.24.32Zm-6.4 4.8-.179.358.221.11.198-.148-.24-.32ZM9.6 8.8l.179-.358-.223-.112-.199.152.243.318Zm-7.043 4.882a.4.4 0 1 0 .486.636l-.486-.636Zm25.21-10.808-5.6 4 .465.651 5.6-4-.464-.65ZM22.58 6.842l-3.2-1.6-.358.716 3.2 1.6.358-.716ZM18.96 5.28l-6.4 4.8.48.64 6.4-4.8-.48-.64Zm-5.981 4.762-3.2-1.6-.358.716 3.2 1.6.358-.716Zm-3.622-1.56-6.8 5.2.486.636 6.8-5.2-.486-.636Z"
    />
  </svg>
);

export default SeoToolsIcon;
