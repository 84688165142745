import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import classNames from 'classnames';

const { Icon } = designSystem;

function IconStatus(props) {
  const { iconGlyph, iconType, children, align } = props;

  const classes = classNames('ws-icon-status', `ws-icon-status--${align}`);

  return (
    <div className={classes}>
      <Icon glyph={iconGlyph} type={iconType} />
      {children}
    </div>
  );
}

IconStatus.propTypes = {
  iconGlyph: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(['left', 'right', 'center']),
};

IconStatus.defaultProps = {
  align: 'left',
};

export default IconStatus;
