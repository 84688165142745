function getStripeAppearanceConfig() {
  const styles = window.getComputedStyle(document.documentElement);

  const getPropertyValue = (property, defaultValue) =>
    styles.getPropertyValue(property) || defaultValue;

  return {
    variables: {
      borderRadius: getPropertyValue('--token-C013--border-radius', '4px'),
      colorDanger: getPropertyValue('--token-error-text', '#c62828'),
      colorText: getPropertyValue('--token-C031--color-text--high-emphasis', '#141b21'),
      fontFamily: getPropertyValue('--font-family-base', '"Roboto", sans-serif'),
      fontLineHeight: '24px',
      fontSizeBase: getPropertyValue('--token-font-size-14', '14px'),
      fontWeightNormal: '400',
      gridRowSpacing: getPropertyValue('--token-C052--gap-m', '24px'),
      gridColumnSpacing: '16px',
    },
    rules: {
      '.Label': {
        color: getPropertyValue('--token-C031--color-text--high-emphasis', '#d32f2f'),
        fontWeight: getPropertyValue('--token-C030-font--heading-6__fontWeight', '500'),
        fontSize: getPropertyValue('--token-C030-font--heading-6__fontSize', '14px'),
        letterSpacing: getPropertyValue('--token-C030-font--heading-6__letterSpacing', '0'),
        lineHeight: getPropertyValue('--token-C030-font--heading-6__lineHeight', '24px'),
        marginBottom: getPropertyValue('--token-C027-title--padding--after', '8px'),
        textTransform: getPropertyValue('--token-C030-font--heading-6__textCase', 'none'),
        textDecoration: getPropertyValue('--token-C030-font--heading-6__textDecoration', 'none'),
      },
      '.Label--invalid': {},
      '.Input': {
        backgroundColor: getPropertyValue('--token-C013--color-bg--default', '#f7f8fa'),
        border: `${getPropertyValue('--token-C013--border-width', '1px')} solid ${getPropertyValue('--token-C013--color-border', '#dadde0')}`,
        borderRadius: getPropertyValue('--token-C013--border-radius', '4px'),
        color: getPropertyValue('--token-C013-user-text--color--default', '#141b21'),
        fontSize: getPropertyValue('--token-C013-placeholder--font__fontSize', '14px'),
        fontWeight: getPropertyValue('--token-C013-placeholder--font__fontWeight', '400'),
        letterSpacing: getPropertyValue('--token-C013-placeholder--font__letterSpacing', '0'),
        lineHeight: getPropertyValue('--token-C013-placeholder--font__lineHeight', '24px'),
        padding: `calc(${getPropertyValue('--token-C013--padding-top--small', '6px')} - ${getPropertyValue('--token-C013--border-width', '1px')}) calc(${getPropertyValue('--token-C013--padding-right--small', '12px')} - ${getPropertyValue('--token-C013--border-width', '1px')})`,
      },
      '.Input:hover': {
        backgroundColor: getPropertyValue('--token-C013--color-bg--hover', '#fbfcfd'),
      },
      '.Input:focus': {
        backgroundColor: getPropertyValue('--token-C013--color-bg--focus', '#f7f8fa'),
        color: getPropertyValue('--token-C013-user-text--color--focus', '#141b21'),
        border: `${getPropertyValue('--token-C013--border-width', '1px')} solid ${getPropertyValue('--token-C013--color-border--focus', '#005ed9')}`,
        boxShadow: `0 0 0 ${getPropertyValue('--token-C013--outline-width--focus', '2px')} ${getPropertyValue('--token-C013--color-outline--focus', 'rgba(0, 94, 217, 0.1)')}`,
      },
      '.Input:focus::placeholder': {
        color: getPropertyValue('--token-C013-placeholder--color--focus', '#8e959e'),
      },
      '.Input--invalid': {
        color: getPropertyValue('--token-C013-user-text--color--error', '#c62828'),
        backgroundColor: getPropertyValue('--token-C013--color-bg--error', '#f7f8fa'),
        border: `${getPropertyValue('--token-C013--border-width', '1px')} solid ${getPropertyValue('--token-C013--color-border--error', '#c62828')}`,
        boxShadow: 'none',
      },
      '.Input--invalid:hover': {
        backgroundColor: getPropertyValue('--token-C013--color-bg--hover', '#fbfcfd'),
      },
      '.Input--invalid:focus': {
        color: getPropertyValue('--token-C013-user-text--color--error-focus', '#141b21'),
        backgroundColor: getPropertyValue('--token-C013--color-bg--error', '#f7f8fa'),
        border: `${getPropertyValue('--token-C013--border-width', '1px')} solid ${getPropertyValue('--token-C013--color-border--error', '#c62828')}`,
        boxShadow: `0 0 0 ${getPropertyValue('--token-C013--outline-width--focus', '2px')} ${getPropertyValue('--token-C013--color-outline--error--focus', 'rgba(211, 47, 47, 0.1)')}`,
      },
      '.Input::placeholder': {
        color: getPropertyValue('--token-C013-placeholder--color--default', '#646c75'),
      },
      '.Error': {
        color: getPropertyValue('--token-C019--color-text--error', '#c62828'),
        fontWeight: getPropertyValue('--token-C019-text--font__fontWeight', '500'),
        fontSize: getPropertyValue('--token-C019-text--font__fontSize', '12px'),
        letterSpacing: getPropertyValue('--token-C019-text--font__letterSpacing', '0'),
        lineHeight: getPropertyValue('--token-C019-text--font__lineHeight', '20px'),
        marginTop: getPropertyValue('--token-C019--margin-top', '8px'),
      },
    },
  };
}

export default getStripeAppearanceConfig;
