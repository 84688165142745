import React from 'react';

const HotPriceIcon = () => (
  <svg width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="96" height="96" rx="48" fill="#F3E5EE" />
    <path
      d="M44.2986 18.8895C44.2737 18.8025 44.2598 18.6488 44.2598 18.5355C44.2598 17.6878 44.9475 17 45.7952 17C46.157 17 46.4085 17.1351 46.5079 17.1739C48.3849 17.9067 61.2742 23.9849 58.2933 41.5583C62.6218 38.95 61.0739 33.884 62.4899 33.884C63.0208 33.884 63.2397 34.0828 63.5642 34.5485C68.2995 41.3068 71.8859 49.3661 71.8859 56.9069C71.8875 69.6239 61.5801 79.9313 48.8646 79.9313C34.8823 79.9313 25.8418 68.1536 25.8418 56.9084C25.8418 38.2032 47.8865 34.7488 44.2986 18.8895Z"
      fill="url(#paint0_linear_1386_69153)"
    />
    <path
      opacity="0.05"
      d="M54.134 71.047C51.6469 71.047 48.9889 69.5193 48.5324 66.1906L46.2859 69.6388C45.8124 70.3685 45.0097 70.8032 44.1387 70.8032H42.3859C41.6857 70.8032 41.0429 70.4198 40.7091 69.8034C40.3737 69.1886 40.4032 68.4418 40.7883 67.8534L44.5004 62.1415C44.1185 62.2052 43.7242 62.2393 43.3174 62.2393C40.5864 62.2393 37.6475 60.3965 37.6475 56.3505C37.6475 52.6042 40.0477 50.0859 43.6217 50.0859C46.688 50.0859 48.7839 51.9475 49.1954 54.919L51.4202 51.4941C51.8968 50.7629 52.701 50.3266 53.5705 50.3266H55.3466C56.0468 50.3266 56.6896 50.7101 57.0233 51.3264C57.3571 51.9412 57.3277 52.688 56.9457 53.2764L53.218 59.0007C53.6108 58.9293 54.0207 58.8936 54.4415 58.8936C57.9238 58.8936 60.0881 61.1603 60.0881 64.8088C60.0834 68.4806 57.6366 71.047 54.134 71.047ZM54.2256 64.1319C54.2365 64.1536 54.1247 64.371 54.1247 65.0355C54.1247 65.686 54.2443 65.8552 54.2443 65.8552C54.311 65.8459 54.4477 65.6658 54.4477 64.9377C54.4477 64.4828 54.3592 64.2157 54.3033 64.1288C54.2753 64.1288 54.2443 64.1288 54.2256 64.1319ZM43.4354 55.3258C43.4447 55.3444 43.3298 55.5571 43.3298 56.2294C43.3298 56.8799 43.4509 57.0491 43.4509 57.0491C43.4928 57.0383 43.6295 56.8582 43.6295 56.13C43.6295 55.6565 43.572 55.4236 43.5348 55.3212C43.5021 55.3227 43.4587 55.3227 43.4354 55.3258Z"
      fill="black"
    />
    <path
      opacity="0.07"
      d="M54.1341 70.2698C51.6982 70.2698 49.2405 68.6878 49.2405 65.1557C49.2405 64.4881 49.3274 63.8655 49.4904 63.2973L45.6354 69.2156C45.3048 69.7233 44.7458 70.0261 44.1388 70.0261H42.3859C41.9699 70.0261 41.5895 69.7994 41.3908 69.433C41.192 69.0697 41.2107 68.6241 41.4373 68.2779L46.4894 60.5042C45.6479 61.1222 44.5704 61.4622 43.3175 61.4622C40.8815 61.4622 38.4238 59.8801 38.4238 56.3496C38.4238 53.0163 40.4639 50.8613 43.6218 50.8613C46.626 50.8613 48.4922 52.8377 48.4922 56.022C48.4922 56.6368 48.4223 57.2113 48.2872 57.7376L52.0708 51.9155C52.403 51.4063 52.9635 51.102 53.569 51.102H55.3451C55.7597 51.102 56.1416 51.3286 56.3388 51.6951C56.5375 52.0599 56.5189 52.5039 56.2922 52.8517L51.1579 60.7356C52.0227 60.0509 53.1405 59.6674 54.4384 59.6674C57.4877 59.6674 59.3088 61.5879 59.3088 64.8064C59.3073 68.0745 57.2284 70.2698 54.1341 70.2698ZM54.3282 63.3516C53.9851 63.3516 53.3485 63.3516 53.3485 65.0346C53.3485 66.5841 53.9308 66.5841 54.2801 66.5841C54.6092 66.5841 55.224 66.5841 55.224 64.9368C55.224 64.4602 55.1371 63.3516 54.3282 63.3516ZM43.5457 54.5455C43.1995 54.5455 42.5536 54.5455 42.5536 56.2285C42.5536 57.7764 43.1436 57.7764 43.4603 57.7764C43.7895 57.7764 44.4043 57.7764 44.4043 56.1292C44.4058 54.5455 43.8469 54.5455 43.5457 54.5455Z"
      fill="black"
    />
    <path
      d="M43.3162 60.6885C41.7435 60.6885 39.1973 59.8222 39.1973 56.3507C39.1973 53.3465 40.9843 51.6387 43.6189 51.6387C46.1729 51.6387 47.713 53.2595 47.713 56.0246C47.713 58.7897 46.0891 60.6885 43.3162 60.6885ZM55.6419 52.4305L44.9852 68.7929C44.7989 69.0801 44.4806 69.2524 44.139 69.2524H42.3862C42.1036 69.2524 41.9344 68.9403 42.0881 68.7028L52.7216 52.3404C52.9079 52.0532 53.2262 51.8809 53.5693 51.8809H55.3454C55.6264 51.8809 55.7956 52.1945 55.6419 52.4305ZM43.546 53.7703C42.366 53.7703 41.7776 54.5901 41.7776 56.2296C41.7776 57.7806 42.3396 58.5553 43.4621 58.5553C44.6079 58.5553 45.1824 57.748 45.1824 56.1318C45.1824 54.5575 44.6374 53.7703 43.546 53.7703ZM54.1344 69.4946C51.9546 69.4946 50.0155 68.0849 50.0155 65.1568C50.0155 62.2287 51.7963 60.4448 54.4372 60.4448C57.078 60.4448 58.5312 62.0532 58.5312 64.8059C58.5312 67.5601 56.9073 69.4946 54.1344 69.4946ZM54.3269 62.578C53.1563 62.578 52.571 63.3977 52.571 65.0372C52.571 66.5882 53.1408 67.363 54.2788 67.363C55.4246 67.363 55.999 66.5556 55.999 64.9394C55.999 63.7315 55.5379 62.578 54.3269 62.578Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1386_69153"
        x1="18.2149"
        y1="-32.3685"
        x2="105.929"
        y2="2.54676"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E7004F" />
        <stop offset="1" stopColor="#CA0A44" />
      </linearGradient>
    </defs>
  </svg>
);

export default HotPriceIcon;
