import { constants } from '@yola/subscription-manager-js';

const { ACTIVE, CANCELED, EXPIRED } = constants.SubscriptionStatus;

export const appearanceTypes = {
  SUCCESS: 'success',
  DANGER: 'danger',
  MEDIUM_EMPHASIS: 'medium-emphasis',
  HIGH_EMPHASIS: 'high-emphasis',
  CTA: 'cta',
  WARNING: 'warning',
};

export const statusToAppearance = {
  [ACTIVE]: appearanceTypes.SUCCESS,
  [CANCELED]: appearanceTypes.DANGER,
  [EXPIRED]: appearanceTypes.MEDIUM_EMPHASIS,
};

export const subscriptionIcons = {
  NEXT: 'next',
  SELECTED: 'selected',
};
