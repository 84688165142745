import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { ActionButton } = designSystem;

const UpgradeButton = ({ label, ...props }) => (
  <div className="ws-my-navbar__upgrade-btn">
    <ActionButton
      {...props}
      label={label}
      iconPlacement="before"
      iconGlyph={null}
      format="solid"
      size="small"
      appearance="cta"
    />
  </div>
);

UpgradeButton.propTypes = {
  label: PropTypes.string.isRequired,
};

export default UpgradeButton;
