import React from 'react';
import PropTypes from 'prop-types';

const Nav = ({ children }) => <nav className="ws-my-navbar__nav">{children}</nav>;

Nav.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Nav;
