import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Box, Paragraph, Divider } = designSystem;

const BillingsListHeader = ({ items }) => (
  <React.Fragment>
    <Box paddingBottom="spacing-2-xs">
      <div className="ws-billing-header__content">
        {items.map((item, index) => {
          const isLastItem = index === items.length - 1;
          return (
            <Paragraph
              key={item}
              size="small"
              appearance="medium-emphasis"
              {...(isLastItem && { align: 'right' })}
            >
              {item}
            </Paragraph>
          );
        })}
      </div>
    </Box>
    <Divider />
  </React.Fragment>
);

BillingsListHeader.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BillingsListHeader;
