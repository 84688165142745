import React, { Fragment, useMemo, useEffect } from 'react';
import { designSystem } from '@yola/ws-ui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import get from 'lodash.get';
import i18next from 'i18next';
import { constants, selectors, verifiers } from '@yola/subscription-manager-js';
import status from 'src/js/modules/status';
import redirectToUrl from 'src/js/utils/redirect-to-url';
import routesMap from '../../../router/helpers/routes-map';
import features from '../../features';
import segment from '../../analytics/segment';
import subscriptions from '../../subscriptions';
import user from '../../user';
import products from '../../products';
import utils from '../../webapp-plugins/utils';
import useQuery from '../../../common/hooks/use-query';
import useSupportedSearchParams from '../../../router/hooks/use-supported-search-params';
import ErrorNotificationContainer from '../../../common/containers/error-notification-container';
import SubscriptionItemContainer from './subscription-item-container';
import SubscriptionPromo from '../components/subscription-promo';
import SubscriptionsListHeader from '../components/subscriptions-list-header';
import getSubscriptionPromoItems from '../helpers/get-subscription-promo-items';
import useActiveUserSubscriptions from '../hooks/use-active-user-subscriptions';
import supportedTabs from '../constants/supported-tabs';
import supportedSearchParams from '../../common/constants/supported-search-params';

const { Stack, Box } = designSystem;
const {
  constants: { triggerIds, events },
  track,
  trackAsync,
  trackers: { trackEvent },
} = segment;
const {
  hostingPackageFeatureNames: featureNames,
  SubscriptionStatus: { CANCELED },
} = constants;

const getCaptions = () => ({
  headerItems: [i18next.t('Subscription name'), i18next.t('Status')],
  promo: {
    title: i18next.t('Grow your business online with us'),
    upgradeButton: i18next.t('Upgrade now'),
  },
});

const SubscriptionsListContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const captions = getCaptions();

  const availablePlatformComponents = user.hooks.useAvailablePlatformComponents();
  const availablePackages = useSelector(selectors.getHostingPackages, shallowEqual);
  const reactivationStatus = useSelector(subscriptions.selectors.getSubscriptionReactivationStatus);
  const isB2C = useSelector(user.selectors.getIsB2C);
  const availablePackagesStatus = useSelector(features.selectors.getAvailablePackagesStatus);
  const ecommercePackagesStatus = useSelector(features.selectors.getEcommercePackagesStatus);
  const subscriptionsStatus = useSelector(subscriptions.selectors.getSubscriptionsStatus);

  const userSubscriptions = useActiveUserSubscriptions();

  const currentLocation = window.location.href;
  const serchParams = useSupportedSearchParams() || {};
  const { [supportedSearchParams.RESCHEDULE_SUBSCRIPTION]: subscriptionToReschedule } = serchParams;

  const hasCriticalError = [
    availablePackagesStatus,
    ecommercePackagesStatus,
    subscriptionsStatus,
  ].includes(status.constants.FAILED);
  const hasNonCriticalError = reactivationStatus === status.constants.FAILED;

  const hasFreeDomainPromo = useMemo(
    () =>
      Object.values(availablePackages).some(
        ({ features: packageFeatures }) => packageFeatures[featureNames.FREE_DOMAIN_PROMO]
      ),
    [availablePackages]
  );

  const promoItems = useMemo(
    () => getSubscriptionPromoItems(availablePlatformComponents, hasFreeDomainPromo),
    [availablePlatformComponents, hasFreeDomainPromo]
  );

  const { data: paymentMethods } = useQuery({
    name: products.statusNames.BILLING_PROFILES,
    thunk: products.thunks.fetchBillingProfiles,
    selector: products.selectors.getBillingProfiles,
  });

  const getAnalyticsParams = (type, term) => {
    const isHostingSubscription = verifiers.isHostingPackageType(type);

    return {
      triggerId: triggerIds.SUBSCRIPTION_NOTIFICATION,
      type,
      term,
      category: isHostingSubscription ? triggerIds.HOSTING : triggerIds.ONLINE_STORE,
    };
  };

  const redirectToPaywall = () => {
    track(events.UPGRADE_TRIGGER_CLICKED, {
      triggerId: triggerIds.SUBSCRIPTIONS,
    });
    dispatch(
      push(
        routesMap.paywall.url({
          query: { triggerId: triggerIds.SUBSCRIPTIONS, paymentBackUrl: currentLocation },
        })
      )
    );
  };

  const redirectToSubscriptionDetails = (subscriptionId) => {
    const subscriptionDetailsUrl = routesMap.subscriptionDetails.url({ subId: subscriptionId });
    dispatch(push(subscriptionDetailsUrl));
  };

  const renewSubscription = async (type, term, subscriptionStatus) => {
    const paymentUrl = utils.generatePaymentUrl(
      { products: [type], term, backUrl: currentLocation },
      isB2C
    );
    const params = getAnalyticsParams(type, term);

    await trackAsync(events.RENEW_TRIGGER_CLICKED, { ...params, status: subscriptionStatus });
    redirectToUrl(paymentUrl);
  };

  const clearError = () => {
    dispatch(status.actions.resetStatus(subscriptions.statusNames.REACTIVATE_SUBSCRIPTION));
  };

  const reactivateSubscription = (id, type, term) => {
    const params = getAnalyticsParams(type, term);
    const isHostingSubscription = verifiers.isHostingPackageType(type);

    track(events.REACTIVATE_TRIGGER_CLICKED, params);

    if (!isHostingSubscription) {
      const hostingSubscription = userSubscriptions.find(({ isEcommerce }) => !isEcommerce);
      const { subscriptionMeta: hostingSubscriptionMeta } = hostingSubscription || {};

      if (hostingSubscriptionMeta && hostingSubscriptionMeta.status === CANCELED) {
        dispatch(subscriptions.thunks.reactivateSubscription(hostingSubscriptionMeta.id));
      }
    }

    dispatch(subscriptions.thunks.reactivateSubscription(id));
  };

  const rescheduleSubscription = async (id, type, term, subscriptionStatus) => {
    const params = getAnalyticsParams(type, term);
    track(events.AUTO_RENEW_TURN_ON_TRIGGER_CLICKED, { ...params, status: subscriptionStatus });

    if (paymentMethods && paymentMethods.length) {
      await dispatch(subscriptions.thunks.rescheduleSubscription(id));

      return;
    }

    const closeUrl = routesMap.accountManagement.url({ tabId: supportedTabs.SUBSCRIPTIONS });
    const successUrl = `${closeUrl}?${supportedSearchParams.RESCHEDULE_SUBSCRIPTION}=${id}`;

    dispatch(
      push(routesMap.addPaymentMethod.url(), {
        triggerId: triggerIds.RESCHEDULE_SUBSCRIPTION,
        closeUrl,
        successUrl,
        closeState: {
          triggerId: triggerIds.RESCHEDULE_SUBSCRIPTION,
        },
      })
    );
  };

  useEffect(() => {
    if (subscriptionToReschedule) {
      dispatch(subscriptions.thunks.rescheduleSubscription(subscriptionToReschedule));
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [subscriptionToReschedule]);

  useEffect(() => {
    trackEvent(events.SUBSCRIPTIONS_PAGE_DISPLAYED, {
      triggerId: get(location, 'state.triggerId', triggerIds.ACCOUNT_SETTINGS),
      subscriptionAutoRenew: subscriptions.accessors.getHostingAutoRenewStatus(),
      onlineStoreAutoRenew: subscriptions.accessors.getEcommerceAutoRenewStatus(),
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  if (hasCriticalError) {
    return <ErrorNotificationContainer />;
  }

  return (
    <Fragment>
      {hasNonCriticalError && (
        <Box marginBottom="spacing-2-xs">
          <ErrorNotificationContainer onClose={clearError} />
        </Box>
      )}
      {userSubscriptions.length ? (
        <Fragment>
          <SubscriptionsListHeader items={captions.headerItems} />
          <Stack gap="spacing-2-xs">
            {userSubscriptions.map(({ name, isEcommerce, subscriptionMeta }) => (
              <SubscriptionItemContainer
                key={subscriptionMeta.id}
                name={name}
                subscriptionMeta={subscriptionMeta}
                isEcommerce={isEcommerce}
                onClick={redirectToSubscriptionDetails}
                onReactivate={reactivateSubscription}
                onRenew={renewSubscription}
                onReschedule={rescheduleSubscription}
              />
            ))}
          </Stack>
        </Fragment>
      ) : (
        <SubscriptionPromo
          captions={captions.promo}
          items={promoItems}
          onSubmit={redirectToPaywall}
        />
      )}
    </Fragment>
  );
};

export default SubscriptionsListContainer;
