import get from 'lodash.get';
import getIsB2C from './is-b2c';

const getLogoutUrl = (rootState) => {
  if (getIsB2C(rootState)) {
    return get(rootState, 'config.common.auth-gateway.logout_url', '');
  }

  return get(rootState, 'config.common.auth-gateway.logout_wl_url', '');
};

export default getLogoutUrl;
