import React from 'react';
import PropTypes from 'prop-types';
import { designSystem, Modal } from '@yola/ws-ui';
import PasswordInput from './password-input';

const {
  Box,
  ActionButton,
  DialogHeader,
  DialogHeaderTitle,
  ControlGroup,
  ModalButtonGroup,
  Divider,
  Stack,
} = designSystem;

const MODAL_WIDTH = 336;

function ChangePasswordDialog(props) {
  const { onSubmit, onCancel, onKeyDown, isLoading, passwordInputs, captions, modalContentRef } =
    props;

  return (
    <Modal
      width={MODAL_WIDTH}
      maxWidth={MODAL_WIDTH}
      minWidth={MODAL_WIDTH}
      height="auto"
      centered
      resizable={false}
      draggable={false}
      overlay="visible"
      className="ws-change-password-modal"
      isBodyScrollDisabled
      modalContentRef={modalContentRef}
      onKeyDown={onKeyDown}
    >
      <DialogHeader>
        <DialogHeaderTitle>{captions.title}</DialogHeaderTitle>
      </DialogHeader>
      <Divider />
      <Box
        margintTop="spacing-s"
        margintBottom="spacing-s"
        margintLeft="spacing-m"
        margintRight="spacing-m"
      >
        <Stack gap="spacing-xs">
          {passwordInputs.map(({ title, ...input }) => (
            <ControlGroup key={input.name} title={title}>
              <PasswordInput {...input} />
            </ControlGroup>
          ))}
        </Stack>
      </Box>
      <ModalButtonGroup direction="right">
        <ActionButton
          label={captions.submit}
          appearance="accent"
          format="solid"
          isLoading={isLoading}
          onClick={onSubmit}
        />
        <ActionButton label={captions.cancel} onClick={onCancel} format="outline" />
      </ModalButtonGroup>
    </Modal>
  );
}

ChangePasswordDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
  }).isRequired,
  passwordInputs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      control: PropTypes.object.isRequired,
      isVisible: PropTypes.bool.isRequired,
      onVisibilityToggle: PropTypes.func.isRequired,
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  notification: PropTypes.shape({
    title: PropTypes.string,
    appearance: PropTypes.string,
  }),
  modalContentRef: PropTypes.object.isRequired,
  onKeyDown: PropTypes.func,
};

ChangePasswordDialog.defaultProps = {
  notification: null,
  onKeyDown: Function.prototype,
};

export default ChangePasswordDialog;
