import serviceClient from '../service-client';
import {
  camelCaseObjectToSnakeCase,
  snakeCaseObjectToCamelCase,
} from '../../../utils/convert-case';
import statusNames from '../constants/status-names';
import status from '../../status';
import selectors from '../selectors';
import actions from '../actions';
import getUpdatedBillingProfiles from '../helpers/get-updated-billing-profiles';
import resolveServiceError from '../../../common/helpers/resolve-service-error';

const handleBillingProfilesUpdate = (createdProfile, dispatch, state) => {
  const currentProfiles = selectors.getBillingProfiles(state);

  const prevDefaultProfile = currentProfiles.find(({ isDefault }) => isDefault);

  const newProfiles = getUpdatedBillingProfiles(currentProfiles, {
    toAdd: createdProfile,
    ...(createdProfile.isDefault &&
      !!prevDefaultProfile && { toUpdate: [{ ...prevDefaultProfile, isDefault: false }] }),
  });
  dispatch(actions.setBillingProfiles(newProfiles));
};

const createBillingProfile = (data) => async (dispatch, getState) => {
  const state = getState();
  const yoproducts = serviceClient.get();
  const formattedData = camelCaseObjectToSnakeCase(data);
  let responseData;

  dispatch(status.actions.setStatus(statusNames.CREATE_BILLING_PROFILE, status.constants.LOADING));

  try {
    const response = await yoproducts.requests.createBillingProfile(formattedData);
    responseData = snakeCaseObjectToCamelCase(response);

    dispatch(
      status.actions.setStatus(statusNames.CREATE_BILLING_PROFILE, status.constants.SUCCEEDED)
    );

    const billingProfilesStatus = selectors.getBillingProfilesStatus(state);

    if (billingProfilesStatus === status.constants.SUCCEEDED) {
      handleBillingProfilesUpdate(responseData, dispatch, state);
    }
  } catch (e) {
    const error = await resolveServiceError(e);

    console.error(error);
    dispatch(status.actions.setStatus(statusNames.CREATE_BILLING_PROFILE, status.constants.FAILED));

    throw error;
  }

  return responseData;
};

export default createBillingProfile;
