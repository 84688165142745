import actionTypes from '../constants/action-types';

const setBillingProfiles = (billingProfiles) => ({
  type: actionTypes.SET_BILLING_PROFILES,
  payload: {
    billingProfiles,
  },
});

export default setBillingProfiles;
