import actionTypes from '../constants/action-types';

const hide = (modalProps) => ({
  type: actionTypes.HIDE_DIALOG,
  payload: {
    modalProps,
  },
});

export default hide;
