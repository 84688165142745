import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import throttle from 'lodash.throttle';
import { MultipurposePaywall } from '@yola/webapp-plugins';
import { selectors, verifiers, constants } from '@yola/subscription-manager-js';
import redirectToUrl from 'src/js/utils/redirect-to-url';
import useBackButton from 'src/js/router/hooks/use-back-button';
import useMinEcommercePlan from '../hooks/use-min-ecommerce-plan';
import segment from '../../analytics/segment';
import user from '../../user';
import dialogs from '../../dialogs';
import {
  ecommercePremiumFeatures,
  premiumFeatures,
  productCategories,
  purchaseFlowTypes,
} from '../../common/constants/premium-features';
import { PERIOD_VALUES } from '../../common/constants/terms';
import { DEFAULT_INITIATOR } from '../../common/constants';
import usePaymentMethods from '../hooks/use-payment-methods';
import useEcommerceTermConfig from '../hooks/use-ecommerce-term-config';

const {
  constants: { events },
  track,
  trackAsync,
} = segment;

const { B2C_ECOMMERCE_FREE, WL_ECOMMERCE_FREE } = constants.SubscriptionType;

function EcommercePaywallContainer({
  initiator,
  periodToggle,
  extraProducts,
  minRequiredPlan,
  term,
  showHostingSelector,
  backUrl,
  paymentBackUrl,
}) {
  const currentHostingPlanType = useSelector(selectors.getCurrentPackageType);
  const activeEcommercePlanType = useSelector(selectors.getActiveEcommerceSubscriptionType);
  const { currency } = useSelector(user.selectors.getUserPreferences, shallowEqual);
  const { id: userId } = useSelector(user.selectors.getUserData, shallowEqual);
  const availablePlans = useSelector(selectors.getEcommercePlans, shallowEqual);
  const paymentMethods = usePaymentMethods();
  const partnerData = useSelector(user.selectors.getPartnerData, shallowEqual);
  const moneyBackGuarantee = useSelector(user.selectors.getMoneyBackGuarantee);
  const { backButtonLabel, onBackButtonClick } = useBackButton(backUrl);
  const hasExtraProducts = Boolean(extraProducts.length);
  const isB2C = useSelector(user.selectors.getIsB2C);
  const minPlan = useMinEcommercePlan(minRequiredPlan);
  const termConfig = useEcommerceTermConfig(term, periodToggle);
  const FREE_ECOMMERCE_PLAN = isB2C ? B2C_ECOMMERCE_FREE : WL_ECOMMERCE_FREE;
  const captions = {
    backButtonLabel,
    description: {
      first: i18next.t('Choose the subscription that fits your needs'),
    },
  };

  const dispatch = useDispatch();

  const trackingData = useMemo(
    () => ({
      siteId: null,
      templateBuildSlug: null,
      premiumFeature: premiumFeatures.ECOMMERCE,
      ecommercePremiumFeature: ecommercePremiumFeatures.DEFAULT,
      purchaseFlowType: isB2C ? purchaseFlowTypes.B2C : purchaseFlowTypes.WL,
      productCategory: productCategories.ECOMMERCE,
    }),
    [isB2C]
  );

  const paywallProps = useMemo(
    () => ({
      initiator: initiator || DEFAULT_INITIATOR,
      defaultPaymentPeriod: PERIOD_VALUES[termConfig.term],
      ...(!termConfig.annualAvailable && { periodTogglerHidden: true }),
      ...(hasExtraProducts && { products: extraProducts }),
      ...(paymentBackUrl && {
        closeUrl: paymentBackUrl,
        successUrl: paymentBackUrl,
        backUrl: paymentBackUrl,
        failUrl: paymentBackUrl,
      }),
    }),
    [
      initiator,
      termConfig.term,
      termConfig.annualAvailable,
      hasExtraProducts,
      extraProducts,
      paymentBackUrl,
    ]
  );

  const handleBackButtonClick = async () => {
    await trackAsync(events.PLAN_SELECTOR_BACK_BUTTON_CLICKED, trackingData);

    dispatch(dialogs.actions.hide());
    onBackButtonClick();
  };

  const handlePeriodChange = (selectedPlan, selectedTerm) => {
    track(events.PLAN_SELECTOR_TERM_TOGGLE_CLICKED, {
      ...trackingData,
      selectedTerm,
    });
  };

  const handleTooltipHover = (featureTooltip) => {
    track(events.PLAN_SELECTOR_TOOLTIP_DISPLAYED, {
      ...trackingData,
      featureTooltip,
    });
  };

  useEffect(() => {
    track(events.PLAN_SELECTOR_DISPLAYED, trackingData);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const throttledPageScroll = throttle(() => {
      const documentHeight = document.body.offsetHeight;
      const currentScroll = window.scrollY + window.innerHeight;

      if (currentScroll >= documentHeight) {
        track(events.PLAN_SELECTOR_SCROLLED_TO_BOTTOM, trackingData);
      }
    }, 100);

    document.addEventListener('scroll', throttledPageScroll);

    return () => document.removeEventListener('scroll', throttledPageScroll);
  }, [trackingData]);

  const handleSelect = async (paymentUrl, selectedPlan, selectedTerm) => {
    dispatch(dialogs.actions.show(dialogs.dialogTypes.PENDING));

    await trackAsync(events.PLAN_SELECTOR_PLAN_SELECT_BUTTON_CLICKED, {
      ...trackingData,
      selectedTerm,
      selectedPlan,
    });

    if (verifiers.isFreeHostingPackageType(currentHostingPlanType)) {
      dispatch(dialogs.actions.hide());
      showHostingSelector(selectedPlan, selectedTerm);

      return;
    }

    redirectToUrl(paymentUrl);
  };

  return (
    <MultipurposePaywall
      captions={captions}
      view="content"
      currentPlan={activeEcommercePlanType || FREE_ECOMMERCE_PLAN}
      disabledPlans={activeEcommercePlanType !== FREE_ECOMMERCE_PLAN ? [FREE_ECOMMERCE_PLAN] : []}
      userId={userId}
      currency={currency}
      onSelect={handleSelect}
      onBackButtonClick={handleBackButtonClick}
      onTooltipHover={handleTooltipHover}
      onPeriodChange={handlePeriodChange}
      minRequiredPlan={minPlan}
      isB2C={isB2C}
      availablePlans={availablePlans}
      moneyBackGuarantee={moneyBackGuarantee}
      paymentMethods={paymentMethods}
      partnerId={partnerData.id}
      partnerName={partnerData.name}
      {...paywallProps}
    />
  );
}

EcommercePaywallContainer.propTypes = {
  initiator: PropTypes.string,
  domain: PropTypes.string,
  extraProducts: PropTypes.arrayOf(PropTypes.string),
  minRequiredPlan: PropTypes.string,
  term: PropTypes.string,
  backUrl: PropTypes.string,
  showHostingSelector: PropTypes.func.isRequired,
  periodToggle: PropTypes.bool,
  paymentBackUrl: PropTypes.string,
};

EcommercePaywallContainer.defaultProps = {
  initiator: '',
  domain: '',
  extraProducts: [],
  minRequiredPlan: '',
  term: '',
  backUrl: '',
  periodToggle: true,
  paymentBackUrl: '',
};

export default EcommercePaywallContainer;
