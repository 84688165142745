import get from 'lodash.get';
import getIsB2C from './is-b2c';

const getLatitudeTemplateGalleryUrl = (rootState) => {
  if (getIsB2C(rootState)) {
    return get(rootState, 'config.common["yola-template-gallery"].url');
  }

  return get(rootState, 'config.common["yola-template-gallery"].wl_url');
};

export default getLatitudeTemplateGalleryUrl;
