import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import i18next from 'i18next';
import user from 'src/js/modules/user';
import config from 'src/js/modules/config';
import legacyUrlsMap from 'src/js/router/helpers/legacy-urls-map';
import useAvailablePlatformComponents from 'src/js/modules/user/hooks/use-available-platform-components';
import MainMenu from 'src/js/common/components/main-menu';
import routesMap from '../../../router/helpers/routes-map';
import supportedAccountTabs from '../../account-management/constants/supported-tabs';
import segment from '../../analytics/segment';

const { platformComponents } = user.constants;
const {
  constants: { triggerIds },
} = segment;

function AccountSettingsContainer() {
  const availablePlatformComponents = useAvailablePlatformComponents();
  const logoutUrl = useSelector(config.selectors.getLogoutUrl);
  const userData = useSelector(user.selectors.getUserData);
  const { name, surname, email: userEmail } = userData;
  const userName = `${name} ${surname}`;
  const dispatch = useDispatch();

  const internalRedirectCreator = useCallback(
    (url, state) => (e) => {
      e.preventDefault();
      dispatch(push(url, state));
    },
    [dispatch]
  );

  const items = useMemo(() => {
    const menuItems = [];

    if (availablePlatformComponents.includes(platformComponents.AUTH)) {
      menuItems.push(
        {
          title: userName,
          description: userEmail,
          strong: true,
          noninteractive: true,
        },
        {
          type: 'divider',
        },
        {
          title: i18next.t('Profile'),
          href: routesMap.profile.url(),
          onClick: internalRedirectCreator(routesMap.profile.url(), {
            triggerId: triggerIds.ACCOUNT_SETTINGS,
          }),
        }
      );
    }

    if (availablePlatformComponents.includes(platformComponents.PAYMENTS)) {
      menuItems.push(
        {
          title: i18next.t('Subscriptions'),
          href: routesMap.accountManagement.url({ tabId: supportedAccountTabs.SUBSCRIPTIONS }),
          onClick: internalRedirectCreator(
            routesMap.accountManagement.url({ tabId: supportedAccountTabs.SUBSCRIPTIONS }),
            {
              triggerId: triggerIds.ACCOUNT_SETTINGS,
            }
          ),
        },
        {
          title: i18next.t('Billing history'),
          href: routesMap.accountManagement.url({ tabId: supportedAccountTabs.BILLING_HISTORY }),
          onClick: internalRedirectCreator(
            routesMap.accountManagement.url({ tabId: supportedAccountTabs.BILLING_HISTORY }),
            {
              triggerId: triggerIds.ACCOUNT_SETTINGS,
            }
          ),
        },
        {
          title: i18next.t('Payment methods'),
          href: routesMap.accountManagement.url({ tabId: supportedAccountTabs.PAYMENT_METHODS }),
          onClick: internalRedirectCreator(
            routesMap.accountManagement.url({ tabId: supportedAccountTabs.PAYMENT_METHODS }),
            {
              triggerId: triggerIds.ACCOUNT_SETTINGS,
            }
          ),
        }
      );
    }

    if (availablePlatformComponents.includes(platformComponents.AUTH)) {
      menuItems.push(
        {
          title: i18next.t('Language'),
          href: legacyUrlsMap.language(),
        },
        {
          type: 'divider',
        },
        {
          title: i18next.t('Logout'),
          href: logoutUrl,
        }
      );
    }

    return menuItems;
  }, [availablePlatformComponents, logoutUrl, userName, userEmail, internalRedirectCreator]);

  if (!items.length) {
    return null;
  }

  return (
    <MainMenu
      captions={{
        name: i18next.t('Account settings'),
      }}
      className="ws-mainmenu--account-settings"
      items={items}
    />
  );
}

export default AccountSettingsContainer;
