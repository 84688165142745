import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { reactHookForm } from '@yola/ws-ui';
import user from 'src/js/modules/user';
import segment from 'src/js/modules/analytics/segment';
import sectionIds from 'src/js/modules/profile/constants/section-ids';
import EmailSubscriptionsSection from 'src/js/modules/profile/components/email-subscriptions-section';
import getEmailSubscriptionOptions, {
  emailSubscriptionFieldNames,
  CHECKBOX_GROUP_NAME,
} from '../constants/email-subscription-options';
import { appearanceTypes } from '../constants/common';

const {
  constants: { events },
  track,
} = segment;

const { useForm } = reactHookForm;

const BOOLEAN_VALUES = {
  TRUE: 'True',
  FALSE: 'False',
};

const preferenceNameToTraitMap = {
  [emailSubscriptionFieldNames.PRODUCT_UPDATES]: 'emailProductUpdates',
  [emailSubscriptionFieldNames.SPECIAL_OFFERS]: 'emailSpecialOffers',
  [emailSubscriptionFieldNames.SURVEYS]: 'emailSurveys',
  [emailSubscriptionFieldNames.WELCOME_SERIES]: 'emailTipsAndBestPractices',
  [emailSubscriptionFieldNames.UNSUBSCRIBE_ALL]: 'unsubscribeFromAll',
};

const CURRENT_SECTION_ID = sectionIds.EMAIL_SUBSCRIPTIONS_INFO;

const getCaptions = () => ({
  title: i18next.t('Email subscriptions'),
  saveButton: i18next.t('Save'),
  messages: {
    successMessage: i18next.t('Successfully updated notification preferences'),
    errorMessage: i18next.t('Something went wrong'),
  },
});

function EmailSubscriptionsContainer({ notification, setNotification }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const userPreferences = useSelector(user.selectors.getUserPreferences);
  const captions = getCaptions();

  const emailSubscriptionOptions = useMemo(getEmailSubscriptionOptions, []);

  const initialValue = useMemo(() => {
    const checkedOptions = emailSubscriptionOptions
      .map(({ value }) => value)
      .filter((preferenceName) => userPreferences[preferenceName] === BOOLEAN_VALUES.TRUE);

    return checkedOptions.length ? checkedOptions : [emailSubscriptionFieldNames.UNSUBSCRIBE_ALL];
  }, [emailSubscriptionOptions, userPreferences]);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      [CHECKBOX_GROUP_NAME]: initialValue,
    },
  });

  const handleChange = (checkedOptions, isChecked, changedOption) => {
    if (checkedOptions.length === 0) {
      setValue(CHECKBOX_GROUP_NAME, [emailSubscriptionFieldNames.UNSUBSCRIBE_ALL]);
      return;
    }

    if (changedOption === emailSubscriptionFieldNames.UNSUBSCRIBE_ALL && isChecked) {
      setValue(CHECKBOX_GROUP_NAME, [emailSubscriptionFieldNames.UNSUBSCRIBE_ALL]);
      return;
    }

    if (changedOption === emailSubscriptionFieldNames.UNSUBSCRIBE_ALL && !isChecked) {
      const newOptions = emailSubscriptionOptions
        .map(({ value }) => value)
        .filter((value) => value !== emailSubscriptionFieldNames.UNSUBSCRIBE_ALL);
      setValue(CHECKBOX_GROUP_NAME, newOptions);
      return;
    }

    if (checkedOptions.includes(emailSubscriptionFieldNames.UNSUBSCRIBE_ALL)) {
      const newOptions = checkedOptions.filter(
        (value) => value !== emailSubscriptionFieldNames.UNSUBSCRIBE_ALL
      );
      setValue(CHECKBOX_GROUP_NAME, newOptions);
    }
  };

  const onSubmit = async (data) => {
    const { [CHECKBOX_GROUP_NAME]: checkedOptions } = data;
    setIsLoading(true);

    const analyticTraits = emailSubscriptionOptions.reduce((acc, { value }) => {
      const traitName = preferenceNameToTraitMap[value];
      acc[traitName] = checkedOptions.includes(value);
      return acc;
    }, {});

    const updatedPreferences = emailSubscriptionOptions
      .filter(({ value }) => value !== emailSubscriptionFieldNames.UNSUBSCRIBE_ALL)
      .reduce((acc, { value }) => {
        acc[value] = checkedOptions.includes(value) ? BOOLEAN_VALUES.TRUE : BOOLEAN_VALUES.FALSE;
        return acc;
      }, {});

    try {
      await dispatch(user.thunks.updateUserPreferences(updatedPreferences));

      setNotification({
        sectionId: CURRENT_SECTION_ID,
        title: captions.messages.successMessage,
        appearance: appearanceTypes.SUCCESS,
      });

      track(events.PROFILE_PAGE_EMAIL_SUBSCRIPTION_UPDATED, analyticTraits);
    } catch (e) {
      setNotification({
        sectionId: CURRENT_SECTION_ID,
        title: captions.messages.errorMessage,
        appearance: appearanceTypes.DANGER,
      });

      track(events.PROFILE_PAGE_ERROR_DISPLAYED, {
        section: sectionIds.EMAIL_SUBSCRIPTIONS_INFO,
      });
    }

    setIsLoading(false);
  };

  return (
    <EmailSubscriptionsSection
      control={control}
      captions={captions}
      options={emailSubscriptionOptions}
      isLoading={isLoading}
      onSubmit={handleSubmit(onSubmit)}
      onChange={handleChange}
      {...(notification?.sectionId === CURRENT_SECTION_ID && {
        notification,
      })}
    />
  );
}

EmailSubscriptionsContainer.propTypes = {
  notification: PropTypes.shape({
    sectionId: PropTypes.string,
    title: PropTypes.string,
    appearance: PropTypes.string,
  }),
  setNotification: PropTypes.func.isRequired,
};

EmailSubscriptionsContainer.defaultProps = {
  notification: null,
};

export default EmailSubscriptionsContainer;
