import actionTypes from '../constants/action-types';

const criticalError = (state = null, action) => {
  if (action.type === actionTypes.SET_CRITICAL_ERROR) {
    return action.payload.error;
  }

  return state;
};

export default criticalError;
