import { useSelector } from 'react-redux';
import urlJoin from 'url-join';
import config from 'src/js/modules/config';

const useStaticAsset = (resource) => {
  const staticPrefix = useSelector(config.selectors.getStaticPrefix);

  return urlJoin(staticPrefix, resource);
};

export default useStaticAsset;
