import { batch } from 'react-redux';
import yousersjs from '@yola/yousersjs';
import status from 'src/js/modules/status';
import statusNames from 'src/js/modules/user/constants/status-names';
import { camelCaseObjectToSnakeCase } from 'src/js/utils/convert-case';
import resolveServiceError from 'src/js/common/helpers/resolve-service-error';

const changePassword = (oldPassword, newPassword) => async (dispatch) => {
  try {
    dispatch(status.actions.setStatus(statusNames.CHANGE_PASSWORD, status.constants.LOADING));
    await yousersjs.requests.changePassword(
      camelCaseObjectToSnakeCase({ oldPassword, newPassword })
    );

    batch(() => {
      dispatch(status.actions.setStatus(statusNames.CHANGE_PASSWORD, status.constants.SUCCEEDED));
    });
  } catch (e) {
    const error = await resolveServiceError(e);
    dispatch(status.actions.setStatus(statusNames.CHANGE_PASSWORD, status.constants.FAILED));
    throw error;
  }
};

export default changePassword;
