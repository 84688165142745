import actionTypes from '../constants/action-types';

const prices = (state = {}, action) => {
  if (action.type === actionTypes.SET_PRICES) {
    return action.payload.prices;
  }

  return state;
};

export default prices;
