import i18next from 'i18next';
import statusTypes from '../constants/payment-method-status-types';
import cardTypes from '../constants/card-types';

const MONTH_IN_MS = 31 * 24 * 60 * 60 * 1000;

const CARD_TYPE_TO_ICON_MAP = {
  mastercard: cardTypes.MASTER_CARD,
  amex: cardTypes.AMERICAN_EXPRESS,
};

export const getCardIconType = (cardType) => CARD_TYPE_TO_ICON_MAP[cardType] || cardType;

const getPaymentMethodStatus = (expirationMonth, expirationYear) => {
  const currentDate = new Date(Date.now());
  const expirationDate = new Date(expirationYear, expirationMonth);
  const remainingTime = expirationDate.getTime() - currentDate.getTime();

  if (remainingTime < 0) {
    return statusTypes.EXPIRED;
  }

  if (remainingTime < MONTH_IN_MS) {
    return statusTypes.EXPIRY_SOON;
  }

  return statusTypes.ACTIVE;
};

const getNotificationCaptions = (status) => {
  switch (status) {
    case statusTypes.EXPIRED:
      return {
        title: i18next.t('Payment failed. Card expired. Please update payment method.'),
        buttonLabel: i18next.t('Update'),
      };
    case statusTypes.EXPIRY_SOON:
      return {
        title: i18next.t('Your card is about to expire soon. Please update your payment method'),
        buttonLabel: i18next.t('Update'),
      };
    case statusTypes.FAILED:
      return {
        title: i18next.t('Payment failed. Please update your payment method to proceed'),
        buttonLabel: i18next.t('Update'),
      };
    default:
      return null;
  }
};

const getTitle = ({ type, cardLastDigits, cardExpirationMonth, cardExpirationYear }) =>
  `${type.toUpperCase()} **** ${cardLastDigits}, ${i18next.t(
    'Expires'
  )} ${cardExpirationMonth}/${cardExpirationYear.slice(-2)}`;

const getBillingInfo = ({
  address1 = '',
  city = '',
  state = '',
  countryCode = '',
  postalCode = '',
}) => {
  const address = [address1, city, state, countryCode, postalCode].filter((str) => str).join(', ');
  return `${i18next.t('Billing information:')} ${address}`;
};

const processBillingProfile = (data) => {
  const {
    id,
    address1,
    cardExpirationMonth,
    cardExpirationYear,
    cardLastDigits,
    cardType,
    city,
    countryCode,
    isDefault,
    name,
    paypalEmail,
    postalCode,
    state,
    type: profileType,
  } = data;

  let status;
  let title;
  let billingInfo;
  let notification;

  const isCardPaymentMethod = profileType !== cardTypes.PAYPALL;
  const type = isCardPaymentMethod ? cardType : profileType;

  if (isCardPaymentMethod) {
    status = getPaymentMethodStatus(cardExpirationMonth, cardExpirationYear);
    title = getTitle({ type, cardLastDigits, cardExpirationMonth, cardExpirationYear });
    billingInfo = getBillingInfo({ address1, city, state, countryCode, postalCode });
    notification = getNotificationCaptions(status);
  } else {
    status = statusTypes.ACTIVE;
    title = paypalEmail;
  }

  return {
    id,
    type,
    status,
    address1,
    cardExpirationMonth,
    cardExpirationYear,
    cardLastDigits,
    city,
    countryCode,
    postalCode,
    state,
    name,
    captions: {
      title,
      payerName: name,
      ...(billingInfo && { billingInfo }),
      ...(notification && { notification }),
    },
    isDefault,
    isCardPaymentMethod,
    iconType: getCardIconType(type),
  };
};

export default processBillingProfile;
