import { camelCaseObjectToSnakeCase } from 'src/js/utils/convert-case';
import yousersjs from '@yola/yousersjs';
import status from 'src/js/modules/status';
import statusNames from 'src/js/modules/user/constants/status-names';

const updateUserPreferences = (userPreferencesData) => async (dispatch) => {
  try {
    dispatch(
      status.actions.setStatus(statusNames.UPDATE_USER_PREFERENCES, status.constants.LOADING)
    );
    await yousersjs.preferences().update(camelCaseObjectToSnakeCase(userPreferencesData));
    dispatch(
      status.actions.setStatus(statusNames.UPDATE_USER_PREFERENCES, status.constants.SUCCEEDED)
    );
  } catch (e) {
    dispatch(
      status.actions.setStatus(statusNames.UPDATE_USER_PREFERENCES, status.constants.FAILED)
    );
    throw e.response.body;
  }
};

export default updateUserPreferences;
