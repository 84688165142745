import { useEffect, useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { selectors } from '@yola/subscription-manager-js';
import i18next from 'i18next';
import routesMap from 'src/js/router/helpers/routes-map';
import supportedTabs from 'src/js/modules/account-management/constants/supported-tabs';
import user from 'src/js/modules/user';

function PageTitleContainer() {
  const location = useLocation();

  const currentPackage = useSelector(selectors.getCurrentPackage, shallowEqual);
  const partnerName = useSelector(user.selectors.getPartnerName);

  const titleName = useMemo(() => {
    const mapPathToTitle = {
      [routesMap.paywall.patterns]: `${i18next.t('Upgrade Your Subscription')} | ${partnerName}`,
      [routesMap.ecommercePaywall.patterns]: `${i18next.t(
        'Upgrade Your Online Store'
      )} | ${partnerName}`,
      [routesMap.domainSelector.patterns]: `${i18next.t('Search Your Domain')} | ${partnerName}`,
      [routesMap.subscriptionDetails.patterns]: `${currentPackage.name} | ${partnerName}`,
      [`/account/${supportedTabs.PAYMENT_METHODS}`]: `${i18next.t(
        'Payment Methods'
      )} | ${partnerName}`,
      [`/account/${supportedTabs.BILLING_HISTORY}`]: `${i18next.t(
        'Billing history'
      )} | ${partnerName}`,
      [`/account/${supportedTabs.SUBSCRIPTIONS}`]: `${i18next.t('Subscriptions')} | ${partnerName}`,
    };
    routesMap.dashboard.patterns.forEach((pattern) => {
      mapPathToTitle[pattern] = `${i18next.t('Dashboard')} | ${partnerName}`;
    });

    const path = Object.keys(mapPathToTitle).find((titlePath) =>
      matchPath(location.pathname, { path: `${titlePath}`, exact: true })
    );
    return mapPathToTitle[path] || partnerName;
  }, [currentPackage.name, location.pathname, partnerName]);

  useEffect(() => {
    document.title = titleName;
  }, [titleName]);

  return null;
}

export default PageTitleContainer;
