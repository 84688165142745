import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import i18next from 'i18next';
import { useLocation } from 'react-router-dom';
import user from '../../modules/user';
import segment from '../../modules/analytics/segment';

const {
  constants: { events },
  track,
} = segment;

const { NotificationMessage, Paragraph } = designSystem;

const getDefaultCaption = (contactSupportUrl) =>
  contactSupportUrl
    ? i18next.t(
        'Oops! Something went wrong. Please try again later or contact our {supportTeam} for assistance',
        { supportTeam: `<a href="${contactSupportUrl}">${i18next.t('support team')}</a>` }
      )
    : i18next.t('Oops! Something went wrong. Please try again later.');

const ErrorNotificationContainer = ({ title, onClose, disableTracking }) => {
  const location = useLocation();
  const contactSupportUrl = useSelector(user.selectors.getContactSupportUrl);
  const caption = title || getDefaultCaption(contactSupportUrl);

  useEffect(() => {
    if (disableTracking) return;

    const pagePath = location.pathname.replace(/\/$/, '');

    track(events.ERROR_MESSAGE_DISPLAYED, {
      pagePath,
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <NotificationMessage
      appearance="danger"
      iconGlyph="attention"
      title={
        // eslint-disable-next-line yola/react/jsx-wrap-multilines
        <Paragraph appearance="danger">
          <b // eslint-disable-next-line yola/react/no-danger
            dangerouslySetInnerHTML={{ __html: caption }}
          />
        </Paragraph>
      }
      {...(onClose && { onClose })}
    />
  );
};

ErrorNotificationContainer.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.func]),
  disableTracking: PropTypes.bool,
};

ErrorNotificationContainer.defaultProps = {
  title: '',
  onClose: null,
  disableTracking: false,
};

export default ErrorNotificationContainer;
