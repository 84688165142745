import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Route, Switch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { designSystem } from '@yola/ws-ui';
import i18next from 'i18next';
import routesMap from '../../../router/helpers/routes-map';
import SubscriptionsListContainer from '../containers/subscriptions-list-container';
import BillingHistoryContainer from '../containers/billing-history-container';
import PaymentMethodsContainer from '../containers/payment-methods-container';
import UpsellBannersContainer from '../../upsell-banners/containers/upsell-banners-container';
import segment from '../../analytics/segment';
import { PageHeading, PageHeadingLeft } from '../../../common/components/page-container';
import supportedTabs from '../constants/supported-tabs';

const {
  constants: { triggerIds },
} = segment;
const { Tabs, TabsList, Tab, TabsContentList, TabContent, Heading, Paragraph, Stack } =
  designSystem;

const getCaptions = () => ({
  [supportedTabs.SUBSCRIPTIONS]: {
    title: i18next.t('Subscriptions'),
    description: i18next.t('View and manage your subscriptions'),
  },
  [supportedTabs.BILLING_HISTORY]: {
    title: i18next.t('Billing history'),
    description: i18next.t('View your transaction history and billing details'),
  },
  [supportedTabs.PAYMENT_METHODS]: {
    title: i18next.t('Payment methods'),
    description: i18next.t('View and manage the payment methods in your account'),
  },
});

const AccountManagementPage = () => {
  const { tabId: activeTabId } = useParams();
  const dispatch = useDispatch();
  const captions = getCaptions();

  const handleTabChange = (tabId) => {
    dispatch(push(routesMap.accountManagement.url({ tabId }), { triggerId: triggerIds.TABS }));
  };

  return (
    <Fragment>
      <PageHeading>
        <PageHeadingLeft>
          <Stack gap="spacing-2-xs">
            <Heading type="heading-2">{captions[activeTabId].title}</Heading>
            <Paragraph size="large">{captions[activeTabId].description}</Paragraph>
          </Stack>
        </PageHeadingLeft>
      </PageHeading>

      <div className="ws-account-management-content">
        <div>
          <Tabs
            activeTabId={activeTabId}
            onTabChange={handleTabChange}
            disableSwipeableContent
            skipRenderInactiveContent
            noBackground
            noSideSpacing
          >
            <TabsList>
              <Tab
                tabId={supportedTabs.SUBSCRIPTIONS}
                label={captions[supportedTabs.SUBSCRIPTIONS].title}
              />
              <Tab
                tabId={supportedTabs.BILLING_HISTORY}
                label={captions[supportedTabs.BILLING_HISTORY].title}
              />
              <Tab
                tabId={supportedTabs.PAYMENT_METHODS}
                label={captions[supportedTabs.PAYMENT_METHODS].title}
              />
            </TabsList>

            <TabsContentList>
              <TabContent tabId={supportedTabs.SUBSCRIPTIONS}>
                <SubscriptionsListContainer />
              </TabContent>
              <TabContent tabId={supportedTabs.BILLING_HISTORY}>
                <Switch>
                  <Route
                    path={routesMap.billingHistory.patterns}
                    component={BillingHistoryContainer}
                  />
                </Switch>
              </TabContent>
              <TabContent tabId={supportedTabs.PAYMENT_METHODS}>
                <PaymentMethodsContainer />
              </TabContent>
            </TabsContentList>
          </Tabs>
        </div>

        <UpsellBannersContainer />
      </div>
    </Fragment>
  );
};

export default AccountManagementPage;
