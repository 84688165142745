import React from 'react';

const CustomerSupportIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
    <path
      fill="#fff"
      d="M16 30.8a3.551 3.551 0 0 1-2.653-1.187l-.067-.073-.094-.034A8.414 8.414 0 0 1 7.6 21.6V9.905a3.798 3.798 0 0 1 3.794-3.794h9.213A3.798 3.798 0 0 1 24.4 9.905V21.6a8.414 8.414 0 0 1-5.585 7.906l-.095.034-.067.073A3.551 3.551 0 0 1 16 30.8Z"
    />
    <path
      fill="#005ED9"
      d="M20.606 6.511A3.397 3.397 0 0 1 24 9.905V21.6a8.013 8.013 0 0 1-5.32 7.53l-.189.067-.134.148a3.157 3.157 0 0 1-4.714-.001l-.134-.148-.189-.067A8.011 8.011 0 0 1 8 21.6V9.905a3.398 3.398 0 0 1 3.394-3.394h9.212Zm0-.8h-9.212A4.194 4.194 0 0 0 7.2 9.905V21.6c0 3.824 2.444 7.07 5.851 8.283A3.977 3.977 0 0 0 16 31.2a3.975 3.975 0 0 0 2.949-1.318C22.356 28.67 24.8 25.424 24.8 21.6V9.905a4.194 4.194 0 0 0-4.194-4.194Z"
    />
    <path
      fill="#005ED9"
      d="M20.6 19.258a1.085 1.085 0 1 0 0 2.17 1.085 1.085 0 0 0 0-2.17Zm-8.686 0a1.085 1.085 0 1 0 0 2.17 1.085 1.085 0 0 0 0-2.17Z"
    />
    <path
      fill="#005ED9"
      fillOpacity=".03"
      d="M25.6 30c-2.229 0-4.286-.8-5.274-1.253 1.279-.896 4.074-3.313 4.074-7.317v-5.524c0-3.019-3.258-4.968-3.398-5.05l-.356-.21-.198.364c-.02.038-2.156 3.856-7.503 4.594-2.575.356-5.345 2.185-5.345 5.36l.8.066-.8-.019v.418c0 4.057 3.066 6.568 4.498 7.519C11.026 29.343 8.977 30 7.2 30c-3.091 0-4.612-1.429-5.093-1.994.646-.828 2.607-3.505 2.607-5.49 0-1.074-.248-2.31-.51-3.618-.297-1.48-.604-3.01-.604-4.498 0-8.262 5.883-13.2 11.571-13.2 4.272 0 6.067 2.973 6.083 3.002l.117.198h.229c3.684 0 7.6 2.944 7.6 8.4 0 1.775-.389 3.64-.766 5.442-.326 1.564-.634 3.041-.634 4.273 0 2.47 2.488 4.672 3.53 5.486-.9.628-3.157 1.999-5.73 1.999Z"
    />
    <path
      fill="#005ED9"
      d="M15.171 1.6c4.007 0 5.672 2.694 5.739 2.805l.232.395h.458c3.49 0 7.2 2.804 7.2 8 0 1.734-.385 3.578-.757 5.36-.33 1.585-.643 3.082-.643 4.354 0 2.337 2.036 4.416 3.257 5.453-1.04.657-2.942 1.633-5.057 1.633-1.762 0-3.418-.52-4.485-.949 1.454-1.169 3.685-3.56 3.685-7.222v-5.523c0-3.246-3.448-5.31-3.594-5.396l-.715-.42-.394.73c-.02.038-2.037 3.674-7.206 4.388-2.741.378-5.69 2.34-5.69 5.755V21.43c0 3.703 2.428 6.169 4.038 7.397-1.1.361-2.657.774-4.039.774-2.554 0-3.964-1.023-4.568-1.618.818-1.084 2.482-3.526 2.482-5.468 0-1.112-.252-2.367-.518-3.695C4.303 17.36 4 15.85 4 14.4 4 6.388 9.68 1.6 15.171 1.6Zm0-.8C9.224.8 3.201 5.977 3.201 14.4c0 2.869 1.113 5.906 1.113 8.114C4.314 24.724 1.6 28 1.6 28s1.54 2.4 5.6 2.4c2.642 0 5.797-1.375 5.797-1.375S8 26.428 8 21.429v-.419.019-.067c0-2.942 2.594-4.63 5-4.963 5.63-.777 7.8-4.8 7.8-4.8s3.2 1.88 3.2 4.706v5.523c0 4.904-4.485 7.362-4.485 7.362s2.841 1.61 6.085 1.61c3.514 0 6.4-2.4 6.4-2.4s-3.8-2.603-3.8-5.486c0-2.56 1.4-6.32 1.4-9.714 0-5.757-4.22-8.8-8-8.8 0 0-1.878-3.2-6.429-3.2Z"
    />
    <path
      fill="#005ED9"
      fillOpacity=".03"
      d="M23.85 25.6h-1.073a9.953 9.953 0 0 1-2.134 2.397l3.01-.048c.838-.933-.354-1.127.198-2.349Z"
    />
    <path
      stroke="#005ED9"
      strokeMiterlimit="10"
      d="M17.829 26.8h7.314c1.8 0 3.257-1.458 3.257-3.257V22"
    />
    <path fill="#005ED9" d="M17.2 28h-.8a1.2 1.2 0 0 1 0-2.4h.8a1.2 1.2 0 0 1 0 2.4Z" />
    <path
      fill="#F7FAFE"
      d="M27.2 22.8a.4.4 0 0 1-.4-.4v-5.6c0-.22.179-.4.4-.4h1.6c.661 0 1.2.538 1.2 1.2v4c0 .662-.539 1.2-1.2 1.2h-1.6Z"
    />
    <path
      fill="#005ED9"
      d="M28.8 16.8c.44 0 .8.36.8.8v4c0 .44-.36.8-.8.8h-1.6v-5.6h1.6Zm0-.8h-1.6a.8.8 0 0 0-.8.8v5.6a.8.8 0 0 0 .8.8h1.6a1.6 1.6 0 0 0 1.6-1.6v-4a1.6 1.6 0 0 0-1.6-1.6Z"
    />
  </svg>
);

export default CustomerSupportIcon;
