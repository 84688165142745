import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import config from 'src/js/modules/config';
import user from 'src/js/modules/user';

function useGalleryUrl() {
  const latitudeGalleryUrl = useSelector(config.selectors.getLatitudeTemplateGalleryUrl);
  const legacyGalleryUrl = useSelector(config.selectors.getLegacyTemplateGalleryUrl);
  const platformAccess = useSelector(user.selectors.getPlatformAccess);

  return useMemo(() => {
    if (platformAccess === user.constants.platformAccess.LEGACY) {
      return legacyGalleryUrl;
    }

    return `${latitudeGalleryUrl}?back=true`;
  }, [legacyGalleryUrl, latitudeGalleryUrl, platformAccess]);
}

export default useGalleryUrl;
