import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { designSystem } from '@yola/ws-ui';
import i18next from 'i18next';
import routesMap from '../../../router/helpers/routes-map';
import segment from '../../analytics/segment';

const { CtaBanner } = designSystem;
const {
  constants: { triggerIds, events },
  track,
} = segment;

const getCaptions = () => ({
  title: i18next.t('Upgrade subscription'),
  subtitle: i18next.t('Gain access to new features by upgrading your subscription'),
  label: i18next.t('Upgrade now!'),
});

const UpgradeSubscriptionBannerContainer = () => {
  const dispatch = useDispatch();
  const captions = getCaptions();

  const redirectToPaywall = () => {
    const paywallUrl = routesMap.paywall.url({
      query: { triggerId: triggerIds.BANNER, paymentBackUrl: window.location.href },
    });
    track(events.UPGRADE_TRIGGER_CLICKED, { triggerId: triggerIds.BANNER });
    dispatch(push(paywallUrl));
  };

  return (
    <CtaBanner
      title={captions.title}
      subtitle={captions.subtitle}
      ctaButton={{
        label: captions.label,
        onClick: redirectToPaywall,
      }}
      iconGlyph="sale"
      appearance="cta"
      layout="mobile"
      animate={false}
    />
  );
};

export default UpgradeSubscriptionBannerContainer;
