import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import BillingsListHeader from '../billings-list-header';
import BillingItem from '../billing-item';
import { billingItemPropTypes } from '../billing-item/constants';
import LoaderWithText from '../../../../common/components/loader-with-text/index';
import ErrorNotificationContainer from '../../../../common/containers/error-notification-container';
import statusTypes from '../../../products/constants/basket-status-types';

const { Box, Paragraph, Stack, Pagination } = designSystem;
const ITEMS_PER_PAGE = 20;

const BillingHistoryContent = ({
  captions,
  isLoading,
  isFailed,
  billingsList,
  pagesCount,
  currentPage,
  onChangePage,
}) => {
  if (isLoading) {
    return (
      <div className="ws-account-management-loader-wrapper">
        <LoaderWithText />
      </div>
    );
  }

  if (isFailed) {
    return <ErrorNotificationContainer />;
  }

  if (!billingsList.length) {
    return <Paragraph size="large">{captions.emptyState}</Paragraph>;
  }

  return (
    <Stack gap="spacing-xl">
      <Box>
        <BillingsListHeader items={captions.headerItems} />
        {billingsList.map((billing) => (
          <BillingItem
            key={billing.date}
            {...billing}
            statusLabel={captions.status[billing.status]}
          />
        ))}
      </Box>
      {pagesCount > ITEMS_PER_PAGE && (
        <Pagination
          count={pagesCount}
          itemsPerPage={ITEMS_PER_PAGE}
          currentPage={currentPage}
          disabled={isLoading}
          onChange={onChangePage}
        />
      )}
    </Stack>
  );
};

BillingHistoryContent.propTypes = {
  captions: PropTypes.shape({
    emptyState: PropTypes.string,
    headerItems: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.shape({
      [statusTypes.CAPTURED]: PropTypes.string,
      [statusTypes.CANCELLED]: PropTypes.string,
      [statusTypes.FAILED]: PropTypes.string,
      [statusTypes.ERROR]: PropTypes.string,
      [statusTypes.NEW]: PropTypes.string,
      [statusTypes.PENDING]: PropTypes.string,
      [statusTypes.AUTHORIZED]: PropTypes.string,
      [statusTypes.REFUNDED]: PropTypes.string,
      [statusTypes.PARTIAL_REFUND]: PropTypes.string,
    }),
  }).isRequired,
  isLoading: PropTypes.bool,
  isFailed: PropTypes.bool,
  billingsList: PropTypes.arrayOf(PropTypes.shape(billingItemPropTypes)).isRequired,
  pagesCount: PropTypes.number,
  currentPage: PropTypes.number,
  onChangePage: PropTypes.func.isRequired,
};

BillingHistoryContent.defaultProps = {
  isLoading: false,
  isFailed: false,
  pagesCount: 0,
  currentPage: 1,
};

export default BillingHistoryContent;
