import setCriticalError from '../actions/set-critical-error';

const catchReduxError = (error, getState, lastAction, dispatch) => {
  console.error(error);
  dispatch(
    setCriticalError({
      error,
      errorInfo: {
        action: lastAction,
      },
    })
  );
};

export default catchReduxError;
