import { batch } from 'react-redux';
import yousersjs from '@yola/yousersjs';
import user from 'src/js/modules/user';
import status from 'src/js/modules/status';
import { snakeCaseObjectToCamelCase } from 'src/js/utils/convert-case';
import statusNames from '../constants/status-names';

const fetchUserPreferences = () => async (dispatch) => {
  try {
    dispatch(status.actions.setStatus(statusNames.USER_PREFERENCES, status.constants.LOADING));

    const response = await yousersjs.preferences().get();
    const data = snakeCaseObjectToCamelCase(response.body);

    batch(() => {
      dispatch(user.actions.setUserPreferences(data));
      dispatch(status.actions.setStatus(statusNames.USER_PREFERENCES, status.constants.SUCCEEDED));
    });
  } catch (e) {
    dispatch(status.actions.setStatus(statusNames.USER_PREFERENCES, status.constants.FAILED));
    throw e;
  }
};

export default fetchUserPreferences;
