import React from 'react';
import PropTypes from 'prop-types';
import { Panel, PanelGroup, Modal, designSystem } from '@yola/ws-ui';
import WarningIcon from './icons/warning-icon';

const { Box, Heading, Paragraph, ActionButton, Stack, Divider, IconButton, List, ListItem } =
  designSystem;

const MODAL_WIDTH = 444;

function SubscriptionExpiredDialog({
  captions,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onClose,
}) {
  return (
    <Modal
      isBodyScrollDisabled
      width={MODAL_WIDTH}
      maxWidth={MODAL_WIDTH}
      minWidth={MODAL_WIDTH}
      height="auto"
      centered
      resizable={false}
      draggable={false}
      overlay="visible"
      className="ws-subscription-expired-dialog"
    >
      <div className="ws-subscription-expired-dialog__close-button">
        <IconButton glyph="close" transparent onClick={onClose} />
      </div>
      <PanelGroup height="100%">
        <Panel height="100%" scrollable>
          <Box
            paddingTop="spacing-l"
            paddingBottom="spacing-l"
            paddingLeft="spacing-xl"
            paddingRight="spacing-xl"
          >
            <Stack gap="spacing-xs">
              <div className="ws-subscription-expired-dialog__icon">
                <WarningIcon />
              </div>
              <Stack gap="spacing-3-xs">
                <Heading type="heading-4" align="center">
                  {captions.title}
                </Heading>
                {captions.description && (
                  <Paragraph align="center">{captions.description}</Paragraph>
                )}
                {!!captions.list?.length && (
                  <List spacing="spacing-3-xs" styleType="icon">
                    {captions.list.map((text) => (
                      <ListItem key={text} iconGlyph="close" iconAppearance="error">
                        {text}
                      </ListItem>
                    ))}
                  </List>
                )}
              </Stack>
              <Divider />
              <Paragraph align="center">
                <span
                  // eslint-disable-next-line yola/react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: captions.cta,
                  }}
                />
              </Paragraph>
              <Stack gap="spacing-3-xs">
                <ActionButton
                  label={captions.primaryButtonLabel}
                  appearance="cta"
                  format="solid"
                  size="large"
                  fullWidth
                  onClick={onPrimaryButtonClick}
                />
                {onSecondaryButtonClick && (
                  <ActionButton
                    label={captions.secondaryButtonLabel}
                    appearance="cta"
                    format="outline"
                    size="large"
                    fullWidth
                    onClick={onSecondaryButtonClick}
                  />
                )}
              </Stack>
            </Stack>
          </Box>
        </Panel>
      </PanelGroup>
    </Modal>
  );
}

SubscriptionExpiredDialog.propTypes = {
  onPrimaryButtonClick: PropTypes.func.isRequired,
  onSecondaryButtonClick: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  onClose: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.string),
    cta: PropTypes.string.isRequired,
    primaryButtonLabel: PropTypes.string.isRequired,
    secondaryButtonLabel: PropTypes.string,
  }).isRequired,
};

SubscriptionExpiredDialog.defaultProps = {
  onSecondaryButtonClick: null,
};

export default SubscriptionExpiredDialog;
