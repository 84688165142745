import fetchPartnerData from './fetch-partner-data';
import fetchUserData from './fetch-user-data';
import fetchUserPreferences from './fetch-user-preferences';
import updateUserData from './update-user-data';
import deleteAccount from './delete-account';
import updateUserPreferences from './update-user-preferences';
import changePassword from './change-password';
import sendVerificationCode from './send-verification-code';

export default {
  fetchPartnerData,
  fetchUserData,
  fetchUserPreferences,
  updateUserPreferences,
  updateUserData,
  changePassword,
  sendVerificationCode,
  deleteAccount,
};
