import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import status from '../../modules/status';

const useQuery = ({ name, thunk, selector = Function.prototype, args = [] }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const targetStatus = status.hooks.useStatus(name);
  const refresh = useRef(Function.prototype);
  const clearError = useRef(Function.prototype);
  const storedData = useSelector(selector);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(thunk(...args));
        setData(response);
      } catch (e) {
        setError(e);
      }
    };

    refresh.current = fetchData;
    clearError.current = () => setError(null);

    if (storedData && targetStatus === status.constants.SUCCEEDED) {
      return;
    }

    fetchData();
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [...args]);

  return {
    data: storedData && targetStatus === status.constants.SUCCEEDED ? storedData : data,
    isLoading: targetStatus === status.constants.LOADING,
    error,
    refresh: refresh.current,
    clearError: clearError.current,
  };
};

export default useQuery;
