import i18next from 'i18next';

const getCancelPlanDetails = (expiryDate, isHostingSubscription) =>
  `${i18next.t('Your subscription will expire on')} <b>${expiryDate}</b>${
    isHostingSubscription
      ? `, ${i18next.t('and you will be downgraded to')} <b>${i18next.t('Free account')}</b>.`
      : ''
  }`;

export default getCancelPlanDetails;
