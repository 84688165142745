import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import sites from 'src/js/modules/sites';
import dialogs from 'src/js/modules/dialogs';
import status from 'src/js/modules/status';
import user from 'src/js/modules/user';
import useAvailablePlatformComponents from 'src/js/modules/user/hooks/use-available-platform-components';
import segment from 'src/js/modules/analytics/segment';
import useStaticAsset from 'src/js/common/hooks/use-static-asset';
import routesMap from 'src/js/router/helpers/routes-map';
import legacyUrlMap from 'src/js/router/helpers/legacy-urls-map';
import subscriptions from '../../subscriptions';
import { SiteList, SiteListItem } from '../components/site-list';
import { Site, SiteSkeleton } from '../components/site';
import generatePublishUrl from '../helpers/generate-publish-url';
import SiteCreation from '../components/site-creation';
import SiteListError from '../components/site-list-error';
import DomainSearch from '../components/domain-search';
import redirectToUrl from '../../../utils/redirect-to-url';

const { platformComponents } = user.constants;
const { constants } = status;
const {
  constants: { triggerIds, events },
  track,
  trackAsync,
} = segment;

function getSkeletonItems() {
  return ['skeleton-0', 'skeleton-1'];
}

function SitesListContainer({ onAddNewSiteClick }) {
  const history = useHistory();

  const sitesList = useSelector(sites.selectors.getSites, shallowEqual);
  const sitesStatus = useSelector(sites.selectors.getSitesStatus);
  const contactSupportUrl = useSelector(user.selectors.getContactSupportUrl);
  const domainPurchaseAvailable = useSelector(user.selectors.getDomainPurchaseAvailable);
  const dispatch = useDispatch();
  const availablePlatformComponents = useAvailablePlatformComponents();
  const siteExampleImage = useStaticAsset('/img/site-example-brandless.png');

  const onBuyDomain = useCallback(
    (domainName) => {
      track(events.BUY_A_DOMAIN_TRIGGER_CLICKED, {
        triggerId: triggerIds.SECURE_DOMAIN,
      });

      history.push(
        routesMap.domainSelector.url({
          query: { prefill: domainName, triggerId: triggerIds.SECURE_DOMAIN },
        })
      );
    },
    [history]
  );

  const onDomainSearchSubmit = useCallback(
    (domainName) => {
      track(events.DASHBOARD_DOMAIN_SEARCH_CLICKED, {
        domainName: domainName || null,
      });

      history.push(
        routesMap.domainSelector.url({
          query: { prefill: domainName, triggerId: triggerIds.DASHBOARD_DOMAIN_SEARCH },
        })
      );
    },
    [history]
  );

  const onDomainSettingsClick = useCallback(async (siteData) => {
    const { hasCustomDomain: customDomain = false } = siteData;
    const activeDomainSubscriptions = subscriptions.accessors.getActiveDomainSubscriptions();
    const domainsUrl = siteData.isWs
      ? legacyUrlMap.domains()
      : legacyUrlMap.legacyDomainsAdvanced(siteData.sitebuilderUrl);

    await trackAsync(events.DOMAIN_SETTINGS_TRIGGER_CLICKED, {
      customDomain,
      hasActiveDomainSubscription: Boolean(activeDomainSubscriptions.length),
    });

    redirectToUrl(domainsUrl);
  }, []);

  const userSites = useMemo(() => {
    switch (sitesStatus) {
      case constants.LOADING:
        return getSkeletonItems().map((item) => (
          <SiteListItem key={item}>
            <SiteSkeleton />
          </SiteListItem>
        ));
      case constants.FAILED:
        return [
          <SiteListItem key="load-failed">
            <SiteListError contactSupportUrl={contactSupportUrl} />
          </SiteListItem>,
        ];

      default: {
        if (sitesList.length) {
          return sitesList.map((siteData) => (
            <SiteListItem key={siteData.id}>
              <Site
                availablePlatformComponents={availablePlatformComponents}
                siteData={siteData}
                publishSiteUrl={siteData.isWs ? generatePublishUrl(siteData.sitebuilderUrl) : null}
                onPreviewClick={() => redirectToUrl(siteData.sitebuilderUrl)}
                onDelete={() => {
                  dispatch(
                    dialogs.actions.show(dialogs.dialogTypes.DELETE_SITE, {
                      siteId: siteData.id,
                    })
                  );
                }}
                onBuyDomain={onBuyDomain}
                onDomainSettingsClick={onDomainSettingsClick}
              />
            </SiteListItem>
          ));
        }

        return [
          <SiteListItem key="no-sites">
            <SiteCreation imageUrl={siteExampleImage} onAddNewSiteClick={onAddNewSiteClick} />
          </SiteListItem>,
        ];
      }
    }
  }, [
    sitesList,
    availablePlatformComponents,
    dispatch,
    sitesStatus,
    contactSupportUrl,
    siteExampleImage,
    onBuyDomain,
    onAddNewSiteClick,
    onDomainSettingsClick,
  ]);

  const userSitesWithDomainSearch = useMemo(() => {
    if (
      !availablePlatformComponents.includes(platformComponents.DOMAINS) ||
      !domainPurchaseAvailable
    ) {
      return userSites;
    }

    return [
      ...userSites.slice(0, 2),
      <SiteListItem key="domain-search">
        <DomainSearch onSubmit={onDomainSearchSubmit} />
      </SiteListItem>,
      ...userSites.slice(2),
    ];
  }, [userSites, availablePlatformComponents, onDomainSearchSubmit, domainPurchaseAvailable]);

  return (
    <SiteList
      noSites={
        (!sitesList.length && sitesStatus === constants.SUCCEEDED) ||
        sitesStatus === constants.FAILED
      }
    >
      {userSitesWithDomainSearch}
    </SiteList>
  );
}

SitesListContainer.propTypes = {
  onAddNewSiteClick: PropTypes.func.isRequired,
};

export default SitesListContainer;
