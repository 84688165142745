import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { InputGroupField } = designSystem;

function PasswordInput(props) {
  const { name, control, rules, isVisible, onVisibilityToggle, onChange } = props;

  const handleButtonClick = () => {
    onVisibilityToggle(name);
  };

  return (
    <InputGroupField
      name={name}
      control={control}
      rules={rules}
      type={isVisible ? 'text' : 'password'}
      iconButtonGlyph={isVisible ? 'eye-off' : 'eye'}
      onIconButtonClick={handleButtonClick}
      onChange={onChange}
    />
  );
}

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onVisibilityToggle: PropTypes.func.isRequired,
  rules: PropTypes.object,
  onChange: PropTypes.func,
};

PasswordInput.defaultProps = {
  rules: {},
  onChange: Function.prototype,
};

export default PasswordInput;
