const cardTypes = {
  VISA: 'visa',
  AMERICAN_EXPRESS: 'american-express',
  DISCOVER: 'discover',
  MASTER_CARD: 'master-card',
  PAYPALL: 'paypal',
  BOLETO: 'boleto',
  STRIPE: 'stripe',
};

export const STRIPE_CARD_TYPES = [
  cardTypes.VISA,
  cardTypes.MASTER_CARD,
  cardTypes.AMERICAN_EXPRESS,
  cardTypes.DISCOVER,
];

export default cardTypes;
