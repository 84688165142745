const legacyUrlsMap = {
  domains: () => '/domains/',
  legacyDomainsAdvanced: (sitebuilderUrl) => `${sitebuilderUrl}/settings/advanced/`,
  email: () => '/email/',
  profile: () => '/account/profile/',
  language: () => '/account/profile/#set_language',
  pricing: ({ base } = { base: '/' }) => `${base}pricing`,
  trafficBuilder: () => '/seo/',
  sitewit: () => '/sitewit/',
  cancelSubscriptions: () => '/account/cancel-subscriptions',
};

export default legacyUrlsMap;
