import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import routesMap from 'src/js/router/helpers/routes-map';
import PaywallRoute from 'src/js/router/routes/paywall-route';
import EcommercePaywallRoute from 'src/js/router/routes/ecommerce-paywall-route';
import DomainSelectorRoute from 'src/js/router/routes/domain-selector-route';
import InitiateUpgradeRoute from 'src/js/router/routes/initiate-upgrade-route';
import CancelSubscriptionsRoute from 'src/js/router/routes/cancel-subscriptions-route';
import DashboardRoute from 'src/js/router/routes/dashboard-route';
import AccountManagementPage from 'src/js/modules/account-management/pages/account-management-page';
import ProfilePageContainer from 'src/js/modules/profile/containers/profile-page-container';
import SubscriptionDetailsRoute from 'src/js/modules/account-management/routes/subscription-details-route';
import PageTitleContainer from 'src/js/common/containers/page-title-container';
import DialogsContainer from 'src/js/modules/dialogs/containers/dialogs-container';
import ErrorBoundaryContainer from 'src/js/modules/error/containers/error-boundary-container';
import { FeatureFlagsProvider } from 'src/js/modules/feature-flags/context';
import user from './modules/user';
import config from './modules/config';
import { PageContainer } from './common/components/page-container';
import dialogs from './modules/dialogs';

function App() {
  const configData = useSelector(config.selectors.getConfig);
  const userData = useSelector(user.selectors.getUserData);
  const isB2C = useSelector(user.selectors.getIsB2C);

  const dispatch = useDispatch();

  useEffect(() => {
    if (configData) {
      const loadingItems = document.querySelectorAll('.js-loading-screen-item');
      if (loadingItems.length > 0) {
        loadingItems.forEach((item) => item.remove());
      }

      if (isB2C && !userData.isLatestUserAgreementAccepted && !userData.employeeId) {
        dispatch(dialogs.actions.show(dialogs.dialogTypes.TERMS_OF_USE_DIALOG));
      }
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [configData]);

  if (!configData) return null;

  return (
    <ErrorBoundaryContainer>
      <FeatureFlagsProvider>
        <Switch>
          <Route path={routesMap.initiateUpgrade.patterns} component={InitiateUpgradeRoute} />
          <Route path={routesMap.paywall.patterns} component={PaywallRoute} />
          <Route path={routesMap.ecommercePaywall.patterns} component={EcommercePaywallRoute} />
          <Route path={routesMap.domainSelector.patterns} component={DomainSelectorRoute} />
          <Route>
            <PageContainer>
              <Switch>
                <Route
                  path={routesMap.cancelSubscriptions.patterns}
                  component={CancelSubscriptionsRoute}
                />
                <Route
                  path={routesMap.subscriptionDetails.patterns}
                  component={SubscriptionDetailsRoute}
                />
                <Route
                  path={routesMap.accountManagement.patterns}
                  component={AccountManagementPage}
                />
                <Route path={routesMap.profile.patterns} component={ProfilePageContainer} />
                <Route path={routesMap.dashboard.patterns} component={DashboardRoute} />
              </Switch>
            </PageContainer>
          </Route>
        </Switch>
        <DialogsContainer />
        <PageTitleContainer />
      </FeatureFlagsProvider>
    </ErrorBoundaryContainer>
  );
}

export default App;
