import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { designSystem } from '@yola/ws-ui';
import ErrorNotificationContainer from '../../../../common/containers/error-notification-container';

const { Heading, Paragraph, Box, Stack, List, ListItem, Mark, NotificationMessage } = designSystem;

const LIMIT_ITEMS_FOR_ONE_COLUMN = 4;

const CancelPlanConfirmation = ({
  captions,
  plans,
  details,
  isError,
  clearError,
  additionalChanges,
}) => {
  const isTwoColumns = additionalChanges.length > LIMIT_ITEMS_FOR_ONE_COLUMN;
  const additionalChangesClasses = classNames('ws-cancel-plan-confirmation-additional-changes', {
    'ws-cancel-plan-confirmation-additional-changes--two-columns': isTwoColumns,
  });

  const [firstColumnArray, secondColumnArray] = useMemo(() => {
    if (!isTwoColumns) {
      return [additionalChanges];
    }

    const halfOfArray = Math.round(additionalChanges.length / 2);

    return [
      additionalChanges.slice(0, halfOfArray),
      additionalChanges.slice(halfOfArray, additionalChanges.length),
    ];
  }, [additionalChanges, isTwoColumns]);

  return (
    <Stack gap="spacing-m">
      {isError && <ErrorNotificationContainer onClose={clearError} />}
      <Stack gap="spacing-2-xs">
        <Heading type="heading-6">{captions.youAreCancelling}</Heading>
        <Stack gap="spacing-3-xs">
          {plans.map(({ name, renew, price, notification, id }) => (
            <div key={id} className="ws-cancel-plan-confirmation-plan">
              <Box padding="spacing-xs">
                <Stack gap="spacing-2-xs">
                  <div className="ws-cancel-plan-confirmation-plan__details">
                    <Heading type="heading-6">{name}</Heading>
                    <Paragraph appearance="medium-emphasis">
                      {renew}{' '}
                      <Mark appearance="high-emphasis">
                        <b>{price}</b>
                      </Mark>
                    </Paragraph>
                  </div>
                  {notification && <NotificationMessage appearance="danger" title={notification} />}
                </Stack>
              </Box>
            </div>
          ))}
        </Stack>
      </Stack>
      <Stack gap="spacing-2-xs">
        <Heading type="heading-6">{captions.cancellationDetails}</Heading>
        <List styleType="icon">
          <ListItem iconGlyph="date" iconAppearance="light">
            <span
              // eslint-disable-next-line yola/react/no-danger
              dangerouslySetInnerHTML={{
                __html: details,
              }}
            />
          </ListItem>
        </List>
      </Stack>
      <Stack gap="spacing-2-xs">
        <Heading type="heading-6">{captions.additionalChanges}</Heading>
        <div className={additionalChangesClasses}>
          <List styleType="icon" spacing="spacing-3-xs">
            {firstColumnArray.map((text) => (
              <ListItem key={text} iconGlyph="close" iconAppearance="error">
                {/* eslint-disable-next-line yola/react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: text }} />
              </ListItem>
            ))}
          </List>
          {secondColumnArray && (
            <List styleType="icon" spacing="spacing-3-xs">
              {secondColumnArray.map((text) => (
                <ListItem key={text} iconGlyph="close" iconAppearance="error">
                  {/* eslint-disable-next-line yola/react/no-danger */}
                  <span dangerouslySetInnerHTML={{ __html: text }} />
                </ListItem>
              ))}
            </List>
          )}
        </div>
      </Stack>
    </Stack>
  );
};

CancelPlanConfirmation.propTypes = {
  captions: PropTypes.shape({
    youAreCancelling: PropTypes.string,
    cancellationDetails: PropTypes.string,
    additionalChanges: PropTypes.string,
  }).isRequired,
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      renew: PropTypes.string,
      price: PropTypes.string,
      notification: PropTypes.string,
    })
  ).isRequired,
  details: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  additionalChanges: PropTypes.arrayOf(PropTypes.string).isRequired,
  clearError: PropTypes.func.isRequired,
};

CancelPlanConfirmation.defaultProps = {
  isError: false,
};

export default CancelPlanConfirmation;
