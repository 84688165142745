import React, { useImperativeHandle } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

const StripeForm = React.forwardRef((props, ref) => {
  const { onReady, onLoaderror, options, ...otherProps } = props;
  const stripe = useStripe();
  const elements = useElements();

  useImperativeHandle(ref, () => ({
    getElements: () => elements,
    getStripe: () => stripe,
  }));

  return (
    <PaymentElement
      options={options}
      onReady={onReady}
      onLoaderror={onLoaderror}
      className="ioioio"
      {...otherProps}
    />
  );
});

StripeForm.displayName = 'StripeForm';

StripeForm.propTypes = {
  onReady: PropTypes.func,
  onLoaderror: PropTypes.func,
  options: PropTypes.shape(),
};

StripeForm.defaultProps = {
  onReady: Function.prototype,
  onLoaderror: Function.prototype,
  options: {},
};

export default StripeForm;
