import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Box, Paragraph, Heading, PaymentMethod, Icon, Loader } = designSystem;

const PlanPaymentDetails = ({ captions, paymentMethod, shouldBeShownDiscount, buttonProps }) => {
  const getRenewalPrice = () => {
    if (shouldBeShownDiscount) {
      return (
        <React.Fragment>
          <Paragraph size="small" appearance="medium-emphasis">
            <s>{captions.basePrice}</s>
          </Paragraph>
          <Heading type="heading-4" appearance="success">
            {captions.price}
          </Heading>
          <Paragraph size="small" appearance="success">
            {captions.discountExpiry}
          </Paragraph>
        </React.Fragment>
      );
    }

    return <Heading type="heading-4">{captions.price}</Heading>;
  };

  return (
    <div className="ws-plan-payment-details">
      <Box marginTop="spacing-m" marginBottom="spacing-m">
        <div className="ws-plan-payment-details__container">
          <div className="ws-plan-payment-details__item">
            <Box paddingLeft="spacing-m" paddingRight="spacing-m">
              <Box marginBottom="spacing-2-xs">
                <Paragraph size="small" appearance="medium-emphasis">
                  {captions.renewalPrice}
                </Paragraph>
              </Box>
              {getRenewalPrice()}
            </Box>
          </div>
          <div className="ws-plan-payment-details__item">
            <Box paddingLeft="spacing-m" paddingRight="spacing-m">
              <Box marginBottom="spacing-2-xs">
                <Paragraph size="small" appearance="medium-emphasis">
                  {captions.planRenewal}
                </Paragraph>
              </Box>
              <Paragraph>{captions.dateLabel}</Paragraph>
              {captions.date && <Paragraph>{captions.date}</Paragraph>}
              {buttonProps &&
                (buttonProps.isLoading ? (
                  <div className="ws-plan-payment-details__loader">
                    <Loader />
                  </div>
                ) : (
                  <Paragraph size="small">
                    {/* eslint-disable-next-line yola/jsx-a11y/anchor-is-valid */}
                    <a href="#" onClick={buttonProps.onClick}>
                      {buttonProps.caption}
                    </a>
                  </Paragraph>
                ))}
            </Box>
          </div>
          <div className="ws-plan-payment-details__item">
            <Box paddingLeft="spacing-m" paddingRight="spacing-m">
              <Box marginBottom="spacing-2-xs">
                <Paragraph size="small" appearance="medium-emphasis">
                  {captions.paymentMethod}
                </Paragraph>
              </Box>
              {paymentMethod ? (
                <div className="ws-plan-payment-details__payment-method">
                  <PaymentMethod size="small" type={paymentMethod} />
                  {captions.paymentMethodTitle && (
                    <Paragraph>{captions.paymentMethodTitle}</Paragraph>
                  )}
                </div>
              ) : (
                <Icon glyph="minus" type="inherit" />
              )}
            </Box>
          </div>
        </div>
      </Box>
    </div>
  );
};

PlanPaymentDetails.propTypes = {
  captions: PropTypes.shape({
    price: PropTypes.string.isRequired,
    basePrice: PropTypes.string.isRequired,
    discountExpiry: PropTypes.string.isRequired,
    dateLabel: PropTypes.string.isRequired,
    date: PropTypes.string,
    paymentMethodTitle: PropTypes.string,
    renewalPrice: PropTypes.string.isRequired,
    planRenewal: PropTypes.string.isRequired,
    paymentMethod: PropTypes.string.isRequired,
  }).isRequired,
  paymentMethod: PropTypes.string,
  shouldBeShownDiscount: PropTypes.bool,
  buttonProps: PropTypes.shape({
    caption: PropTypes.string,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
  }),
};

PlanPaymentDetails.defaultProps = {
  paymentMethod: '',
  shouldBeShownDiscount: false,
  buttonProps: null,
};

export default PlanPaymentDetails;
