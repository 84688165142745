import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import SectionInfo from 'src/js/common/components/section-info';
import { CHECKBOX_GROUP_NAME } from '../../constants/email-subscription-options';

const { ActionButton, Stack, Box, NotificationMessage, CheckboxGroupField } = designSystem;

function EmailSubscriptionsSection(props) {
  const { control, captions, options, isLoading, onSubmit, notification, onChange } = props;

  return (
    <SectionInfo title={captions.title}>
      <Stack gap="spacing-m">
        {notification && (
          <NotificationMessage title={notification.title} appearance={notification.appearance} />
        )}
        <CheckboxGroupField
          name={CHECKBOX_GROUP_NAME}
          control={control}
          options={options}
          onChange={onChange}
        />
        <Box>
          <ActionButton
            appearance="accent"
            format="solid"
            onClick={onSubmit}
            isLoading={isLoading}
            fontWeight="regular"
            label={captions.saveButton}
            size="large"
          />
        </Box>
      </Stack>
    </SectionInfo>
  );
}

EmailSubscriptionsSection.propTypes = {
  control: PropTypes.object.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    saveButton: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string.isRequired, label: PropTypes.string.isRequired })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  notification: PropTypes.shape({
    title: PropTypes.string,
    appearance: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

EmailSubscriptionsSection.defaultProps = {
  notification: null,
  onChange: Function.prototype,
};

export default EmailSubscriptionsSection;
