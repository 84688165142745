import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { AlertDialog } from '@yola/ws-ui';
import dialogs from '../../dialogs';

function DomainSearchFailureDialog({ onDialogCancel }) {
  const dispatch = useDispatch();

  const captions = useMemo(
    () => ({
      title: i18next.t('Domain search failed'),
      description: i18next.t('Please try again later.'),
      dismiss: i18next.t('Dismiss'),
    }),
    []
  );

  const handleDismiss = () => {
    onDialogCancel();
    dispatch(dialogs.actions.hide());
  };

  return <AlertDialog captions={captions} onDismiss={handleDismiss} height="auto" />;
}

DomainSearchFailureDialog.propTypes = {
  onDialogCancel: PropTypes.func,
};

DomainSearchFailureDialog.defaultProps = {
  onDialogCancel: Function.prototype,
};

export default DomainSearchFailureDialog;
