import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Box, Stack, Heading, Paragraph, ActionButton } = designSystem;

const SubscriptionPromo = (props) => {
  const { captions, items, onSubmit } = props;

  return (
    <div className="ws-subscription-promo">
      <Box padding="spacing-xl">
        <Box marginBottom="spacing-s">
          <Heading type="heading-3">{captions.title}</Heading>
        </Box>
        <Stack gap="spacing-xs">
          {items.map(({ id, IconComponent, title, description }) => (
            <div key={id} className="ws-subscription-promo__item">
              <IconComponent />
              <Paragraph>
                <strong>{title}</strong> — {description}
              </Paragraph>
            </div>
          ))}
        </Stack>
        <Box marginTop="spacing-l">
          <ActionButton
            appearance="cta"
            format="solid"
            size="large"
            label={captions.upgradeButton}
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </div>
  );
};

SubscriptionPromo.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    upgradeButton: PropTypes.string.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      IconComponent: PropTypes.element.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

SubscriptionPromo.defaultProps = {};

export default SubscriptionPromo;
