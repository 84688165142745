import toQueryString from 'src/js/common/helpers/to-query-string';
import supportedAccountTabs from '../../modules/account-management/constants/supported-tabs';

export default {
  paywall: {
    patterns: ['/upgrade'],
    url: ({ query } = {}) => `/upgrade${toQueryString(query)}`,
  },
  ecommercePaywall: {
    patterns: ['/upgrade-ecommerce'],
    url: ({ query } = {}) => `/upgrade-ecommerce${toQueryString(query)}`,
  },
  domainSelector: {
    patterns: ['/domain-name-search'],
    url: ({ query } = {}) => `/domain-name-search${toQueryString(query)}`,
  },
  dashboard: {
    url: () => '/dashboard',
    patterns: ['/dashboard/', '/sites/', '/'],
  },
  home: {
    patterns: ['/'],
    url: () => '/',
  },
  initiateUpgrade: {
    patterns: ['/initiate-upgrade'],
    url: ({ query } = {}) => `/initiate-upgrade${toQueryString(query)}`,
  },
  subscriptionDetails: {
    patterns: ['/account/subscriptions/:subId'],
    url: ({ subId }) => `/account/subscriptions/${subId}`,
  },
  accountManagement: {
    patterns: [`/account/:tabId(${Object.values(supportedAccountTabs).join('|')})`],
    url: ({ tabId }) => `/account/${tabId}`,
  },
  billingHistory: {
    patterns: [`/account/${supportedAccountTabs.BILLING_HISTORY}/:pageId?`],
    url: ({ pageId }) => `/account/${supportedAccountTabs.BILLING_HISTORY}/${pageId}`,
  },
  addPaymentMethod: {
    patterns: [`/account/${supportedAccountTabs.PAYMENT_METHODS}/create`],
    url: () => `/account/${supportedAccountTabs.PAYMENT_METHODS}/create`,
  },
  editPaymentMethod: {
    patterns: [`/account/${supportedAccountTabs.PAYMENT_METHODS}/:methodId/edit`],
    url: (methodId) => `/account/${supportedAccountTabs.PAYMENT_METHODS}/${methodId}/edit`,
  },
  deletePaymentMethod: {
    patterns: [`/account/${supportedAccountTabs.PAYMENT_METHODS}/:methodId/delete`],
    url: (methodId) => `/account/${supportedAccountTabs.PAYMENT_METHODS}/${methodId}/delete`,
  },
  setAsDefaultPaymentMethod: {
    patterns: [`/account/${supportedAccountTabs.PAYMENT_METHODS}/:methodId/set-as-default`],
    url: (methodId) =>
      `/account/${supportedAccountTabs.PAYMENT_METHODS}/${methodId}/set-as-default`,
  },
  cancelSubscriptions: {
    patterns: ['/account/subscriptions/cancel'],
    url: () => '/account/subscriptions/cancel',
  },
  cancelSubscription: {
    patterns: ['/account/subscriptions/:subId/cancel'],
    url: ({ subId }) => `/account/subscriptions/${subId}/cancel`,
  },
  profile: {
    patterns: ['/account/profile/'],
    url: () => '/account/profile',
  },
};
