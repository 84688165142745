import React from 'react';
import PropTypes from 'prop-types';
import { Modal, designSystem } from '@yola/ws-ui';
import i18next from 'i18next';
import status from '../../../status';
import { PAYMENT_METHOD_ITEM_PROP_TYPES } from '../payment-method-item/constants';
import PaymentMethodItem from './components/payment-method-item';

const {
  DialogHeader,
  DialogHeaderTitle,
  ActionButton,
  ModalButtonGroup,
  Box,
  Paragraph,
  DialogHeaderControls,
  DialogHeaderIconButton,
  Stack,
  Divider,
} = designSystem;

const MODAL_WIDTH = 600;

const getCaptions = (captionsOverrides) => ({
  title: i18next.t('Set default payment method'),
  description: i18next.t(
    "Auto-bill service agreement(s) will renew until you cancel or change plan. You can cancel your plan at any time and won't be billed for the next billing cycle"
  ),
  submitButton: i18next.t('Set as default'),
  cancelButton: i18next.t('Cancel'),
  ...captionsOverrides,
});

const SetDefaultPaymentMethodDialog = (props) => {
  const { paymentMethod, onSubmit, onCancel, statusName, captions: captionsOverrides } = props;
  const targetStatus = status.hooks.useStatus(statusName);
  const isLoading = targetStatus === status.constants.LOADING;

  const captions = getCaptions(captionsOverrides);

  return (
    <Modal
      isBodyScrollDisabled
      width={MODAL_WIDTH}
      maxWidth={MODAL_WIDTH}
      minWidth={MODAL_WIDTH}
      height="auto"
      centered
      resizable={false}
      draggable={false}
      overlay="visible"
      className="ws-account-management-modal"
    >
      <DialogHeader>
        <DialogHeaderTitle>{captions.title}</DialogHeaderTitle>
        <DialogHeaderControls>
          <DialogHeaderIconButton onClick={onCancel} disabled={isLoading} glyph="close" />
        </DialogHeaderControls>
      </DialogHeader>
      <Divider />
      <Box
        marginTop="spacing-s"
        marginRight="spacing-m"
        marginBottom="spacing-s"
        marginLeft="spacing-m"
      >
        <Stack gap="spacing-m">
          <PaymentMethodItem type={paymentMethod.iconType} captions={paymentMethod.captions} />
          <Paragraph appearance="medium-emphasis">{captions.description}</Paragraph>
        </Stack>
      </Box>
      <ModalButtonGroup direction="right">
        <ActionButton
          appearance="accent"
          format="solid"
          label={captions.submitButton}
          onClick={onSubmit}
          isLoading={isLoading}
        />
        <ActionButton label={captions.cancelButton} disabled={isLoading} onClick={onCancel} />
      </ModalButtonGroup>
    </Modal>
  );
};

SetDefaultPaymentMethodDialog.propTypes = {
  paymentMethod: PropTypes.shape(PAYMENT_METHOD_ITEM_PROP_TYPES).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  statusName: PropTypes.string.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    submitButton: PropTypes.string,
    cancelButton: PropTypes.string,
  }),
};

SetDefaultPaymentMethodDialog.defaultProps = {
  captions: {},
};

export default SetDefaultPaymentMethodDialog;
