import store from 'src/js/store';
import analytics from 'src/js/modules/analytics';
import config from 'src/js/modules/config';
import user from 'src/js/modules/user';
import features from 'src/js/modules/features';
import subscriptions from 'src/js/modules/subscriptions';
import i18n from 'src/js/modules/i18n';
import sites from 'src/js/modules/sites';
import dialogs from 'src/js/modules/dialogs';
import segment from './modules/analytics/segment';
import products from './modules/products';
import zendesk from './modules/zendesk';

const initApp = async () => {
  await store.dispatch(config.thunks.fetchConfig());
  analytics.sentry.instance.init(config.selectors.getSentryDSN(store.getState()));
  user.helpers.configureYousersJS(store);
  subscriptions.helpers.configureSubscriptionsManager(store);

  await store.dispatch(user.thunks.fetchUserData());

  try {
    await Promise.all([
      store.dispatch(user.thunks.fetchUserPreferences()),
      store.dispatch(user.thunks.fetchPartnerData()),
    ]);
  } catch (error) {
    console.error(error);
    return;
  }

  await Promise.all([
    store.dispatch(features.thunks.fetchAvailablePackages()),
    store.dispatch(features.thunks.fetchEcommercePackages()),
    store.dispatch(products.thunks.fetchPrices()),
  ]);
  await i18n.initializers.initializeI18next();
  dialogs.initializers.registerDialogs();

  await store.dispatch(i18n.thunks.loadTranslations());
  await Promise.all([
    store.dispatch(subscriptions.thunks.fetchSubscriptions()),
    store.dispatch(subscriptions.thunks.fetchCurrentPackage()),
  ]);
  store.dispatch(sites.thunks.loadSites());
  segment.trackers.trackAppOpened();
  zendesk.initializers.initializeZendesk(store);
};

export default initApp;
