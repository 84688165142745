import serviceClient from '../service-client';
import statusNames from '../constants/status-names';
import status from '../../status';
import selectors from '../selectors';
import actions from '../actions';
import getUpdatedBillingProfiles from '../helpers/get-updated-billing-profiles';
import resolveServiceError from '../../../common/helpers/resolve-service-error';

const handleBillingProfilesUpdate = (deletedId, dispatch, state) => {
  const currentProfiles = selectors.getBillingProfiles(state);
  const newProfiles = getUpdatedBillingProfiles(currentProfiles, { toRemove: { id: deletedId } });
  dispatch(actions.setBillingProfiles(newProfiles));
};

const deleteBillingProfile = (id) => async (dispatch, getState) => {
  const state = getState();
  const yoproducts = serviceClient.get();

  dispatch(status.actions.setStatus(statusNames.DELETE_BILLING_PROFILE, status.constants.LOADING));

  try {
    await yoproducts.requests.deleteBillingProfile(id);

    dispatch(
      status.actions.setStatus(statusNames.DELETE_BILLING_PROFILE, status.constants.SUCCEEDED)
    );

    const billingProfilesStatus = selectors.getBillingProfilesStatus(state);

    if (billingProfilesStatus === status.constants.SUCCEEDED) {
      handleBillingProfilesUpdate(id, dispatch, state);
    }
  } catch (e) {
    const error = await resolveServiceError(e);

    console.error(error);
    dispatch(status.actions.setStatus(statusNames.DELETE_BILLING_PROFILE, status.constants.FAILED));

    throw error;
  }
};

export default deleteBillingProfile;
