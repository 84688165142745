import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { AlertDialog } from '@yola/ws-ui';
import user from 'src/js/modules/user';
import setCriticalError from '../actions/set-critical-error';
import getCriticalError from '../selectors/critical-error';

const getCaptions = () => ({
  defaultCaptions: {
    title: i18next.t('Something went wrong'),
    description: i18next.t(
      'Possible service interruption. Please reload the page. Contact customer support if issue persists.'
    ),
    submit: i18next.t('Reload'),
    dismiss: i18next.t('Contact support'),
  },
});

class ErrorBoundary extends React.Component {
  static reloadPage() {
    window.location.reload();
  }

  constructor(props) {
    super(props);

    this.navigateToSupport = this.navigateToSupport.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    const { setError } = this.props;

    setError({
      error,
      errorInfo,
    });
  }

  navigateToSupport() {
    const { contactSupportUrl } = this.props;

    window.location.assign(contactSupportUrl);
  }

  render() {
    const { children, criticalError, contactSupportUrl } = this.props;
    const supportRef = Boolean(contactSupportUrl) || null;
    const { defaultCaptions: captions } = getCaptions();

    if (criticalError) {
      return (
        <AlertDialog
          captions={captions}
          glyph="alert-circle"
          onDismiss={supportRef && this.navigateToSupport}
          onSubmit={ErrorBoundary.reloadPage}
        />
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  contactSupportUrl: PropTypes.string,
  criticalError: PropTypes.shape(),
  setError: PropTypes.func.isRequired,
};

ErrorBoundary.defaultProps = {
  criticalError: null,
  contactSupportUrl: null,
};

const mapStateToProps = (state) => ({
  criticalError: getCriticalError(state),
  contactSupportUrl: user.selectors.getContactSupportUrl(state),
});

const mapDispatchToProps = {
  setError: setCriticalError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
